<app-header-options
(updateInfo)="getPurchaseOrderLines()"
(search)="searchData($event)"
[isLoading]="isLoading"
[buttonCreateVisible]="buttonCreateVisible"
[PermissionList]="permissionList"
>
</app-header-options>

<div class="row" *ngIf="mode == 1 && OrderNumber!= '' else mode0">
    <div class="col-md-12">
        <h4>{{'purchase_order' | translate}} #{{OrderNumber}}</h4>
    </div>
</div>

<ng-template #mode0>
    <div class="row" *ngIf="mode == 0 && objRow != null">
        <div class="col-md-12">
            <h4>{{'purchase_order' | translate}} #{{objRow.OrderNumber}}</h4>
        </div>
    </div>
</ng-template>


<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i class="icon mdi mdi-filter-remove" *ngIf="showIconF" (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i class="icon mdi mdi-filter" *ngIf="!showIconF" (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="ID">ID</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="OC">{{'purchase_order_line' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="Division">{{'Division' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="SupplierContract">{{'SupplierContract' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="Supplier">{{'Supplier' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="ItemName">{{'ItemName' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="QuantityRequired">{{'QuantityRequired' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="Status">{{'Status' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="GrossWeightDispatched">{{'GrossWeightDispatched' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="EtdReal">{{'EtdReal' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="EtaReal">{{'EtaReal' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="Bl">{{'Bl' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="Invoice">{{'Invoice' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label  class="text-nowrap" name="IncoTerm">{{'IncoTerm' | translate}}</label>
                        </th>
                        <th class="sh">
                         <label  class="text-nowrap" name="actions"> {{'actions' | translate}}</label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!--Filtros de Table-->
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>
                        <td>

                            <div (click)="resetData(0,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="arrayList[0]" [indexPosition]="0" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="arrayList[1]" [indexPosition]="1" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'SupplierContract')">
                                <app-select-filter [column]="'SupplierContract'" [arrayList]="arrayList[2]" [indexPosition]="2" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="arrayList[3]" [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                         <td>
                            <div (click)="resetData(4,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="arrayList[4]" [indexPosition]="4" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'GrossWeight')">
                                <app-select-filter [column]="'GrossWeight'" [arrayList]="arrayList[5]" [indexPosition]="5" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'Status')">
                                <app-select-filter [column]="'Status'" [arrayList]="arrayList[6]" [indexPosition]="6" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'GrossWeightDispatched')">
                                <app-select-filter [column]="'GrossWeightDispatched'" [arrayList]="arrayList[7]" [indexPosition]="7" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'EtdReal')">
                                <app-select-filter [column]="'EtdReal'" [arrayList]="arrayList[8]" [indexPosition]="8" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="arrayList[9]" [indexPosition]="9" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'Bl')">
                                <app-select-filter [column]="'Bl'" [arrayList]="arrayList[10]" [indexPosition]="10" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'Invoice')">
                                <app-select-filter [column]="'Invoice'" [arrayList]="arrayList[11]" [indexPosition]="11" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'IncoTerm')">
                                <app-select-filter [column]="'IncoTerm'" [arrayList]="arrayList[12]" [indexPosition]="12" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>

                    <!--Detalle de Table-->
                    <ng-container  *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i" >
                        <tr>
                            <td>
                                <div>
                                    <i class="icon mdi mdi-arrow-down-bold-circle" *ngIf="hidden[i]" (click)="hidden[i] = !hidden[i]"></i>
                                    <i class="icon mdi mdi-arrow-right-bold-circle" *ngIf="!hidden[i]" (click)="seeDetailsRow(i, row)"></i>
                                </div>
                            </td>
                            <td>
                                <label for="code">{{i + 1}}</label>
                            </td>
                            <td>
                                <label for="OrderDisplay">{{row.OrderDisplay}}</label>
                            </td>
                            <td>
                                <label for="Division">{{row.Division}}</label>
                            </td>
                            <td>
                                <label for="SupplierContract">{{row.SupplierContract}}</label>
                            </td>
                            <td>
                                <label for="CompanyName">{{row.CompanyName}}</label>
                            </td>
                            <td>
                                <label for="ItemName">{{row.ItemName}}</label>
                            </td>
                            <td>
                                <label for="GrossWeight">{{row.GrossWeight  | number: '1.0-0'  }}</label>
                            </td>
                            <td>
                                <label for="Status">{{row.Status}}</label>
                            </td>
                            <td>
                                <label for="GrossWeightDispatched">{{row.GrossWeightDispatched  | number: '1.0-0' }}</label>
                            </td>
                            <td>
                                <label for="EtdReal">{{row.EtdReal | date:'y-MM-d'}}</label>
                            </td>
                            <td>
                                <label for="EtaReal">{{row.EtaReal | date:'y-MM-d'}}</label>
                            </td>
                            <td>
                                <label for="Bl">{{row.Bl}}</label>
                            </td>
                            <td>
                                <label for="Invoice">{{row.Invoice}}</label>
                            </td>
                            <td>
                                <label for="IncoTerm">{{row.IncoTerm}}</label>
                            </td>
                            <td>
                              <!-- <i *ngIf="(row.Containers >0 )" (click)="globalService.goToParameterDetail(this.urlDetail+ row.OrderLineId)" style="cursor:pointer;" class="far fa-file-alt text-warning m-r-10" title="{{this.urlDetail }}"></i> -->
                              <i *ngIf="(row.Containers >0 )" (click)="redirect(row)" style="cursor:pointer;" class="far fa-file-alt text-warning m-r-10" title="{{this.urlDetail }}"></i>
                            </td>
                        </tr>
                        <tr *ngIf="hidden[i]">
                            <td></td>
                            <td colspan="17">
                                <app-container-detail-pol [obj]="row" [user]="user" [index]="i" [userType]="userType" (refreshData)="refreshData($event)"></app-container-detail-pol>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <td colspan="17">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>
