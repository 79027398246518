import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';;
import { ApiService } from 'src/app/services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { GlobalService } from '../../../../services/main/global.service';
import { AuthService } from '../../../../services/authentication/auth.service';

@Component({
  selector: 'app-report-import-expenses',
  templateUrl: './report-import-expenses.component.html',
  styleUrls: ['./report-import-expenses.component.css']
})
export class ReportImportExpensesComponent extends BaseCrudComponent implements OnInit {
  //Filter
  showIconF = false;
  dataSearchList = [];
  private workbook: Workbook;
  private lista_titulos;
  wareHouses = [];
  CompanyTrans = [];

  @ViewChild('thead', { static: true }) thead: ElementRef;
  userInfo;
  option_processes = null;
  origenList = environment.Global.COUNTRY;
  date_init = null;
  date_end = null;
  type_product = "";
  name = 'Imports';
  list_processes = [{ title: 'purchase_orders', id: 1 }, { title: 'Dispatchs', id: 2 }, { title: 'submenu_skretting_payments', id: 3 },
  { title: 'submenu_skretting_purchase_order_detail_line', id: 4 }, { title: 'ImportCustomAgent', id: 5 },
  // { title: 'TributaryCredit', id: 6 }, { title: 'CustomsAclarance', id: 7 },
  { title: 'ImportCosts', id: 8 }, { title: 'ProvisionVSReal', id: 9 }, { title: 'ArrivalByTypeProduct', id: 10 },
  { title: 'AssignedContainers', id: 11 }
  ];
  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = false;
  isSkretting = false;
  isClean = false;
  pageSize = 10;
  flag = false;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  col_value = 0;
  url_report_ = '';
  fontSize: number = 0;
  marginLeft: number = 30;
  l = [];
  constructor(
    public GlobalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService,

  ) {
    super(srv, modalService, storage, router);
    this.option_processes = 8;
    // this.search_fields = ['TypeProduct', 'AgentName', 'UserAgentName', 'OrderNumber', 'ItemNumber', 'Origen', 'PackagingName'];

  }


  async ngOnInit() {
    await super.ngOnInit();
    this.user = this._authService.getUserLogged();
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
    await this.getCompanyTransport();
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
      });
    /*this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(this.userInfo)
      if (isNumeric(this.userInfo.logon)) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });*/
    //this.getCompanies();
    if (this.request_selected) {
      this.disabledCompany = false;
      this.disabledRuc = false;
    } else {
      this.disabledCompany = true;
      this.disabledRuc = true;
    }
  }

  public async getCompanyTransport() {
    this.url = environment.Global.API_GET_ACTIVE_TRS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.CompanyTrans = result.Object
        //console.log("Bodegas:",this.CompanyTrans)
      }
    })
  }
  clearFilters() {
    if (this.option_processes == null) {
      this.option_processes = null;
    }
    this.options_company = null;
    this.options_ruc = null;
    this.date_end = null;
    this.date_init = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.url_report_ = '';
    this.disabledCompany = true;
    this.disabledRuc = true;
    // console.log(this.options_company);
    // console.log(this.options_ruc);
  }
  dataToFind() {
    this.isFind = true;
    this.showIconF = false;

    if (this.option_processes === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }
    if (this.date_init == null || this.date_end == null) {
      this.isFind = false;
      return Swal.fire("Error", this.translations.msg_error_empty_date, 'error');
    }


    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    this.setUrlToFindData();
  }
  async setUrlToFindData() {
    let url = '';
    // console.log(this.option_processes)
    switch (Number(this.option_processes)) {
      case 1:
        //console.log('entro aqui 1', this.userInfo.logon)
        url = environment.Global.GET_ALL_PUR_ORD;
        this.search_fields = ['OrderNumber', 'Supplier', 'GrossWeightOrder', 'CreationTime', 'UserName', 'AgentName'];
        /*if (isNumeric(this.userInfo.logon)) {
          url = environment.Global.GET_ALL_REQUEST_INDUCTION_BY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          url = environment.Global.GET_ALL_REQUEST_INDUCTION;
          this.isSkretting = true;
        }*/
        break;
      case 2:
        // console.log('entro aqui 2')
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        //  console.log("URL:", url);
        this.search_fields = ['TypeProduct', 'Division', 'AgentName', 'UserAgentName', 'OrderNumber', 'DealNumber', 'ItemNumber', 'Origen', 'PackagingName'];
        break;
      case 3:
        // Pago a proveedores
        url = environment.Global.API_GET_PAYMENTS;
        this.search_fields = ['ItemName', 'OrderNumber', 'Div', 'IssueDate', 'UserAgentName', 'InvoiceNumber', 'Supplier'];
        // console.log("URL:", url);

        break;
      case 4:
        //Contenedores por despacho
        url = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
        this.search_fields = ['AgentName', 'Agent', 'EtaRequired', 'OrderNumber', 'CompanyName', 'PackagingName', 'ItemName'];
        break;

      case 5:
        // Importación de agente de Aduana
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['ProcedureImportline', 'Division', 'Invoice', 'InvoiceDate', 'ItemNumber', 'ItemName', 'TariffHeading', 'Status', 'CompanyName'];
        // console.log("URL:", url);
        break;

      case 6:
        // ISD Tributary Credit
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['OrderNumber', 'Invoice', 'CustomsAclarance', 'DAI', 'TariffHeading', 'QuantityLiquidation', 'CompanyName'];
        // console.log("URL:", url);
        break;
      // Liquidaciones Aduaneras del mes


      case 8:
        // Imports cost
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['OrderNumber', 'Division', 'OrderDisplay', 'Invoice', 'InvoiceDate', 'CompanyName', 'ItemName'];
        // console.log("URL:", url);
        break;
      case 9:
        // <!-- Provision vs Real  -->
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['OrderNumber', 'Division', 'OrderDisplay', 'CompanyName', 'ItemName'];

        break;
      case 10:
        url = environment.Global.GET_ALL_SUM_PUR_ORDER_PRODUCT_TYPE;
        this.search_fields = ['TypeProduct', 'ItemNumber', 'ItemName', 'GrossWeightDispatched'];

        break;
      case 11:
        //Contenedores por despacho
        url = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
        this.search_fields = ['DateDepartureReception'];
        break;

    }
    // console.log(url);
    this.isFind = false;
    this.getListFromApi(url);
  }
  getListFromApi(url) {
    // console.log(this.option_processes);
    this.isFind = true;
    // console.log(url)
    let list_tmp = [];
    // console.log( this.date_init, this.date_end, this.option_processes,this.options_ruc, this.options_company);
    this.messageShowLoading();
    this.srv.getCall(url).subscribe(x => {
      if (x.Status) {
        if (x.Object != null) {
          // console.log(x.Object);
          this.rawdata = x.Object;
          let new_list = [];
          list_tmp = x.Object;

          list_tmp.forEach(element => {
            element['PlantPrice'] = this.setMaskNumber(Number(element['PlantPrice']));
            element['PlantPriceReal'] = this.setMaskNumber(Number(element['PlantPriceReal']));
            element['GrossWeightOrder'] = this.setMaskNumber(Number(element['GrossWeightOrder']));
            element['GrossWeight'] = this.setMaskNumber(Number(element['GrossWeight']));
            element['PurchasePrice'] = this.setMaskNumber(Number(element['PurchasePrice']));
            element['GrossWeightDispatched'] = this.setMaskNumber(Number(element['GrossWeightDispatched']));
          });
          if ((!(this.date_init === null) && !(this.date_end === null))
            && this.option_processes !== null) {
            // console.log('Primer filtro')
            if (this.option_processes == 1) {
              // console.log("parametros:",)
              list_tmp.forEach(tmp => {
                tmp.CreationTime = this.formatOnlyDate(new Date(tmp.CreationTime));
                if (tmp.CreationTime >= this.date_init && tmp.CreationTime <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 3) {
              list_tmp.forEach(tmp => {
                tmp.IssueDate = this.formatOnlyDate(new Date(tmp.IssueDate));
                if (tmp.IssueDate >= this.date_init && tmp.IssueDate <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 4) {
              list_tmp.forEach(tmp => {
                tmp.RescheduleDate = this.formatOnlyDate(new Date(tmp.RescheduleDate));
                tmp.DateDepartureReception = this.formatOnlyDate(new Date(tmp.DateDepartureReception));
                if (tmp.RescheduleDate >= this.date_init && tmp.RescheduleDate <= this.date_end || tmp.DateDepartureReception >= this.date_init && tmp.DateDepartureReception <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 5) {
              list_tmp.forEach(tmp => {
                tmp.InvoiceDate = this.formatOnlyDate(new Date(tmp.InvoiceDate));
                if (tmp.InvoiceDate >= this.date_init && tmp.InvoiceDate <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 8) {
              list_tmp.forEach(tmp => {
                tmp.InvoiceDate = this.formatOnlyDate(new Date(tmp.InvoiceDate));
                if (tmp.InvoiceDate >= this.date_init && tmp.InvoiceDate <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            }
            else if (this.option_processes == 9 || this.option_processes == 2 || this.option_processes == 10) {

              list_tmp.forEach(tmp => {
                tmp.EtaReal = this.formatOnlyDate(new Date(tmp.EtaReal));
                if (tmp.EtaReal >= this.date_init && tmp.EtaReal <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 11) {
              list_tmp.forEach(tmp => {
                tmp.DateDepartureReception = this.formatOnlyDate(new Date(tmp.DateDepartureReception));
                if (tmp.DateDepartureReception >= this.date_init && tmp.DateDepartureReception <= this.date_end) {
                  new_list.push(tmp);
                }
              })

            }

            this.rawdata = new_list;
            this.rows = new_list;
          }


          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.closeMessageDialog();
          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      } else {
        this.isFind = false;
        Swal.fire(x.Message, '', 'error');
        return;
      }
    });

  }

  public async GetWarehouseActive() {
    this.url = environment.Global.API_GET_ACTIVE_WH;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.wareHouses = result.Object
        //console.log("Bodegas:",this.wareHouses)
      }
    })
  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }
  selectRequest() {
    this.request_selected = true;
    this.rows = [];
    this.page = 0;
    this.pageTotal = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
    this.url_report_ = '';
    if (this.isSkretting) {
      if (this.option_processes == 1) {
        this.col_value = 7
      } /*else if (this.option_processes == 2) {
      this.col_value = 8
    } else if (this.option_processes == 3) {
      this.col_value = 8
    }*/
    } else {
      if (this.option_processes == 1) {
        this.col_value = 6
      } /*else if (this.option_processes == 2) {
      this.col_value = 7
    } else if (this.option_processes == 3) {
      this.col_value = 8
    }*/
    }

    if (this.option_processes == 1) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD;//Global.GET_REPORT_REQUEST;
    } else if (this.option_processes == 2) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 3) {
      this.url_report_ = environment.Global.API_GET_PAYMENTS;
    } else if (this.option_processes == 4) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
    } else if (this.option_processes == 5) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 6) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 8) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 9) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 10) {
      this.url_report_ = environment.Global.GET_ALL_SUM_PUR_ORDER_PRODUCT_TYPE;
    } else if (this.option_processes == 11) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
    }

    this.options_ruc = null;
    this.options_company = null;
    this.date_end = null;
    this.date_init = null;
  }


  async reports() {
    this.l = [];
    this.rows.forEach(element => {
      if (this.rows !== null && this.rows !== ' ') {
        if (this.option_processes == 1) {
          let obj = ['', '', '', '', '', '', '']//,'',''
          obj[0] = element.OrderNumber;
          obj[1] = element.Supplier;
          obj[2] = element.CompanyName;
          obj[3] = element.GrossWeightOrder;
          obj[4] = element.CreationTime.split('T')[0];
          obj[5] = element.UserName;
          obj[6] = element.AgentName;
          //obj[7] = element.UserName;
          //obj[8] = element.AgentName;
          /*if (evals.status_end == 'AP'){
            obj[9] = 'APROBADOS';
          } else if (evals.status_end == 'RP'){
            obj[9] = 'REPROBADOS';
          }*/
          this.l.push(obj)

        } else if (this.option_processes == 2) {
          let obj = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']

          obj[0] = element.UserName;
          obj[1] = element.AgentName;
          obj[2] = element.UserAgentName;
          obj[3] = element.ProcedureImportline;
          obj[4] = element.IsdException;
          obj[5] = element.DealNumber;
          obj[6] = element.GrossWeightOrder;
          obj[7] = element.OrderNumber;
          obj[8] = element.Division;
          obj[9] = element.OrderDisplay;
          obj[10] = element.Supplier;
          obj[11] = element.CompanyName;
          obj[12] = element.TypeProduct;
          obj[13] = element.ItemNumber;
          obj[14] = element.ItemName;
          obj[15] = element.SupplierContract;
          obj[16] = element.IncoTerm;
          obj[17] = element.Origen;
          obj[18] = element.PackagingName;
          obj[19] = element.EtaReal == null ? "" : element.EtaReal.split('T')[0];
          this.l.push(obj);

        } else if (this.option_processes == 3) {
          let obj = ['', '', '', '', '', '', '', '']//,'',''
          obj[0] = element.OrderNumber;
          obj[1] = element.Dispatch;
          obj[2] = element.Div;
          obj[3] = element.Supplier;
          obj[4] = element.InvoiceNumber;
          obj[5] = element.IssueDate == null ? "" : element.IssueDate.split('T')[0];
          obj[6] = element.AmountLine;
          obj[7] = element.SubConcept;
          this.l.push(obj);

        } else if (this.option_processes == 4) {
          let obj = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']//,'',''
          obj[0] = element.OrderNumber;
          obj[1] = element.Division == null ? "" : element.Division;
          obj[2] = element.OrderDisplay;
          obj[3] = element.ContainerNumber;

          obj[4] = element.DateDepartureReception == null ? "" : element.DateDepartureReception.split('T')[0];
          // obj[5] = element.HourDepartureReception == null ? "" : element.HourDepartureReception.split('T')[0];
          obj[5] = element.RescheduleDate == null ? "" : element.RescheduleDate.split('T')[0];
          // obj[7] = element.RescheduleHour == null ? "" : element.RescheduleHour.split('T')[0];
          obj[6] = element.Agent;
          obj[7] = element.UserIdsNames == null ? "" : element.UserIdsNames;
          obj[8] = element.EtaRequired == null ? "" : element.EtaRequired.split('T')[0];
          obj[9] = element.CAS == null ? "" : element.EtaRequired.split('T')[0];
          obj[10] = element.PortShipment;
          obj[11] = element.NameResposabilitySK;
          obj[12] = element.CompanyName;
          obj[13] = element.ItemName;
          obj[14] = element.PackagingName;
          obj[15] = element.Bl;
          obj[16] = element.BlSeal;
          obj[17] = element.ProductWeight;
          this.l.push(obj);

        } else if (this.option_processes == 5) {
          let obj = ['', '', '', '', '', '', '', '']//,'',''

          obj[0] = element.Division;
          obj[1] = element.ProcedureImportline;
          obj[2] = element.ProvisionedFI;
          obj[3] = element.Invoice;
          obj[4] = element.InvoiceDate == null ? "" : element.InvoiceDate.split('T')[0]
          obj[5] = element.PurchasePrice;
          obj[6] = element.ItemNumber;
          obj[7] = element.ItemName;
          obj[8] = element.TariffHeading;
          obj[9] = element.Status;
          obj[10] = element.CompanyName;

          this.l.push(obj);
        } else if (this.option_processes == 6) {
          let obj = ['', '', '', '', '', '', '', '']//,'',''

          obj[0] = element.CompanyName;
          obj[1] = element.OrderNumber;
          obj[2] = element.CustomsAclarance;
          obj[3] = element.DAI;
          obj[4] = element.Invoice;
          obj[5] = element.TariffHeading;
          obj[6] = "Tipo de importación";
          obj[7] = element.QuantityLiquidation;

          this.l.push(obj);
        } else if (this.option_processes == 8) {
          let obj = ['', '', '', '', '', '', '']//,'',''

          obj[0] = element.OrderNumber;
          obj[1] = element.OrderDisplay;
          obj[2] = element.Division;
          obj[3] = element.ProvisionedFI;
          obj[4] = element.Invoice;
          obj[5] = element.InvoiceDate == null ? "" : element.InvoiceDate.split('T')[0];
          obj[6] = element.PurchasePrice;
          obj[7] = element.PlantPrice;
          obj[8] = element.CompanyName;
          obj[9] = element.ItemName;
          this.l.push(obj);
        } else if (this.option_processes == 9) {
          let obj = ['', '', '', '', '', '']//,'',''

          obj[0] = element.OrderNumber;
          obj[1] = element.OrderDisplay;
          obj[2] = element.Division;
          obj[3] = element.CompanyName;
          obj[4] = element.ItemName;
          obj[5] = element.PlantPrice;
          obj[6] = element.PlantPriceReal;
          obj[7] = element.EtaReal == null ? "" : element.EtaReal.split('T')[0];
          this.l.push(obj);
        } else if (this.option_processes == 10) {
          let obj = ['', '', '', '']//,'',''
          obj[0] = element.TypeProduct == null ? "-" : element.TypeProduct;
          obj[1] = element.ItemNumber;
          obj[2] = element.ItemName;
          obj[3] = element.GrossWeightDispatched == null ? "0" : element.GrossWeightDispatched.toString();
          obj[4] = element.EtaReal == null ? "" : element.EtaReal.split('T')[0];
          this.l.push(obj);
        } else if (this.option_processes == 11) {
          if (element.DateDepartureReception != null && element.HourDepartureReception != null) {
            let obj = ['', '', '', '', '', '', '']//,'',''
            obj[0] = element.OrderNumber,
              obj[1] = element.WarehousesDiv_Name,
              obj[2] = element.CompanyName,
              obj[3] = element.ContainerNumber,
              obj[4] = element.Type,
              obj[5] = element.BlSeal,
              obj[6] = element.ItemNumber,
              obj[7] = element.ItemName,
              obj[8] = element.ProductWeight,
              obj[9] = element.PackagingName,
              obj[10] = element.DateDepartureReception == null ? "" : element.DateDepartureReception.split('T')[0],
              obj[11] = element.HourDepartureReception,
              obj[12] = element.TransportName,
              obj[13] = element.Date == null ? "" : element.Date.split('T')[0],
              obj[14] = element.Hour,
              this.l.push(obj);
          }
        }
      }

    });
  }


  async converDataToRow(list) {
    let nueva_lista = [];
    this.reports();
    if (this.rows !== null && this.rows !== ' ') {
      nueva_lista = this.l;
      return nueva_lista;
    }
  }

  async downloadReport() {
    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    let listInReport = this.rows;
    const pdf = new PdfMakeWrapper();
    let nameDoc = '';
    let title = '';
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];


    if (this.option_processes == 1) {
      nameDoc = 'Ordenes de compra';
      title = 'Reporte de órdenes de compra';
    } else if (this.option_processes == 2) {
      nameDoc = 'Despacho';
      title = 'Reporte de despachos';
    } else if (this.option_processes == 3) {
      nameDoc = 'Pagos a proveedores';
      title = 'Reporte de pagos a proveedores';
    } else if (this.option_processes == 4) {
      nameDoc = 'Contenedores';
      title = 'Reporte de contenedores de despacho ';
    } else if (this.option_processes == 5) {
      nameDoc = 'Importación de agente';
      title = 'Reporte de importación de agentes';
    } else if (this.option_processes == 6) {
      nameDoc = 'ISD Crédito Tributario';
      title = 'Reporte de ISD crédito tributario';
    } else if (this.option_processes == 8) {
      nameDoc = 'Gastos de importación';
      title = 'Reporte de Gastos de importación';
    } else if (this.option_processes == 9) {
      nameDoc = 'Provisión vs Real';
      title = 'Reporte de Provisión vs Real';
    } else if (this.option_processes == 10) {
      nameDoc = 'Llegada por tipo de producto';
      title = 'Reporte de Llegada por tipo de producto';
    } else if (this.option_processes == 11) {
      nameDoc = 'Contenedores Asignados';
      title = 'Reporte de Contenedores asignados';
    }

    if (this.isSkretting) {
      if (this.option_processes == 1) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CodeSupplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CompanyName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.GrossWeightOrder).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.date_creation).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.UserResponsableSk).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.duration_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.calification_inducton_evaluation).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.status_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.quantity_repeat_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*', 'auto', '*', 'auto', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 2) {
        lista_titulos = [

          new Cell(new Txt(this.translations.ResponsableSK).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableAgent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ProcedureImportline).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.IsdException).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DealNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.GrossWeightOrder).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.SupplierCode).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CompanyName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.TypeProduct).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.SupplierContract).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.IncoTerm).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Origen).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Packaging).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EtaReal).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*', 'auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 3) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.dispatch).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EmissionDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Amount).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Detail).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 4) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OC).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.dispatch).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ContainerNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,

          new Cell(new Txt(this.translations.DateDepartureReception).margin([0, 5, 0, 5]).color('white').bold().end).end,
          // new Cell(new Txt(this.translations.HourDepartureReception).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.RescheduleDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          // new Cell(new Txt(this.translations.RescheduleHour).margin([0, 5, 0, 5]).color('white').bold().end).end,

          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableAgent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Eta).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EndCas).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PortShipment).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableSK).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Packaging).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Bl).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.StampBl).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.WeightItem).margin([0, 5, 0, 5]).color('white').bold().end).end,

        ];
        lista_widths = [
          '*', '*', 'auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      }
    } else {
      if (this.option_processes == 1) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CodeSupplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CompanyName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.GrossWeightOrder).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.date_creation).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.UserResponsableSk).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell( new Txt(this.translations.status_induction).margin([0,5,0,5]).color('white').bold().end).end,
          //new Cell( new Txt(this.translations.quantity_repeat_induction).margin([0,5,0,5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*', 'auto', '*', '*', 'auto', 'auto', 'auto'//,'auto','auto'
        ]
      } else if (this.option_processes == 2) {
        lista_titulos = [

          new Cell(new Txt(this.translations.ResponsableSK).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableAgent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ProcedureImportline).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.IsdException).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DealNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.GrossWeightOrder).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderDisplay).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.SupplierCode).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CompanyName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.TypeProduct).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.SupplierContract).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.IncoTerm).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Origen).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Packaging).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EtaReal).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 3) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.dispatch).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EmissionDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Amount).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Detail).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 4) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OC).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.dispatch).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ContainerNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DateDepartureReception).margin([0, 5, 0, 5]).color('white').bold().end).end,
          // new Cell(new Txt(this.translations.HourDepartureReception).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.RescheduleDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          // new Cell(new Txt(this.translations.RescheduleHour).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableAgent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Eta).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EndCas).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PortShipment).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableSK).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Packaging).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Bl).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.StampBl).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.WeightItem).margin([0, 5, 0, 5]).color('white').bold().end).end

        ];
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 5) {
        lista_titulos = [
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ProcedureImportline).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ProvisionedFI).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.InvoiceDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PurchasePrice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.TariffHeading).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Status).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CompanyName).margin([0, 5, 0, 5]).color('white').bold().end).end,

        ];
        lista_widths = [
          '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', '*'
        ]
      } else if (this.option_processes == 6) {
        lista_titulos = [
          new Cell(new Txt(this.translations.CompanyName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.CustomsAclarance).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt("DAI").margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.TariffHeading).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt("Tipo de importación").margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.QuantityLiquidation).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 8) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderDisplay).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ProvisionedFI).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.InvoiceDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PurchasePrice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PlantPrice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          'auto', 'auto', '*', '*', 'auto', 'auto', '*', 'auto', '*', '*'
        ]
      } else if (this.option_processes == 9) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderDisplay).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EstimatedCost).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.RealCost).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EtaReal).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          'auto', 'auto', 'auto', '*', '*', 'auto', 'auto', 'auto'
        ]
      } else if (this.option_processes == 10) {
        lista_titulos = [
          new Cell(new Txt(this.translations.TypeProduct).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.GrossWeightDispatched).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EtaReal).margin([0, 5, 0, 5]).color('white').bold().end).end,

        ];
        lista_widths = [
          'auto', 'auto', '*', 'auto', '*'
        ]
      } else if (this.option_processes == 11) {
        lista_titulos = [
          new Cell(new Txt(this.translations.OC).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.WarehouseName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ContainerNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Type).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.BlSeal).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.WeightItem).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Packaging).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DateDepartureReception).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.HourDepartureReception).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.TransportCompany).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Date).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Hour).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      }
    }
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${nameDoc}`,
      author: `${"Skretting"}`,//this.userInfo.name
      subject: 'Listado',
    });

    if (this.option_processes == "2" || this.option_processes == "4" || this.option_processes == "11") {
      this.fontSize = 6;
      this.marginLeft = 10;
    } else {
      this.fontSize = 10;
      this.marginLeft = 30;
    }
    //OPTIONS

    pdf.defaultStyle({
      bold: false,
      fontSize: this.fontSize,
      alignment: 'justify'
    });

    pdf.pageMargins([10, 110, 10, 110]);
    // if (this.option_processes == "11") {
    //   pdf.pageOrientation("portrait");
    //   pdf.background(
    //     await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 765).width(900).build() //505
    //   )
    // } else {
    pdf.pageOrientation("landscape");
    pdf.background(
      await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 515).width(900).build() //505
    )
    // }


    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/Skretting-logo.png').width('160').build()).end,
          ]
        ]
      )
        .margin([this.marginLeft, 30, 30, 0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0, 0, 0, 20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        //new Txt([new Txt(this.translations.requested_by + ': ').bold().end,"_"]).fontSize(10).alignment('left').end,//this.userInfo.name
        //new Txt([new Txt(this.translations.user + ': ').bold().end, "_"]).fontSize(10).alignment('left').margin([0,0,0,20]).end,//this.userInfo.logon
        new Table(
          [
            lista_titulos,
            ...await this.converDataToRow(listInReport)
          ]
        ).layout({
          fillColor: (rowIndex: number, node: any, columnIndex: number) => {
            return rowIndex === 0 ? '#f62d51' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(this.fontSize)
          .widths(lista_widths)
          // .widths('*')
          .alignment('center')
          .end,
      ]
    )

    pdf.footer(function (currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0, 10, 0, 0]).end).end,
          new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40, 20, 0]).end).end
        ]
      ).end
    });

    let nombre = 'Reporte';


    if (this.option_processes == 1) {
      nombre = 'Reporte de Ordenes de compra';

    } else if (this.option_processes == 2) {
      nombre = 'Reporte de despachos';
    } else if (this.option_processes == 3) {
      nombre = 'Reporte de pagos a proveedores';
    } else if (this.option_processes == 4) {
      nombre = 'Reporte de contenedores de despacho';
    }
    else if (this.option_processes == 5) {
      nombre = 'Reporte de importacion de agente';
    }
    else if (this.option_processes == 6) {
      nombre = 'Reporte de ISD crédito tributario';
    } else if (this.option_processes == 8) {
      nombre = 'Reporte de Gastos de importación';
    } else if (this.option_processes == 9) {
      nombre = 'Reporte de Provisión vs Real';
    } else if (this.option_processes == 10) {
      nombre = 'Reporte de Llegada por tipo de producto';
    } else if (this.option_processes == 11) {
      nombre = 'Reporte de Contenedores Asignados';
    }
    pdf.create().download(nombre);
    Swal.close();
  }

  public showIconFilter(flag: boolean) {
    this.showIconF = flag;
    if (!flag) {
      this.searchData('');
      // this.arrayList = [];
    }

  }
  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public resetData(index, columnName) {
    let array = [];
    this.l[index] = [];
    this.rows.forEach(element => {
      if (element[columnName] != undefined) {
        let flag = array.indexOf(element[columnName]);
        if (flag == -1) {
          array.push(element[columnName]);
        }
      }
    });

    array.push('(Vacíos)');
    this.l[index] = array;
  }



  public searchDataSelect(event) {
    let data = event.split('|'); debugger;
    if (data[2] == '(Vacíos)') {
      data[2] = null;
    }
    this.dataSearchList[data[0]] = data[2] != '' ? (data[1] + '|' + data[2]) : null;//''
    this.search2 = this.dataSearchList;
    this.filterAllSelect();
  }

  downloadExcel() {

    this.workbook = new Workbook();
    let nombreXLSX = "";


    if (this.isSkretting) {
      if (this.option_processes == 1) {
        nombreXLSX = "Reporte de Ordenes de compra.xlsx";
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.CodeSupplier, this.translations.CompanyName, this.translations.GrossWeightOrder,
          this.translations.date_creation, this.translations.UserResponsableSk, this.translations.Agent, this.translations.duration_induction,
          this.translations.calification_inducton_evaluation, this.translations.status_induction, this.translations.quantity_repeat_induction];
      } else if (this.option_processes == 2) {
        nombreXLSX = 'Reporte de despachos';
        this.lista_titulos = [
          this.translations.TypeProduct,
          this.translations.ResponsableSK,
          this.translations.Agent,
          this.translations.ResponsableAgent,
          this.translations.ProcedureImportline,
          this.translations.IsdException,
          this.translations.DealNumber,
          this.translations.GrossWeightOrder,
          this.translations.OrderNumber,
          this.translations.SupplierCode,
          this.translations.CompanyName,
          this.translations.ItemNumber,
          this.translations.ItemName,
          this.translations.SupplierContract,
          this.translations.IncoTerm,
          this.translations.Origen,
          this.translations.Packaging,
        ];


      } else if (this.option_processes == 3) {
        nombreXLSX = 'Reporte de pagos a proveedores';
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.dispatch, this.translations.Division, this.translations.Supplier, this.translations.Invoice, this.translations.EmissionDate,
          this.translations.Amount, this.translations.Detail];

      } else if (this.option_processes == 4) {
        nombreXLSX = 'Reporte de contenedores de despacho';
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.Division, this.translations.dispatch, this.translations.ContainerNumber, this.translations.Agent, this.translations.ResponsableAgent,
          this.translations.Eta, this.translations.EndCas, this.translations.PortShipment, this.translations.ResponsableSK, this.translations.Supplier, this.translations.ItemName,
          this.translations.Packaging, this.translations.Bl, this.translations.StampBl, this.translations.WeightItem]
      }
    } else {
      if (this.option_processes == 1) {
        nombreXLSX = "Reporte de Ordenes de compra.xlsx";
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.CodeSupplier, this.translations.CompanyName, this.translations.GrossWeightOrder,
          this.translations.date_creation, this.translations.UserResponsableSk, this.translations.Agent];

      } else if (this.option_processes == 2) {
        nombreXLSX = 'Reporte de despachos.xlsx';
        this.lista_titulos = [
          this.translations.TypeProduct, this.translations.ResponsableSK, this.translations.Agent, this.translations.ResponsableAgent, this.translations.ProcedureImportline,
          this.translations.IsdException, this.translations.DealNumber, this.translations.GrossWeightOrder, this.translations.OrderNumber, this.translations.Division,
          this.translations.OrderDisplay, this.translations.SupplierCode, this.translations.CompanyName, this.translations.ItemNumber, this.translations.ItemName,
          this.translations.SupplierContract, this.translations.IncoTerm, this.translations.Origen, this.translations.Packaging, this.translations.EtaReal
        ];

      } else if (this.option_processes == 3) {
        nombreXLSX = 'Reporte de pagos a proveedores.xlsx';
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.dispatch, this.translations.Division, this.translations.Supplier, this.translations.Invoice, this.translations.EmissionDate,
          this.translations.Amount, this.translations.Detail];


      } else if (this.option_processes == 4) {
        nombreXLSX = 'Reporte de contenedores de despacho.xlsx';
        this.lista_titulos = [
          this.translations.OC, this.translations.Division, this.translations.dispatch, this.translations.ContainerNumber,
          this.translations.DateDepartureReception, this.translations.HourDepartureReception, this.translations.RescheduleDate, this.translations.RescheduleHour,
          this.translations.Agent,
          this.translations.ResponsableAgent, this.translations.Eta, this.translations.EndCas, this.translations.PortShipment, this.translations.ResponsableSK,
          this.translations.Supplier, this.translations.ItemName, this.translations.Packaging, this.translations.Bl, this.translations.StampBl,
          this.translations.WeightItem];

      } else if (this.option_processes == 5) {
        nombreXLSX = 'Reporte de importacion de agente.xlsx';
        this.lista_titulos = [
          this.translations.Division, this.translations.ProcedureImportline, this.translations.ProvisionedFI, this.translations.Invoice, this.translations.InvoiceDate,
          this.translations.PurchasePrice, this.translations.ItemNumber, this.translations.ItemName, this.translations.TariffHeading, this.translations.Status,
          this.translations.CompanyName]

      } else if (this.option_processes == 6) {
        nombreXLSX = 'Reporte de ISD crédito tributario.xlsx'
        this.lista_titulos = [
          this.translations.CompanyName, this.translations.OrderNumber, this.translations.CustomsAclarance, "DAI", this.translations.Invoice, this.translations.TariffHeading,
          "Tipo de importación", this.translations.QuantityLiquidation]

      } else if (this.option_processes == 8) {
        nombreXLSX = 'Reporte de Gastos de importación.xlsx';
        this.lista_titulos = [
          this.translations.OrderNumber, this.translations.OrderDisplay, this.translations.Division, this.translations.ProvisionedFI, this.translations.Invoice,
          this.translations.InvoiceDate, this.translations.PurchasePrice, this.translations.PlantPrice, this.translations.Supplier, this.translations.ItemName]

      } else if (this.option_processes == 9) {
        nombreXLSX = 'Reporte de Provisión vs Real.xlsx';
        this.lista_titulos = [
          this.translations.OrderNumber,
          this.translations.OrderDisplay,
          this.translations.Division,
          this.translations.Supplier,
          this.translations.ItemName,
          this.translations.EstimatedCost,
          this.translations.RealCost,
          this.translations.EtaReal,
        ]


      } else if (this.option_processes == 10) {
        nombreXLSX = 'Reporte de Llegada por tipo de producto.xlsx'
        this.lista_titulos = [
          this.translations.TypeProduct, this.translations.ItemNumber, this.translations.ItemName, this.translations.GrossWeightDispatched, this.translations.EtaReal,];

      } else if (this.option_processes == 11) {
        nombreXLSX = 'Reporte de Contenedores Asignados.xlsx';
        this.lista_titulos = [
          this.translations.Agent,
          this.translations.ResponsableAgent,
          this.translations.Eta,
          this.translations.EndCas,
          this.translations.PortShipment,
          this.translations.ResponsableSK,
          this.translations.OC,
          this.translations.CompanyName,
          this.translations.ItemNumber,
          this.translations.ItemName,
          this.translations.Packaging,
          this.translations.Bl,
          this.translations.ContainerNumber,
          this.translations.Type,
          this.translations.StampBl,
          this.translations.WeightItem,
          this.translations.WeightItemBl,
          this.translations.DifferenceProductBl,
          this.translations.Observation + " " + this.translations.DifferenceProductBl,
          this.translations.WeightTareBl,
          this.translations.DifferenceBlTare,
          this.translations.WeightItemInStorage,
          this.translations.DifferenceBlTareStorage,
          this.translations.WeightItemOutStorage,
          this.translations.DifferenceMRNOutputPort,
          this.translations.DifferenceBlvsInPort,
          this.translations.Observation,
          this.translations.DateDeparturePort,
          this.translations.HourDeparturePort,
          this.translations.HourInPlant,
          this.translations.TransportCompany,
          this.translations.WarehouseReception,
          this.translations.DateDepartureReception,
          this.translations.HourDepartureReception,
          this.translations.DeparturePort,
          this.translations.Observation,
          this.translations.Reason,
          this.translations.CommentaryOther,
          this.translations.InPlant,
          this.translations.RescheduleDate,
          this.translations.RescheduleHour,
          this.translations.Commentary,
          this.translations.DateInWarehouse,
          this.translations.HourInWarehouse,
          this.translations.WeightInWarehouse,
          this.translations.WeightDifference,
          this.translations.TransportAssign,
          this.translations.EntryToPort,
          this.translations.StampWarehouse,
          this.translations.StampDifferent,
          this.translations.DepartureHour,
          this.translations.DownloadTime,
          this.translations.Commentary,

        ]

      }
    }

    this.ContainsExcel();
    this.workbook.creator = "Skomex";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      // fs.saveAs(blob, "valor.xlsx");
      // console.log("Nombre:", nombreXLSX);
      fs.saveAs(blob, nombreXLSX);
    })
  }
  private ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 33;
    sheet.getColumn("B").width = 33;
    sheet.getColumn("C").width = 33;
    sheet.getColumn("D").width = 33;
    sheet.getColumn("E").width = 33;
    sheet.getColumn("F").width = 33;
    sheet.getColumn("G").width = 33;
    sheet.getColumn("H").width = 33;
    sheet.getColumn("I").width = 33;
    sheet.getColumn("J").width = 33;
    sheet.getColumn("K").width = 33;
    sheet.getColumn("L").width = 33;
    sheet.getColumn("M").width = 33;
    sheet.getColumn("N").width = 33;
    sheet.getColumn("O").width = 33;
    sheet.getColumn("P").width = 33;
    sheet.getColumn("Q").width = 33;
    sheet.getColumn("R").width = 33;
    sheet.getColumn("S").width = 33;
    sheet.getColumn("T").width = 33;
    sheet.getColumn("U").width = 33;
    sheet.getColumn("V").width = 33;
    sheet.getColumn("W").width = 33;
    sheet.getColumn("X").width = 33;
    sheet.getColumn("Y").width = 33;
    sheet.getColumn("Z").width = 33;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });



    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    // console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12 };

    // agregar los datos a las filas
    const rowsToInsert = sheet.getRows(2, this.rows.length);

    for (let index = 0; index < rowsToInsert.length; index++) {
      const itemData = this.rows[index]; //obtenemos el item segun el index de la iteraciòn(recorrido)
      const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
      // los valores de itemData seran asignados a row (fila actual en la iteraciòn)

      if (this.option_processes == 1) {
        row.values = [
          itemData.OrderNumber,
          itemData.Supplier,
          itemData.CompanyName,
          itemData.GrossWeightOrder,
          itemData.CreationTime.split('T')[0],
          itemData.UserName,
          itemData.AgentName,
        ];

      } else if (this.option_processes == 2) {
        row.values = [
          itemData.TypeProduct,
          itemData.UserName,
          itemData.AgentName,
          itemData.UserAgentName,
          itemData.ProcedureImportline,
          itemData.IsdException,
          itemData.DealNumber,
          itemData.GrossWeightOrder,
          itemData.OrderNumber,
          itemData.Division,
          itemData.OrderDisplay,
          itemData.Supplier,
          itemData.CompanyName,
          itemData.ItemNumber,
          itemData.ItemName,
          itemData.SupplierContract,
          itemData.IncoTerm,
          itemData.Origen,
          itemData.PackagingName,
          itemData.EtaReal == null ? "" : itemData.EtaReal.split('T')[0]
        ];

      } else if (this.option_processes == 3) {
        row.values = [
          itemData.OrderNumber,
          itemData.Dispatch,
          itemData.Div,
          itemData.Supplier,
          itemData.InvoiceNumber,
          itemData.IssueDate == null ? "" : itemData.IssueDate.split('T')[0],
          itemData.AmountLine,
          itemData.SubConcept,
        ]

      } else if (this.option_processes == 4) {
        row.values = [


          itemData.OrderNumber,
          itemData.Division,
          itemData.OrderDisplay,
          itemData.ContainerNumber,

          itemData.DateDepartureReception == null ? "" : itemData.DateDepartureReception.split('T')[0],
          itemData.HourDepartureReception,
          itemData.RescheduleDate == null ? "" : itemData.RescheduleDate.split('T')[0],
          itemData.RescheduleHour,

          itemData.Agent,
          itemData.NameAgenteAduana,
          itemData.EtaRequired == null ? "" : itemData.EtaRequired.split('T')[0],
          itemData.CAS == null ? "" : itemData.CAS.split('T')[0],
          itemData.PortShipment,
          itemData.NameResposabilitySK,
          itemData.CompanyName,
          itemData.ItemName,
          itemData.PackagingName,
          itemData.Bl,
          itemData.BlSeal,
          itemData.ProductWeight,

        ];

      } else if (this.option_processes == 5) {
        row.values = [
          itemData.Division,
          itemData.ProcedureImportline,
          itemData.ProvisionedFI,
          itemData.Invoice,
          itemData.InvoiceDate == null ? "" : itemData.InvoiceDate.split('T')[0],
          itemData.PurchasePrice,
          itemData.ItemNumber,
          itemData.ItemName,
          itemData.TariffHeading,
          itemData.Status,
          itemData.CompanyName,
        ];


      } else if (this.option_processes == 6) {
        row.values = [

        ];


      } else if (this.option_processes == 8) {
        row.values = [
          itemData.OrderNumber,
          itemData.OrderDisplay,
          itemData.Division,
          itemData.ProvisionedFI,
          itemData.Invoice,
          itemData.InvoiceDate == null ? "" : itemData.InvoiceDate.split('T')[0],
          itemData.PurchasePrice,
          itemData.PlantPrice,
          itemData.CompanyName,
          itemData.ItemName,
        ];


      } else if (this.option_processes == 9) {
        row.values = [

          itemData.OrderNumber,
          itemData.OrderDisplay,
          itemData.Division,
          itemData.CompanyName,
          itemData.ItemName,
          itemData.PlantPrice,
          itemData.PlantPriceReal,
          itemData.EtaReal == null ? "" : itemData.EtaReal.split('T')[0]

        ];

      } else if (this.option_processes == 10) {
        row.values = [
          itemData.TypeProduct == null ? "-" : itemData.TypeProduct,
          itemData.ItemNumber,
          itemData.ItemName,
          itemData.GrossWeightDispatched == null ? "0" : itemData.GrossWeightDispatched.toString(),
          itemData.EtaReal == null ? "" : itemData.EtaReal.split('T')[0]
        ];
      } else if (this.option_processes == 11) {
        row.values = [
          itemData.Agent,
          itemData.UserIdsNames,
          itemData.EtaRequired == null ? "" : itemData.EtaRequired.split('T')[0],
          itemData.CAS == null ? "" : itemData.CAS.split('T')[0],
          itemData.PortShipment,
          itemData.NameResposabilitySK,
          itemData.OrderNumber,
          itemData.CompanyName,
          itemData.ItemNumber,
          itemData.ItemName,
          itemData.PackagingName,
          itemData.Bl,
          itemData.ContainerNumber,
          itemData.Type,
          itemData.BlSeal,
          itemData.ProductWeight,
          itemData.BlWeight,
          itemData.WeightDifferenceProductBl,
          itemData.ObservationDifferenceProductBl,
          itemData.TareBl,
          itemData.WeightBlTare,
          itemData.WeightInStorage,
          itemData.WeightDifferenceBlTareStorage,
          itemData.WeightOutPutStorage,
          itemData.WeightDifferenceMRNOutputPort,
          itemData.WeightDifferenceBlInPort,
          itemData.ObservationDifferenceBlInPort,
          itemData.Date == null ? "" : itemData.Date.split('T')[0],
          itemData.Hour,
          itemData.HourInPlant,
          itemData.TransportName,
          itemData.WarehousesDiv_Name,
          itemData.DateDepartureReception == null ? "" : itemData.DateDepartureReception.split('T')[0],
          itemData.HourDepartureReception,
          itemData.DeparturePort,
          itemData.ObservationAgent,
          itemData.Reason,
          itemData.ObservationDetailAgent,
          itemData.InPlant,
          itemData.RescheduleDate == null ? "" : itemData.RescheduleDate.split('T')[0],
          itemData.RescheduleHour,
          itemData.ObservationAgent,
          itemData.DateInWarehouse == null ? "" : itemData.DateInWarehouse.split('T')[0],
          itemData.HourInWarehouse,
          itemData.WeightInWarehouse,
          itemData.WeightDifference,
          itemData.CarAssigned,
          itemData.EntryToPort == null ? "" : itemData.EntryToPort.split('T')[0],
          itemData.SealWarehouse,
          itemData.SealDifference,
          itemData.DepartureHour,
          itemData.DownloadHour,
          itemData.ObservationWareHouse,

        ];

      }
    }

  }

}
