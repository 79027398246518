import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { setTheme } from 'ngx-bootstrap/utils';
setTheme('bs4');

import { Comexroutes } from './comex-routing';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ComexComponent } from './comex.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { QuillModule } from 'ngx-quill';
import { NgxImageCompressService } from 'ngx-image-compress';

import { BaseCrudComponent } from './base-crud/base-crud.component';

import { LaddaModule } from 'angular2-ladda';
import { OrderModule } from 'ngx-order-pipe';

import { SettingsComponent } from './settings/settings.component';
//CRUDS
import { CrudComponent } from './maint/crudhome/crud.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { SafePipeModule } from 'safe-pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { PurchaseordersComponent } from './dashboard/purchaseorders/purchaseorders.component';
import { HeaderOptionsComponent } from '../components/header-options/header-options.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PurchaseorderlinesComponent } from './dashboard/purchaseorderlines/purchaseorderlines.component';
import { PurchaseorderlinedetailsComponent } from './forms/purchaseorderlinedetails/purchaseorderlinedetails.component';
import { WarehousesComponent } from './maint/warehouses/warehouses.component';
import { PackagingComponent } from './maint/packaging/packaging.component';
import { TransportcompanyComponent } from './maint/transportcompany/transportcompany.component';
import { TransportcompanyemployeesComponent } from './maint/transportcompanyemployees/transportcompanyemployees.component';
import { DocumentsComponent } from './maint/documents/documents.component';
import { CustomagentComponent } from './maint/customagent/customagent.component';
import { CustomagentemployeeComponent } from './maint/customagentemployee/customagentemployee.component';
import { ObservationsComponent } from './maint/observations/observations.component';
import { StatusComponent } from './maint/status/status.component';
import { AlertsComponent } from './maint/alerts/alerts.component';
import { PurchaseorderassignComponent } from './maint/purchaseorderassign/purchaseorderassign.component';
import { ParametersComponent } from './maint/parameters/parameters.component';
import { LiberatorycodeComponent } from './maint/liberatorycode/liberatorycode.component';
import { PermissionsComponent } from './maint/permissions/permissions.component';
import { PurchaseorderlinedetailassignComponent } from './maint/purchaseorderlinedetailassign/purchaseorderlinedetailassign.component';
import { PurchaseorderlineassignComponent } from './maint/purchaseorderlineassign/purchaseorderlineassign.component';
import { ParametersdetailComponent } from './maint/parametersdetail/parametersdetail.component';
import { ContainerDetailPOLComponent } from '../components/container-detail-pol/container-detail-pol.component';
import { SelectFilterComponent } from '../components/select-filter/select-filter.component';
import { ApprovedocumentComponent } from './dashboard/approvedocument/approvedocument.component';
import { PaymentsComponent } from './dashboard/payments/payments.component';
import { ApproveFieldComponent } from './dashboard/approve-field/approve-field.component';
import { ContainerDetailPOLDComponent } from "../components/container-detail-pold/container-detail-pold.component";
import { ReportingComponent } from './processes/reporting/reporting.component';
import { AddDocumentationComponent } from './maint/add-documentation/add-documentation.component';
import { UserComponent } from './maint/user/user.component';
import { CustomquantityDirective } from './directives/customquantity.directive';
import { DocumentrepositoryComponent } from './dashboard/documentrepository/documentrepository.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ConceptComponent } from './maint/concept/concept.component';
import { SubconceptComponent } from './maint/subconcept/subconcept.component';
import { ReportPurchaseOrderComponent } from './processes/reporting/report-purchase-order/report-purchase-order.component';
import { ReportDispatchComponent } from './processes/reporting/report-dispatch/report-dispatch.component';
import { ReportPaymentsComponent } from './processes/reporting/report-payments/report-payments.component';
import { ReportPurchaseOrderDetailLineComponent } from './processes/reporting/report-purchase-order-detail-line/report-purchase-order-detail-line.component';
import { ReportImportExpensesComponent } from './processes/reporting/report-import-expenses/report-import-expenses.component';
import { ReportProvisionRealComponent } from './processes/reporting/report-provision-real/report-provision-real.component';
import { ReportArrivalProductTypeComponent } from './processes/reporting/report-arrival-product-type/report-arrival-product-type.component';
import { ReportAssignedContainersComponent } from './processes/reporting/report-assigned-containers/report-assigned-containers.component';
import { ReportImportCustomAgentComponent } from './processes/reporting/report-import-custom-agent/report-import-custom-agent.component';
import { ReportStateDispatchsComponent } from './processes/reporting/report-state-dispatchs/report-state-dispatchs.component';
import { ReportArrivalsComponent } from './processes/reporting/report-arrivals/report-arrivals.component';
import { SettlementsDaiComponent } from './processes/reporting/settlements-dai/settlements-dai.component';
import { ProvisionsComponent } from './processes/reporting/provisions/provisions.component';
import { TransitsComponent } from './processes/reporting/transits/transits.component';
import { DailyLogisticsComponent } from './processes/reporting/daily-logistics/daily-logistics.component';
import { DispatchLogisticsComponent } from './processes/reporting/dispatch-logistics/dispatch-logistics.component';
import { NgxMaskModule } from 'ngx-mask';
import { SoloLetrasDirective } from './directives/solo-letras.directive';
import { SoloNumerosEnterosDirective } from './directives/solo-numeros-enteros.directive';
import { SilentRefreshComponent } from './redirectS/silent-refresh/silent-refresh.component';





const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ComexComponent,
    BaseCrudComponent,
    CrudComponent,
    SettingsComponent,
    FormshomeComponent,
    ProchomeComponent,
    DashboardComponent,
    PurchaseordersComponent,
    HeaderOptionsComponent,
    AboutusComponent,
    PurchaseorderlinesComponent,
    PurchaseorderlinedetailsComponent,
    WarehousesComponent,
    PackagingComponent,
    TransportcompanyComponent,
    TransportcompanyemployeesComponent,
    DocumentsComponent,
    CustomagentComponent,
    CustomagentemployeeComponent,
    ObservationsComponent,
    StatusComponent,
    AlertsComponent,
    ParametersComponent,
    LiberatorycodeComponent,
    PermissionsComponent,
    PurchaseorderassignComponent,
    PurchaseorderlineassignComponent,
    PurchaseorderlinedetailassignComponent,
    ParametersdetailComponent,
    ContainerDetailPOLComponent,
    SelectFilterComponent,
    ApprovedocumentComponent,
    PaymentsComponent,
    ApproveFieldComponent,
    ContainerDetailPOLDComponent,
    ReportingComponent,
    AddDocumentationComponent,
    UserComponent,
    CustomquantityDirective,
    DocumentrepositoryComponent,
    //
    ConceptComponent,
    SubconceptComponent,
    ReportPurchaseOrderComponent,
    ReportDispatchComponent,
    ReportPaymentsComponent,
    ReportPurchaseOrderDetailLineComponent,
    ReportImportExpensesComponent,
    ReportProvisionRealComponent,
    ReportArrivalProductTypeComponent,
    ReportAssignedContainersComponent,
    ReportImportCustomAgentComponent,
    ReportStateDispatchsComponent,
    ReportArrivalsComponent,
    SettlementsDaiComponent,
    ProvisionsComponent,
    TransitsComponent,
    DailyLogisticsComponent,
    DispatchLogisticsComponent,
    SoloLetrasDirective,
    SoloNumerosEnterosDirective,
    SilentRefreshComponent
    ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    RouterModule.forRoot(Comexroutes),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LaddaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OrderModule,
    SafePipeModule,
    QuillModule.forRoot(),
    PdfViewerModule,
    
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    NgSelectConfig,
    ɵs,
    DatePipe,
    NgxImageCompressService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComexModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

