import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { BaseCrudComponent } from 'src/app/comex/base-crud/base-crud.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { saveAs as importedSaveAs } from "file-saver";

//import { ConsoleReporter } from 'jasmine';
//import { Console } from 'console';

@Component({
  selector: 'app-container-detail-pol',
  templateUrl: './container-detail-pol.component.html',
  styleUrls: ['./container-detail-pol.component.css']
})
export class ContainerDetailPOLComponent extends BaseCrudComponent implements OnInit {

  divisionList = [{ id: '5A0', name: '5A0' }, { id: '5B0', name: '5B0' }, { id: '5F0', name: '5F0' }];
  wareHouses = [];
  wareHousesPort = ['ADUANOR', 'ANDIPUERTO', 'CONTECON',
    'DPWORLD',
    'ECUABULK',
    'ECUACARGAS',
    'ESMERALDAS',
    'FERTISA',
    'FRIOEXPORT',
    'INARPI',
    'NAPORTEC',
    'NUEVO TRANSPORTE COMPAÑIA LIMITADA',
    'TABABELA CARGO CENTER',
    'TCE',
    'TPM',
    'TRANSCOMERINTER',
  ];
  TypeProduct = [];//['EQUIPO', 'FUNC AQUA', 'IT', 'LAP', 'MARINE', 'MICRO', 'MUESTRAS', 'NOVEL', 'PT', 'REPUESTO', 'SERVICIO', 'VEG'];
  TypeContainer = ['0', '20 FLAT RACK', '20 OPEN TOP', '20 RF', '20 ST', '40 FLAT RACK', '40 HC', '40 HC & 20 ST', '40 NOR', '40 OPEN TOP', '40 RF', '40 ST', '40 ST & 20 ST', 'CAMION', 'GRANEL', 'LCL'];
  Shipping = [];/*[{ id: 1, nombre: 'DHL' }, { id: 2, nombre: 'IBERIA L.A.E. S. A' }, { id: 3, nombre: 'HAMBURG SUD' }, { id: 4, nombre: 'SCHRYVER' }, { id: 5, nombre: 'PLUSCARGO' }, { id: 6, nombre: 'ONE-EXNETSA' }, { id: 7, nombre: 'GSLINE' }, { id: 8, nombre: 'ATLASLOGISTICS' }, { id: 9, nombre: 'MAERSK' }, { id: 10, nombre: 'SEALAND' }, { id: 11, nombre: 'CITIKOLD' }, { id: 12, nombre: 'EVERGREEN' },
  { id: 13, nombre: 'COSCO' }, { id: 14, nombre: 'CMA CGM' }, { id: 15, nombre: 'YANG MING' }, { id: 16, nombre: 'COLUMTRAD' }, { id: 17, nombre: 'TRANSPORTADORA DE CARGA SEMMOVICAR S.A. ' }, { id: 18, nombre: 'ANDINAVE' }, { id: 19, nombre: 'ECUWORLDWIDE' }, { id: 20, nombre: 'FEDEX' }, { id: 21, nombre: 'INCALINES' }, { id: 22, nombre: 'LOGUNSA' }, { id: 23, nombre: 'IAN TAYLOR' }, { id: 24, nombre: 'PACIFICLINK' },
  { id: 25, nombre: 'OOCL' }, { id: 26, nombre: 'COPA' }, { id: 27, nombre: 'REMAR' }, { id: 28, nombre: 'SACO SHIPPING' }, { id: 29, nombre: 'SURTAX' }, { id: 30, nombre: 'TECNISEA' }, { id: 31, nombre: 'MARGLOBAL' }, { id: 32, nombre: 'AKTION PROJECTS & LOGISTICS S.A.' }, { id: 33, nombre: 'EUROPARTNERS ECUADOR SA' }, { id: 34, nombre: 'FERTYTRAN' }, { id: 35, nombre: 'TRANSTONKA' }, { id: 36, nombre: 'TRANSOCEAN LOGISTICS' },
  { id: 37, nombre: 'SAMISA' }, { id: 38, nombre: 'FARLETZA' }, { id: 39, nombre: 'VICSAN LOGISTICS S.A.' }, { id: 40, nombre: 'KLM' }, { id: 41, nombre: 'TRANS. CARRE. FRANKS' }, { id: 42, nombre: 'TRANSPORTE CERRO  NEGRO S.A' }, { id: 43, nombre: 'OPTRANSA' }, { id: 44, nombre: 'MSC' }, { id: 45, nombre: 'SOLUCAI' }, { id: 46, nombre: 'TIBA' }, { id: 47, nombre: 'SERVICIOS GENERALES VIVIANA' }, { id: 48, nombre: 'TAMPA' },
  { id: 49, nombre: 'APL' }, { id: 50, nombre: 'AIR EUROPA' }, { id: 51, nombre: 'CONSULCAL' }, { id: 52, nombre: 'DP WORLD LOGISTICS' }, { id: 53, nombre: 'SEABORD' }, { id: 54, nombre: 'NUEVO TRANSPORTE COMPAÑIA LIMITADA' }, { id: 55, nombre: 'COMPAÑIA DE TRANSPORTE DE CARGA PESADA NACIONAL E INTERNACIONAL APOLOSEVEN S.A.' }, { id: 56, nombre: 'TAEWONG LOGISTICS' }, { id: 57, nombre: 'UPS' }, { id: 58, nombre: 'PIL' },
  { id: 59, nombre: 'HAPAG LLOYD' }, { id: 60, nombre: 'UNILINE TRANSPORT SYSTEM C.LTDA' }, { id: 61, nombre: 'DSV-AIR&SEA S.A.' }, { id: 62, nombre: 'CORPORACION LOGISTICA INTEGRAL S.A' }, { id: 63, nombre: 'DOIL INTERNATIONAL SAC' }, { id: 64, nombre: 'STARCARGO CIA LTDA' }, { id: 65, nombre: 'BROOM ECUADOR' }, { id: 66, nombre: 'HOYER' }, { id: 67, nombre: 'TRANSGLORY' }, { id: 68, nombre: 'KUEHNE + NAGEL' },
  { id: 69, nombre: 'ALOGINSA' }, { id: 70, nombre: 'ECUATRANSCARGO' }, { id: 71, nombre: 'TRANSTONKA' }, { id: 72, nombre: 'AMESS' }, { id: 73, nombre: 'CARGOLOGIC' }, { id: 74, nombre: 'CRAFT ECUADOR MULTIMODAL CRAFTECMUL S.A' }, { id: 75, nombre: 'DEMEGLIO S.A' }, { id: 76, nombre: 'CARGOFREIGHT LOGISTICS S.A.' }, { id: 77, nombre: 'WAN HAI' }, { id: 78, nombre: 'HAPAG / ATLASLOGISTICS' }, { id: 79, nombre: 'ATLAS/ ITAWGROUP' },
  { id: 80, nombre: 'AVIANCA' }, { id: 81, nombre: 'TICSA' }, { id: 82, nombre: 'MSL' }, { id: 83, nombre: 'EXPAIR CARGO' }, { id: 84, nombre: 'IBERIA' }
  ];*/



  active = 1;
  activeStatus = 0;
  documentList = [];
  statusList = [];
  optionsISD = ['SI', 'NO'];
  optionSelected = 'NO'
  optionDemoraje = 'NO'
  archivos = [];
  permissionList = [];
  pack = [];
  pack_E = [];
  releaseIva = [];
  releaseADV = [];
  origenList = environment.Global.COUNTRY;
  countryList = environment.Global.COUNTRY;
  fieldsToApproval = [];
  objectCopy = null;
  isChange = false;
  editContainer = true;
  logList = [];
  pageLog = 1;
  pageSizeLog = 20;
  pageTotalLog = 10;
  pageLogDetail = 1;
  pageSizeLogDetail = 20;
  hiddenLog = [];
  objRowLog = null;
  userTypeLog: any = false;
  contador = 0;

  isPDF = false;
  isImage = false;
  pdfSrc = "";
  RemarkUpdate: boolean = false;


  @Input() obj: any = {};
  @Input() user: any = null;
  @Input() index: any = null;
  @Input() userType: any = null;
  permisosCajaTexto: any = [];
  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public globalService: GlobalService, private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.RemarkUpdate = false;


  }

  async ngOnInit() {
    console.log("this.userType:",this.userType)

    if (this.userType == 'AGA' || this.userType == 'CM' || this.userType == 'LOG' || this.userType == 'ADM') {
      this.RemarkUpdate = true;
    } else {
      this.RemarkUpdate = false;
    }

    // console.log(this.userType);
    // console.log(this.RemarkUpdate)
    await super.ngOnInit();
    await this.getStatus();
    await this.getPermisos();
    this.contador = 0;
    //this.permissionList = await this.permissionListGlobal ;

    // console.log("Lista");
    // console.log(this.permissionList);
    this.u_obj = JSON.parse(JSON.stringify(this.obj));
    // console.log(this.u_obj);
    this.u_obj['Delay'] = 'NO';
    this.u_obj['PlantPrice'] = this.u_obj['PlantPrice'] != null ? this.u_obj['PlantPrice'].toFixed(4) : null;


    // await this.getPermissionsByUser(this.user);


    await this.GetWarehouseActive();
    await this.GetPackagingsActive();
    await this.GetShippingCompaniesActive();
    await this.GetTypeProductActive();
    await this.GetReleaseCodeIVA();
    await this.GetReleaseCodeADV();
    await this.GetLogFields();
    await this.getPermissionLog();


    // console.log(this.user.sub);




    this.u_obj['EtdInit'] = this.u_obj['EtdInit'] != null ? this.u_obj['EtdInit'].split('T')[0] : null;
    this.u_obj['EtdEnd'] = this.u_obj['EtdEnd'] != null ? this.u_obj['EtdEnd'].split('T')[0] : null;
    this.u_obj['EtdRequired'] = this.u_obj['EtdRequired'] != null ? this.u_obj['EtdRequired'].split('T')[0] : null;
    this.u_obj['EtaRequired'] = this.u_obj['EtaRequired'] != null ? this.u_obj['EtaRequired'].split('T')[0] : null;
    this.u_obj['EtaReal'] = this.u_obj['EtaReal'] != null ? this.u_obj['EtaReal'].split('T')[0] : null;
    this.u_obj['EtdReal'] = this.u_obj['EtdReal'] != null ? this.u_obj['EtdReal'].split('T')[0] : null;
    this.u_obj['PlannedDate'] = this.u_obj['PlannedDate'] != null ? this.u_obj['PlannedDate'].split('T')[0] : null;
    this.u_obj['EtdInit'] = this.u_obj['EtdInit'] != null ? this.u_obj['EtdInit'].split('T')[0] : null;
    this.u_obj['EmissionDate'] = this.u_obj['EmissionDate'] != null ? this.u_obj['EmissionDate'].split('T')[0] : null;
    this.u_obj['PaymentDateGuaranted'] = this.u_obj['PaymentDateGuaranted'] != null ? this.u_obj['PaymentDateGuaranted'].split('T')[0] : null;
    this.u_obj['PaymentDateEfectiveLq'] = this.u_obj['PaymentDateEfectiveLq'] != null ? this.u_obj['PaymentDateEfectiveLq'].split('T')[0] : null;
    this.u_obj['InvoiceDate'] = this.u_obj['InvoiceDate'] != null ? this.u_obj['InvoiceDate'].split('T')[0] : null;
    this.u_obj['PlantArrival'] = this.u_obj['PlantArrival'] != null ? this.u_obj['PlantArrival'].split('T')[0] : null;
    this.u_obj['SenaeDate'] = this.u_obj['SenaeDate'] != null ? this.u_obj['SenaeDate'].split('T')[0] : null;
    this.u_obj['CAS'] = this.u_obj['CAS'] != null ? this.u_obj['CAS'].split('T')[0] : null;
    this.u_obj['AuthorizedDepartureDate'] = this.u_obj['AuthorizedDepartureDate'] != null ? this.u_obj['AuthorizedDepartureDate'].split('T')[0] : null;
    this.u_obj['PurchasePrice'] = Number(this.u_obj['PurchasePrice']).toFixed(4);
    this.u_obj['RateExchangeDate'] = this.u_obj['RateExchangeDate'] != null ? this.u_obj['RateExchangeDate'].split('T')[0] : null;

    if(this.u_obj['GrossWeightDispatched'] != null &&this.u_obj['PlantPrice'] != null ){
      let GrossWeightDispatched = this.u_obj['GrossWeightDispatched'].toString().replace(/'|,|/g, "");
      let PurchasePrice = this.u_obj['PurchasePrice'].toString().replace(/'|,|/g, "");
      this.u_obj['AmountUE']  = Number(GrossWeightDispatched) * Number(PurchasePrice);
    }

    this.objectCopy = JSON.parse(JSON.stringify(this.u_obj));
    if (!isNaN(Number(this.objectCopy['Containers']))) {
      if (this.objectCopy['Containers'] != 0) {
        this.editContainer = false;
      } else {
        this.editContainer = true;
      }
    }
    // Initialization
    //const anElement = new AutoNumeric('#GrossWeightOrder');
    //new AutoNumeric('',null, { currencySymbol : '$' });
    // console.log(this.objectCopy)
    this.pageLog = 1
  }

  public async getStatus() {
    this.url = environment.Global.API_GET_ALL_ST;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        result.Object.forEach(element => {
          if (element.Name != 'S') {
            this.statusList.push(element)
          }
        });
      }
    })
    this.activeStatus = 0;
  }

  checkInputLength() {
    if (this.u_obj['RemarkUpdate'].length >= 200) {
      this.isUpdating = false;
      this.messageDialog('La Observación de Actualización ha superado el límite de caracteres permitido (Max:200)', '', 'error');
      return
    }
  }
  public async getPermisos() {
    this.url = environment.Global.API_GET_PERMISSIONS_BY_USER_LOG + this.user.sub;
    // console.log(this.url);
    await this.srv.get(this.url).toPromise().then(result => {
      // console.log(result.Object)
      if (result.Object != null) {
        if (result.Status && result.Object.Permissions[0] != null) {
          result.Object.Permissions[0].PermissionsDetail.forEach(element => {
            this.permisosCajaTexto.push(element)

          });
        } else {
          this.permisosCajaTexto = []
        }
      } else {
        this.permisosCajaTexto = []
      }

    })
    // console.log(this.permisosCajaTexto);
  }

  // public async getPermissionsByUser(user) {
  //   // this.url = environment.Global.API_GET_PERMISSIONS_BY_USER + user.sub;
  //   this.url = environment.Global.API_GET_PERMISSIONS_BY_USER_LOG + user.sub;
  //   await this.srv.get(this.url).toPromise().then(result => {
  //     console.log(result.Object.Agent.Permissions[0].PermissionsDetail);
  //     if (result.Status) {
  //       if (result.Object != null) {
  //         this.permissionList = result.Object.Agent.Permissions[0].PermissionsDetail;

  //       } else {
  //         this.permissionList = [];
  //       }
  //     }
  //    console.log("Permisos:",this.permissionList);
  //   })
  // }

  public async getPermissionLog() {
    this.url = environment.Global.API_GET_PERMISSIONS_LOG + this.userType;
    // console.log(this.url);
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object != null) {
          this.userTypeLog = true
          // console.log(this.userTypeLog);
        } else {
          this.userTypeLog = false
        }
      }
    })
  }

  public AddFile(event, id) {
    const archivocapture = event.target.files[0];
    let obj = {
      id: id,
      file: archivocapture,
    }
    if (archivocapture) {
      if (archivocapture.size > environment.Global.SizeFile) {
        // Mostrar alerta si el archivo es mayor a 3MB
        Swal.fire({
          icon: 'error',
          title: 'Archivo demasiado grande',
          text: 'El tamaño del archivo no debe exceder los 3MB.',
          confirmButtonText: 'Entendido'
        }).then((result) => {
          if (result.isConfirmed) {
            // Vaciar el input file después de que el usuario cierra la alerta
            event.target.value = '';
          }
        });
      } else {
        if (this.archivos.length == 0) {
          this.archivos.push(obj);
        } else {
          let flag = false;
          this.archivos.forEach(element => {
            if (element.id == obj.id) {
              element.file = archivocapture
              flag = true;
            }
          })
          if (!flag) {
            this.archivos.push(obj)
          }
        }
      }
    }
  }

  public async SaveFiles(value: number, modal) {
    if (this.archivos.length > 0) {
      let lista = [];
      this.archivos.forEach(file => {
        if (file.file.size > environment.Global.SizeFile) {
          lista.push(file.file.name)
        }
      });debugger;
      if (lista.length > 0) {
        let files = lista.join('-');
        this.errorMessageWithoutHtml('FileError', 'Estos archivos poseen mas de 3 MB: ' + files + '. Deberá cargar otros', 'error');
        return;
      } else {
        this.isUploadFile = true;
        // debugger
        let formData = new FormData();
        this.archivos.forEach(file => {
          formData.append('Files[-' + file.id + '-]', file.file);
        })
        if (value == 1) {
          this.url = environment.Global.API_POST_FILES_POL;
        } else {
          this.url = environment.Global.API_POST_FILES_POR;
          formData.append('DocumentComment', this.c_obj.DocumentComment);
        }
        // console.log(this.url)
        formData.append('OrderLineId', this.u_obj.OrderLineId);
        formData.append('CreatorUser', this.user.name);
        formData.append('UserCode', this.user.sub);

        this.messageShowLoading();

        await this.srv.AddFiles(this.url, formData).toPromise().then(result => {
          if (result) {
            // debugger
            console.log("AddFiles",{ id: this.index, idRow: this.u_obj.OrderLineId })
            this.refreshData.emit({ id: this.index, idRow: this.u_obj.OrderLineId });
            this.messageDialog("Archivos cargados con exito", '', 'success');
            if (value != 1) {
              this.closeModal(modal)
            }

          } else {
            this.messageDialog('', '', 'error');
          }
          this.isUploadFile = false;
        });
      }

    } else {
      this.errorMessage('EmptyList', 'UploadFileDocument', "error");
      this.isUploadFile = false;
    }
  }

  public async UpdateDataUnite(object) {

    // object['GrossWeight'] = object['GrossWeight'].toString().replace(/'|,|/g, "");
    // object['GrossWeightDispatched'] = object['GrossWeightDispatched'].toString().replace(/'|,|/g, "");
    // object['GrossWeightDifference'] = object['GrossWeightDifference'].toString().replace(/'|,|/g, "");
    // object['GrossWeightOrder'] = object['GrossWeightOrder'].toString().replace(/'|,|/g, "");
    // object['AmountUE'] = object['AmountUE'].toString().replace(/'|,|/g, "");


    this.isUpdating = true;
    this.obj = object;
    console.log(object);
    this.obj.UserEdit = this.user.sub;
    this.obj._ApprovalField = this.fieldsToApproval;
    this.url = environment.Global.API_POST_POLUNITE;

    this.messageShowLoading();
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.refreshData.emit({ id: this.index, idRow: this.u_obj.OrderLineId, user: this.user });
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }


  public async UpdateData(object,modal) {

    // object['GrossWeight'] = object['GrossWeight'].toString().replace(/'|,|/g, "");
    // object['GrossWeightDispatched'] = object['GrossWeightDispatched'].toString().replace(/'|,|/g, "");
    // object['GrossWeightDifference'] = object['GrossWeightDifference'].toString().replace(/'|,|/g, "");
    // object['GrossWeightOrder'] = object['GrossWeightOrder'].toString().replace(/'|,|/g, "");
    // object['AmountUE'] = object['AmountUE'].toString().replace(/'|,|/g, "");

    this.isUpdating = true;
    this.obj = object;
    this.obj.UserEdit = this.user.sub;
    this.obj._ApprovalField = this.fieldsToApproval;
    this.url = environment.Global.API_POST_POL;

    console.log(this.obj);

    this.messageShowLoading();
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        // GUARDAMOS LOS DOCUMENTOS.
        this.SaveFiles(1,modal);

        this.refreshData.emit({ id: this.index, idRow: this.u_obj.OrderLineId, user: this.user });
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })

  }

  public VerifyApprove(field: string) {
    if (this.u_obj[field] != this.objectCopy[field] && this.userType == "AGA") {
      let permission = null;
      let flag = false;
      this.permissionList.forEach(perm => {
        if (perm.Description == field && (perm.UsersApproval != "" && perm.UsersApproval != null)) {
          permission = perm;
          flag = true;
        }
      })
      if (flag) {
        this.AddComment(permission, this.u_obj[field], this.objectCopy[field], this.u_obj['OrderLineId']);
      }
    }
  }

  public AddComment(permission: any, value: any, oldValue: any, OrderLineId) {
    Swal.fire(
      {
        title: this.translations.FieldModification,
        html: `<p>El campo <b>${permission.ParameterName}</b> requiere aprobación para actualizar el dato, ingrese el motivo de cambio`,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.ButtonComment,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
        input: 'text',
        preConfirm: (data) => {
          if (data == '') {
            Swal.showValidationMessage(
              this.translations.AddComment
            )
          }
        }
      }
    ).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          Field: permission.Description,
          Commentary: result.value,
          UsersToApproval: permission.UsersApproval,
          FieldESP: permission.ParameterName,
          CurrentValue: value,
          PreviousValue: oldValue,
          UserId: "",
          UserSub: this.user.sub,
          TableName: "PurchaseOrderLine",
          OrderLineId: OrderLineId
        }
        this.validateFields(obj);
        this.objectCopy[permission.Description] = this.u_obj[permission.Description];
      } else if (result.isDismissed) {
        this.u_obj[permission.Description] = this.objectCopy[permission.Description];
        this.executeFunctions(permission);
      }
    })
  }

  public executeFunctions(permission: any) {
    if (permission.Description == "PlantPrice") {
      this.setFIProvisioned();
    } else if (permission.Description == "GrossWeightDispatched") {
      this.setDifferenceWeight();
    } else if (permission.Description == "EtdRequired" || permission.Description == "EtaRequired") {
      this.setDifferenceDaysTT();
    } else if (permission.Description == "EtdReal" || permission.Description == "EtaReal") {
      this.setDifferenceDaysTTReal();
    } else if (permission.Description == "CAS") {
      this.setDaysFree();
    } else if (permission.Description == "AuthorizedDepartureDate") {
      this.setEtaDepartureAuthorized();
    } else if (permission.Description == "PlantArrival") {
      this.setPortPlantTT();
    } else if (permission.Description == "PlantPriceReal") {
      this.setRealFI();
    }
  }

  public validateFields(obj) {
    let flag = false;
    let object = JSON.parse(JSON.stringify(obj));
    if (this.fieldsToApproval.length == 0) {
      this.fieldsToApproval.push(object);
    } else {
      this.fieldsToApproval.forEach(element => {
        if (element.Field == object.Field) {
          flag = true;
          element.Commentary = object.Commentary;
          element.PreviousValue = object.PreviousValue;
          element.CurrentValue = object.CurrentValue;
        }
      });
      if (!flag) {
        this.fieldsToApproval.push(obj);
      }
    }
  }

  public VerifactePermissionRead(field: string) {
    let flag = false;
    if (this.userType == 'ADM' || this.userType == 'CM') {
      return true;
    } else if (this.userType == 'AGA') {
      this.permissionList.forEach(element => {
        if (element.Description == field) {
          flag = element.ReadField == 1 ? true : false;
        }
      })
      return flag;
    } else {
      return flag;
    }
  }
  // metodo antiguo antes 04/16/2022
  // public VerifactePermissionUpdate(field: string) {
  //   let flag = false;
  //   if (this.userType == 'ADM' || this.userType == 'CM') {
  //     return true;
  //   }
  //   else if (this.userType == 'AGA') {
  //     this.permissionList.forEach(element => {
  //       if (element.Description == field && element.StatusIds.includes(this.obj.StatusId)) {
  //         flag = true;
  //       }
  //     })
  //     return flag;
  //   } else {
  //     return flag;
  //   }
  // }

  // metodo nuevo creado 04/16/2022
  public VerifactePermissionUpdate(field: string) {

    let flag = false;
    if (this.userType == 'ADM' || this.userType == 'CM') {

      return true;
    }
    else if (this.userType == 'AGA' || this.userType == 'COM' || this.userType == 'FIN'
      || this.userType == 'TAX' || this.userType == 'CAL') {

      // debugger
      this.permisosCajaTexto.forEach(element => {
        if (element.Description == field && element.StatusIds.includes(this.obj.StatusId)) {
          if (element.ReadField == 1 && element.UpdateField == 1) {
            flag = true;
          }
          // console.log(element);

          // console.log("bandera:",element.Description, "-",flag)
        }
      })
      return flag;
    } else {
      // console.log("bandera  else:",flag)
      return flag;
    }
  }

  public validateStatus() {
    if (this.userType == 'ADM' || this.userType == 'CM') {
      return true;
    }
    return false;
  }
  public validateStatusUnite() {
    if (this.userType == 'ADM' || this.userType == 'CM') {
      let estado = this.u_obj['StatusUnite'];
      if (this.u_obj['Status'] == 'E') {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }


  public VerifacteUnite() {
    let flag = false;
    if (this.userType == 'ADM' || this.userType == 'CM') {
      let estado = this.u_obj['StatusUnite'];
      if (estado < 40) {
        return true;
      }
      else {
        return flag;
      }
    } else {
      return flag;
    }
  }

  public ChangeStatus() {
    //console.log("UPDATE:", this.u_obj);
    let list = this.ValidateFieldsToChange();
    let sizeList = list.length;
    if (sizeList > 0) {
      let fields = "";
      list.forEach(element => {
        let field = this.translations[element] == undefined ? element : this.translations[element];
        fields = fields + ", " + field;
      })
      fields = fields.slice(1);
      let data = "<span><b>" + fields + "</b></span>";
      this.translations.ErrorToChangeStatusBody = this.translations.ErrorToChangeStatusBody.replace("{DATA}", data)
      Swal.fire(
        {
          title: this.translations.ErrorToChangeStatus,
          html: this.translations.ErrorToChangeStatusBody,
          icon: "error",
          allowOutsideClick: false
        }
      );
    } else {
      Swal.fire(
        {
          title: this.translations.ChangeStatusDispatch + ' ' + this.u_obj['OrderDisplay'],
          html: this.translations.ChangeStatusReason,
          icon: 'info',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: this.translations.Cancel,
          cancelButtonColor: '#84888b',
          confirmButtonText: this.translations.Change,
          confirmButtonColor: '#C8102E',
          showCloseButton: true,
        }
      ).then((result) => {
        if (result.isConfirmed) {
          this.isChange = true;
          this.url = environment.Global.API_POST_CHANGE_STATUS;
          let obj = {
            OrderLineId: this.u_obj['OrderLineId'],
            UserCode: this.user.sub,
            GrossWeight: this.u_obj['GrossWeight'],
            GrossWeightDispatched: this.u_obj['GrossWeightDispatched'],
            GrossWeightDifference: this.u_obj['GrossWeightDifference'],
            GrossWeightOrder: this.u_obj['GrossWeightOrder'],
          }
          // console.log("DATO:", obj);
          this.srv.post(this.url, obj).subscribe(result => {
            if (result.Status) {
              this.refreshData.emit({ id: this.index, idRow: this.u_obj.OrderLineId, user: this.user });
              this.messageDialog(result.Message, '', 'success');
              this.clearData();
            } else {
              this.messageDialog(result.Message, '', 'error');
            }
            this.isChange = false;
          })
        }
      })
    }
  }

  public ValidateFieldsToChange(): string[] {
    debugger
    let fields = [];
    let status = this.objectCopy.Status;
    if (status == "N") {
      if (this.objectCopy['GrossWeightDispatched'] == "" || this.objectCopy['GrossWeightDispatched'] == null) {
        fields.push('GrossWeightDispatched');
      }
      if (this.objectCopy['PlantPrice'] == 0 || this.objectCopy['PlantPrice'] == null) {
        fields.push('PlantPrice');
      }
      if (this.objectCopy['EtdInit'] == "" || this.objectCopy['EtdInit'] == null) {
        fields.push('EtdInit');
      }
      if (this.objectCopy['EtdEnd'] == "" || this.objectCopy['EtdEnd'] == null) {
        fields.push('EtdEnd');
      }
      if (this.objectCopy['EtdRequired'] == "" || this.objectCopy['EtdRequired'] == null) {
        fields.push('EtdRequired');
      }
      if (this.objectCopy['EtaRequired'] == "" || this.objectCopy['EtaRequired'] == null) {
        fields.push('EtaRequired');
      }
      if (this.objectCopy['DaysFreeNegotiated'] == null) {
        fields.push('DaysFreeNegotiated');
      }
      if (this.objectCopy['Origen'] == "" || this.objectCopy['Origen'] == null) {
        fields.push('Origen');
      }
      if (this.objectCopy['PackagingNumber'] == "" || this.objectCopy['PackagingNumber'] == null) {
        fields.push('Packaging');
      }
      if (this.objectCopy['Permission'] == "" || this.objectCopy['Permission'] == null) {
        fields.push('Permission');
      }

      if (this.objectCopy['DocumentsN'].length > 0) {
        debugger
        // console.log("Copia del documento:", this.objectCopy);
        // console.log("Copia del documento:", this.objectCopy['DocumentsN']);

        this.objectCopy['DocumentsN'].forEach(document => {
          debugger
          if (document.DocumentUpload == null && document.RequireApproval == 1) {
            fields.push(document.DocumentName);
          }

          else if (document.DocumentUpload != null && document.RequireApproval == 1) {

            if (document.DocumentUpload.StatusFile != 'AP') {
              fields.push(document.DocumentName);
            }
          }
          else if (document.ItemCode > 0 || this.objectCopy['Origen'] != null) {
            // console.log(this.objectCopy['Origen'] == document.Origen);
            // console.log(this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber);
            if (this.objectCopy['Origen'] == document.Origen && this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber) {
              fields.push(document.DocumentName);
            }
          }
        });
      }

    } else if (status == "E") {
      if (this.objectCopy['GrossWeightDispatched'] == "" || this.objectCopy['GrossWeightDispatched'] == null) {
        fields.push('GrossWeightDispatched');
      }
      if (this.objectCopy['PlantPrice'] == 0 || this.objectCopy['PlantPrice'] == null) {
        fields.push('PlantPrice');
      }
      if (this.objectCopy['EtdReal'] == "" || this.objectCopy['EtdReal'] == null) {
        fields.push('EtdReal');
      }
      if (this.objectCopy['EtaReal'] == "" || this.objectCopy['EtaReal'] == null) {
        fields.push('EtaReal');
      }
      if (this.objectCopy['PortShipment'] == "" || this.objectCopy['PortShipment'] == null) {
        fields.push('PortShipment');
      }
      if (this.objectCopy['Bl'] == "" || this.objectCopy['Bl'] == null) {
        fields.push('Bl');
      }
      if (this.objectCopy['Shipping'] == "" || this.objectCopy['Shipping'] == null) {
        fields.push('Shipping');
      }
      if (this.objectCopy['TypeCNT'] == "" || this.objectCopy['TypeCNT'] == null) {
        fields.push('TypeCNT');
      }
      if (this.objectCopy['PackagingNumber'] == "" || this.objectCopy['PackagingNumber'] == null) {
        fields.push('Packaging');
      }
      if (this.objectCopy['Policy'] == "" || this.objectCopy['Policy'] == null) {
        fields.push('Policy');
      }
      if (this.objectCopy['Invoice'] == "" || this.objectCopy['Invoice'] == null) {
        fields.push('Invoice');
      }
      if (this.objectCopy['InvoiceDate'] == "" || this.objectCopy['InvoiceDate'] == null) {
        fields.push('InvoiceDate');
      }
      if (this.objectCopy['AmountUE'] == 0 || this.objectCopy['AmountUE'] == null) {
        fields.push('AmountUE');
      }

      if (this.objectCopy['DocumentsE'].length > 0) {
        let counter = 0;
        this.objectCopy['DocumentsE'].forEach(document => {
          if (document.DocumentUpload == null && document.RequireApproval == 1) {
            fields.push(document.DocumentName);
          } else if (document.DocumentUpload != null && document.RequireApproval == 1) {
            if (document.DocumentUpload.StatusFile != 'AP') {
              fields.push(document.DocumentName);
            }
          } else if (document.ItemCode > 0 || this.objectCopy['Origen'] != null) {
            // console.log(this.objectCopy['Origen'] == document.Origen);
            // console.log(this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber);
            if (this.objectCopy['Origen'] == document.Origen && this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber) {
              fields.push(document.DocumentName);
            }
          }
        });
        if (counter >= 1) {
          fields.push('Documents');
        }
      }
    } else if (status == "T") {
      if (this.objectCopy['CAS'] == "" || this.objectCopy['CAS'] == null) {
        fields.push('CAS');
      }
      if (this.objectCopy['AuthorizedDepartureDate'] == "" || this.objectCopy['AuthorizedDepartureDate'] == null) {
        fields.push('AuthorizedDepartureDate');
      }
      if (this.objectCopy['WarehouseNumber'] == "" || this.objectCopy['WarehouseNumber'] == null) {
        fields.push('Warehouse');
      }
      if (this.objectCopy['CustomsAclarance'] == 0 || this.objectCopy['CustomsAclarance'] == null) {
        fields.push('CustomsAclarance');
      }
      // if (this.objectCopy['EtaDepartureAuthorized'] == "" || this.objectCopy['EtaDepartureAuthorized'] == null) {
      if (this.objectCopy['EtaDepartureAuthorized'] == null) {
        fields.push('EtaDepartureAuthorized');
      }
      if (this.objectCopy['EmissionDate'] == "" || this.objectCopy['EmissionDate'] == null) {
        fields.push('EmissionDate');
      }
      if (this.objectCopy['DAI'] == "" || this.objectCopy['DAI'] == null) {
        fields.push('DAI');
      }
      if (this.objectCopy['PaymentDateEfectiveLq'] == "" || this.objectCopy['PaymentDateEfectiveLq'] == null) {
        fields.push('PaymentDateEfectiveLq');
      }
      // if (this.objectCopy['QuantityLiquidation'] == 0 || this.objectCopy['QuantityLiquidation'] == null) {
      if (this.objectCopy['QuantityLiquidation'] == null) {
        fields.push('QuantityLiquidation');
      }
      // if (this.objectCopy['Tariff'] == 0 || this.objectCopy['Tariff'] == null) {
      if (this.objectCopy['Tariff'] == null) {
        fields.push('Tariff');
      }
      // if (this.objectCopy['SpecificTariff'] == 0 || this.objectCopy['SpecificTariff'] == null) {
      if (this.objectCopy['SpecificTariff'] == null) {
        fields.push('SpecificTariff');
      }
      // if (this.objectCopy['Iva'] == 0 || this.objectCopy['Iva'] == null) {
      if (this.objectCopy['Iva'] == null) {
        fields.push('Iva');
      }
      // if (this.objectCopy['Fodinfa'] == 0 || this.objectCopy['Fodinfa'] == null) {
      if (this.objectCopy['Fodinfa'] == null) {
        fields.push('Fodinfa');
      }
      if (this.objectCopy['TariffHeading'] == "" || this.objectCopy['TariffHeading'] == null) {
        fields.push('TariffHeading');
      }
      // if (this.objectCopy['TariffPercent'] == 0 || this.objectCopy['TariffPercent'] == null) {
      if (this.objectCopy['TariffPercent'] == null) {
        fields.push('TariffPercent');
      }
      if (this.objectCopy['Delay'] == "" || this.objectCopy['Delay'] == null) {
        fields.push('Delay');
      }
      if (this.objectCopy['Capacity'] == "" || this.objectCopy['Capacity'] == null) {
        fields.push('Capacity');
      }

      if (this.objectCopy['DocumentsT'].length > 0) {
        this.objectCopy['DocumentsT'].forEach(document => {
          if (document.DocumentUpload == null && document.RequireApproval == 1) {
            fields.push(document.DocumentName);
          } else if (document.DocumentUpload != null && document.RequireApproval == 1) {
            if (document.DocumentUpload.StatusFile != 'AP') {
              fields.push(document.DocumentName);
            }
          } else if (document.ItemCode > 0 || this.objectCopy['Origen'] != null) {
            // console.log(this.objectCopy['Origen'] == document.Origen);
            // console.log(this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber);
            if (this.objectCopy['Origen'] == document.Origen && this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber) {
              fields.push(document.DocumentName);
            }
          }
        });
      }
    } else if (status == "P") {
      if (this.objectCopy['PlantArrival'] == "" || this.objectCopy['PlantArrival'] == null) {
        fields.push('PlantArrival');
      }

      if (this.objectCopy['DocumentsP'].length > 0) {
        this.objectCopy['DocumentsP'].forEach(document => {
          if (document.DocumentUpload == null && document.RequireApproval == 1) {
            fields.push(document.DocumentName);
          } else if (document.DocumentUpload != null && document.RequireApproval == 1) {
            if (document.DocumentUpload.StatusFile != 'AP') {
              fields.push(document.DocumentName);
            }
          } else if (document.ItemCode > 0 || this.objectCopy['Origen'] != null) {
            // console.log(this.objectCopy['Origen'] == document.Origen);
            // console.log(this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber);
            if (this.objectCopy['Origen'] == document.Origen && this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber) {
              fields.push(document.DocumentName);
            }
          }
        });
      }
    } else if (status == "F") {
      if (this.objectCopy['PlantPriceReal'] == 0 || this.objectCopy['TariffPercent'] == null) {
        fields.push('PlantPriceReal');
      }
      if (this.objectCopy['LiftDateSenae'] == "" || this.objectCopy['LiftDateSenae'] == null) {
        fields.push('LiftDateSenae');
      }

      if (this.objectCopy['DocumentsF'].length > 0) {
        this.objectCopy['DocumentsF'].forEach(document => {
          if (document.DocumentUpload == null && document.RequireApproval == 1) {
            fields.push(document.DocumentName);
          } else if (document.DocumentUpload != null && document.RequireApproval == 1) {
            if (document.DocumentUpload.StatusFile != 'AP') {
              fields.push(document.DocumentName);
            }
          }
          else if (document.ItemCode > 0 || this.objectCopy['Origen'] != null) {
            // console.log(this.objectCopy['Origen'] == document.Origen);
            // console.log(this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber);
            if (this.objectCopy['Origen'] == document.Origen && this.objectCopy['ItemNumber'] == document.ItemCode.ItemNumber) {
              fields.push(document.DocumentName);
            }
          }
        });
      }
    }
    return fields;
  }

  public WarehouseActive(division) {
    // console.log("entro bodegas",division)
    this.url = environment.Global.API_GET_ACTIVE_WH + division;
    this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.wareHouses = result.Object
        //console.log("Bodegas:",this.wareHouses)
      }
    })
  }

  public async GetWarehouseActive() {
    this.url = environment.Global.API_GET_ACTIVE_WH;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.wareHouses = result.Object
        //console.log("Bodegas:",this.wareHouses)
      }
    })
  }

  public async GetPackagingsActive() {
    this.url = environment.Global.API_GET_ACTIVE_PK;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.pack = result.Object;
        this.pack_E = result.Object;
        //console.log("Bodegas:",this.wareHouses)
      }
    })
  }

  public async GetShippingCompaniesActive() {
    this.url = environment.Global.API_GET_ACTIVE_SC;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.Shipping = result.Object;
        //console.log("Bodegas:",this.wareHouses)
      }
    })
  }
  public async GetTypeProductActive() {
    this.url = environment.Global.API_GET_ACTIVE_TP;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.TypeProduct = result.Object;
      }
    })
  }

  public async GetReleaseCodeIVA() {
    this.url = environment.Global.API_GET_SPECIFIC_LC + "IVA";
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.releaseIva = result.Object;
      }
    })
  }
  public async GetReleaseCodeADV() {
    this.url = environment.Global.API_GET_SPECIFIC_LC + "ADV";
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.releaseADV = result.Object;
      }
    })
  }

  public async GetLogFields() {
    this.url = environment.Global.API_GET_SPECIFIC_LOG + 'PurchaseOrderLine/' + this.u_obj['OrderLineId'];
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        let table = JSON.parse(JSON.stringify(result.Object));
        let list = [];
        table.TableFields.forEach(field => {
          if (field.TableFieldDetail.length != 0) {
            list.push(field);
          }
        });
        this.logList = list;
        // console.log(this.logList)
        if (Array.isArray(this.logList)) {
          this.pageTotalLog = this.logList.length;
        }
      }
    })
  }

  public OpenDetail(index, log) {
    this.objRowLog = log;
    this.hiddenLog[index] = !this.hiddenLog[index];

  }

  //DESCARGA DE DOCUMENTOS
  public DownloadFile(obj) {
    //console.log("entramos a descargar, objeto:",row)
    let FileName = obj.FileName;
    this.url = environment.Global.API_DOWNLOAD_FILE_POL + obj.PurchaseOrderLineDocumentId;
    //console.log("url:",this.url, "filename:",FileName)
    this.messageShowLoading();
    this.srv.downloadFiles(this.url).subscribe(result => {
      importedSaveAs(result, FileName)
      this.closeMessageDialog();
    })
  }

  // Functions to fields
  public setDifferenceWeight() {
    // delete (,)
    let GrossWeightDispatched = this.u_obj['GrossWeightDispatched'].toString().replace(/'|,|/g, "");
    let GrossWeight = this.u_obj['GrossWeight'].toString().replace(/'|,|/g, "");

    if (this.u_obj['GrossWeightDispatched'] != null) {
      this.u_obj['GrossWeightDifference'] = this.setMaskNumber(Number(GrossWeight) - Number(GrossWeightDispatched));
      this.u_obj['AmountUE']  = Number(GrossWeightDispatched) * Number(this.u_obj['PurchasePrice'] != null ? this.u_obj['PurchasePrice']:0);

    } else {
      this.u_obj['GrossWeightDifference'] = this.u_obj['GrossWeightDispatched'];
    }

    // calculamos el monto


  }

  public setFIProvisioned() {
    let valor = "";
    if (this.u_obj['PlantPrice'] != null) {
      //this.u_obj['ProvisionedFI'] = (((this.u_obj['PlantPrice'] / this.u_obj['PurchasePrice']) - 1) * 100).toFixed(2);
      if (this.u_obj['RateExchange'] != null) {
        // this.u_obj['ProvisionedFI'] = ((this.u_obj['PurchasePrice'] * this.u_obj['RateExchange']) / this.u_obj['PlantPrice']) * (-1);
        valor = (((this.u_obj['PlantPrice'] / this.u_obj['PurchasePrice']) - 1) * 100).toString();
        // console.log("FI:",valor.toString().indexOf('.'))
        this.u_obj['ProvisionedFI'] = valor.substring(0, (valor.toString().indexOf('.') + 5))

      }
      else {
        this.u_obj['ProvisionedFI'] = this.u_obj['PlantPrice'];
      }

    } else {
      this.u_obj['ProvisionedFI'] = this.u_obj['PlantPrice'];
    }
  }

  public setDifferenceDaysTT() {
    if (this.u_obj['EtdRequired'] != null && this.u_obj['EtaRequired'] != null) {
      let EtdRequired = new Date(this.u_obj['EtdRequired']);
      let EtaRequired = new Date(this.u_obj['EtaRequired']);
      EtdRequired.setDate(EtdRequired.getDate() + 1);
      EtaRequired.setDate(EtaRequired.getDate() + 1);
      let differenceDays = EtaRequired.getTime() - EtdRequired.getTime();
      let result = differenceDays / (1000 * 60 * 60 * 24);
      this.u_obj['DifferenceDaysTT'] = result;
    } else {
      this.u_obj['DifferenceDaysTT'] = this.u_obj['DifferenceDaysTT'];
    }
  }

  public setDifferenceDaysTTReal() {
    if (this.u_obj['EtdReal'] != null && this.u_obj['EtaReal'] != null) {
      let EtdReal = new Date(this.u_obj['EtdReal']);
      let EtaReal = new Date(this.u_obj['EtaReal']);
      EtdReal.setDate(EtdReal.getDate() + 1);
      EtaReal.setDate(EtaReal.getDate() + 1);
      let differenceDays = EtaReal.getTime() - EtdReal.getTime();
      let result = differenceDays / (1000 * 60 * 60 * 24);
      this.u_obj['DifferenceDaysTTReal'] = result;
    } else {
      this.u_obj['DifferenceDaysTTReal'] = this.u_obj['DifferenceDaysTTReal'];
    }

    if (this.u_obj['EtdReal'] != null) {
      let EtdRequired = new Date(this.u_obj['EtdRequired']);
      let EtdReal2 = new Date(this.u_obj['EtdReal']);
      EtdRequired.setDate(EtdRequired.getDate() + 1);
      EtdReal2.setDate(EtdReal2.getDate() + 1);
      let differenceDays = EtdReal2.getTime() - EtdRequired.getTime();
      let result = differenceDays / (1000 * 60 * 60 * 24);
      this.u_obj['ShipmentAlert'] = result;
    }
  }

  public setDaysFree() {
    if (this.u_obj['CAS'] != null && this.u_obj['EtaReal'] != null) {
      let CAS = new Date(this.u_obj['CAS']);
      let EtaReal = new Date(this.u_obj['EtaReal']);
      CAS.setDate(CAS.getDate() + 1);
      EtaReal.setDate(EtaReal.getDate() + 1);
      let differenceDays = CAS.getTime() - EtaReal.getTime();
      let result = differenceDays / (1000 * 60 * 60 * 24);
      this.u_obj['DaysFree'] = result;
    } else {
      this.u_obj['DaysFree'] = this.u_obj['DaysFree'];
    }
  }

  public setEtaDepartureAuthorized() {
    if (this.u_obj['AuthorizedDepartureDate'] != null && this.u_obj['EtaReal'] != null) {
      let AuthorizedDepartureDate = new Date(this.u_obj['AuthorizedDepartureDate']);
      let EtaReal = new Date(this.u_obj['EtaReal']);
      AuthorizedDepartureDate.setDate(AuthorizedDepartureDate.getDate() + 1);
      EtaReal.setDate(EtaReal.getDate() + 1);
      let differenceDays = AuthorizedDepartureDate.getTime() - EtaReal.getTime();
      let result = differenceDays / (1000 * 60 * 60 * 24);
      this.u_obj['EtaDepartureAuthorized'] = result;
    } else {
      this.u_obj['EtaDepartureAuthorized'] = this.u_obj['EtaDepartureAuthorized'];
    }
  }

  public setPortPlantTT() {
    if (this.u_obj['PlantArrival'] != null && this.u_obj['EtaReal'] != null) {
      let PlantArrival = new Date(this.u_obj['PlantArrival']);
      let EtaReal = new Date(this.u_obj['EtaReal']);
      PlantArrival.setDate(PlantArrival.getDate() + 1);
      EtaReal.setDate(EtaReal.getDate() + 1);
      let differenceDays = PlantArrival.getTime() - EtaReal.getTime();
      let result = differenceDays / (1000 * 60 * 60 * 24);
      this.u_obj['PortPlantTT'] = result;
    } else {
      this.u_obj['PortPlantTT'] = this.u_obj['PortPlantTT'];
    }
  }

  public setRealFI() {
    if (this.u_obj['PlantPriceReal'] != null && this.u_obj['PurchasePrice'] != null) {
      let total = (this.u_obj['PlantPriceReal'] / this.u_obj['PurchasePrice']) - 1;
      this.u_obj['RealFI'] = total;
    } else {
      this.u_obj['RealFI'] = this.u_obj['RealFI'];
    }

    if (this.u_obj['RealFI'] != null) {
      let total = this.u_obj['RealFI'] - this.u_obj['ProvisionedFI'];
      this.u_obj['DifferenceFI'] = total;
    } else {
      this.u_obj['DifferenceFI'] = this.u_obj['DifferenceFI'];
    }
  }

  handleKeyDown(e, max: number) {
    // console.log(e)
    if (e.key == "Backspace" || e.key == "ArrowRight" || e.key == "ArrowLeft") {
      --this.contador;
    } else {
      ++this.contador;
      // console.log(this.contador);
      if (this.contador > max) {
        this.messageDialog("Ha alcanzado el límite de caracteres permitidos(200)", '', 'warning');
        e.stopPropagation(); // rechazamos las pulsaciones de teclas
      } else {
        // console.log(this.contador)
      }

    }
  }
  public async SaveComment(obj) {
    this.url = environment.Global.API_SAVE_SPECIFIC_LOG_COMMENT + obj.Comment + "/" + obj.TableFieldDetailId;
    // console.log(this.url);

    // console.log("user:", this.user.sub == obj.UserCode);
    // console.log("UserCode", obj.UserCode);
    // console.log("user == UserCode", this.user.sub == obj.UserCode);
    // console.log("user = UserCode", this.user.sub == obj.UserCode);
    // console.log("Desabilitado = false", obj.Desabilitado);

    if (obj.Comment == null) {
      this.errorMessageWithoutHtml('Error', 'No se ha ingresado ninguna observación', 'error');
      return;

    }
    if (this.user.sub == obj.UserCode && obj.Desabilitado == false) {
      this.srv.post(this.url, obj).toPromise().then(result => {
        if (result.Status) {
          this.messageDialog(result.Message, '', 'success');
          this.clearData();

        } else {
          // console.log("error:", result.Message);
          this.messageDialog(result.Message, '', 'error');
        }
      }, err => {
        this.messageDialog(err, '', 'error');
        this.isCreating = false;
      })
      // console.log(obj)
    }

  }

  public disabledComment(obj) {
    // debugger
    let flag = false;
    // console.log(this.user.sub == obj.UserCode);
    // console.log(obj.Desabilitado);

    if (this.user.sub == obj.UserCode && obj.Desabilitado == false) {
      // console.log("Se encuentra deshabilitado")
      return true;
    }

  }

  public openUpdateModal(modal, object) {
    // debugger
    // console.log(object);

    this.c_obj = JSON.parse(JSON.stringify(object));
    this.c_obj["DocumentComment"] = ""
    this.c_obj['OrderNumber'] = this.u_obj['OrderNumber'];
    // console.log(this.c_obj);
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });

  }

  public openUpdateModalView(modal, object) {

    //console.log("object=>",object)
    let url = environment.Global.url.substring(0, (environment.Global.url.length - 4))
    let termino = "Documents";

    let PathFile = object.PathFile;
    let posicion = PathFile.indexOf(termino);

    //let extension='pdf';

    let extension = object.FileName.split('.');
    extension = extension[extension.length - 1];


    if (extension == 'pdf') {
      this.isPDF = true;
      this.isImage = false;
    } else if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' || extension == 'JPEG' || extension == 'png' || extension == 'PNG') {
      this.isPDF = false;
      this.isImage = true;
    } else {
      this.isPDF = false;
      this.isImage = false;
    }


    this.pdfSrc = url + (PathFile.substring(posicion, PathFile.length));

    this.pdfSrc = this.pdfSrc.replace(/\\/g, "/");

    // console.log(this.pdfSrc);

    //this.isUpdating = false;
    if (this.isPDF != false || this.isImage != false) {
      this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
    }
    else {
      this.messageDialog("Archivo de extension no valido para visualizar", '', 'error');
      this.closeModal(modal);
    }
  }
}
