<app-header-options (updateInfo)="getPurchaseOrderLinesDetails()" (search)="searchData($event)" [isLoading]="isLoading"
  [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>

<div class="row" *ngIf="mode != 0 && OrderNumber!= '' else mode0">
  <div class="col-md-12">
    <h4>{{'purchase_order_line' | translate}} #{{OrderNumber}} - {{OrderDisplay}}</h4>
  </div>
</div>

<ng-template #mode0>
  <div class="row" *ngIf="mode == 0 && objRow != null">
    <div class="col-md-12">
      <!--      <h4>{{'purchase_order' | translate}} #{{objRow.OrderNumber}}</h4>-->
    </div>
  </div>
</ng-template>


<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-color">
          <tr>
            <th>
              <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                  class="icon mdi mdi-filter-remove" *ngIf="showIconF" (click)="showIconFilter(false)"></i></label>
              <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i class="icon mdi mdi-filter"
                  *ngIf="!showIconF" (click)="showIconFilter(true)"></i></label>
            </th>
            <th class="sh min-wrap">
              <label name="ID">#</label>
            </th>
            <th class="sh min-wrap">
              <label name="OC">{{'OC' | translate}}</label>
            </th>
            <th class="sh">
              <label name="AssociatedPurchaseOrders">{{'AssociatedPurchaseOrders' | translate}}</label>
            </th>
            <!-- <th class="sh">
              <label class="text-nowrap" name="dispatch">{{'dispatch' | translate}}  {{'DateDepartureReception' | translate}} {{'HourDepartureReception' | translate}} {{'Agent' | translate}} {{'ResponsableAgent' | translate}}</label>
            </th> -->
            <th class="sh">
              <label name="ContainerNumber">{{'ContainerNumber' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="DateDepartureReception">Fecha Planificada<br>de Recepción</label>
            </th>
            <th class="sh break-line">
              <label class="text-nowrap" name="HourDepartureReception">Hora Planificada<br>de Recepción</label>
            </th>
            <th class="sh">
              <label name="RescheduleDate">{{'RescheduleDate' | translate}}</label>
            </th>
            <th class="sh">
              <label name="RescheduleHour">{{'RescheduleHour' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="Agent">Agente<br>de aduana</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="ResponsableAgent">Responsable<br>agente aduana</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="Eta">{{'Eta' | translate}}</label>
            </th>
            <th class="sh">
              <label name="EndCas">{{'EndCas' | translate}}</label>
            </th>
            <th class="sh">
              <label name="PortShipment">{{'PortShipment' | translate}}</label>
            </th>
            <th class="sh">
              <label name="ResponsableSK">{{'ResponsableSK' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="Supplier">{{'Supplier' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="ItemName">{{'ItemName' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="Packaging">{{'Packaging' | translate}}</label>
            </th>
            <th class="sh">
              <label name="Bl">{{'Bl' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="StampBl">{{'StampBl' | translate}}</label>
            </th>
            <th class="sh">
              <label class="text-nowrap" name="WeightItem">{{'WeightItem' | translate}}</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <!--        *ngIf="showIconF"-->
          <tr *ngIf="showIconF">
            <td></td>
            <td></td>
            <td>
              <div (click)="resetData(0,'OrderNumber')">
                <app-select-filter [column]="'OrderNumber'" [arrayList]="arrayList[0]" [indexPosition]="0"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(1,'AssociatedPurchaseOrders')">
                <app-select-filter [column]="'AssociatedPurchaseOrders'" [arrayList]="arrayList[1]" [indexPosition]="1"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <!-- <td>
              <div (click)="resetData(2,'OrderDisplay')">
                <app-select-filter [column]="'OrderDisplay'" [arrayList]="arrayList[2]" [indexPosition]="2"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td> -->
            <td>
              <div (click)="resetData(3,'ContainerNumber')">
                <app-select-filter [column]="'ContainerNumber'" [arrayList]="arrayList[3]" [indexPosition]="3"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(4,'DateDepartureReception')">
                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="arrayList[4]" [indexPosition]="4"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>

            <td>
              <div (click)="resetData(5,'HourDepartureReception')">
                <app-select-filter [column]="'HourDepartureReception'" [arrayList]="arrayList[5]" [indexPosition]="5"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>

            <td>
              <div (click)="resetData(6,'RescheduleDate')">
                <app-select-filter [column]="'RescheduleDate'" [arrayList]="arrayList[6]" [indexPosition]="6"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>

            <td>
              <div (click)="resetData(7,'RescheduleHour')">
                <app-select-filter [column]="'RescheduleHour'" [arrayList]="arrayList[7]" [indexPosition]="7"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>



            <td>
              <div (click)="resetData(8,'Agent')">
                <app-select-filter [column]="'Agent'" [arrayList]="arrayList[8]" [indexPosition]="8"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(9,'NameAgenteAduana')">
                <app-select-filter [column]="'NameAgenteAduana'" [arrayList]="arrayList[9]" [indexPosition]="9"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(10,'EtaRequired')">
                <app-select-filter [column]="'EtaRequired'" [arrayList]="arrayList[10]" [indexPosition]="10"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(11,'CAS')">
                <app-select-filter [column]="'CAS'" [arrayList]="arrayList[11]" [indexPosition]="11"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(12,'PortShipment')">
                <app-select-filter [column]="'PortShipment'" [arrayList]="arrayList[12]" [indexPosition]="12"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(13,'NameResposabilitySK')">
                <app-select-filter [column]="'NameResposabilitySK'" [arrayList]="arrayList[13]" [indexPosition]="13"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(14,'CompanyName')">
                <app-select-filter [column]="'CompanyName'" [arrayList]="arrayList[14]" [indexPosition]="14"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(15,'ItemName')">
                <app-select-filter [column]="'ItemName'" [arrayList]="arrayList[15]" [indexPosition]="15"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(16,'PackagingName')">
                <app-select-filter [column]="'PackagingName'" [arrayList]="arrayList[16]" [indexPosition]="16"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(17,'Bl')">
                <app-select-filter [column]="'Bl'" [arrayList]="arrayList[17]" [indexPosition]="17"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(18,'BlSeal')">
                <app-select-filter [column]="'BlSeal'" [arrayList]="arrayList[18]" [indexPosition]="18"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
            <td>
              <div (click)="resetData(19,'ProductWeight')">
                <app-select-filter [column]="'ProductWeight'" [arrayList]="arrayList[19]" [indexPosition]="19"
                  (filterValue)="searchDataSelect($event)"></app-select-filter>
              </div>
            </td>
          </tr>
          <ng-container
            *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
            <tr>
              <td>
                <div>
                  <i class="icon mdi mdi-arrow-down-bold-circle" *ngIf="hidden[i]" (click)="hidden[i] = !hidden[i]"></i>
                  <i class="icon mdi mdi-arrow-right-bold-circle" *ngIf="!hidden[i]"
                    (click)="seeDetailsRow(i, row)"></i>
                </div>
              </td>
              <td>
                <label for="code">{{i + 1}}</label>
              </td>
              <td>
                <label for="OrderNumber">{{row.OrderNumber}}</label>
              </td>
              <td>
                <label for="OrderDisplay" *ngIf="row.AssociatedPurchaseOrders != null">{{row.AssociatedPurchaseOrders}}</label>
                <label for="OrderDisplay" *ngIf="row.AssociatedPurchaseOrders == null">-</label>

              </td>
              <!-- <td>
                <label for="OrderDisplay">{{row.OrderDisplay}}</label>
              </td> -->

              <td>
                <label for="ContainerNumber">{{row.ContainerNumber}}</label>
              </td>
              <td>
                <label for="DateDepartureReception">{{row.DateDepartureReception | date:'y-MM-d'}}</label>
              </td>
              <td>
                <label for="HourDepartureReception">{{row.HourDepartureReception}}</label>
              </td>
              <td>
                <label for="RescheduleDate">{{row.RescheduleDate | date:'y-MM-d'}}</label>
              </td>
              <td>
                <label for="RescheduleHour">{{row.RescheduleHour}}</label>
              </td>
              <td>
                <label for="Agent">{{row.Agent}}</label>
              </td>
              <td>
                <!--<label for="NameAgenteAduana">{{row.NameAgenteAduana}}</label>-->
                <label for="NameAgenteAduana">{{row.UserIdsNames}}</label>
              </td>
              <td>
                <label for="EtaRequired">{{row.EtaRequired | date:'y-MM-d'}}</label>
              </td>
              <td>
                <label for="CAS">{{row.CAS | date:'y-MM-d'}}</label>
              </td>
              <td>
                <label for="PortShipment">{{row.PortShipment}}</label>
              </td>
              <td>
                <label for="NameResposabilitySK">{{row.NameResposabilitySK}}</label>
              </td>
              <td>
                <label for="CompanyName">{{row.CompanyName}}</label>
              </td>
              <td>
                <label for="ItemName">{{row.ItemName}}</label>
              </td>

              <td>
                <label for="PackagingName">{{row.PackagingName}}</label>
              </td>
              <td>
                <label for="Bl">{{row.Bl}}</label>
              </td>
              <td>
                <label for="BlSeal">{{row.BlSeal}}</label>
              </td>
              <td>
                <label for="ProductWeight">{{row.ProductWeight}}</label>
              </td>
            </tr>
            <tr *ngIf="hidden[i]">
              <td></td>
              <td colspan="19">
                <app-container-detail-pold [obj]="row" [user]="user" [index]="i" [userType]="userType"
                  (refreshData)="refreshData($event)"></app-container-detail-pold>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>

        </tfoot>

      </table>



    </div>
    <ngb-pagination class="d-flex justify-content-end mt-2" [collectionSize]="pageTotal" [(page)]="page"
      [pageSize]="pageSize" [maxSize]="5">
    </ngb-pagination>
  </div>
</div>
