import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent extends BaseCrudComponent implements OnInit {
  statusList = [];
  itemList = [];
  userList = [];
  origenList = environment.Global.COUNTRY;
  constructor(
    private globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['DocumentName', 'Description', 'Observation', 'DescriptionStatus'];
  }


  async ngOnInit() {
    await super.ngOnInit();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_DOC");
    await this.getInitialData();
    await this.getInternalUser();
  }

  public async getInitialData() {
    this.messageShowLoading();
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
    this.url = environment.Global.API_GET_ALL_DOC;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if (Array.isArray(this.rows)) {
          this.pageTotal = this.rows.length;
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
    await this.getStatus();
    await this.getItems();
  }

  public async getStatus() {
    this.url = environment.Global.API_GET_ALL_ST;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.statusList = result.Object;
      }
    })
  }
  public async getItems() {
    this.url = environment.Global.API_GET_ITEM;

    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.itemList = result.Object;
      }
    })
    // console.log(this.itemList);
  }

  public createForm(object: any): boolean {
    let myForm: any = {};
    myForm = new FormGroup({
      'DocumentName': new FormControl(object.DocumentName, [Validators.required]),
      'Description': new FormControl(object.Description, [Validators.required]),
      'Observation': new FormControl(object.Observation, [Validators.required]),
      'StatusId': new FormControl(object.StatusId, [Validators.required]),
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.c_obj['DocumentUserApp'] = [];
    this.c_obj['ItemId'] = "0";
    this.c_obj['Origen'] = "0";
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public createItem(modal, object) {
    this.isCreating = true;
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    let users = [];
    if (object.DocumentUserApp.length > 0) {
      object.DocumentUserApp.forEach(element => {
        let data = {
          UserId: Number(element)
        }
        users.push(data)
      });
    }
    if (object.RequireApproval && users.length == 0) {
      this.isCreating = false
      return this.errorMessage('ChooseUser', 'YouMustSelectUser', 'error');
    }
    this.obj = {
      DocumentName: object.DocumentName,
      Description: object.Description,
      Observation: object.Observation,
      StatusId: Number(object.StatusId),
      ItemId: Number(object.ItemId),
      Origen: object.Origen,
      RequireApproval: object.RequireApproval ? 1 : 0,
      CreatorUser: this.user.name,
      DocumentUserApp: object.RequireApproval ? users : []
    }
    this.url = environment.Global.API_CREATE_DOC;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isCreating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isCreating = false;
    })
  }

  public clickOnCheck(event, id) {
    if (event.target.checked) {
      this.changeStatus('1', id);
    } else {
      this.changeStatus('2', id);
    }
  }

  public changeStatus(value, id) {
    this.url = environment.Global.API_UPDATE_STATUS_DOC;
    this.obj = {
      DocumentId: id,
      StatusDocument: value,
      ModifierUser: this.user.name,
      DeleteUser: this.user.name
    }
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    }, err => {
      this.messageDialog(err, '', 'error');
    })
  }

  public openUpdateModal(modal, object) {
     console.log(object);
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    this.u_obj['UpdateUserByTransportCompany'] = [];
    this.u_obj.StatusId = this.u_obj.StatusId.toString();

    if (this.u_obj.ItemId != null)
      this.u_obj.ItemId = this.u_obj.ItemId.toString();
    else
      this.u_obj.ItemId = 0;
    if (this.u_obj.Origen != null)
      this.u_obj.Origen = this.u_obj.Origen;
    else
      this.u_obj.Origen = "0";

    this.u_obj.ItemId = this.u_obj.ItemId.HasValue ? 0 : this.u_obj.ItemId.toString();
    this.u_obj.Origen = this.u_obj.Origen.HasValue ? 0 : this.u_obj.Origen.toString();

    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    if (this.u_obj['DocumentUserApp'].length > 0) {
      this.u_obj['DocumentUserApp'].forEach(element => {
        this.u_obj['UpdateUserByTransportCompany'].push(element.UserId.toString());
      });
    }
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    }
    let users = [];
    if (object.UpdateUserByTransportCompany.length > 0) {
      object.UpdateUserByTransportCompany.forEach(element => {
        let data = {
          UserId: Number(element)
        }
        users.push(data)
      });
    }
    if (object.RequireApproval && users.length == 0) {
      this.isUpdating = false
      return this.errorMessage('ChooseUser', 'YouMustSelectUser', 'error');
    }
    this.obj = {
      DocumentId: object.DocumentId,
      DocumentName: object.DocumentName,
      Description: object.Description,
      Observation: object.Observation,
      StatusId: Number(object.StatusId),
      ItemId: Number(object.ItemId),
      Origen: object.Origen,
      RequireApproval: object.RequireApproval ? 1 : 0,
      ModifierUser: this.user.name,
      DocumentUserApp: object.RequireApproval ? users : []
    }
    this.url = environment.Global.API_UPDATE_DOC;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }

  public async getInternalUser() {
    this.url = environment.Global.API_GET_USER_INT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.userList = result.Object;
      }
    })
  }

}
