import { Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { LoginGuard } from './services/login.guard';

import { BlankComponent } from './comex/layouts/blank/blank.component';
import { AuthCallbackComponent } from './comex/redirectS/auth-callback/auth-callback.component';
import { NotfoundComponent } from './authentication/404/not-found.component';
import { SilentRefreshComponent } from './comex/redirectS/silent-refresh/silent-refresh.component'; 

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '',
        pathMatch: 'full', 
        redirectTo: '/comex',
      },
      { path: 'silent-refresh', component: SilentRefreshComponent },
      {
        path: 'comex',
        loadChildren:
          () => import('./comex/comex.module').then(m => m.ComexModule)
      },
      { path: '**', redirectTo: 'authentication/404', pathMatch: 'full' },
      { path: 'callback', component: AuthCallbackComponent },
    ],
  },
];
