import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { computeStartOfLinePositions } from "@angular/compiler-cli/src/ngtsc/sourcemaps/src/source_file";
import { forEachChild } from 'typescript';
import { element } from 'protractor';
import { data } from 'jquery';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent extends BaseCrudComponent implements OnInit {
  optionsList = ["Dispatchs", "Containers"];
  optionSelected = null;
  enabledDepartment = false;
  enabledAgent = false;
  showErrorMessage = false;
  showAgent = false;
  showTransport = false;
  agentList = [];
  userList = [];
  columnsList = [];
  columnsList2 = [];
  statusList = [];
  status: any = [];
  checkedRead: boolean = false;
  checkedUpdate: boolean = false;
  checkedReadUp: boolean = true;
  checkedUpdateUp: boolean = true;
  tipoPer = 0;
  DepartmentList = [];
  prueba: any;
  optionUser = ['Compras', 'Logística', 'Bodega', 'Transporte', "Agente Aduana", "Finanzas", "Taxes", "Calidad", "Compras 2"];

  ListAgentPermisions = [];


  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['AgentName', 'RUC', 'TypeName', 'TotalPermissions','ParameterName'];
    this.pageSize2 = 45;
  }

  async ngOnInit() {

    this.DepartmentList = [];
    await this.getUser();
    await this.getStatus();

    await super.ngOnInit();
    await this.getInitialData();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_PRS");

  }

  public async getInitialData() {
    this.DepartmentList = [];
    this.c_obj['DepartmentId'] = ""
    this.enabledAgent = false;

    this.messageShowLoading();
    this.url = environment.Global.API_GET_PER;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        // console.log(result.Object);
        this.rows = result.Object;
        this.rawdata = result.Object;
        //console.log("data:", this.rows)
        this.ListAgentPermisions = this.rows.filter(t => t.Type == 0);
        if (Array.isArray(this.rows)) {
          this.pageTotal = this.rows.length;
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
  }

  public openCreateModal(modal) {
    this.status = [];
    this.columnsList = [];
    this.isCreating = false;
    this.c_obj['AgentIdCopy'] = ''
    this.enabledAgent = false;

    this.showErrorMessage = true;
    this.optionSelected = null;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true, windowClass: 'myCustomModalClass' });
  }

  public async copyPermmitions(AgentIdCopy) {
    this.getPermitionsByAgent(AgentIdCopy)
  }

  public async getPermitionsByAgent(AgentIdCopy) {

    let AgentPermisionsSelected = []
    this.columnsList = []

    // Obtiene la informacion del agente seleccionado
    this.ListAgentPermisions.forEach(element => {
      if (element.AgentId == AgentIdCopy) {
        // console.log(element);
        AgentPermisionsSelected = element.PermissionsDetail
      }
    });

    // Recrea el arreglo de permisos, con los permisos seleccionados del Agente
    AgentPermisionsSelected.forEach(element2 => {

      var optionRead: any
      var optionUpdate: any

      optionRead = (element2.ReadField == 1) ? true : false
      optionUpdate = (element2.UpdateField == 1) ? true : false

      let obj = {
        'ColumnName': element2.Description,
        'Id': element2.SystemParameterDetailId,
        'Read': optionRead,
        'Update': optionUpdate,
      }
      this.columnsList.push(obj)

    });

    return this.columnsList

  }


  // public select(event, id: number) {


  //   this.columnsList.forEach(element => {
  //     switch (id) {
  //       case 1:
  //         if (!element.Read) {
  //           return this.checkedRead = false
  //         }
  //         break;
  //       case 2:
  //         if (!element.Update) {
  //           return this.checkedUpdate = false
  //         }
  //         break;

  //       default:
  //         break;
  //     }
  //   });

  // }


  public selectedHead(event, id: number) {
    debugger
    this.columnsList.forEach(element => {
      switch (id) {
        case 1:
          element.Read = event
          break;
        case 2:
          element.Update = event
          break;

        default:
          break;
      }


    });
  }
  public selectedHeadUpdate(event, id: number) {
    debugger

    this.u_obj.PermissionsDetail.forEach(field => {

      switch (id) {
        case 1:
          field.Read = event;

          // element.Read = event
          break;
        case 2:
          field.Update = event
          break;

        default:
          break;
      }
    });

  }
  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public async GetColumns() {
    this.DepartmentList = [];
    this.c_obj['DepartmentId'] = ""
    await this.getDepartment();
    await this.getStatus();
    this.enabledAgent = false;
    if (this.optionSelected != null) {
      this.showErrorMessage = false;
      this.enabledDepartment = true
      if (this.optionSelected == "Dispatchs") {


        //  await this.GetColumnsNamePerTable(1);
        //  await this.GetAgent();
        this.tipoPer = 1;
        this.GetColumnsNamePerTable(1);
      } else {
        this.tipoPer = 2;
        await this.GetColumnsNamePerTable(2);
      }


    } else {
      this.showErrorMessage = true;
    }
  }
  public async GetColumnsAgent(obj) {

    // validamos que el id seleccionado sea del Agente de Aduana
    this.url = environment.Global.API_GET_CUSTOMBROKERID + obj.DepartmentId;
    console.log(this.url)
    await this.srv.get(this.url).toPromise().then(result => {
      // console.log(result.Status);
      if (result.Status) {
        this.enabledAgent = true;
        debugger
        this.GetAgent();
      } else {
        this.enabledAgent = false;
      }

      // console.log(obj)
      this.DepartmentList.forEach(element => {
        // console.log(obj)
        if (element.SystemParameterDetailId == obj.DepartmentId && this.optionSelected == 'Containers') {

          this.columnsList.forEach(element2 => {
            // this.checkedRead = element.Read == false ? false : true
            // this.checkedUpdate = element.Update == false ? false : true
            // seteamos todos los estados al combo de estados
            // console.log(this.optionUser);
            let estados = [];
            for (let index = 0; index < this.optionUser.length; index++) {

              if (index == 0 || index == 1 || index == 2 || index == 3 || index == 4 || this.optionUser[index] == element.ParameterName)
                estados.push(this.optionUser[index]);
            }
            element2.StatusList = estados;

          });

        }
      });

    })



    // console.log(this.enabledAgent);




    // this.DepartmentList.forEach(element => {
    //   if (element.ParameterName == 'Agente de Aduana' && obj.DepartmentId == element.SystemParameterDetailId) {
    //     console.log("Es un agente de Aduana");

    //     this.GetColumnsNamePerTable(1);
    //     this.GetAgent();
    //     return this.enabledAgent = true;

    //   } else {
    //     console.log("No es un agente de Aduana");
    //     this.enabledAgent = false;
    //   }

    // });
    // this.DepartmentAduanaId = this.DepartmentList.find(element => )





    // if (obj.DepartmentId == this.DepartmentAduanaId) {
    //   console.log("usted ha seleccionado el agente de aduana");
    //   await this.GetColumnsNamePerTable(1);
    //   await this.GetAgent();
    //   this.EnabledCustomBroker == true;
    // } else {
    //   this.EnabledCustomBroker == false;
    //   this.tipoPer = 2;
    //   await this.GetColumnsNamePerTable(2);
    // }

  }

  public async GetAgent() {
    this.url = environment.Global.API_GET_AG_PERM;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.agentList = result.Object;
      }
    })
  }

  public async getUser() {
    this.url = environment.Global.API_GET_USER_INT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.userList = result.Object;
      }
    })
  }

  public async GetColumnsNamePerTable(type: number) {
    //console.log("tipo:",type)
    if (type == 1) {
      this.url = environment.Global.API_GET_COLUMNS_NAME_POL;
      await this.srv.get(this.url).toPromise().then(result => {
        if (result.Status) {
          this.columnsList = JSON.parse(JSON.stringify(result.Object));
          let estados = [];

          this.statusList.forEach(dat => {
            estados.push(dat.Description)
          });


          if (Array.isArray(this.rows)) {
            this.pageTotal2 = this.columnsList.length;

            this.columnsList.forEach(element => {
              this.checkedRead = element.Read == false ? false : true
              this.checkedUpdate = element.Update == false ? false : true

              element.StatusList = estados; // seteamos todos los estados al combo de estados
            });
          }
          // console.log(this.columnsList)

        }
      });
    } else {
      this.url = environment.Global.API_GET_COLUMNS_NAME_POLD;
      await this.srv.get(this.url).toPromise().then(result => {
        if (result.Status) {
          //console.log("resulta:",result)
          // let optionUser = [];

          // this.optionUser.forEach(dat => {
          //   estados.push( dat.Description)
          // });

          this.columnsList = JSON.parse(JSON.stringify(result.Object));

          this.columnsList.forEach(element => {
            // this.checkedRead = element.Read == false ? false : true
            // this.checkedUpdate = element.Update == false ? false : true
            // seteamos todos los estados al combo de estados
            // console.log(this.optionUser);
            let estados = [];
            for (let index = 0; index < this.optionUser.length; index++) {
              if (index == 0 || index == 1 || index == 2 || index == 3 || index == 4)
                estados.push(this.optionUser[index]);
            }
            element.StatusList = estados;

          });

          if (Array.isArray(this.rows)) {
            this.pageTotal2 = this.columnsList.length;
          }
        }
      });
    }

  }

  public createItem(modal, Object) {
    // console.log("tipo per:", this.tipoPer)

    this.isCreating = true;
    debugger
    if (this.tipoPer == 1) { // crea permisos despchos

      if (Object.DepartmentId == undefined || Object.DepartmentId == null || Object.DepartmentId == "") {
        this.isCreating = false;
        return this.errorFormMessage();
      }

      // COMPROBAMOS QUE EL DEPARTAMENTO SELECCIONADO NO SEA AGENTE DE ADUANA
      if (this.enabledAgent == true) {
        // console.log(" es un agente de aduana")
        if (Object.AgentId == undefined || Object.AgentId == null) {
          this.isCreating = false;
          return this.errorFormMessage();
        }
      } else {
        // no se ha seleccionado el departamento de Agente de aduana, se va a colocar 0

        Object.AgentId = 0

      }

      this.columnsList.forEach(element => {
        element.DepartmentId = Object.DepartmentId
        element.ReadField = element.Read ? 1 : 0;
        element.UpdateField = element.Update ? 1 : 0;
        element.SystemParameterDetailId = element.Id;
        element.StatusIds = (element.StatusList != undefined || element.StatusList != null) ? element.StatusList.join(',') : null;
        element.UsersApproval = (element.UserList == undefined || element.UserList == null) ? null : element.UserList.join(',');
      })


      this.obj = {
        AgentId: Object.AgentId,
        DepartmentId: Object.DepartmentId,
        PermissionsDetail: this.columnsList,
        CreatorUser: this.user.name,
        Type: this.optionSelected == 'Dispatchs' ? 0 : 1
      }
      // console.log("despachos:", this.obj);
      this.url = environment.Global.API_CREATE_PER;
      this.srv.post(this.url, this.obj).subscribe(result => {
        if (result.Status) {
          this.messageDialog(result.Message, '', 'success');
          this.clearData();
        } else {
          this.messageDialog(result.Message, '', 'error');
        }
        this.optionSelected = null;
        this.isCreating = false;
        this.closeModal(modal);
      }, err => {
        this.messageDialog(err, '', 'error');
        this.isCreating = false;
      })
    }
    else { //crea permisos contenedores

      if (Object.DepartmentId == undefined || Object.DepartmentId == null || Object.DepartmentId == "") {
        this.isCreating = false;
        return this.errorFormMessage();
      }

      this.columnsList.forEach(element => {
        element.ReadField = element.Read ? 1 : 0;
        element.UpdateField = element.Update ? 1 : 0;
        element.SystemParameterDetailId = element.Id;
        element.StatusIds = (element.StatusList != undefined || element.StatusList != null) ? element.StatusList.join(',') : null;
        element.UsersApproval = (element.UserList == undefined || element.UserList == null) ? null : element.UserList.join(',');
      })
      //console.log("lista",this.columnsList)

      Object.AgentId = 0

      this.obj = {
        // como es permiso para contenedores en AgentId se envia el DepartmentID

        AgentId: Object.AgentId,
        DepartmentId: Object.DepartmentId,
        PermissionsDetail: this.columnsList,
        CreatorUser: this.user.name,
        Type: 1
      }
      // console.log("obj:",this.obj)
      this.url = environment.Global.API_CREATE_PER;
      this.srv.post(this.url, this.obj).subscribe(result => {
        if (result.Status) {
          this.messageDialog(result.Message, '', 'success');
          this.clearData();
        } else {
          this.messageDialog(result.Message, '', 'error');
        }
        this.optionSelected = null;
        this.isCreating = false;
        this.closeModal(modal);
      }, err => {
        this.messageDialog(err, '', 'error');
        this.isCreating = false;
      })
    }
  }

  public async openUpdateModal(modal, object) {

    this.checkedReadUp = true;
    this.checkedUpdateUp = true;

    debugger
    // console.log(object);
    this.getDepartment();

    if (object.AgentName == null || object.AgentName == "CONTENEDORES") {
      this.tipoPer = 2;
      this.enabledAgent == true;
    } else {
      this.tipoPer = 1;
      this.enabledAgent == false;

    }



    // //AgentName: "CONTENEDORES"
    // if (object.AgentName == "CONTENEDORES" || object.AgentName == "DESPACHOS"
    //   || object.AgentName == "Transportista" || object.AgentName == "Logística" || object.AgentName == "Importaciones"
    //   || object.AgentName == "Finanzas" || object.AgentName == "Calidad" || object.AgentName == "Compras" || object.AgentName == "Taxes") {
    //   this.tipoPer = 2;
    //   this.enabledAgent == false;

    // }
    // else {
    //   this.tipoPer = 1;
    //   this.enabledAgent == true;
    // }
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true, windowClass: 'myCustomModalClass' });
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    // console.log(this.u_obj);
    this.u_obj.PermissionsDetail.forEach(field => {
      field.Read = field.ReadField == 1 ? true : false;
      if (field.ReadField != 1) {
        this.checkedReadUp = false;
      }
      if (field.UpdateField != 1) {
        this.checkedUpdateUp = false;
      }
      field.Update = field.UpdateField == 1 ? true : false;
      field.UsersApproval = field.UsersApproval == null ? '' : field.UsersApproval;
      field.UsersApproval = field.UsersApproval.includes(',') ? field.UsersApproval.split(',') : [field.UsersApproval];
      field.StatusIds = field.StatusIds == null ? '' : field.StatusIds;
      field.StatusIds = field.StatusIds.includes(',') ? field.StatusIds.split(',') : [field.StatusIds];
      field.List = field.UsersApproval == '' ? [] : field.UsersApproval;
      field.StatusValues = field.StatusIds == '' ? [] : field.StatusIds;
    });



    // console.log("this.u_obj.PermissionsDetail:", this.u_obj.PermissionsDetail)
    this.optionSelected = this.u_obj.Type == 0 ? 'Dispatchs' : 'Containers';
    if (Array.isArray(this.u_obj.PermissionsDetail)) {
      this.pageTotal2 = this.u_obj.PermissionsDetail.length;
    }
    if (this.u_obj.Type == 0) {
      await this.GetColumnsNamePerTable(1);
      this.columnsList.forEach(column => {
        let flag = false;
        this.u_obj.PermissionsDetail.forEach(permission => {
          if (permission.Description == column.ColumnName) {
            flag = true;
          }
        })
        if (!flag) {
          let obj = {
            PermissionDetailId: 0,
            ReadField: 0,
            UpdateField: 0,
            StatusIds: [],
            UsersApproval: [],
            SystemParameterDetailId: column.Id,
            ParameterName: '',
            Description: column.ColumnName,
            Read: false,
            Update: false,
            List: [],
            StatusValues: []
          }
          this.u_obj.PermissionsDetail.push(obj);
        }
      })
    }
    else {
      await this.GetColumnsNamePerTable(2);
      this.columnsList.forEach(column => {
        let flag = false;
        this.u_obj.PermissionsDetail.forEach(permission => {
          if (permission.Description == column.ColumnName) {
            flag = true;
          }
        })
        if (!flag) {
          let obj = {
            PermissionDetailId: 0,
            ReadField: 0,
            UpdateField: 0,
            StatusIds: [],
            UsersApproval: [],
            SystemParameterDetailId: column.Id,
            ParameterName: '',
            Description: column.ColumnName,
            Read: false,
            Update: false,
            List: [],
            StatusValues: []
          }
          this.u_obj.PermissionsDetail.push(obj);
        }
      })
    }
    //console.log("this.columnsList:",this.columnsList)

  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    object.PermissionsDetail.forEach(element => {
      element.ReadField = element.Read ? 1 : 0;
      element.UpdateField = element.Update ? 1 : 0;
      element.StatusIds = element.StatusValues.join(',');
      element.UsersApproval = element.List.join(',');
    });
    this.obj = {
      AgentId: object.AgentId,
      DepartmentId: object.DepartmentId,
      PermissionId: object.PermissionId,
      CreatorUser: this.user.name,
      Type: object.Type,
      PermissionsDetail: object.PermissionsDetail
    }
    this.url = environment.Global.API_UPDATE_PER;
    this.srv.post(this.url, this.obj).subscribe(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }

  public async getStatus() {
    this.url = environment.Global.API_GET_ALL_ST;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object != null) {
          this.statusList = result.Object;
        }
      }
    });
    // console.log(this.statusList);
  }

  public setStatus(type: Number) {
    if (type == 1) {
      this.c_obj['ParamStatusByUpdate'] = [];
      this.status.forEach(job => {
        this.c_obj['ParamStatusByUpdate'].push(
          {
            Name: job
          }
        )
      });
    } else {
      this.status.forEach(job => {
        this.u_obj['PermissionsDetail'].statusList.push(
          {
            Name: job
          }
        )
      });
    }
  }

  public async getDepartment() {
    this.url = environment.Global.API_GET_DEPARTMENT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        result.Object.forEach(element => {
          this.DepartmentList.push(element)
        });
      }
    })
    // console.log(this.DepartmentList);
  }


}
