<app-header-options (updateInfo)="getPurchaseOrdersDocuments()" (search)="searchData($event)" [isLoading]="isLoading"
    [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>

<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th class="sh">
                            ID
                        </th>


                        <th (click)="setOrder('OrderNumber')" class="sh">
                            {{'OrderNumber' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='OrderNumber')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('OrderDisplay')" class="sh">
                            {{'OrderDisplay' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='OrderDisplay')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>


                        <th (click)="setOrder('Division')" class="sh">
                            {{'Division' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Division')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>
                        <th (click)="setOrder('Supplier')" class="sh">
                            {{'Supplier' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Supplier')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('ItemName')" class="sh">
                            {{'ItemName' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='ItemName')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('ProcedureImportline')" class="sh">
                            {{'ProcedureImportline' | translate}}<i
                                *ngIf="(orderKey!==null)&&(orderKey=='ProcedureImportline')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('GrossWeightDispatched')" class="sh">
                            {{'GrossWeightDispatched' | translate}}<i
                                *ngIf="(orderKey!==null)&&(orderKey=='GrossWeightDispatched')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>



                        <th (click)="setOrder('DocumentName')" class="sh">
                            {{'DocumentName' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DocumentName')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('StateDocumentDispatch')" class="sh">
                            {{'StateDocumentDispatch' | translate}}<i
                                *ngIf="(orderKey!==null)&&(orderKey=='StateDocumentDispatch')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>


                        <th (click)="setOrder('RequireApproval')" class="sh">
                            {{'RequireApproval' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='RequireApproval')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('FileName')" class="sh">
                            {{'FileName' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='FileName')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>


                        <th (click)="setOrder('PathFile')" class="sh">
                            {{'PathFile' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PathFile')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th (click)="setOrder('StatusFileDocumentUpload')" class="sh">
                            {{'StatusFileDocumentUpload' | translate}}<i
                                *ngIf="(orderKey!==null)&&(orderKey=='StatusFile')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>


                        <th>
                            {{'actions' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>
                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="arrayList[0]"
                                    [indexPosition]="0" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="arrayList[1]"
                                    [indexPosition]="1" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="arrayList[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="arrayList[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="arrayList[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="arrayList[5]"
                                    [indexPosition]="5" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(6,'GrossWeightDispatched')">
                                <app-select-filter [column]="'GrossWeightDispatched'" [arrayList]="arrayList[6]"
                                    [indexPosition]="6" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'DocumentName')">
                                <app-select-filter [column]="'DocumentName'" [arrayList]="arrayList[7]"
                                    [indexPosition]="7" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'StateDocumentDispatch')">
                                <app-select-filter [column]="'StateDocumentDispatch'" [arrayList]="arrayList[8]"
                                    [indexPosition]="8" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'RequireApproval')">
                                <app-select-filter [column]="'RequireApproval'" [arrayList]="arrayList[9]"
                                    [indexPosition]="9" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'FileName')">
                                <app-select-filter [column]="'FileName'" [arrayList]="arrayList[10]"
                                    [indexPosition]="10" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'StatusFileDocumentUpload')">
                                <app-select-filter [column]="'StatusFileDocumentUpload'" [arrayList]="arrayList[11]"
                                    [indexPosition]="11" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>


                    </tr>
                    <!-- <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i"> -->
                    <tr
                        *ngFor="let row of rows |slice: (page-1) * pageSize : page * pageSize | orderBy:orderKey:orderDesc; index as i">

                        <td>

                        </td>
                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>

                        <td>
                            <label for="OrderNumber">{{row.OrderNumber}}</label>
                        </td>
                        <td>
                            <label for="OrderDisplay">{{row.OrderDisplay}}</label>
                        </td>

                        <td>
                            <label for="Division">{{row.Division}}</label>
                        </td>


                        <td>
                            <label for="Supplier">{{row.Supplier}}</label>
                        </td>
                        <td>
                            <label for="ItemName">{{row.ItemName}}</label>
                        </td>
                        <td>
                            <label for="ProcedureImportline">{{row.ProcedureImportline}}</label>
                        </td>

                        <td>
                            <label for="GrossWeightDispatched">{{row.GrossWeightDispatched}}</label>
                        </td>


                        <td>
                            <label for="DocumentName">{{row.DocumentName}}</label>
                        </td>


                        <td>
                            <label for="StateDocumentDispatch">{{row.StateDocumentDispatch}}</label>
                        </td>

                        <td>
                            <label for="RequireApproval">{{row.RequireApproval == 0 ? 'NO':'SI'}}</label>
                        </td>
                        <td>
                            <label for="FileName">{{row.FileName}}</label>
                        </td>

                        <td>
                            <label for="PathFile">{{row.PathFile}}</label>
                        </td>

                        <!-- <td>
                            <label for="StatusFile">{{row.StatusFile == "A" ? "Activo": row.StatusFile == "RV" == "En Revisión" }}</label>
                        </td> -->

                        <td>
                            <label for="StatusFileDocumentUpload">{{row.StatusFileDocumentUpload}}</label>
                        </td>



                        <td>
                            <!-- <i (click)="globalService.goToParameterDetail(urlDetail + row.OrderId)"
                                style="cursor:pointer;" class="far fa-file-alt text-warning m-r-10"
                                title="{{'submenu_skretting_purchase_order_detail' | translate}}"></i> -->

                            <!--
                                    *ngIf="(document.DocumentUpload != NULL)"
                                    (click)="DownloadFile(document.DocumentUpload)" -->
                            <span>
                                <i style="cursor:pointer;" class="far fa-edit text-info m-r-10" *ngIf="row.StateDocumentDispatch == 'Notificar al proveedor' || row.StateDocumentDispatch == 'Embarcado' || row.StateDocumentDispatch == 'Trámite de aduana'
                                || row.StateDocumentDispatch == 'Planificada' || row.StateDocumentDispatch == 'Trámite finalizado'"

                                    (click)="openUpdateModal(update1, row, 1)" title="{{'edit' | translate}}"></i>
                            </span>

                            <span
                                *ngIf="row.Extension != 'no valido' || row.StatusFile == 'jpeg' || row.StatusFile == 'jpg'|| row.StatusFile == 'png'">

                                <i style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10"
                                    (click)="openUpdateModal(view, row, 2)" title="{{'Visualize' | translate}}"></i>
                            </span>

                            <i style="cursor:pointer;" class="mdi mdi-cloud-download text-info m-r-10"
                                *ngIf="(row.PathFile != NULL)" (click)="DownloadFile(row)"
                                title="{{'DownloadFile' | translate}}"></i>

                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="17">

                        <div class="d-flex justify-content-end">
                            <div class="col-md-1 col-sm-2">
                                <select [(ngModel)]="pageSize" class="form-control" name="pageSize">
                                    <option *ngFor="let pg of PageSizeSelect" value="{{pg.Id}}">
                                        {{pg.Name}}
                                    </option>
                                </select>
                            </div>

                            <ngb-pagination [collectionSize]="pageTotal" [(page)]="page"
                                [(pageSize)]="pageSize"></ngb-pagination>

                        </div>

                    </td>
                </tfoot>


            </table>

        </div>
    </div>
</div>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'update_item' | translate}}</h4>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="card-body">
                <div class="form-group">


                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'OrderNumber' | translate}}</label>
                            <input disabled type="text" class="form-control" name="OrderNumber"
                                [(ngModel)]="u_obj['OrderNumber']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'OrderDisplay' | translate}}</label>
                            <input type="text" disabled class="form-control" name="OrderDisplay"
                                [(ngModel)]="u_obj['OrderDisplay']">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Status' | translate}} {{'dispatch'| translate}}</label>
                            <input type="text" disabled class="form-control" name="StateDocumentDispatch"
                                [(ngModel)]="u_obj['StateDocumentDispatch']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'DocumentName' | translate}}</label>
                            <input type="text" disabled class="form-control" name="DocumentName"
                                [(ngModel)]="u_obj['DocumentName']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Commentary' | translate}}</label>
                            <textarea type="text" class="form-control" name="Commentary" value="N/A"
                                [(ngModel)]="u_obj['Commentary']">
                            </textarea>
                        </div>
                    </div>

                    <td>
                        <input type="file" class="form-control" name="File"
                            accept="image/png, image/jpg, image/jpeg, .xlsx,.xls,.doc, .docx,.pdf"
                            (change)="AddFile($event, u_obj.DocumentId)">
                        <!-- [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'" -->
                        <small style="color: red; font-weight: bold;">{{'MaximumSize' | translate}} 10MB,
                            {{'FileUploadMessage' | translate}}</small>
                    </td>



                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' |
            translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
            (click)="SaveFiles(update1, u_obj)">{{'update' | translate}}</button>
    </div>
</ng-template>


<ng-template #view let-c="close" let-d="dismiss" let-modal>

    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'preview' | translate}}</h4>


        <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
            <a [href]="pdfSrc" target="_blank"><i class="mdi mdi-arrow-right text-info m-r-10"></i></a>
            <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

    </div>
    <div class="modal-body">

        <form class="form-horizontal">
            <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
                <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                    style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
            </ng-container>
            <ng-template #elseTemplate>
                <div style="display: flex;">
                    <!-- src="https://wa-t-skomex.azurewebsites.net/Documents/PurchaseOrderLine/15/3/not%20access.png" -->
                    <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
                        alt="">
                </div>
            </ng-template>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'Close' |
            translate}}</button>

    </div>
</ng-template>
