import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserCx } from 'src/app/interfaces/User';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {

  constructor(private _authService: AuthService, private router: Router) { }

  async ngOnInit() {
    await this._authService.finishLogin().then( data => {
    });
    await this._authService.isAuthenticated().then(data => {
      if (data) {
        setTimeout(() => {     
          this._authService.SetUserData();
          this._authService.SetPermissionData();
          this.router.navigateByUrl('comex/purchase')
        }, 1000);
      }
    })
  }

}
