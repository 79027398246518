<app-header-options (updateInfo)="getInitialData()" (search)="searchData($event)" [isLoading]="isLoading"
  (openModal)="openCreateModal(create1)" [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>


<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-color">
          <tr>
            <th class="sh">
              ID
            </th>
            <th>
              {{'Type' | translate}}
            </th>
            <th>
              {{'Department' | translate}}
            </th>

            <th (click)="setOrder('AgentName')" class="sh">
              {{'submenu_skretting_custom_agent' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='AgentName')"
                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
            <th>
              {{'RUC' | translate}}
            </th>

            <th>
              {{'TotalPermissions' | translate}}
            </th>
            <th>
              {{'actions' | translate}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
            <td>
              <label for="code">{{row.DepartmentId != null ? row.DepartmentId: ""}}</label>
            </td>
            <td>
              <label for="TypeName">{{row.TypeName}}</label>
            </td>
            <td>
              <label for="ParameterName">{{row.ParameterName == null? "Sin Asignar":row.ParameterName}}</label>
            </td>
            <td>
              <label for="AgentName">{{row.ParameterName == "Agente Aduana"? row.AgentName : "-"}}</label>
            </td>
            <td>
              <label for="RUC">{{row.AgentRuc}}</label>
            </td>

            <td>
              <label for="ColumnsList">{{row.PermissionsDetail.length}}</label>
            </td>
            <td class="text-center">
              <i *ngIf="globalService.containPermission(permissionList,'Update')"
                (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10"
                title="{{'update' | translate}}"></i>
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="rows.length > 0">
          <td colspan="10">
            <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
              style="float: right;"></ngb-pagination>
          </td>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'create_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">

          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'SelectOption' | translate}}</label>
              <ng-select [(ngModel)]="optionSelected" name="optionSelected" [multiple]="false" (change)="GetColumns()">
                <ng-option *ngFor="let option of optionsList; index as i" value="{{option}}">{{i + 1}} - {{option |
                  translate}}</ng-option>
              </ng-select>
              <small style="color: #C8102E;" *ngIf="showErrorMessage">{{'MustSelectOption' | translate}}</small>
            </div>
            <div class="col-md-12 sk-ibox" *ngIf="optionSelected != null && this.enabledDepartment==true">
              <label>{{'Select' | translate}}&nbsp;{{'Department' | translate}}</label>

              <select [(ngModel)]="c_obj['DepartmentId']" class="form-control" name="DepartmentId"
                (change)="GetColumnsAgent(c_obj)">
                <option *ngFor="let Department of DepartmentList" value="{{Department.SystemParameterDetailId}}">
                  {{Department.Description}}
                </option>
              </select>

            </div>
          </div>

          <div class="row" *ngIf="this.enabledAgent == true && optionSelected == 'Dispatchs'" ngFor="let dt">

            <div class="col-md-9 sk-ibox">
              <label>{{'Agent' | translate}}</label>
              <ng-select [(ngModel)]="c_obj['AgentId']" name="agentSelected" [multiple]="false">
                <ng-option *ngFor="let agent of agentList; index as i" value="{{agent.AgentId}}">{{i + 1}} -
                  {{agent.Name}}</ng-option>
              </ng-select>
            </div>

            <div class="col-md-3 sk-ibox">
              <label>{{'CopyToAgent' | translate}}</label>
              <ng-select [(ngModel)]="c_obj['AgentIdCopy']" name="AgentIdCopy" [multiple]="false"
                (change)="copyPermmitions(c_obj['AgentIdCopy'])">
                <ng-option *ngFor="let agent of ListAgentPermisions; index as i" value="{{agent.AgentId}}">
                  {{agent.AgentName}}
                </ng-option>
              </ng-select>
            </div>

          </div>



          <div class="row" *ngIf="optionSelected != null && optionSelected == 'Dispatchs'">
            <div class="col-md-12 sk-ibox">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-color">
                    <tr>
                      <th class="sh text-nowrap">
                        ID
                      </th>
                      <th class="sh text-nowrap">
                        {{'Field' | translate}}
                      </th>
                      <th class="sh text-nowrap">
                        {{'Read' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedRead"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHead(checkedRead,1)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Update' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedUpdate"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHead(checkedUpdate,2)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Approver' | translate}}
                      </th>
                      <th>
                        {{'Status' | translate}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr
                      *ngFor="let column of columnsList | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; index as i">
                      <td>
                        <label for="code">{{i + 1}}</label>
                      </td>
                      <td>
                        <label for="ColumnName">{{column.ColumnName | translate}}</label>
                      </td>
                      <td>
                        <div>
                          <!-- <input name="Read" [checkedRead]="checkedRead"  type="checkbox"> -->
                          <!-- <input name="Read" [(ngModel)]="column.Read" (change)="select(column.Read,1)" type="checkbox"> -->
                          <input name="Read" [(ngModel)]="column.Read" (change)="select(column.Read,1)" type="checkbox"
                            [ngModelOptions]="{standalone: column.Read}">

                          <!-- <input class="form-check-input" type="checkbox" [name]="Read" (change)="select(column.Read,1)" [value]="column.ColumnName" id="{{column.Id}}" [checked]="p.checked"> -->
                        </div>
                      </td>
                      <td>
                        <div>
                          <!-- <input name="Update" [(ngModel)]="column.Update" type="checkbox"> -->
                          <!-- <input name="Update" [(ngModel)]="column.Update" (change)="select(column.Update,2)" type="checkbox"> -->
                          <input name="Update" [(ngModel)]="column.Update" (change)="select(column.Update,2)"
                            type="checkbox" [ngModelOptions]="{standalone: column.Update}">

                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.UserList" name="UserList" [multiple]="true">
                            <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                              {{user.FirstName}} {{user.LastName}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.StatusList" name="StatusList" [multiple]="true">
                            <ng-option *ngFor="let status of statusList; let i = index"
                              value="{{status.StatusId}}">{{status.Description}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot *ngIf="columnsList.length > 0">
                    <td colspan="7">
                      <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2"
                        [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!--AQUI VAMOS A PONER LA DATA PARA CONTENEDORES -->
          <div class="row" *ngIf="optionSelected != null && optionSelected == 'Containers'">
            <div class="col-md-12 sk-ibox">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-color">
                    <tr>
                      <th class="sh text-nowrap">
                        ID
                      </th>
                      <th class="sh text-nowrap">
                        {{'Field' | translate}}
                      </th>
                      <th class="sh text-nowrap">
                        {{'Read' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedRead"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHead(checkedRead,1)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Update' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedUpdate"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHead(checkedUpdate,2)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Approver' | translate}}
                      </th>
                      <th>
                        {{'Status' | translate}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let column of columnsList | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; index as i">
                      <td>
                        <label for="code">{{i + 1}}</label>
                      </td>
                      <td>
                        <label for="ColumnName">{{column.ColumnName | translate}}</label>
                      </td>
                      <td>
                        <div>
                          <input name="Read" [(ngModel)]="column.Read" type="checkbox">
                        </div>
                      </td>
                      <td>
                        <div>
                          <input name="Update" [(ngModel)]="column.Update" type="checkbox">
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.UserList" name="UserList" [multiple]="true">
                            <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                              {{user.FirstName}} {{user.LastName}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.StatusList" name="StatusList" [multiple]="true">
                            <ng-option *ngFor="let status of optionUser; let i = index"
                              value="{{status}}">{{status}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot *ngIf="columnsList.length > 0">
                    <td colspan="7">
                      <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2"
                        [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
    <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
      (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
  </div>
</ng-template>


<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'update_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'SelectOption' | translate}}</label>
              <ng-select [(ngModel)]="optionSelected" name="optionSelected" [multiple]="false" (change)="GetColumns()"
                disabled>
                <ng-option *ngFor="let option of optionsList; index as i" value="{{option}}">{{i + 1}} - {{option |
                  translate}}</ng-option>
              </ng-select>
              <small style="color: #C8102E;" *ngIf="showErrorMessage">{{'MustSelectOption' | translate}}</small>
            </div>
          </div>

          <div class="col-md-12 sk-ibox">
            <label>{{'Select' | translate}}&nbsp;{{'Department' | translate}}</label>

            <select [(ngModel)]="u_obj.DepartmentId" disabled class="form-control" name="DepartmentId">
              <option *ngFor="let Department of DepartmentList" value="{{Department.SystemParameterDetailId}}">
                {{Department.Description}}
              </option>
            </select>

          </div>

          <div class="row" *ngIf="this.tipoPer==1">
            <div class="col-md-12 sk-ibox">
              <label>{{'Agent' | translate}}</label>
              <input type="text" class="form-control" name="Agent" [(ngModel)]="u_obj['AgentName']" disabled>
            </div>
          </div>
          <div class="row" *ngIf="optionSelected != null && optionSelected == 'Dispatchs'">
            <div class="col-md-12 sk-ibox">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-color">
                    <tr>
                      <th class="sh text-nowrap">
                        ID
                      </th>
                      <th class="sh text-nowrap">
                        {{'Field' | translate}}
                      </th>
                      <th class="sh text-nowrap">
                        {{'Read' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedReadUp"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHeadUpdate(checkedReadUp,1)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Update' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedUpdateUp"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHeadUpdate(checkedUpdateUp,2)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Approver' | translate}}
                      </th>
                      <th>
                        {{'Status' | translate}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let column of u_obj['PermissionsDetail'] | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; index as i">
                      <td>
                        <label for="code">{{i + 1}}</label>
                      </td>
                      <td>
                        <label for="Description">{{column.Description | translate}}</label>
                      </td>
                      <td>
                        <!-- <div>
                          <input [name]="column.Read" type="checkbox" [(ngModel)]="column.Read"
                            [ngModelOptions]="{standalone: true}">
                        </div> -->

                        <div>
                          <input name="column.Read" [(ngModel)]="column.Read" type="checkbox"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td>
                        <div>
                          <input [name]="column.Update" type="checkbox" [(ngModel)]="column.Update"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.List" [name]="column.List" [multiple]="true">
                            <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                              {{user.FirstName}} {{user.LastName}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.StatusValues" [name]="column.StatusValues" [multiple]="true">
                            <ng-option *ngFor="let status of statusList; let i = index"
                              value="{{status.StatusId}}">{{status.Description}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <td colspan="6">
                      <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2"
                        [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!--AQUI VAMOS A PONER LA DATA PARA CONTENEDORES -->
          <div class="row" *ngIf="optionSelected != null && optionSelected == 'Containers'">
            <div class="col-md-12 sk-ibox">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-color">
                    <tr>
                      <th class="sh text-nowrap">
                        ID
                      </th>
                      <th class="sh text-nowrap">
                        {{'Field' | translate}}
                      </th>
                      <th class="sh text-nowrap">
                        {{'Read' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedRead"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHead(checkedRead,1)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Update' | translate}}
                        <input class="ml-1" type="checkbox" [(ngModel)]="checkedUpdate"
                          [ngModelOptions]="{standalone: true}" (change)="selectedHead(checkedUpdate,2)">
                      </th>
                      <th class="sh text-nowrap">
                        {{'Approver' | translate}}
                      </th>
                      <th>
                        {{'GroupName' | translate}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let column of u_obj['PermissionsDetail'] | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; index as i">
                      <td>
                        <label for="code">{{i + 1}}</label>
                      </td>
                      <td>
                        <label for="Description">{{column.Description | translate}}</label>
                      </td>
                      <td>
                        <div>
                          <input [name]="column.Read" type="checkbox" [(ngModel)]="column.Read"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td>
                        <div>
                          <input [name]="column.Update" type="checkbox" [(ngModel)]="column.Update"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.List" [name]="column.List" [multiple]="true">
                            <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                              {{user.FirstName}} {{user.LastName}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                      <td>
                        <div>
                          <ng-select [(ngModel)]="column.StatusValues" [name]="column.StatusValues" [multiple]="true">
                            <ng-option *ngFor="let status of optionUser; let i = index"
                              value="{{status}}">{{status}}</ng-option>
                          </ng-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <td colspan="6">
                      <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2"
                        [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
    <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
      (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
  </div>
</ng-template>