<app-header-options (updateInfo)="getInitialData()" (search)="searchData($event)" [isLoading]="isLoading"
  [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>



<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-color">
          <tr>
            <th class="sh">
              ID
            </th>
            <th (click)="setOrder('Name')" class="sh">
              {{'Name' | translate}}
            </th>
            <th (click)="setOrder('Description')" class="sh">
              {{'Email' | translate}}
            </th>
            <th (click)="setOrder('External')" class="sh">
              {{'Externo' | translate}}
            </th>
            <th (click)="setOrder('DepartmentName')" class="sh">
              {{'Department' | translate}}
            </th>

            <th class="text-center">
              {{'GroupName' | translate}}
            </th>

            <th>
              {{'actions' | translate}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
            <td>
              <label for="code">{{i + 1}}</label>
            </td>
            <td>
              <label for="Name">{{row.FirstName}} {{row.LastName}}</label>
            </td>
            <td>
              <label for="Email">{{row.Email}}</label>
            </td>
            <td>
              <label for="IsExternalName">{{row.IsExternalName}}</label>
            </td>
            <td>
              <label for="DepartmentName">{{row.DepartmentName == null ? "Sin Asignar":row.DepartmentName}}</label>
            </td>


            <td class="text-center">
             
              <label for="GroupId" *ngIf="row.GroupIdName">{{row.GroupIdName | translate}}</label>
            </td>

            <!-- <td class="text-center">
            <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null"
                   name="{{row.Name}}" (change)="clickOnCheck($event,row.AlertId)" type="checkbox">
          </td> -->
            <td>
              <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10"
                title="{{'edit' | translate}}"></i>

            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="rows.length > 0">
          <td colspan="9">
            <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
              style="float: right;"></ngb-pagination>
          </td>
        </tfoot>
      </table>
    </div>
  </div>
</div>



<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'update_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Name' | translate}}</label>
              <input type="text" class="form-control" name="FirstName" [(ngModel)]="u_obj['FirstName']" disabled>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'LastName' | translate}}</label>
              <input type="text" class="form-control" name="LastName" [(ngModel)]="u_obj['LastName']" disabled>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Email' | translate}}</label>
              <input type="text" class="form-control" name="Description" [(ngModel)]="u_obj['Email']" disabled>
            </div>
          </div>

          <div class="row">

            <div class="col-md-12 sk-ibox">
              <label>{{'Select' | translate}}&nbsp;{{'Department' | translate}}</label>

              <select [(ngModel)]="u_obj['DepartmentId']" class="form-control" name="DepartmentId">
                <option *ngFor="let Department of DepartmentList" value="{{Department.SystemParameterDetailId}}">
                  {{Department.ParameterName}}
                </option>
              </select>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Select' | translate}}&nbsp;{{'GroupName' | translate}}</label>
              <ng-select name="GroupId" [(ngModel)]="u_obj['GroupId']" [multiple]="false">
                <ng-option *ngFor="let group of GroupUser; index as i" value="{{group.GroupId}}">{{i + 1}} -
                  {{group.Name | translate}}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Externo' | translate}}</label>
              <ng-select name="IsExternal" [(ngModel)]="u_obj['IsExternal']" [multiple]="false" [(disabled)]="this.userType">
                <ng-option *ngFor="let item of ExternalUser; index as i" value="{{item.IsExternal}}">{{i + 1}} -
                  {{item.name | translate}}</ng-option>
              </ng-select>
            </div>
          </div>


        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
    <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
      (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
  </div>
</ng-template>
