<app-header-options
  (updateInfo)="getInitialData()"
  (search)="searchData($event)"
  [isLoading]="isLoading"
  (openModal)="openCreateModal(create1)"
  [buttonCreateVisible]="buttonCreateVisible"
  [PermissionList]="permissionList"
>
</app-header-options>


<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr >
                        <th class="sh">
                            ID
                        </th>
                        <th (click)="setOrder('Name')" class="sh">
                            {{'Name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>
                        <th (click)="setOrder('Description')" class="sh">
                            {{'Description' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>
                        <th (click)="setOrder('DurationTime')" class="sh">
                            {{'DurationTime' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DurationTime')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>
                        <th (click)="setOrder('Status')" class="sh text-center">
                            {{'Status' | translate}}
                        </th>
                        <th class="text-center">
                            {{'enable/disable' | translate}}
                        </th>
                        <th >
                            {{'actions' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>
                        <td>
                            <label for="Name">{{row.Name}}</label>
                        </td>
                        <td>
                            <label for="Description">{{row.Description}}</label>
                        </td>
                        <td>
                            <label for="DurationTime">{{row.DurationTime}}</label>
                        </td>
                        <td class="text-center">
                            <label for="Status">{{row.IsActive}}</label>
                        </td>
                        <td class="text-center"> 
                            <input class="form-check-input" [attr.checked]="(row.IsActive == 'A') ? 'checked' : null" name="{{row.Name}}" (change)="clickOnCheck($event,row.StatusId)" type="checkbox">
                        </td>
                        <td *ngIf="row.IsActive == 'A'">
                            <i *ngIf="globalService.containPermission(permissionList,'Update')" (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                            <i *ngIf="globalService.containPermission(permissionList,'Delete')" (click)="changeStatus('3', row.StatusId)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="rows.length > 0">
                    <td colspan="7">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'create_item' | translate}}</h4>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Name' | translate}}</label>
                            <input type="text" class="form-control" name="Name" [(ngModel)]="c_obj['Name']"> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Description' | translate}}</label>
                            <input type="text" class="form-control" name="Description" [(ngModel)]="c_obj['Description']"> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'DurationTime' | translate}}</label>
                            <input type="number" min="1" max="1000000" class="form-control" name="Type" [(ngModel)]="c_obj['DurationTime']"> 
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-C8102E" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>


<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'update_item' | translate}}</h4>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="card-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Name' | translate}}</label>
                            <input type="text" class="form-control" name="Name" [(ngModel)]="u_obj['Name']"> 
                        </div>
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Description' | translate}}</label>
                            <input type="text" class="form-control" name="Description" [(ngModel)]="u_obj['Description']"> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'DurationTime' | translate}}</label>
                            <input type="number" min="1" max="1000000" class="form-control" name="Type" [(ngModel)]="u_obj['DurationTime']"> 
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E" (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
    </div>
</ng-template>