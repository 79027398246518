<div class="row">
    <div class="col-md-12 div-filter m-t-10">
        <select class="custom-select custom-select-md form-control u-select" name="process"
            [(ngModel)]="option_processes" (change)=selectRequest() *ngIf="!isSkretting">
            <option [value]=null disabled>{{'Select_type_report' | translate}}</option>
            <option *ngFor="let processes of list_processes; let i = index" [value]="processes.id">{{processes.title
                |translate}}</option>
        </select>
        <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init"
            *ngIf="option_processes == 1 || option_processes == 2 || option_processes == 3 || option_processes == 4 || option_processes == 5 || option_processes == 7  || option_processes == 8 || option_processes == 9 || option_processes == 10 || option_processes == 11">
        <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end"
            *ngIf="option_processes == 1 || option_processes == 2 || option_processes == 3 || option_processes == 4 || option_processes == 5 || option_processes == 7  || option_processes == 8 || option_processes == 9 || option_processes == 10 || option_processes == 11">
        <!-- <select class="custom-select custom-select-md form-control u-select" name="type_product" [(ngModel)]="type_product"
      [multiple]="false" *ngIf="option_processes == 10">
      <option value="Ninguno"></option>
      <option *ngFor="let option of TypeProduct" value="{{option}}">{{option}}</option>
    </select> -->
        <button [ladda]="isFind" type="button" (click)="dataToFind()" *ngIf="option_processes>0"
            class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
        <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
            class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>



    </div>
</div>

<!-- *ngIf="this.rows.length > 0" -->

<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>
        <!-- Purchase Orders (1) -->
        <div class="table-responsive text-center" *ngIf="option_processes == 1 && rows.length > 0">
            <table class="table">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <!-- <th>{{'OrderNumber' | translate}} </th> -->
                        <th>{{'OC' | translate}} </th>
                        <th>{{'CodeSupplier' | translate}}</th>
                        <th *ngIf="isSkretting">{{'CompanyName' | translate}}</th>
                        <th>{{'GrossWeightOrder' | translate}} </th>
                        <th>{{'date_creation' | translate}}</th>
                        <th>{{'UserResponsableSk' | translate}}</th>
                        <th>{{'Agent' | translate}}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td *ngIf="isSkretting">
                            <div (click)="resetData(2,'CompanyName')">
                                <app-select-filter [column]="'UserAgentName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'GrossWeightOrder')">
                                <app-select-filter [column]="'GrossWeightOrder'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'CreationTime')">
                                <app-select-filter [column]="'CreationTime'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'UserName')">
                                <app-select-filter [column]="'UserName'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>

                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                        <td></td>
                        <td>{{row.OrderNumber}}</td>
                        <td>{{row.Supplier}}</td>
                        <td *ngIf="isSkretting">{{row.CompanyName}}</td>
                        <td>{{row.GrossWeightOrder}}</td>
                        <td>{{row.CreationTime.split('T')[0]}}</td>
                        <!---->
                        <td>{{row.UserName}}</td>
                        <td>{{row.AgentName}}</td>
                    </tr>
                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>
        </div>

        <!-- Dispatchs (2) -->
        <div *ngIf="option_processes == 2 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th> <label class="text-nowrap" name="ID">ID</label></th>
                        <th> <label class="text-nowrap" name="ResponsableSK">{{'ResponsableSK' | translate}}</label>
                        </th>
                        <th *ngIf="isSkretting"> <label class="text-nowrap" name="CompanyName">{{'CompanyName' |
                                translate}}</label>
                        <th class="sh"><label class="text-nowrap" name="Agent">{{'Agent' | translate}}</label></th>
                        <th class="sh"><label class="text-nowrap" name="ResponsableAgent">{{'ResponsableAgent' |
                                translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="IsdException">{{'ProcedureImportline' |
                                translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="DealNumber">{{'DealNumber' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="QuantityContract">{{'QuantityContract' |
                                translate}}</label>
                        </th>
                        <!-- <th class="sh"><label class="text-nowrap" name="OrderNumber">{{'OrderNumber' | translate}}</label></th> -->
                        <th class="sh"><label class="text-nowrap" name="OrderNumber">{{'OC' | translate}}</label></th>
                        <th class="sh">
                            <label class="text-nowrap" name="Division">{{'Division' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="OC">{{'purchase_order_line' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="SupplierCode">{{'SupplierCode' |
                                translate}}</label></th>
                        <th class="sh"><label class="text-nowrap" name="Supplier">{{'Supplier' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="TypeProduct">{{'TypeProduct' |
                                translate}}</label></th>
                        <th class="sh"><label class="text-nowrap" name="ItemNumber">{{'ItemNumber' | translate
                                }}</label></th>
                        <th class="sh"><label class="text-nowrap" name="ItemName">{{'ItemName' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="SupplierContract">{{'SupplierContract' |
                                translate}}</label>
                        </th>
                        <th class="sh"> <label class="text-nowrap" name="IncoTerm">{{'IncoTerm' | translate}}</label>
                        </th>
                        <th class="sh"> <label class="text-nowrap" name="ProcedureImportline"> {{'ProcedureImportline' |
                                translate}}</label> </th>
                        <th class="sh"> <label class="text-nowrap" name="Origen"> {{'Origen' | translate}}</label> </th>
                        <th class="sh"> <label class="text-nowrap" name="PackagingName"> {{'Packaging' |
                                translate}}</label>
                        <th class="sh"> <label class="text-nowrap" name="EtaReal"> {{'EtaReal'| translate}}</label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'UserName')">
                                <app-select-filter [column]="'UserName'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'UserAgentName')">
                                <app-select-filter [column]="'UserAgentName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="l[3]"
                                    [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'DealNumber')">
                                <app-select-filter [column]="'DealNumber'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'GrossWeight')">
                                <app-select-filter [column]="'GrossWeight'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'TypeProduct')">
                                <app-select-filter [column]="'TypeProduct'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[13]" [indexPosition]="13"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(14,'SupplierContract')">
                                <app-select-filter [column]="'SupplierContract'" [arrayList]="l[14]"
                                    [indexPosition]="14" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'IncoTerm')">
                                <app-select-filter [column]="'IncoTerm'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(16,'IsdException')">
                                <app-select-filter [column]="'IsdException'" [arrayList]="l[16]" [indexPosition]="16"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'Origen')">
                                <app-select-filter [column]="'Origen'" [arrayList]="l[17]" [indexPosition]="17"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(18,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[18]" [indexPosition]="18"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td>
                                <label for="code">{{i + 1}}</label>
                            </td>
                            <td>
                                <label for="UserName">{{row.UserName}}</label>
                            </td>
                            <td>
                                <label for="AgentName">{{row.AgentName}}</label>
                            </td>
                            <td>
                                <label for="UserAgentName">{{row.UserAgentName}}</label>
                            </td>
                            <td>
                                <label for="ProcedureImportline">{{row.ProcedureImportline}}</label>
                            </td>
                            <td>
                                <label for="DealNumber">{{row.DealNumber}}</label>
                            </td>
                            <td>
                                <label for="GrossWeight">{{row.GrossWeight}}</label>
                            </td>
                            <td>
                                <label for="OrderNumber">{{row.OrderNumber}}</label>
                            </td>
                            <td>
                                <label for="Division">{{row.Division}}</label>
                            </td>
                            <td>
                                <label for="OrderDisplay">{{row.OrderDisplay}}</label>
                            </td>
                            <td>
                                <label for="Supplier">{{row.Supplier}}</label>
                            </td>
                            <td>
                                <label for="CompanyName">{{row.CompanyName}}</label>
                            </td>
                            <td>
                                <label for="TypeProduct">{{row.TypeProduct}}</label>
                            </td>
                            <td>
                                <label for="ItemNumber">{{row.ItemNumber}}</label>
                            </td>
                            <td>
                                <label for="ItemName">{{row.ItemName}}</label>
                            </td>
                            <td>
                                <label for="SupplierContract">{{row.SupplierContract}}</label>
                            </td>
                            <td>
                                <label for="IncoTerm">{{row.IncoTerm}}</label>
                            </td>
                            <td>
                                <label for="IsdException">{{row.IsdException}}</label>
                            </td>
                            <td>
                                <label for="Origen">{{row.Origen}}</label>
                            </td>
                            <td>
                                <label for="PackagingName">{{row.PackagingName}}</label>
                            </td>

                            <td><label for="EtaReal">{{row.EtaReal | date:'y-MM-dd'}}</label></td>
                        </tr>
                    </ng-container>
                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

        <!-- Payments (3) -->
        <div class="text-center" *ngIf="option_processes == 3 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th> {{'OC' }}</th>
                        <th>{{'dispatch' | translate}}</th>
                        <th> {{'Division' | translate}}</th>
                        <th> {{'ItemName' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th> {{'Invoice' | translate}}</th>
                        <th>{{'EmissionDate' | translate}}</th>
                        <th>{{'Amount' | translate}}</th>
                        <th>{{'DetailSubconcept' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'Dispatch')">
                                <app-select-filter [column]="'Dispatch'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'Div')">
                                <app-select-filter [column]="'Div'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'InvoiceNumber')">
                                <app-select-filter [column]="'InvoiceNumber'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'IssueDate')">
                                <app-select-filter [column]="'IssueDate'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'AmountLine')">
                                <app-select-filter [column]="'AmountLine'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'SubConcept')">
                                <app-select-filter [column]="'SubConcept'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td><label for="OrderNumber">{{row.OrderNumber}} </label></td>
                            <td><label for="Dispatch">{{row.Dispatch}} </label></td>
                            <td><label for="Div">{{row.Div}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="Supplier">{{row.Supplier}} </label></td>
                            <td><label for="InvoiceNumber">{{row.InvoiceNumber}} </label></td>
                            <td><label for="IssueDate">{{row.IssueDate.split('T')[0]}} </label></td>
                            <td><label for="AmountLine">{{row.AmountLine}} </label></td>
                            <td><label for="SubConcept">{{row.SubConcept}} </label></td>

                        </tr>

                    </ng-container>
                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
        </div>

        <!-- Purchase order line (4)-->
        <div *ngIf="option_processes == 4 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>{{'OC' | translate}}</th>
                        <th>{{'dispatch' | translate}}</th>
                        <th>{{'Division' | translate}}</th>
                        <th>{{'ContainerNumber' | translate}}</th>

                        <th>{{'DateDepartureReception' | translate}}</th>
                        <th>{{'HourDepartureReception' |translate}}</th>
                        <th>{{'RescheduleDate' | translate}}</th>
                        <th>{{'RescheduleHour' | translate}}</th>

                        <th>{{'Agent' | translate}}</th>
                        <th>{{'ResponsableAgent' | translate}}</th>
                        <th>{{'Eta' | translate}}</th>
                        <th>{{'EndCas' | translate}}</th>
                        <th>{{'PortShipment' | translate}}</th>
                        <th>{{'ResponsableSK' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'Packaging' | translate}}</th>
                        <th>{{'Bl' | translate}}</th>
                        <th>{{'StampBl' | translate}}</th>
                        <th>{{'WeightItem' | translate}}</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>
                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ContainerNumber')">
                                <app-select-filter [column]="'ContainerNumber'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(4,'DateDepartureReception')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[4]"
                                    [indexPosition]="4" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(5,'HourDepartureReception')">
                                <app-select-filter [column]="'HourDepartureReception'" [arrayList]="l[5]"
                                    [indexPosition]="5" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'RescheduleDate')">
                                <app-select-filter [column]="'RescheduleDate'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'RescheduleHour')">
                                <app-select-filter [column]="'RescheduleHour'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'Agent')">
                                <app-select-filter [column]="'Agent'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'UserIdsNames')">
                                <app-select-filter [column]="'UserIdsNames'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'EtaRequired')">
                                <app-select-filter [column]="'EtaRequired'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'CAS')">
                                <app-select-filter [column]="'CAS'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'PortShipment')">
                                <app-select-filter [column]="'PortShipment'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'NameResposabilitySK')">
                                <app-select-filter [column]="'NameResposabilitySK'" [arrayList]="l[13]"
                                    [indexPosition]="13" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(14,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(16,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[16]" [indexPosition]="16"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'Bl')">
                                <app-select-filter [column]="'Bl'" [arrayList]="l[17]" [indexPosition]="17"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(18,'BlSeal')">
                                <app-select-filter [column]="'BlSeal'" [arrayList]="l[18]" [indexPosition]="18"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'ProductWeight')">
                                <app-select-filter [column]="'ProductWeight'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td><label for="OrderNumber">{{row.OrderNumber}} </label></td>
                            <td><label for="OrderDisplay">{{row.OrderDisplay}} </label></td>
                            <td><label for="Division">{{row.Division}}</label></td>
                            <td><label for="ContainerNumber">{{row.ContainerNumber}} </label></td>
                            <td><label for="DateDepartureReception">{{row.DateDepartureReception |
                                    date:'y-MM-d'}}</label></td>
                            <td><label for="HourDepartureReception">{{row.HourDepartureReception}}</label></td>
                            <td><label for="RescheduleDate">{{row.RescheduleDate | date:'y-MM-d'}}</label></td>
                            <td><label for="RescheduleHour">{{row.RescheduleHour}}</label></td>
                            <td><label for="Agent">{{row.Agent}} </label></td>
                            <td><label for="UserIdsNames">{{row.UserIdsNames}} </label></td>
                            <td><label for="EtaRequired">{{row.EtaRequired | date:'y-MM-dd'}} </label></td>
                            <td><label for="CAS">{{row.CAS | date:'y-MM-dd'}} </label></td>
                            <td><label for="PortShipment">{{row.PortShipment}} </label></td>
                            <td><label for="NameResposabilitySK">{{row.NameResposabilitySK}} </label></td>
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="PackagingName">{{row.PackagingName}} </label></td>
                            <td><label for="Bl">{{row.Bl}} </label></td>
                            <td><label for="BlSeal">{{row.BlSeal}} </label></td>
                            <td><label for="ProductWeight">{{row.ProductWeight}} </label></td>

                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

        <!-- Import Agent (5) -->
        <div *ngIf="option_processes == 5 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>ID</th>
                        <th>{{'Division' | translate}}</th>
                        <th>{{'ProcedureImportline' | translate}}</th>
                        <th>{{'ProvisionedFI' | translate}}</th>
                        <th>{{'Invoice' | translate}}</th>
                        <th>{{'InvoiceDate' | translate}}</th>
                        <th>{{'PurchasePrice' | translate}}</th>
                        <th>{{'ItemNumber' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'TariffHeading' | translate}} </th>
                        <th>{{'Status' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(1,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="l[1]"
                                    [indexPosition]="1" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'ProvisionedFI')">
                                <app-select-filter [column]="'ProvisionedFI'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'Invoice')">
                                <app-select-filter [column]="'Invoice'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'InvoiceDate')">
                                <app-select-filter [column]="'InvoiceDate'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>

                        </td>
                        <td>
                            <div (click)="resetData(6,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'TariffHeading')">
                                <app-select-filter [column]="'TariffHeading'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(9,'Status')">
                                <app-select-filter [column]="'Status'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>
                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; ; let i = index">
                        <td></td>
                        <td>{{i+1}}</td>
                        <td><label for="Division">{{row.Division}}</label></td>
                        <td><label for="ProcedureImportline">{{row.ProcedureImportline}}</label></td>
                        <td><label for="ProvisionedFI">{{row.ProvisionedFI}}</label></td>
                        <td><label for="Invoice">{{row.Invoice}}</label></td>
                        <td><label for="InvoiceDate">{{row.InvoiceDate | date: 'y-MM-dd'}}</label></td>
                        <td><label for="PurchasePrice">{{row.PurchasePrice}}</label></td>
                        <td><label for="ItemNumber">{{row.ItemNumber}}</label></td>
                        <td><label for="ItemName">{{row.ItemName}}</label></td>
                        <td><label for="TariffHeading">{{row.TariffHeading}}</label></td>
                        <td><label for="Status">{{row.Status}}</label></td>
                        <td><label for="CompanyName">{{row.CompanyName}}</label></td>

                    </tr>

                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

        <!-- ISD Tributary Credit (6) -->
        <div class="table-responsive text-center" *ngIf="option_processes == 6 && rows.length > 0">
            <table class="table">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th> <label class="text-nowrap" name="ID">ID</label></th>
                        <th><label class="text-nowrap" name="Supplier">{{'Supplier' | translate}}</label></th>
                        <th><label class="text-nowrap" name="OrderNumber">{{'OC' }}</label>
                            <!-- <th><label class="text-nowrap" name="OrderNumber">{{'OrderNumber' | translate}}</label> -->
                        <th><label class="text-nowrap" name="CustomsAclarance">{{'CustomsAclarance' |
                                translate}}</label></th>
                        <th><label class="text-nowrap" name="DAI">{{'DAI' | translate}}</label></th>
                        <th><label class="text-nowrap" name="Invoice">{{'Invoice' | translate}}</label></th>
                        <th><label class="text-nowrap" name="TariffHeading">{{'TariffHeading' | translate}}</label></th>
                        <th><label class="text-nowrap" name="TypeImport">{{'TypeImport' | translate}}</label></th>
                        <th><label class="text-nowrap"
                                name="QuantityLiquidation">{{'QuantityLiquidation'|translate}}</label> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>
                        <td>
                            <div (click)="resetData(0,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'CustomsAclarance')">
                                <app-select-filter [column]="'CustomsAclarance'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'DAI')">
                                <app-select-filter [column]="'DAI'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'Invoice')">
                                <app-select-filter [column]="'Invoice'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'TariffHeading')">
                                <app-select-filter [column]="'TariffHeading'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'Tipo de importacion')">
                                <app-select-filter [column]="'tipo de importacion'" [arrayList]="l[6]"
                                    [indexPosition]="6" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'QuantityLiquidation')">
                                <app-select-filter [column]="'QuantityLiquidation'" [arrayList]="l[7]"
                                    [indexPosition]="7" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td>
                                <label for="code">{{i + 1}}</label>
                            </td>
                            <td>
                                <label for="CompanyName">{{row.CompanyName}}</label>
                            </td>
                            <td>
                                <label for="OrderNumber">{{row.OrderNumber}}</label>
                            </td>
                            <td>
                                <label for="CustomsAclarance">{{row.CustomsAclarance}}</label>
                            </td>
                            <td>
                                <label for="DAI">{{row.DAI}}</label>
                            </td>
                            <td>
                                <label for="Invoice">{{row.Invoice}}</label>
                            </td>
                            <td>
                                <label for="TariffHeading">{{row.TariffHeading}}</label>
                            </td>
                            <td>
                                <label for="OrderNumber">Tipo de importación</label>
                            </td>
                            <td>
                                <label for="QuantityLiquidation">{{row.QuantityLiquidation}}</label>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>
        </div>

        <!-- Liquidaciones Aduaneras del mes  -->

        <!-- Imports cost  (8)-->
        <div *ngIf="option_processes == 8 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <!-- Orden de compra - Despacho -  Factura -  Costo -  Proveedor - Producto -->
                        <th>ID</th>
                        <th>{{'purchase_order' | translate}}</th>
                        <th>{{'purchase_order_line' | translate}}</th>
                        <th>{{'Division' | translate}}</th>
                        <th>{{'ProvisionedFI' | translate}}</th>
                        <th>{{'Invoice' | translate}}</th>
                        <th>{{'InvoiceDate' | translate}} </th>
                        <th>{{'PurchasePrice' | translate}}</th>
                        <th>{{'PlantPrice' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ProvisionedFI')">
                                <app-select-filter [column]="'ProvisionedFI'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'Invoice')">
                                <app-select-filter [column]="'Invoice'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'InvoiceDate')">
                                <app-select-filter [column]="'InvoiceDate'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'PurchasePrice')">
                                <app-select-filter [column]="'PurchasePrice'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'PlantPrice')">
                                <app-select-filter [column]="'PlantPrice'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; ; let i = index">
                        <td></td>
                        <td>{{i+1}}</td>

                        <td><label for="OrderNumber">{{row.OrderNumber}}</label></td>
                        <td><label for="OrderDisplay">{{row.OrderDisplay}}</label></td>
                        <td><label for="Division">{{row.Division}}</label></td>
                        <td><label for="ProvisionedFI">{{row.ProvisionedFI}}</label></td>
                        <td><label for="Invoice">{{row.Invoice}}</label></td>
                        <td><label for="InvoiceDate">{{row.InvoiceDate | date:'y-MM-dd'}}</label></td>
                        <td><label for="PurchasePrice">{{row.PurchasePrice}}</label></td>
                        <td><label for="PlantPrice">{{row.PlantPrice}}</label></td>
                        <td><label for="CompanyName">{{row.CompanyName}}</label></td>
                        <td><label for="ItemName">{{row.ItemName}}</label></td>

                    </tr>

                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

        <!-- Provision vs Real (9) -->
        <div class="table-responsive text-center" *ngIf="option_processes == 9 && rows.length > 0">
            <table class="table">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>ID</th>
                        <th>{{'OC'}}</th>
                        <th>{{'purchase_order_line' | translate}}</th>
                        <th>{{'Division' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'EstimatedCost' | translate}}</th>
                        <th>{{'RealCost' | translate}} </th>
                        <th>{{'EtaReal' | translate}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(1,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'PlantPrice')">
                                <app-select-filter [column]="'PlantPrice'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'PlantPriceReal')">
                                <app-select-filter [column]="'PlantPriceReal'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>
                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; ; let i = index">
                        <td></td>
                        <td>{{i+1}}</td>

                        <td><label for="OrderNumber">{{row.OrderNumber}}</label></td>
                        <td><label for="OrderDisplay">{{row.OrderDisplay}}</label></td>
                        <td><label for="Division">{{row.Division}}</label></td>
                        <td><label for="CompanyName">{{row.CompanyName}}</label></td>
                        <td><label for="ItemName">{{row.ItemName}}</label></td>
                        <td><label for="PlantPrice">{{row.PlantPrice}}</label></td>
                        <td><label for="PlantPriceReal">{{row.PlantPriceReal}}</label></td>
                        <td><label for="EtaReal">{{row.EtaReal | date:'y-MM-dd'}}</label></td>

                    </tr>

                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

        <!-- Arrived by type of product (10) -->
        <div class="table-responsive " *ngIf="option_processes == 10 && rows.length > 0">
            <table class="table">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>ID</th>
                        <th>{{'TypeProduct' | translate}}</th>
                        <th>{{'ItemNumber' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'GrossWeightDispatched' | translate}}</th>
                        <th>{{'EtaReal' | translate}}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'TypeProduct')" class="text-center">
                                <app-select-filter [column]="'TypeProduct'" [arrayList]="l[0]" [indexPosition]="0"
                                    class="bg-primary !important"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'ItemNumber')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'GrossWeightDispatched')">
                                <app-select-filter [column]="'GrossWeightDispatched'" [arrayList]="l[3]"
                                    [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; ; let i = index">
                        <td></td>
                        <td>{{i+1}}</td>
                        <td><label for="TypeProduct">{{row.TypeProduct}}</label></td>
                        <td><label for="TypeProduct">{{row.ItemNumber}}</label></td>
                        <td><label for="ItemName">{{row.ItemName}}</label></td>
                        <td><label for="GrossWeightDispatched">{{row.GrossWeightDispatched }}</label></td>
                        <td><label for="EtaReal">{{row.EtaReal | date:'y-MM-dd' }}</label></td>
                    </tr>

                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

        <!-- Contenedores Asignados (11)-->
        <div *ngIf="option_processes == 11 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>{{'OC' | translate}}</th>
                        <th>{{'WarehouseName' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'ContainerNumber' | translate}}</th>
                        <th>{{'Type' | translate}}</th>
                        <th>{{'BlSeal' | translate}}</th>
                        <th>{{'ItemNumber' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'WeightItem' | translate}}</th>
                        <th>{{'Packaging' | translate}}</th>
                        <th>{{'DateDepartureReception' | translate}}</th>
                        <th>{{'HourDepartureReception' | translate}}</th>
                        <th>{{'TransportCompany' | translate}}</th>
                        <th>{{'Date' | translate}}/{{'Happens' | translate}}</th>
                        <th>{{'Hour' | translate}}/{{'Happens' | translate}}</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>
                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'WarehousesName')">
                                <app-select-filter [column]="'WarehousesName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(2,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ContainerNumber')">
                                <app-select-filter [column]="'ContainerNumber'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'Type')">
                                <app-select-filter [column]="'Type'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'BlSeal')">
                                <app-select-filter [column]="'BlSeal'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'ProductWeight')">
                                <app-select-filter [column]="'ProductWeight'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'DateDepartureReception')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[10]"
                                    [indexPosition]="10" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'HourDepartureReception')">
                                <app-select-filter [column]="'HourDepartureReception'" [arrayList]="l[11]"
                                    [indexPosition]="11" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'TransportCompanyName')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[12]"
                                    [indexPosition]="12" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'Date')">
                                <app-select-filter [column]="'Date'" [arrayList]="l[13]" [indexPosition]="13"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(14,'Hour')">
                                <app-select-filter [column]="'Hour'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>

                            <td><label for="OrderNumber">{{row.OrderNumber}} </label></td>
                            <td><label for="WarehousesDiv_Name">{{row.WarehousesDiv_Name}} </label></td>
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="ContainerNumber">{{row.ContainerNumber}} </label></td>
                            <td><label for="Type">{{row.Type}} </label></td>
                            <td><label for="BlSeal">{{row.BlSeal}} </label></td>
                            <td><label for="ItemNumber">{{row.ItemNumber}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="ProductWeight">{{row.ProductWeight}} </label></td>
                            <td><label for="PackagingName">{{row.PackagingName}} </label></td>
                            <td><label for="DateDepartureReception">{{row.DateDepartureReception |
                                    date:'y-MM-dd'}}</label></td>
                            <td><label for="HourDepartureReception">{{row.HourDepartureReception}} </label></td>
                            <td><label for="TransportCompanyName">{{row.TransportName}} </label></td>
                            <td><label for="Date">{{row.Date | date:'y-MM-dd'}} </label></td>


                            <td><label for="Hour">{{row.Hour}} </label></td>
                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

    </div>
</div>
