import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { BaseCrudComponent } from 'src/app/comex/base-crud/base-crud.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-container-detail-pold',
  templateUrl: './container-detail-pold.component.html',
  styleUrls: ['./container-detail-pold.component.css']
})
export class ContainerDetailPOLDComponent extends BaseCrudComponent implements OnInit {

  active = 1;
  activeStatus = 1;
  objectCopy = null;
  optionsDeparturePort = ['SI', 'NO'];
  optionsInPlant = ['SI', 'NO'];
  Reason = ['FALTA DE ESPACIO BODEGA', 'FALTA PERSONAL', 'MANTENIMIENTO', 'NO CUMPLE ESPECIFICACIONES CALIDAD', 'OTRO'];
  StatusBodega = [];/*['Por ingresar a bodega', 'Ingresado a bodega', 'Salida de bodega', 'Retornado por peso', 'Retornado por sello', 'Perdida de turno',
  ' En espera de turno entrega de vacío','En ruta a bodega','Llegada a bodega','Inició descarga','Carga entregada sin novedad',
  'No se puede entregar vacío','Demora en la carga del contenedor o carga suelta','Debe ir a bodega de tránsito','Avería mecánica (en ruta a bodega)']*/
  CompanyTrans = [];
  ContainerNumber = [];
  ObservationAduana = [];
  ObservationTrans = [];
  ObservationWarehouse = [];
  wareHouses = [];
  fieldsToApproval = [];
  res = true;

  //EDITABLES

  //Compras
  EditTransportId = true;
  //------Campos Importline
  EditContainerNumber = true;
  EditType = true;
  EditBlSeal = true;
  EditProductWeight = true;
  EditBlWeight = true;
  EditDateDeparturePort = true;
  EditEntryToPort = true;
  //------ Bodega
  EditDateInWarehouse = true;
  EditHourInWarehouse = true;
  EditWeightInWarehouse = true;
  EditInPlant = true;
  EditSealWarehouse = true;

  logList = [];
  pageLog = 1;
  pageSizeLog = 20;
  pageTotalLog = 10;
  pageLogDetail = 1;
  pageSizeLogDetail = 20;
  hiddenLog = [];
  objRowLog = null;
  aux: string = "";



  @Input() obj: any = {};
  @Input() user: any = null;
  @Input() index: any = null;
  @Input() userType: any = null;
  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.getPermissionsByUser(this.user);
    await this.getCompanyTransport();
    await this.getContainerNumber();
    await this.getObservationAduana();
    await this.getObservationTrans();
    await this.getObservationWarehouse();
    await this.GetWarehouseActive();
    await this.getMonitoringStates();
    this.u_obj = JSON.parse(JSON.stringify(this.obj));
    this.u_obj['DateDeparturePort'] = this.u_obj['DateDeparturePort'] != null ? this.u_obj['DateDeparturePort'].split('T')[0] : null;
    this.u_obj['DateDepartureReception'] = this.u_obj['DateDepartureReception'] != null ? this.u_obj['DateDepartureReception'].split('T')[0] : null;
    this.u_obj['RescheduleDate'] = this.u_obj['RescheduleDate'] != null ? this.u_obj['RescheduleDate'].split('T')[0] : null;
    this.u_obj['DateInWarehouse'] = this.u_obj['DateInWarehouse'] != null ? this.u_obj['DateInWarehouse'].split('T')[0] : null;
    this.u_obj['EntryToPort'] = this.u_obj['EntryToPort'] != null ? this.u_obj['EntryToPort'].split('T')[0] : null;
    this.u_obj['Date'] = this.u_obj['Date'] != null ? this.u_obj['Date'].split('T')[0] : null;
    this.u_obj['EtaRequired'] = this.u_obj['EtaRequired'] != null ? this.u_obj['EtaRequired'].split('T')[0] : null;
    this.u_obj['EtdRequired'] = this.u_obj['EtdRequired'] != null ? this.u_obj['EtdRequired'].split('T')[0] : null;
    this.u_obj['Date'] = this.u_obj['Date'] != null ? this.u_obj['Date'].split('T')[0] : null;
    this.u_obj['DateInWarehouse'] = this.u_obj['DateInWarehouse'] != null ? this.u_obj['DateInWarehouse'].split('T')[0] : null;
    this.u_obj['CAS'] = this.u_obj['CAS'] != null ? this.u_obj['CAS'].split('T')[0] : null;
    this.u_obj['DepartureDate'] = this.u_obj['DepartureDate'] != null ? this.u_obj['DepartureDate'].split('T')[0] : null;
    this.u_obj['CasupdateDate'] = this.u_obj['CasupdateDate'] != null ? this.u_obj['CasupdateDate'].split('T')[0] : null;
    this.u_obj['Hour'] = this.u_obj['Hour'] != null ? this.u_obj['Hour'].replace(/\s/g, '') : null;
    this.objectCopy = JSON.parse(JSON.stringify(this.u_obj));
    await this.GetLogFields();
    //console.log("objeto:",this.u_obj)
    //console.log("userType:",this.userType)
    this.pageLog = 1
  }


  public async getCompanyTransport() {
    this.url = environment.Global.API_GET_ACTIVE_TRS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.CompanyTrans = result.Object
        //console.log("Bodegas:",this.CompanyTrans)
      }
    })
  }
  public async getMonitoringStates() {
    this.url = environment.Global.API_GET_ACTIVE_MS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.StatusBodega = result.Object
      }
    })
  }

  public async getContainerNumber() {
    this.url = environment.Global.API_GET_CONTAINER_NUMBER + this.obj.OrderNumber;
    // console.log(this.url)
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.ContainerNumber = result.Object
        // console.log("Contenedores:",this.ContainerNumber)
      }
    })
  }

  public async getObservationAduana() {
    this.url = environment.Global.API_GET_ACTIVE_OB_BY_TYPE + "Recepción Aduana";
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.ObservationAduana = result.Object
        //console.log("Bodegas:",this.CompanyTrans)
      }
    })
  }

  public async getObservationWarehouse() {
    this.url = environment.Global.API_GET_ACTIVE_OB_BY_TYPE + "Recepción Bodega";
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.ObservationWarehouse = result.Object
        // console.log("Bodegas:",this.CompanyTrans)
      }
    })
  }

  public async getObservationTrans() {
    this.url = environment.Global.API_GET_ACTIVE_OB_BY_TYPE + "Transporte";
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.ObservationTrans = result.Object
        // console.log("Bodegas:",this.CompanyTrans)
      }
    })
  }

  public async GetWarehouseActive() {
    this.url = environment.Global.API_GET_ACTIVE_WH;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.wareHouses = result.Object
        //console.log("Bodegas:",this.wareHouses)
      }
    })
  }

  public async getPermissionsByUser(user) {
    this.url = environment.Global.API_GET_PERMISSIONS_BY_USER_POLD + user.sub;
    // console.log(" this.url:", this.url)
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object.Permissions != null) {
          this.permissionList = result.Object.Permissions[0].PermissionsDetail;
          // console.log("Permisos:", this.permissionList)
        } else {
          this.permissionList = [];
        }
      }
    })
  }

  public VerifactePermissionUpdate(field: string) {
    // debugger
    let tipo = "";
    let flag = false;
    let flag2 = false;
    // let containerSK = this.u_obj.ContainerNumber.split('-', 1);
    let containerSK = this.u_obj.ContainerNumber != null ? this.u_obj.ContainerNumber.split('-', 1) : null

    //console.log("containerSK:",containerSK)
    if (this.userType == 1 || this.userType == 3) {
      tipo = "Compras"; // "Importaciones";
    }
    else if (this.userType == 4) {
      tipo = "Agente Aduana";
    }
    else if (this.userType == 5) {
      tipo = "Transporte";
    } else if (this.userType == 6) {
      tipo = "Bodega";
    } else if (this.userType == 7) {
      tipo = "Logística";
    } else if (this.userType == 11) {
      tipo = "Finanzas";
    } else if (this.userType == 8) {
      tipo = "Calidad";
    }
    else if (this.userType == 9) {
      tipo = "Taxes";
    }
    else if (this.userType == 10) {
      tipo = "Compras 2";
    }

    // Finanzas:7, Calidad: 8, Taxes: 9 
    // validar todos los campos

    // console.log(this.permissionList);
    this.permissionList.forEach(element => {
      if (element.Description == field && element.StatusIds.includes(tipo)) {
        if (element.ReadField == 1 && element.UpdateField == 1) {
          flag = true;
        }
      }
    })
    return flag;
  }

  /* public VerifactePermissionUpdate(field: string) {
     let tipo = "";
     let flag = false;
     let flag2=false;
     let containerSK=this.u_obj.ContainerNumber.split('-',1);

     //console.log("containerSK:",containerSK)
     if (this.userType == 1 || this.userType == 3) {
       tipo = "Compras";
       return true;
     }
     else if (this.userType == 4) {
       tipo = "Agente Aduana";
       this.permissionList.forEach(element => {
         if (element.Description == field && element.StatusIds.includes(tipo)) {
           if(field == 'ContainerNumber') {
             flag2=true;
             if (this.u_obj.OrderLineId != containerSK) {
               flag = false;
             }else {flag = true;}
           }
           if(field== 'Type') {
             flag2=true;
             if (this.objectCopy['Type'] != undefined) {
               flag = false;
             }else {
               flag = true;
             }

           }
           if(field== 'BlSeal') {
             flag2=true;
             if (this.objectCopy['BlSeal'] != undefined) {
               flag = false;
             }else {flag = true;}
           }
           if(field== 'ProductWeight') {
             flag2=true;
             if (this.objectCopy['ProductWeight'] != undefined) {
               if (this.objectCopy['ProductWeight'] != 0) {
                 flag = false;
               }else {flag = true;}
             }
           }
           if(field== 'BlWeight') {
             if (this.objectCopy['BlWeight'] != undefined) {
               if (this.objectCopy['BlWeight'] != 0) {
                 flag = false;
               }else {flag = true;}
             }
           }
           if(field== 'DateDeparturePort') {
             flag2=true;
             if (this.objectCopy['DateDeparturePort'] != undefined) {
               flag = false;
             }else {flag = true;}
           }
           if(field== 'EntryToPort') {
             flag2=true;
             if (this.objectCopy['EntryToPort'] != undefined) {
               flag = false;
             }else {flag = true;}
           }

           if (flag2==false) {
             flag = true;
           }
           //flag = true;
         }
       })
       return flag;
     }
     else if (this.userType == 5) {
       tipo = "Transporte";
       this.permissionList.forEach(element => {
         if (element.Description == field && element.StatusIds.includes(tipo)) {
           flag = true;
         }
       })
       return flag;
     }
     else if (this.userType == 6)
     {
       tipo = "Bodega";
       this.permissionList.forEach(element => {
         if (element.Description == field && element.StatusIds.includes(tipo)) {
           if(field== 'DateInWarehouse') {
             flag2=true;
             if (this.objectCopy['DateInWarehouse'] != undefined) {
               flag = false;
             } else {flag = true;}
           }
           if(field== 'HourInWarehouse') {
             flag2=true;
             if (this.objectCopy['HourInWarehouse'] != undefined) {
               flag = false;
             }else {flag = true;}
           }

           if(field== 'WeightInWarehouse') {
             flag2=true;
             if (this.objectCopy['WeightInWarehouse'] != undefined) {
               flag = false;
             }else {flag = true;}
           }
           if(field== 'InPlant') {
             flag2=true;
             if (this.objectCopy['InPlant'] != undefined) {
               flag = false;
             }else {flag = true;}
           }
           if(field== 'SealWarehouse') {
             flag2=true;
             if (this.objectCopy['SealWarehouse'] != undefined) {
               flag = false;
             }else {flag = true;}
           }

           if(field== 'DepartureHour') {
             flag2=true;
             if (this.objectCopy['DepartureHour'] != undefined) {
               flag = false;
             }else {flag = true;}
           }
           if (flag2==false) {
             flag = true;
           }
           //flag = true;
         }
       })
       return flag;

     }
     else if (this.userType == 7)
     {
       tipo="Logìstica";
       this.permissionList.forEach(element => {
         if (element.Description == field && element.StatusIds.includes(tipo)) {
           if(field== 'DateDepartureReception')
           {
             flag2=true;
             if (this.objectCopy['DateDepartureReception'] != undefined) {
              flag = false;
             }
             else {flag = true;}
           }
           if(field== 'HourDepartureReception')
           {
             flag2=true;
             if (this.objectCopy['HourDepartureReception'] != undefined) {
               flag= false;
             }
             else {flag = true;}
           }
           if( flag2==false)
           {
             flag= false;
           }

         }
       })
       return flag;
     }
     else {
       return flag;
     }
   }*/

  public VerifyApprove(field: string) {
    if (this.u_obj[field] != this.objectCopy[field] && (this.userType == 4 || this.userType == 5)) {
      let permission = null;
      let flag = false;
      this.permissionList.forEach(perm => {
        if (perm.Description == field && (perm.UsersApproval != "" && perm.UsersApproval != null)) {
          permission = perm;
          flag = true;
        }
      })
      if (flag) {
        this.AddComment(permission, this.u_obj[field], this.objectCopy[field], this.u_obj['OrderLineDetailId']);
      }
    }
  }


  public ValidarTiempoDescarga(object) {

    if (object.ObservationWarehouseId == undefined || object.ObservationWarehouseId != "") {
      this.res = false;
    }
    if (object.ObservationWareHouse == undefined || object.ObservationWareHouse != "") {
      this.res = false;
    }
  }

  public async UpdateData(object) {
    this.isUpdating = true;
    this.obj = object;
    this.res = true;
    //debugger
    if (this.userType == 6) {
      if (this.u_obj['DownloadHour'] != null) {
        let t1 = this.u_obj['DownloadHour'].split(":");
        t1 = parseInt(t1[0]);
        //this.userType = 6
        if (t1 > 4 && this.obj['DownloadHour'] != undefined && this.userType == 6) {
          this.ValidarTiempoDescarga(this.obj);
          if (this.res != true) {
            this.isUpdating = false;
            this.messageDialog("Información", 'Tiempo de descarga es mayor al permitido, ingresar Motivo descarga y Observaciones', 'information');
            return;
          }
        }
      }

    }
    //console.log("this.user.sub:",this.user.sub)
    this.obj.UserEdit = this.user.sub;
    this.obj.typeUserUp = this.userType;
    this.obj.inUse = true;
    this.obj._ApprovalField = this.fieldsToApproval;
    this.url = environment.Global.API_POST_POLD;
    console.log(this.obj);
    console.log("this.obj:", this.obj, "this.copy:", this.objectCopy);
    this.messageShowLoading();


    Swal.fire(
      {
        title: this.translations.MessageConfirmTitle,
        text: this.translations.TextConfirm,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.Accept,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
      }
    ).then((result) => {
      if (result.isConfirmed) {
        this.messageShowLoading();
        this.srv.post(this.url, this.obj).toPromise().then(result => {
          if (result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.refreshData.emit({ id: this.index, idRow: this.u_obj.OrderLineDetailId, user: this.user });
           
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
          this.isUpdating = false;
          
        }, err => {
          this.messageDialog(err, '', 'error');
          this.isUpdating = false;
        })
      } else {
        this.isUpdating = false;
      }
    })


  }



  public AddComment(permission: any, value: any, oldValue: any, OrderLineDetailId) {
    Swal.fire(
      {
        title: this.translations.FieldModification,
        html: `<p>El campo <b>${permission.ParameterName}</b> requiere aprobación para actualizar el dato, ingrese el motivo de cambio`,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.ButtonComment,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
        input: 'text',
        preConfirm: (data) => {
          if (data == '') {
            Swal.showValidationMessage(
              this.translations.AddComment
            )
          }
        }
      }
    ).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          Field: permission.Description,
          Commentary: result.value,
          UsersToApproval: permission.UsersApproval,
          FieldESP: permission.ParameterName,
          CurrentValue: value,
          PreviousValue: oldValue,
          UserId: "",
          UserSub: this.user.sub,
          TableName: "PurchaseOrderLineDetail",
          OrderLineId: OrderLineDetailId
        }
        this.validateFields(obj);
        this.objectCopy[permission.Description] = this.u_obj[permission.Description];
      } else if (result.isDismissed) {
        this.u_obj[permission.Description] = this.objectCopy[permission.Description];
      }
    })
  }

  public validateFields(obj) {
    let flag = false;
    let object = JSON.parse(JSON.stringify(obj));
    if (this.fieldsToApproval.length == 0) {
      this.fieldsToApproval.push(object);
    } else {
      this.fieldsToApproval.forEach(element => {
        if (element.Field == object.Field) {
          flag = true;
          element.Commentary = object.Commentary;
          element.PreviousValue = object.PreviousValue;
          element.CurrentValue = object.CurrentValue;
        }
      });
      if (!flag) {
        this.fieldsToApproval.push(obj);
      }
    }
  }

  public async GetLogFields() {
    //console.log("this.u_obj",this.u_obj)
    this.url = environment.Global.API_GET_SPECIFIC_LOG + 'PurchaseOrderLineDetail/' + this.u_obj['OrderLineDetailId'];
    //console.log("this.url",this.url)
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        let table = JSON.parse(JSON.stringify(result.Object));
        let list = [];
        table.TableFields.forEach(field => {
          if (field.TableFieldDetail.length != 0) {
            list.push(field);
          }
        });
        this.logList = list;
        if (Array.isArray(this.logList)) {
          this.pageTotalLog = this.logList.length;
        }
      }
    })
  }

  public OpenDetail(index, log) {
    this.objRowLog = log;
    this.hiddenLog[index] = !this.hiddenLog[index];

  }

  //CAMPOS CALCULADOS
  public setWeightDifferenceProductBl() {
    if (this.u_obj['BlWeight'] != null && this.u_obj['ProductWeight'] != null) {
      let BlWeight = this.u_obj['BlWeight'];
      let ProductWeight = this.u_obj['ProductWeight'];

      let difference = (BlWeight / ProductWeight) - 1;
      this.u_obj['WeightDifferenceProductBl'] = difference;

      if (difference <= -1) {
        this.u_obj['ObservationDifferenceProductBl'] = 'Urgente Revisar e informar';
      }
      else if (difference >= 3) {
        this.u_obj['ObservationDifferenceProductBl'] = "Validad Embalaje";
      }
      else {
        this.u_obj['ObservationDifferenceProductBl'] = 'OK';
      }
    }
    else {
      this.u_obj['WeightDifferenceProductBl'] = this.u_obj['WeightDifferenceProductBl'];
      this.u_obj['ObservationDifferenceProductBl'] = this.u_obj['ObservationDifferenceProductBl']
    }
  }


  public setWeightBlTare() {
    if (this.u_obj['TareBl'] != null && this.u_obj['BlWeight'] != null) {
      let tara = this.u_obj['TareBl'];
      let bl = this.u_obj['BlWeight'];
      let total = tara + bl
      this.u_obj['WeightBlTare'] = total;
    }
    else {
      this.u_obj['WeightBlTare'] = this.u_obj['WeightBlTare'];
    }
  }

  public setWeightDifferenceBlTareStorage() {
    if (this.u_obj['TareBl'] != null && this.u_obj['BlWeight'] != null && this.u_obj['WeightInStorage'] != null) {
      let tara = this.u_obj['TareBl'];
      let bl = this.u_obj['BlWeight'];
      let InStore = this.u_obj['WeightInStorage'];
      let total = (tara + bl) - InStore;
      this.u_obj['WeightDifferenceBlTareStorage'] = total;
      let totalBlInStore = bl - InStore;
      this.u_obj['WeightDifferenceBlInPort'] = totalBlInStore;
      if (totalBlInStore <= -50) {
        this.u_obj['ObservationDifferenceBlInPort'] = "EXCEDENTE PEDIR COMPROBANTE Y NOTIFICAR";
      }
      else if (totalBlInStore >= 50) {
        this.u_obj['ObservationDifferenceBlInPort'] = "FALTANTE PEDIR COMPROBANTE Y NOTIFICAR";
      }
      else {
        this.u_obj['ObservationDifferenceBlInPort'] = "OK";
      }

    } else {
      this.u_obj['WeightDifferenceBlTareStorage'] = this.u_obj['WeightDifferenceBlTareStorage'];
      this.u_obj['WeightDifferenceBlInPort'] = this.u_obj['WeightDifferenceBlInPort']
      this.u_obj['ObservationDifferenceBlInPort'] = this.u_obj['ObservationDifferenceBlInPort'];
    }

  }

  public setWeightDifferenceMRNOutputPort() {
    if (this.u_obj['TareBl'] != null && this.u_obj['BlWeight'] != null && this.u_obj['WeightOutPutStorage'] != null) {
      let tara = this.u_obj['TareBl'];
      let bl = this.u_obj['BlWeight'];
      let OutStore = this.u_obj['WeightOutPutStorage'];
      let total = OutStore - (tara + bl);
      this.u_obj['WeightDifferenceMRNOutputPort'] = total;
    }
    else {
      this.u_obj['WeightDifferenceMRNOutputPort'] = this.u_obj['WeightDifferenceMRNOutputPort'];
    }
  }

  public setWeightDifference() {
    if (this.u_obj['WeightInWarehouse'] != null && this.u_obj['WeightOutPutStorage'] != null) {
      let OutStore = this.u_obj['WeightInStorage'];
      let WareHouse = this.u_obj['WeightInWarehouse'];
      let total = WareHouse - OutStore;

      this.u_obj['WeightDifference'] = total;
    }
    else {
      this.u_obj['WeightDifference'] = this.u_obj['WeightDifference'];
    }
  }

  public setSealDifference() {
    if (this.u_obj['SealWarehouse'] != null && this.u_obj['BlSeal'] != null) {
      if (this.u_obj['SealWarehouse'] != this.u_obj['BlSeal'] != null) {
        this.u_obj['SealDifference'] = "SI";
      }
      else {
        this.u_obj['SealDifference'] = "NO";
      }

    }
    else {
      this.u_obj['SealDifference'] = "";
    }
  }

  public setDownloadHour() {
    if (this.u_obj['DepartureHour'] != null && this.u_obj['HourInWarehouse'] != null) {
      let t1 = this.u_obj['DepartureHour'].split(":");
      let t2 = this.u_obj['HourInWarehouse'].split(":");
      let hora = t1[0] - t2[0];
      let minut = 0;

      if (hora < 0) {
        let v1 = parseInt(t2[0]);
        let v2 = parseInt(t1[0]);
        hora = (24 - v1) + v2;
      }

      if (t1[1] >= t2[1]) {
        minut = t1[1] - t2[1];
      }
      else {

        hora = hora - 1;
        minut = 60 - (t2[1] - t1[1]);

      }

      let tiempo = "";


      if (minut == 0) {
        if (hora == 0) {
          tiempo = "00" + ":0" + minut;
        }
        else if (hora > 0 && hora < 10) {
          tiempo = "0" + hora + ":0" + minut;
        }
        else {
          tiempo = hora + ":00";
        }
      }
      else if (minut > 0 && minut < 10) {
        if (hora == 0) {
          tiempo = "00" + ":0" + minut;
        }
        else if (hora > 0 && hora < 10) {
          tiempo = "0" + hora + ":0" + minut;
        }
        else if (hora < 0) {

        }
        else {
          tiempo = hora + ":0" + minut;
        }
      }
      else {
        if (hora == 0) {
          tiempo = "00" + ":" + minut;
        }
        else if (hora > 0 && hora < 10) {
          tiempo = "0" + hora + ":" + minut;
        }
        else {
          tiempo = hora + ":" + minut;
        }

      }
      let result = tiempo;

      this.u_obj['DownloadHour'] = result;
    }
    else {
      this.u_obj['DownloadHour'] = this.u_obj['DownloadHour'];
    }
  }
  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }

  // Selected date before the planning date
  public selectedDateBefore() {
    if (this.u_obj['RescheduleDate'] != null && this.u_obj['DateDepartureReception'] != null) {
      // let OutStore = this.u_obj['DateDepartureReception'];
      // let WareHouse = this.u_obj['RescheduleDate'];
      let CreationTime = this.formatOnlyDate(new Date(this.u_obj['DateDepartureReception']));
      let AssignmentDate = this.formatOnlyDate(new Date(this.u_obj['RescheduleDate']));
      var TimeAssignment = new Date(AssignmentDate).getTime() - new Date(CreationTime).getTime();
      let value = Math.round(TimeAssignment / (1000 * 60 * 60 * 24));
      if (value < 0) {


        Swal.fire(
          'The Internet?',
          'That thing is still around?',
          'question'
        )

        Swal.fire(
          {
            title: this.translations.MessageConfirmTitle,
            text: this.translations.DateBefore,
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,

            confirmButtonText: this.translations.Accept,
            confirmButtonColor: '#C8102E',
            showCloseButton: true,
          }
        )


        // this.messageDialog("Información", 'Tiempo de descarga es mayor al permitido, ingresar Motivo descarga y Observaciones', 'information');
      }
    }
  }

}
