<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="mdi mdi-menu font-24 second-color-text"></i>
        </a>
    </li>
</ul>


<ul class="navbar-nav float-right">

    <!-- Aprobaciones pendientes -->
    <li class="nav-item" ngbDropdown>
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            title="Aprobaciones pendientes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="icon-notificacion">
                <i class="mdi mdi-account-edit icon-color"></i>
                <div class="icon-count">
                    <!-- <input type="text" class="form-control" name="Name" [(ngModel)]="c_obj['Name']"> -->
                    <span *ngIf="this.countAP != '0'" class="icon-background">{{(NotificationObserverAP$ |
                        async).total}}</span>
                </div>

            </div>
        </a>

        <!-- <div class="dropdown-menu-right" style="overflow:scroll; overflow-x: hidden; width: 250px; height:300px;" -->

        <div class="dropdown-menu-left" ngbDropdownMenu>
            <span class="with-arrow">
                <span style="background-color: white;"></span>
            </span>
            <SPAN style="display: flex;align-items: center;">{{'PendingApprovals' | translate}}</SPAN>
            <div class="dropdown-divider"></div>
            <DIV style="overflow:scroll; overflow-x: hidden; width: 290px; height:250px;">

                <a *ngFor="let item of notificacionesAP" class="dropdown-item second-color-text"
                    style="cursor: pointer;" (click)="redirect(item,1)">
                    <span *ngIf="item.Reviewed != true && item.documento[0].lenght !=0" style="font-weight: bold;">
                        <SPAN>{{'DocumentToBeApproved' | translate}}</SPAN>
                        <br>
                        <SPAN>{{item.documento[0].FileName}}</SPAN>
                        <br>
                    </span>
                    <span *ngIf="item.Reviewed != false && item.documento[0].lenght !=0">
                        <SPAN>{{'DocumentToBeApproved' | translate}}</SPAN>
                        <br>
                        <SPAN>{{item.documento[0].FileName}}</SPAN>
                        <br>
                    </span>
                </a>

            </DIV>




        </div>

    </li>

    <!-- Aprobaciones completadas. -->
    <li class="nav-item" ngbDropdown>
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Aprobaciones completadas">
            <div class="icon-notificacion">
                <i class="mdi mdi-account-check icon-color"></i>
                <div class="icon-count">
                    <span *ngIf="this.countAC != '0'" class="icon-background">{{(NotificationObserverAC$ |
                        async).total}}</span>
                </div>

            </div>
        </a>
        <div class="dropdown-menu-left" ngbDropdownMenu>
            <span class="with-arrow">
                <span style="background-color: white;"></span>
            </span>
            <SPAN style="display: flex;align-items: center;">{{'CompletedApprovals' | translate}}</SPAN>
            <div class="dropdown-divider"></div>
            <div style="overflow:scroll; overflow-x: hidden; width: 290px; height:250px;">
                <a *ngFor="let item of notificacionesAC" class="dropdown-item second-color-text"
                    style="cursor: pointer;" (click)="redirect(item,2)">
                    <span *ngIf="item.Reviewed != true && item.documento.length > 0" style="font-weight: bold;">
                        <SPAN>{{'DocumentCompleted' | translate}}</SPAN>
                        <br>
                        <SPAN>{{item.documento[0].FileName}}</SPAN>
                        <br>
                    </span>
                    <span *ngIf="item.Reviewed != false && item.documento.length >0">
                        <SPAN>{{'DocumentCompleted' | translate}}</SPAN>
                        <br>
                        <SPAN>{{item.documento[0].FileName}}</SPAN>
                        <br>
                    </span>
                </a>
            </div>

        </div>
    </li>

    <!-- Contenedores pendientes de planificar. -->
    <li class="nav-item" ngbDropdown>

        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Contenedores por planificar">
            <div class="icon-notificacion">
                <i class="mdi mdi-account-convert icon-color"></i>
                <div class="icon-count">
                    <span *ngIf="this.countCXP != '0'" class="icon-background">{{(NotificationObserverCXP$ |
                        async).total}}</span>

                </div>

            </div>
        </a>
        <div class="dropdown-menu-left" ngbDropdownMenu>
            <span class="with-arrow">
                <span style="background-color: white;"></span>
            </span>
            <SPAN style="display: flex;align-items: center;">{{'ContainersPendingPlanning' | translate}}</SPAN>
            <div class="dropdown-divider"></div>
            <div style="overflow:scroll; overflow-x: hidden; width: 290px; height:250px;">

                <a *ngFor="let item of notificacionesCXP" class="dropdown-item second-color-text"
                    style="cursor: pointer;" (click)="redirect(item,3)">
                    <span *ngIf="item.Reviewed != true && item != null  && item.pol.length >0" style="font-weight: bold;">
                        <SPAN>{{'ContainersPendingPlanning' | translate}}</SPAN>
                        <br>
                        <SPAN>{{'dispatch' | translate}}: {{item.pol[0].OrderDisplay}}</SPAN>
                        <br>
                    </span>
                    <span *ngIf="item.Reviewed != false && item != null  && item.pol.length >0">
                        <SPAN>{{'ContainersPendingPlanning' | translate}}</SPAN>
                        <br>
                        <SPAN>{{'dispatch' | translate}}: {{item.pol[0].OrderDisplay}}</SPAN>
                        <br>
                    </span>
                    <div class="dropdown-divider"></div>
                </a>
            </div>

        </div>
    </li>

    <!-- Contenedores planificados. -->
    <li class="nav-item" ngbDropdown placement="bottom-right">

        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Contenedores planificados.">
            <div class="icon-notificacion">
                <i class="mdi mdi-calendar icon-color"></i>
                <div class="icon-count">
                    <span *ngIf="this.countCP != '0'" class="icon-background">{{(NotificationObserverCP$ |
                        async).total}}</span>
                </div>

            </div>
        </a>
        <div class="dropdown-menu-right" ngbDropdownMenu>
            <span class="with-arrow">
                <span style="background-color: white;"></span>
            </span>

            <SPAN style="display: flex;align-items: center;">{{'PlannedContainers' | translate}}</SPAN>
            <div class="dropdown-divider"></div>
            <div style="overflow:scroll; overflow-x: hidden; width: 290px; height:250px;">

                <a *ngFor="let item of notificacionesCP" class="dropdown-item second-color-text"
                    style="cursor: pointer;" (click)="redirect(item,4)">
                    <span *ngIf="item.Reviewed != true && item != null  && item.pold.length >0" style="font-weight: bold;">

                        <SPAN>{{'submenu_skretting_purchase_order_detail_line' | translate}}:
                            <br>
                            {{item.pold[0].purchaseOrderLine[0].OrderDisplay}}</SPAN>
                    </span>
                    <span *ngIf="item.Reviewed != false && item != null  && item.pold.length >0 ">

                        <SPAN (refreshData)="refreshData($event)">{{'submenu_skretting_purchase_order_detail_line' | translate}}:
                            <br>{{item.pold[0].purchaseOrderLine[0].OrderDisplay}}</SPAN>
                        <br>
                    </span>
                    <div class="dropdown-divider"></div>
                </a>
            </div>

        </div>

    </li>

    <!-- Contenedores pendientes de asignar -->
    <li class="nav-item" ngbDropdown placement="bottom-right">


        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Contenedores por asignar">
            <div class="icon-notificacion">
                <i class="mdi mdi-account-alert icon-color"></i>
                <div class="icon-count">
                    <span *ngIf="this.countCA != '0'" class="icon-background">{{(NotificationObserverCA$ |
                        async).total}}</span>
                </div>
            </div>
        </a>

        <div class="dropdown-menu-right" ngbDropdownMenu>
            <span class="with-arrow">
                <span style="background-color: white;"></span>
            </span>
            <SPAN style="display: flex;align-items: center;">{{'ContainersToAssign' | translate}}</SPAN>
            <div class="dropdown-divider"></div>
            <div style="overflow:scroll; overflow-x: hidden; width: 290px; height:250px;">
                <a *ngFor="let item of notificacionesCA" class="dropdown-item second-color-text"
                    style="cursor: pointer;" (click)="redirect(item,5)">
                    <span *ngIf="item.Reviewed != true && item != null  && item.pold.length >0" style="font-weight: bold;">

                        <SPAN>{{'submenu_skretting_purchase_order_detail_line' | translate}}:
                            <br>
                            {{item.pold[0].purchaseOrderLine[0].OrderDisplay}}</SPAN>
                    </span>
                    <span *ngIf="item.Reviewed != false  && item != null  && item.pold.length >0">

                        <SPAN>{{'submenu_skretting_purchase_order_detail_line' | translate}}:
                            <br>{{item.pold[0].purchaseOrderLine[0].OrderDisplay}}</SPAN>
                        <br>
                    </span>
                    <div class="dropdown-divider"></div>
                </a>
            </div>


        </div>
    </li>


    <li class="nav-item" ngbDropdown placement="bottom-right">


        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
            <!-- <span *ngIf="UserlogName" class="mdi mdi-account" style="font-size: 30px;"></span>  -->
            <span *ngIf="!UserlogName"> ¡{{'hello' | translate}} {{(user$ | async).name}}! </span>

        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span style="background-color: white;"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 text-white m-b-10 second-background-color">
                <div class="">
                    <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="50">
                    <span *ngIf="UserlogName"> ¡{{'hello' | translate}} {{(user$ | async).name}}! </span>
                </div>
                <div class="m-l-10">
                    <!-- <h4 class="m-b-0">{{(user$ | async).name}}</h4> -->
                </div>
            </div>
            <a class="dropdown-item second-color-text" (click)="logout()" style="cursor: pointer;">
                <i class="fa fa-power-off m-r-5 m-l-5"></i>Logout</a>
            <div class="dropdown-divider"></div>
        </div>

    </li>



</ul>
