import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';



@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent extends BaseCrudComponent implements OnInit {
  showErrorMessage = false;
  optionsList = ["GroupName", "User"];
  optionSelected = null;
  GroupId: number;
  // Group = [
  //   { GroupId: "1", Name: "Imports" },
  //   { GroupId: "2", Name: "Logistics" },
  //   { GroupId: "3", Name: "Finance" },

  // ];

  userList = [];
  userListGroup = [];
  constructor(
    private globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Name', 'Description', 'Status'];
  }


  async ngOnInit() {
    await super.ngOnInit();
    this.user = this._authService.getUserLogged();
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
    await this.getInitialData();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_AL");
    await this.getInternalUser();
  }

  public async getInitialData() {
    this.messageShowLoading();
    this.url = environment.Global.API_GET_ALL_ALERT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if (Array.isArray(this.rows)) {
          this.pageTotal = this.rows.length;
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })

  }



  public createForm(object: any): boolean {
    let myForm: any = {};
    this.optionSelected = null;
    this.GroupId = 0;
    myForm = new FormGroup({
      'Name': new FormControl(object.Name, [Validators.required]),
      'Description': new FormControl(object.Description, [Validators.required]),
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.c_obj['AlertByUser'] = [];
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public async createItem(modal, object) {
    this.isCreating = true;
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    let users = [];

    if (object.AlertByUser.length > 0) {

      object.AlertByUser.forEach(element => {
        let data = {
          UserId: Number(element)
        }
        users.push(data)
      });
    }


    if (object.Type && users.length == 0) {
      this.isCreating = false
      return this.errorMessage('ChooseUser', 'ChooseUser', 'error');
    }

    this.obj = {
      Name: object.Name,
      Description: object.Description,
      Status: object.Status,
      Type: object.GroupId ? 1 : 0,
      CreatorUser: this.user.name,
      GroupId: object.GroupId,
      AlertByUser: users
    }
    // console.log("Objeto enviado:", this.obj);
    // console.log("Grupo ID:", this.GroupId);
    this.url = environment.Global.API_CREATE_ALERT;

    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.optionSelected = "";
        this.GroupId = null;
      } else {
        // console.log("error:", result.Message);
        this.messageDialog(result.Message, '', 'error');
      }
      this.isCreating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isCreating = false;
    })
  }

  public clickOnCheck(event, id) {
    // console.log("Campo a actualizar:", this.obj);
    if (event.target.checked) {
      this.changeStatus('1', id);
    } else {
      this.changeStatus('2', id);
    }
  }

  public changeStatus(value, id) {
    this.url = environment.Global.API_UPDATE_STATUS_ALERT;
    this.obj = {
      AlertId: id,
      Status: value,
      ModifierUser: this.user.name,
      DeleteUser: this.user.name
    }

    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    }, err => {
      this.messageDialog(err, '', 'error');
    })
  }

  public openUpdateModal(modal, object) {

    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    // console.log("Objeto:", this.u_obj);
    this.u_obj['UpdateAlertByUser'] = [];
    // console.log("Type:", this.u_obj);

    if (this.u_obj.Type == 1) {
      this.optionSelected = "GroupName";
    } else {
      this.optionSelected = "User";
    }

    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    if (this.u_obj['AlertByUser'].length > 0) {
      this.u_obj['AlertByUser'].forEach(element => {
        this.u_obj['UpdateAlertByUser'].push(element.UserId.toString());
      });
    }
  }


  public updateItem(modal, object) {
    this.isUpdating = true;
    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    }
    let users = [];

    if (object.UpdateAlertByUser.length > 0) {
      object.UpdateAlertByUser.forEach(element => {
        let data = {
          UserId: Number(element)
        }
        users.push(data)
      });
    }
    if (users.length == 0) {
      this.isCreating = false
      return this.errorMessage('ChooseUser', 'ChooseUser', 'error');
    }
    this.obj = {
      AlertId: object.AlertId,
      Name: object.Name,
      Description: object.Description,
      Status: object.Status,
      Type: object.Type,
      GroupId: this.u_obj['GroupId'],
      ModifierUser: this.user.name,
      AlertByUser: object.Type == "0" ? users : []
    }
    // console.log("DATA:", this.obj);
    this.url = environment.Global.API_UPDATE_ALERT;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      // console.log("Objeto enviado:", result);
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
        this.optionSelected = null;
        this.GroupId = null;
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }

  public async getInternalUser() {
    this.url = environment.Global.API_GET_USER_INT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.userList = result.Object;
      }
    })
  }



}
