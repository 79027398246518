<app-header-options (updateInfo)="getInitialData()" (search)="searchData($event)" [isLoading]="isLoading"
  (openModal)="openCreateModal(create1)" [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>

<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-color">
          <tr>
            <th class="sh">
              ID
            </th>
            <th (click)="setOrder('DocumentName')" class="sh">
              {{'Document' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DocumentName')"
                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
            <th (click)="setOrder('ClientName')" class="sh">
              {{'Type' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='ClientName')"
                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
            <!-- <th (click)="setOrder('NameFile')" class="sh">
              {{'NameFile' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='ClientName')"
                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th> -->
            <th (click)="setOrder('Status')" class="sh text-center">
              {{'Status' | translate}}
            </th>
            <th class="text-center">
              {{'enable/disable' | translate}}
            </th>
            <th>
              {{'actions' | translate}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
            <td>
              <label for="code">{{i + 1}}</label>
            </td>
            <td>
              <label for="DocumentName">{{row.Name}}</label>
            </td>
            <td>
              <label for="ClientName" *ngIf="row.Type==1">{{'SRIFile' | translate}}</label>
              <label for="ClientName" *ngIf="row.Type==2">{{'ExemptionReport' | translate}}</label>
            </td>
            <!-- <td>
              <label for="DocumentName">{{row.NameFile}}</label>
            </td> -->
            <td class="text-center">
              <label for="Status">{{row.Status}}</label>
            </td>
            <td class="text-center">
              <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null"
                name="{{row.DocumentName}}" (change)="clickOnCheck($event,row.DocumentId)" type="checkbox">
            </td>
            <td *ngIf="row.Status == 'A'">
              <i *ngIf="(row.PathFile != NULL)" (click)="DownloadFile(row)" style="cursor:pointer;"
                class="mdi mdi-cloud-download text-info m-r-10" title="{{'DownloadFile' | translate}}"></i>
              <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10"
                title="{{'edit' | translate}}"></i>
              <!-- <i *ngIf="globalService.containPermission(permissionList,'Update')" (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i> -->
              <i (click)="changeStatus('3', row.DocumentId)" style="cursor:pointer;"
                class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
              <!-- <i *ngIf="globalService.containPermission(permissionList,'Delete')" (click)="changeStatus('3', row.SystemParameterId)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i> -->
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="rows.length > 0">
          <td colspan="9">
            <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
              style="float: right;"></ngb-pagination>
          </td>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'create_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Name' | translate}}</label>
              <input type="text" class="form-control" name="Name" [(ngModel)]="c_obj['Name']">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Type' | translate}}</label>
              <ng-select [(ngModel)]="c_obj['Type']" name="Type" [multiple]="false">
                <ng-option *ngFor="let option of ParameterDetailList" value="{{option.Id}}">{{option.Name | translate}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'UploadFile' | translate}}</label>
              <input type="file" class="form-control" name="file" (change)="UploadFile($event)" appReadexcel
                accept=".xlsx,.xls">
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Date' | translate}} {{'From' | translate}} </label>
              <input type="date" class="form-control u-input-calendar" name="DateFrom" [(ngModel)]="c_obj['DateFrom']">
            </div>
            <div class="col-md-12 sk-ibox">
              <label>{{'Date' | translate}} {{'To' | translate}} </label>

              <input type="date" class="form-control u-input-calendar" name="DateTo" [(ngModel)]="c_obj['DateTo']">
            </div>
          </div> -->
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
    <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
      (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
  </div>
</ng-template>


<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'update_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'DocumentName' | translate}}</label>
              <input type="text" class="form-control" name="Name" [(ngModel)]="u_obj['Name']">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Type' | translate}}</label>
              <ng-select [(ngModel)]="u_obj['Type']" name="Type" [multiple]="false">
                <ng-option *ngFor="let option of ParameterDetailList" value="{{option.Id}}">{{option.Name | translate}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'UploadFile' | translate}}</label>
              <input type="file" class="form-control" name="file" (change)="UploadFile($event)" placeholder="valor"
                accept=".xls,.xlsx">
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Date' | translate}} {{'From' | translate}} </label>
              <input type="date" class="form-control u-input-calendar" name="DateFrom" [(ngModel)]="c_obj['DateFrom'].split('T')[0]">
            </div>
            <div class="col-md-12 sk-ibox">
              <label>{{'Date' | translate}} {{'To' | translate}} </label>

              <input type="date" class="form-control u-input-calendar" name="DateTo" [(ngModel)]="c_obj['DateTo'].split('T')[0]">
            </div>
          </div> -->
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
    <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
      (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
  </div>
</ng-template>
