import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';

@Component({
  selector: 'app-purchaseorderlines',
  templateUrl: './purchaseorderlines.component.html',
  styleUrls: ['./purchaseorderlines.component.css']
})
export class PurchaseorderlinesComponent extends BaseCrudComponent implements OnInit {
  urlDetail = 'comex/logistics/purchase-order-line-detail/';
  id = '';
  mode = 0; // Show all purchase order lines per user
  activeStatus = 0;
  statusList = [];
  documentList = [];
  toggle = true;
  hidden = [];
  OrderNumber = '';
  objRow = null;
  userType = '';
  buttonCreateVisible = false;

  //Filter
  showIconF = false;
  seeFilter = false;
  arrayList = [];
  hiddenFunction = [];
  hiddenFilter = [];
  dataFilter = [];
  dataSearchList = [];

  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['OrderDisplay', 'Division', 'SupplierContract', 'CompanyName', 'ItemName', 'ItemNumber', 'GrossWeight', 'EtdReal', 'EtaReal', 'Bl', 'Invoice', 'IncoTerm', 'Status', 'Origen'];
    this.id = route.snapshot.params.id;
    if (this.id != "All") {
      this.mode = 1; // Show all purchase order lines per purchase order selected
    }
  }


  async ngOnInit() {
    await super.ngOnInit();
    this.permissionList = this._authService.GetPermissionsByModule("CX_POL");
    this.userType = this._authService.ExistPermission("CX_ADM") ? 'ADM'
      : this._authService.ExistPermission("CX_AGA") ? 'AGA'
        : this._authService.ExistPermission("CX_CM") ? 'CM'
          : this._authService.ExistPermission("CX_COM") ? 'COM'
            : this._authService.ExistPermission("CX_FIN") ? 'FIN'
              : this._authService.ExistPermission("CX_CAL") ? 'CAL'
                : this._authService.ExistPermission("CX_TAX") ? 'TAX'
                  : 'ADM';
    this.user = this._authService.getUserLogged();

    await this.getPurchaseOrderLines();
  }

  public async getPurchaseOrderLines() {

    if (this.mode == 1) {
      this.url = environment.Global.API_GET_POL_BY_ON + this.id + '/' + this.userType + '/' + this.user.sub;
    } else {
      this.url = environment.Global.API_GET_ALL_POL_BY_USER + this.userType + '/' + this.user.sub;
    }
    // console.log(this.url);
    this.messageShowLoading();

    // console.log("PurcharseOrderLine URL:", this.url);
    await this.srv.get(this.url).toPromise().then(result => {
      // console.log("PurcharseOrderLine - ", result);
      if (result.Status) {
        if (result.Object.length > 0) {
          this.rows = result.Object;
          this.rawdata = result.Object;
          // console.log(this.rows)
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.OrderNumber = result.Object[0].OrderNumber;

        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      // this.rows.forEach(element => {
      //   element['GrossWeight'] = this.setMaskNumber(Number(element['GrossWeight']));
      //   element['GrossWeightDispatched'] = this.setMaskNumber(Number(element['GrossWeightDispatched']));
      //   element['GrossWeightDifference'] = this.setMaskNumber(Number(element['GrossWeightDifference']));
      //   element['GrossWeightOrder'] = this.setMaskNumber(Number(element['GrossWeightOrder']));
      //   element['AmountUE'] = this.setMaskNumber(Number(element['AmountUE']));
      // });
      this.closeMessageDialog();
    })
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
    this.filterAll();
    this.objRow = null;
  }


  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public async refreshData(obj) {
    let row = {};
    this.hidden[obj.id] = !this.hidden[obj.id];
    await this.getPurchaseOrderLines();
    this.rows.forEach(line => {
      if (line.OrderLineId == obj.idRow) {
        row = line;
      }
    });
    setTimeout(() => {
      this.seeDetailsRow(obj.id, row);
    }, 750);
  }

  public searchDataSelect(event) {
    let data = event.split('|'); debugger;
    if (data[2] == '(Vacíos)') {
      data[2] = null;
    }
    this.dataSearchList[data[0]] = data[2] != '' ? (data[1] + '|' + data[2]) : null;//''
    this.search2 = this.dataSearchList;
    this.filterAllSelect();
  }


  public async seeDetailsRow(index, row) {
    this.objRow = row;
    this.hidden[index] = !this.hidden[index];
  }


  public offFilter(index) {
    this.hiddenFunction[index] = !this.hiddenFunction[index];
    this.hiddenFilter[index] = !this.hiddenFilter[index];
    this.arrayList[index] = [];
    this.searchData('');
  }

  public resetData(index, columnName) {
    let array = [];
    this.arrayList[index] = [];
    this.rows.forEach(element => {
      if (element[columnName] != undefined) {
        let flag = array.indexOf(element[columnName]);
        if (flag == -1) {
          array.push(element[columnName]);
        }
      }
    });
    array.push('(Vacíos)');
    this.arrayList[index] = array;
  }

  public showIconFilter(flag: boolean) {
    this.showIconF = flag;
    if (!flag) {
      this.searchData('');
      this.arrayList = [];
    }
  }
  public async redirect(row: any) {
    this.url = environment.Global.API_GET_ASSOCIATE_DPURCHASEORDERS + '/' + row.OrderLineId + '/' + row.OrderNumber;
    // console.log(this.url);
    await this.srv.get(this.url).toPromise().then(result => {
      // console.log("PurcharseOrderLine - ", result);
      if (result.Status) {
        // console.log(result);
        // console.log(result.Object);
        // console.log(this.urlDetail+ result.Object.OrderLineId);
        this.globalService.goToParameterDetail(this.urlDetail + result.Object.OrderLineId)

      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
  }

}
