<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
        </div>
        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
        </div>
        <div class="col-md-3 col-sm-12">

            <button [ladda]="isFind" type="button" (click)="dataToFind()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
            <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
        </div>
    </div>
</div>

<!-- *ngIf="this.rows.length > 0" -->


<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()" style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i> {{'download_report' |
                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()" style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                {{'download_report_excel' |
                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>


        <!-- Import Agent (5) -->
        <div *ngIf="option_processes == 5 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                  class="icon mdi mdi-filter-remove" *ngIf="showIconF" (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i class="icon mdi mdi-filter"
                  *ngIf="!showIconF" (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>ID</th>
                        <th>{{'Division' | translate}}</th>
                        <th>{{'ProcedureImportline' | translate}}</th>
                        <th>{{'ProvisionedFI' | translate}}</th>
                        <th>{{'Invoice' | translate}}</th>
                        <th>{{'InvoiceDate' | translate}}</th>
                        <th>{{'PurchasePrice' | translate}}</th>
                        <th>{{'ItemNumber' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'TariffHeading' | translate}} </th>
                        <th>{{'Status' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[0]" [indexPosition]="0" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(1,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="l[1]" [indexPosition]="1" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'ProvisionedFI')">
                                <app-select-filter [column]="'ProvisionedFI'" [arrayList]="l[2]" [indexPosition]="2" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'Invoice')">
                                <app-select-filter [column]="'Invoice'" [arrayList]="l[3]" [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'InvoiceDate')">
                                <app-select-filter [column]="'InvoiceDate'" [arrayList]="l[4]" [indexPosition]="4" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>

                        </td>
                        <td>
                            <div (click)="resetData(6,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[6]" [indexPosition]="6" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[7]" [indexPosition]="7" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'TariffHeading')">
                                <app-select-filter [column]="'TariffHeading'" [arrayList]="l[8]" [indexPosition]="8" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(9,'Status')">
                                <app-select-filter [column]="'Status'" [arrayList]="l[9]" [indexPosition]="9" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[10]" [indexPosition]="10" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>
                    <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; ; let i = index">
                        <td></td>
                        <td>{{i+1}}</td>
                        <td><label for="Division">{{row.Division}}</label></td>
                        <td><label for="ProcedureImportline">{{row.ProcedureImportline}}</label></td>
                        <td><label for="ProvisionedFI">{{row.ProvisionedFI}}</label></td>
                        <td><label for="Invoice">{{row.Invoice}}</label></td>
                        <td><label for="InvoiceDate">{{row.InvoiceDate | date: 'y-MM-dd'}}</label></td>
                        <td><label for="PurchasePrice">{{row.PurchasePrice}}</label></td>
                        <td><label for="ItemNumber">{{row.ItemNumber}}</label></td>
                        <td><label for="ItemName">{{row.ItemName}}</label></td>
                        <td><label for="TariffHeading">{{row.TariffHeading}}</label></td>
                        <td><label for="Status">{{row.Status}}</label></td>
                        <td><label for="CompanyName">{{row.CompanyName}}</label></td>

                    </tr>

                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>


    </div>
</div>
