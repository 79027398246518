import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Component({
  selector: 'app-subconcept',
  templateUrl: './subconcept.component.html',
  styleUrls: ['./subconcept.component.css']
})

export class SubconceptComponent extends BaseCrudComponent implements OnInit {
  
  id:any;
  
  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['SubconceptName','Description', 'ParameterName'];
    this.id = route.snapshot.params.id;
  }

   async ngOnInit() {
    await super.ngOnInit();
    await this.getInitialData();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_SC");
    this.user = this._authService.getUserLogged();
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
  }

  public async getInitialData() {
    this.messageShowLoading();
    this.url = environment.Global.API_GET_ALL_SUBCONCEPT + this.id;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if( Array.isArray(this.rows) ){
          this.pageTotal = this.rows.length;
        }
      }  else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
  }

  public createForm(object:any): boolean{
    let myForm:any = {};
    myForm = new FormGroup({
      'SubconceptName': new FormControl(object.SubconceptName, [Validators.required]),
      'Description': new FormControl(object.Description, [Validators.required]),
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }

  public createItem(modal, object) {
    this.isCreating = true;
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    this.obj = {
      SubconceptName: object.SubconceptName,
      ConceptId: this.id,
      Description: object.Description,
      CreatorUser: this.user.name
    }
    this.url = environment.Global.API_CREATE_SUBCONCEPT;
    this.srv.post(this.url, this.obj).toPromise().then( result => {
      if(result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();       
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isCreating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err,'','error');
      this.isCreating = false;
    })
  }

  public clickOnCheck(event, id) {
    if ( event.target.checked ) {
      this.changeStatus('1', id);
    }else {
      this.changeStatus('2', id);
    }
  }

  public changeStatus(value, id) {
    this.url = environment.Global.API_UPDATE_STATUS_SUBCONCEPT;
    this.obj = {
      SubconceptId: id,
      Status: value,  
      ModifierUser: this.user.name,      
      DeleteUser: this.user.name
    }
    this.srv.post(this.url,this.obj).toPromise().then(result => {
      if(result.Status) {
        this.messageDialog(result.Message, '', 'success');
      }  else {
        this.messageDialog(result.Message, '', 'error');
      }
    }, err => {
      this.messageDialog(err,'','error');
    })
  }

  public openUpdateModal(modal, object) {
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }

  public updateItem(modal, object) {
    this.isUpdating = true;

    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    } 

    this.obj = {
      SubconceptId: object.SubconceptId,
      SubconceptName: object.SubconceptName,
      Description: object.Description,
      ModifierUser: this.user.name
    }
    this.url = environment.Global.API_UPDATE_SUBCONCEPT;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err,'','error');
      this.isUpdating = false;
    })
  }
}
