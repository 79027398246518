import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {UserManager, User, UserManagerSettings, WebStorageStateStore, SignoutResponse} from 'oidc-client';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, from, of} from 'rxjs';
import { UserCx } from './../../interfaces/User';
import { UserLogged } from 'src/app/interfaces/UserLogged';
import { ApiService } from '../main/api.service';

import {UserAdapter} from "../../interfaces/UserAdapter";
import {catchError, mergeMap} from "rxjs/operators";
import {tryCatch} from "rxjs/internal-compatibility";
import * as moment from "moment-timezone";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userManager: UserManager;
  private _user: User | null;
  private _userObservable: BehaviorSubject<UserCx>;
  private _permissionObservable: BehaviorSubject<any>;
  momentjs: any = moment;

  private apiUrl = 'https://apim.skretting.com/Skomex';
  private subscriptionKey = '59b47e42dc644b008fcd3c3174df4f86';
  private get idpSettings(): UserManagerSettings {
    return {
      authority: environment.Global.authority,
      client_id: environment.Global.client_Id,
      redirect_uri: environment.Global.redirect_uri,
      scope: environment.Global.scope,
      response_type: environment.Global.response_type,
      silent_redirect_uri: environment.Global.silent_redirect_uri,
      post_logout_redirect_uri: environment.Global.post_logout_redirect_uri,
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTime: 60,
      response_mode: "query",
      userStore: new WebStorageStateStore({ store: window.localStorage })
    }
  }

  constructor(
    private http: HttpClient,
    public apiService: ApiService,
    private storage: Storage,
    private _router: Router) {
    this._userManager = new UserManager(this.idpSettings);
    this._userObservable = new BehaviorSubject<UserCx>(
      {
        name: '',
        company: '',
        isLogged: false,
        sub: '',
        //mail: ''
      }
    );
    this._permissionObservable = new BehaviorSubject<any>([]);
    this.SetUserData();
    this.SetPermissionData();
    /**/this._userManager.events.addAccessTokenExpired(() => {
      console.log('Token de acceso expirado. Renovando token...');
      this.renewToken();
    });
  }

  public login() {
    return this._userManager.signinRedirect(
      { acr_values: 'idp:azureadb2c-skomex' }
    );
  }

  public getClaims(): any {
    return this._user.profile;
  }

  public finishLogin = async (): Promise<User> => {
    return await this._userManager.signinRedirectCallback()
      .then(user => {
        if (!(user == null)) {
          this._user = user;
          this.savePermissions();
          /*this.savePermissionsPro()
            .then(() => this.getCompaniesData2())
            .catch(error => console.error(error));*/
        }
        return user;
      })
      .catch(error => {
        return null;
      });
  }
  completeLogout(): Promise<SignoutResponse> {
    return this._userManager.signoutRedirectCallback();
  }

  public isAuthenticated = async (): Promise<boolean> => {
    return await this._userManager.getUser()
      .then(user => {
        return this.checkUser(user);
      })
  }


  public async logout() {
    await this._userManager.signoutRedirect().then(() => {
      let dato = JSON.parse(localStorage.getItem(environment.Global.key_user));
      this.SetUserLogged(dato);//await

      console.log('Datos almacenados en Bd');
    }).finally(() => {
      localStorage.clear();
      this.storage.clear();
      console.log('Datos limpiados en localStorage');
    });
  }


  private checkUser = (user: User): boolean => {
    return !!user && !user.expired;
  }

  public getUser() {
    return JSON.parse(localStorage.getItem(environment.Global.key_user));
  }

  // Método para renovar el token de forma silenciosa
  /*renewToken(): Promise<User> {
    return this._userManager.signinSilent();
  }*/
  getAccessToken(): Promise<string | null> {
    return this.storage.get('token');
  }
  async renewToken(): Promise<void> {
    try {
      // Renovar el token
      const user = await this._userManager.signinSilent();

      // Almacenar el nuevo token en Ionic Storage
      if (user && user.access_token) {
        await this.storage.set('token', user.access_token);
      }
    } catch (error) {
      console.error('Error al renovar el token:', error);
      await this.logout();
      //throw error; // Re-lanzar el error para que sea manejado por el componente
    }
  }
  getUsuario(): Promise<User> {
    return this._userManager.getUser();
  }
  public savePermissions(){
    this.getCompanies()
      .then((companies) => {
        debugger;
        if (companies && companies.length > 0) {
          // Obtiene el companyId de la primera compañía
          const firstCompanyId = companies[0].id;

          // Llama al segundo API con el companyId obtenido
          return this.getUserPermissions(firstCompanyId)
            .then(() => {
              console.log('Datos almacenados en localStorage');
            });
        }
      });
  }
  public savePermissions3() {
    let user = JSON.parse(localStorage.getItem(environment.Global.key_user));
    let tokens = user.access_token.split('.');
    let token = tokens[0];
    let token_value = tokens[1];
    let value = JSON.parse(atob(token_value)).usertoken;
    let body = {
      ServiceToken: "{9F45B76F-0A08-46E2-AFA0-BB00EC00A854}",
      Token: value,
      Application: "SKOMEX"
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.access_token}`,'Ocp-Apim-Subscription-Key': 'f3c24ae1569144b1a350fff575f24e1f' });
    this.http.get(environment.Global.XMS_TEST, { headers }).toPromise().then((permissions: any) => {
      if (!(permissions == null)) {
        let p = null;
        var lstPermissions = [];
        var first=false;
        permissions.forEach(y => {
          if( lstPermissions.indexOf(y.name) < 0 ){
            lstPermissions.push(y.name);
          }
        });
        /*permissions.forEach(element => {

          if (first == false) {//(element.id == environment.Global.COMPANY_ID)
            p = element.modules;
            element.modules.forEach(y => {
              if( lstPermissions.indexOf(y.name) < 0 ){
                lstPermissions.push(y.name);
              }
            });
            first = true;
          }
        });*/
        //localStorage.setItem(environment.Global.permissions_user, JSON.stringify(p));
        //var aux = CryptoJS.AES.encrypt(lstPermissions.toString(), Global.WHAT.trim()).toString()
        this.storage.set(environment.Global.permissions_user, lstPermissions);
        this.storage.set('token', user.access_token);//user.id_token
        this._permissionObservable.next(p);
        let data: UserCx = {
          name: user.profile.name,
          company: user.profile.CompanyName,
          isLogged: true,
          sub: user.profile.sub,
          //mail: user.profile.Email
        }
        let dataAdapter: UserAdapter = {
          emailAddress:'',
          logon:'',
          name: user.profile.name,
          selectedCompanyId: user.profile.CompanyName,
          selectedCompanyName:user.profile.CompanyId,
          selectedLanguageCode:user.profile.xmsDefaultLanguageCode,
          userId: user.profile.sub
        }
        this.storage.set('userinfo', dataAdapter);
        this.storage.set('token_expires_aquasim', user.expires_at),
          this.SetUserLogged(data);
        //this.getEntitiesData();
        //this.getCompaniesData(user.profile.sub);
      }
    });
  }
  // Función para obtener las compañías
  getCompanies(): Promise<any[]> {
    const url = `${this.apiUrl}/v3.1/companies/application/43/access`;
    const headers = this.createHeaders();
    return this.http.get<any[]>(url, { headers }).toPromise();
  }
  // Función para obtener los permisos de usuario para una compañía específica
  getUserPermissions(companyId: number): Promise<any> {
    const url = `${this.apiUrl}/v3/applications/43/companies/${companyId}/user-permissions`;
    const headers = this.createHeaders();
    return this.http.get<any>(url, { headers }).toPromise()
      .then((permissions) => {
        let user = JSON.parse(localStorage.getItem(environment.Global.key_user));
        // Almacena el resultado en localStorage
        //localStorage.setItem('userPermissions', JSON.stringify(permissions));
        if (permissions != null && permissions.length > 0) {
          let p : any[] = [];
          var lstPermissions = [];
          var first=false;
          permissions.forEach(y => {
            if( lstPermissions.indexOf(y.name) < 0 ){
              const actions = y.permissionSets[0].permissions.map(permission => {
                var action = permission.name.split('.')[1]=='Add'? 'Create':permission.name.split('.')[1];
                return action;  // Extrae la parte de la acción
              });////tmp.details.push({id:y.slinId, name:y.slinName, sitid:y.slinSiteId});
              lstPermissions.push({id:y.id, name:y.name, actions: actions});
            }
          });
          p = lstPermissions;
          localStorage.setItem(environment.Global.permissions_user, JSON.stringify(lstPermissions));
          //var aux = CryptoJS.AES.encrypt(lstPermissions.toString(), Global.WHAT.trim()).toString()
          this.storage.set(environment.Global.permissions_user, lstPermissions);
          this.storage.set('token', user.id_token);
          this._permissionObservable.next(p);
          let data: UserCx = {
            name: user.profile.name,
            company: user.profile.CompanyName,
            isLogged: true,
            sub: user.profile.sub,
            //mail: user.profile.Email
          }
          let dataAdapter: UserAdapter = {
            emailAddress:'',
            logon:'',
            name: user.profile.name,
            selectedCompanyId: user.profile.CompanyName,
            selectedCompanyName:user.profile.CompanyId,
            selectedLanguageCode:user.profile.xmsDefaultLanguageCode,
            userId: user.profile.sub
          }
          this.storage.set('userinfo', dataAdapter);
          this.storage.set('token_expires_aquasim', user.expires_at),
            this.SetUserLogged(data);
          //this.getEntitiesData();
          //this.getCompaniesData(user.profile.sub);
        }
        return permissions;
      });
  }
  // Función para crear las cabeceras comunes
  private createHeaders(): HttpHeaders {
    let user = JSON.parse(localStorage.getItem(environment.Global.key_user));
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.access_token}`, // Reemplaza con tu token de autorización
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
    });
  }

  public savePermissionsPro(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.savePermissions();
    });
  }
  public SetUserData() {

    if (localStorage.getItem(environment.Global.key_user) != null) {
      let dataLS = JSON.parse(localStorage.getItem(environment.Global.key_user));
      let data: UserCx = {
        name: dataLS.profile.name,
        company: dataLS.profile.CompanyName,
        isLogged: true,
        sub: dataLS.profile.sub,
        //mail: dataLS.profile.Email
      }
      this._userObservable.next(data);
    }
  }

  public getUserLogged(): UserCx {
    let user: UserCx = null;
    let dato = JSON.parse(localStorage.getItem(environment.Global.key_user));
    user = {
      name: dato.profile.name,
      company: dato.profile.CompanyName,
      isLogged: true,
      sub: dato.profile.sub,
      //mail: dato.profile.Email

      //UserCode: dato.profile.UserCode,
    }
    return user;
  }

  public SetPermissionData() {
    if (localStorage.getItem(environment.Global.permissions_user) != null) {
      this._permissionObservable.next(JSON.parse(localStorage.getItem(environment.Global.permissions_user)));
    }
  }

  get PermissionObservable() {
    return this._permissionObservable.asObservable();
  }

  get UserObservable() {
    return this._userObservable.asObservable();
  }

  public GetPermissionsByModule(Permission: string) {
    if (localStorage.getItem(environment.Global.permissions_user) != null) {
      let permission = JSON.parse(localStorage.getItem(environment.Global.permissions_user));
      let crud = [];
      permission.forEach(element => {
        if (element.name == Permission) {
          crud = element.actions;
        }
      });
      return crud;
    } else {
      return [];
    }
  }

  public ExistPermission(Permission: string) {
    if (localStorage.getItem(environment.Global.permissions_user) != null) {
      let permission = JSON.parse(localStorage.getItem(environment.Global.permissions_user));
      let crud = false;
      permission.forEach(element => {
        if (element.name == Permission) {
          crud = true;
        }
      });
      return crud;
    } else {
      return false;
    }
  }

  public SetUserLogged(user: UserCx) {
    let url = environment.Global.API_SET_USER_LOG;
    let userLogged: UserLogged = {
      UserName: user.name,
      Sub: user.sub,
      LoggedTime: ''
    }
    this.apiService.post(url, userLogged).toPromise().then(result => {
      if (result.Status) {
        // localStorage.setItem('currentCountry', result.Object.Country);
        localStorage.setItem('currentUser', result.Object.UserCode);
        // localStorage.setItem('currentPosition', result.Object.Position);
        // localStorage.setItem('currentDepartment', result.Object.Department);
      }
    })
  }
  public getUserName() {
    return localStorage.getItem('currentUser');
  }
  // public getCountry() {
  //   return localStorage.getItem('currentCountry');
  // }
  // public getPosition() {
  //   return localStorage.getItem('currentPosition');
  // }
  // public getDepartment() {
  //   return localStorage.getItem('currentDepartment');
  // }

  //aqui revisar los permisos.
  public async UpdateUserSystemList() {
    let url = environment.Global.API_UPDATE_USER_LIST;
    let User = this.getUserLocalStorage().profile;
    let ExpiresAt = this.getUserLocalStorage().expires_at;
    let token = this.getUserLocalStorage().access_token;
    let listUser = environment.Global.USER_LIST_SUB;

    let exist = listUser.indexOf(User.sub);
    let userLogged = await this.UserLoggedInDB();
    let userTime = userLogged.LoggedTime.replace('T', ' ');
    let timeEndTokenValidate = new Date(userTime);
    let timeNow = new Date();
    if (exist != -1) {
      const minuteToken = ExpiresAt / environment.Global.MicrosecondstoMinute;
      timeEndTokenValidate.setMinutes(timeEndTokenValidate.getMinutes() + minuteToken);
      if (Number(timeNow) < Number(timeEndTokenValidate)) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,'Ocp-Apim-Subscription-Key': '20a0838ab26a4f1b8b4cdcd64220a7a0'});
        this.http.get(environment.Global.XMS_USERS,{headers}).toPromise().then((result: any) => {
          if (result != null && result.length > 0) {
            let userList = [];
            result.forEach(user => {
              let obj = {
                UserCode: user.id,
                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,
                IsExternal: user.userType.id > 0 ? 1: 0
              }
              userList.push(obj)
            });
            this.apiService.post(url, userList ).subscribe(data => {
            })
          }
        }).catch((err) => {
        });
      }
    }
  }

  public async UserLoggedInDB() {
    let user = JSON.parse(localStorage.getItem(environment.Global.key_user));
    let urlLogged = environment.Global.API_GET_USER_LOG + '/' + user.profile.sub + '/' + user.profile.name;
    let data = null;
    await this.apiService.get(urlLogged).toPromise().then(result => {
      data = result.Object;
    });
    return data;
  }

  public getUserLocalStorage() {
    let user = JSON.parse(localStorage.getItem(environment.Global.key_user));
    return user;
  }
}
