<div class="card-info">
    <div class="card-flex" *ngFor="let row of items">
      <div class="card-body">
        <div class="container-values">
          <div class="icon"><i class="{{row.icon}}"></i></div>
            <div class="title">
                <a [href]="row.url" class="second-color-text" target="_blank">
                    {{ row.title | translate }}
                </a>
            </div>
        </div>
      </div>
    </div>
</div>
  