import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { saveAs as importedSaveAs } from "file-saver";


@Component({
  selector: 'app-add-documentation',
  templateUrl: './add-documentation.component.html',
  styleUrls: ['./add-documentation.component.css']
})
export class AddDocumentationComponent extends BaseCrudComponent implements OnInit {
  id: any;
  file = null;
  ParameterDetailList = [];
  date_init = null;
  date_end = null;

  constructor(public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Name', 'Type'];
    this.ParameterDetailList = this.TypeDocumentAdditional;

  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.getInitialData();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_ADOC");
    //this.permissionList = this._authService.GetPermissionsByModule("SQ_PARAMETERS");

  }

  public async getInitialData() {
    this.messageShowLoading();
    this.url = environment.Global.API_GET_ADDITIONAL_DOCUMENT;//API_GET_ALL_ASC_DOCS
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if (Array.isArray(this.rows)) {
          this.pageTotal = this.rows.length;
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
  }


  public createForm(object: any): boolean {
    let myForm: any = {};
    myForm = new FormGroup({
      'Name': new FormControl(object.Name, [Validators.required]),
      'Type': new FormControl(object.Type, [Validators.required]),
      // 'DateFrom': new FormControl(object.DateFrom, [Validators.required]),
      // 'DateTo': new FormControl(object.DateTo, [Validators.required]),
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    this.GetParameter();
    //console.log(this.c_obj);
  }

  public createItem(modal, object) {
    console.log(object);
    this.isCreating = true;
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    if (this.file == null) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    this.obj = {
      Name: object.Name,
      Type: Number(object.Type),
      DateFrom: object.DateFrom,
      DateTo: object.DateTo,
      Status: "A",
      // PathFile: this.file.Headers.ContentDisposition.FileName,
      //CreatorUser: "Squinsy",
      CreatorUser: this.user.name
    }
    console.log(this.obj)
    this.url = environment.Global.API_CREATE_ADDITIONAL_DOCUMENT;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        console.log("Objeto del post:", result);
        this.SaveFile(result.Object);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isCreating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isCreating = false;
    })


  }

  public SaveFile(object) {
    let form = new FormData();
    form.append('DocumentId', object.DocumentId);
    form.append('CreatorUser', object.CreatorUser);
    form.append('File', this.file);
    // this.srv.AddFiles(environment.Global.API_UPLOAD_ADDITIONAL_DOCUMENT, form).subscribe(data => {//API_UPLOAD_DOCS
    this.srv.AddFiles(environment.Global.API_READ_FILE_DATA_ADDITIONAL_DOCUMENT, form).subscribe(data => {//API_UPLOAD_DOCS
      if (data) {
        //API_UPLOAD_DOCS
        this.srv.AddFiles(environment.Global.API_UPLOAD_ADDITIONAL_DOCUMENT, form).subscribe(data => {
          this.messageDialog("Successful creation", '', 'success');
          this.clearData();
        });

      }
    });
  }

  public clickOnCheck(event, id) {
    if (event.target.checked) {
      this.changeStatus('1', id);
    } else {
      this.changeStatus('2', id);
    }
  }

  public changeStatus(value, id) {
    this.url = environment.Global.API_UPDATE_STATUS_ADITIONAL_DOCUMENT;
    this.obj = {
      DocumentId: id,
      Status: value,
      // ModifierUser: "Squinsy",
      // DeleteUser: "Squinsy"
      ModifierUser: this.user.name,
      DeleteUser: this.user.name
    }
    console.log(this.obj);
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    }, err => {
      this.messageDialog(err, '', 'error');
    })
  }

  public openUpdateModal(modal, object) {
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    console.log(this.u_obj);
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    this.GetParameter();
    // console.log(this.u_obj);
  }

  public updateItem(modal, object) {
    console.log(object)
    // debugger;
    this.isUpdating = true;

    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    }
    if (this.file == null) {
      this.isUpdating = false;
      return this.errorFormMessage();
    }
    this.obj = {
      DocumentId: object.DocumentId,
      Name: object.Name,
      Type: object.Type,
      DateFrom: object.DateFrom,
      DateTo: object.DateTo,
      ModifierUser: this.user.name
    }
    //console.log(this.obj)
    this.url = environment.Global.API_UPDATE_ADDITIONAL_DOCUMENT;//API_UPDATE_DOCS
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.SaveFile(result.Object);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }

  public UploadFile(event) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null;
    console.log(event.target.files);

  }

  public async GetParameter() {
    this.url = environment.Global.API_GET_ALL_LC;//API_GET_ALL_CLIENT
    // await this.srv.get(this.url).toPromise().then(result => {
    //   if (result.Status) {
    //     if (result.Object != null) {
    //       this.ParameterDetailList = result.Object;
    //       // console.log(this.ParameterDetailList);
    //     }
    //   }
    // })
  }

  public DownloadFile(row) {
    console.log("entramos a descargar, objeto:", row)
    let FileName = row.NameFile;

    this.url = environment.Global.API_DOWNLOAD_FILE_AD + row.DocumentId;
    console.log("url:", this.url, "filename:", FileName)
    this.messageShowLoading();
    this.srv.downloadFiles(this.url).subscribe(result => {
      importedSaveAs(result, FileName)
      this.closeMessageDialog();
    })
  }


}
