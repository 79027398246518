<app-header-options
(updateInfo)="getInitialData()" (search)="searchData($event)"
[isLoading]="isLoading"
[buttonCreateVisible]="buttonCreateVisible"
[PermissionList]="permissionList"
>
</app-header-options>


<div class="row">

  </div>


<div class="row">
    <!--Boton Aprobar-->
    <div class="col-md-1 offset-md-11 ha-ibox">
        <button type="button" class="btn btn-custom btn-success" (click)="validateAllDocuments(this.listDocumentAprovved)"> {{'Approve' | translate}} <i class="fa fa-check" aria-hidden="true"></i>
        </button>
      </div>

    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr >
                        <th class="sh">
                            ID
                        </th>
                        <!--<th class="sh">
                            {{'purchase_order' | translate}}
                        </th>-->
                        <!-- <th (click)="setOrder('OrderNumber')" class="sh">
                          {{'purchase_order' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='OrderNumber')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th> -->
                        <th class="sh">
                            {{'ItemName' | translate}}
                        </th>
                        <th class="sh">
                            {{'ShippingDate' | translate}}
                        </th>
                        <th class="sh">
                            {{'OrderDisplay' | translate}}
                        </th>
                        <th class="sh">
                            {{'Responsable' | translate}} {{'PO' | translate}}
                        </th>
                        <th class="sh">
                            {{'Agent' | translate}}
                        </th>
                        <th >
                            {{'ResponsableAgent' | translate}}
                        </th>
                       <th>
                         {{'Document'| translate}}
                       </th>
                        <th >
                            {{'File' | translate}}
                        </th>
                        <th >
                            {{'Status' | translate}}
                        </th>
                        <th class="text-center">
                            {{'Selection' | translate}}
                        </th>
                        <th >
                            {{'actions' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>
                        <!-- <td>
                            <label for="OrderNumber">{{row.OrderNumber}}</label>
                        </td> -->
                        <td>
                            <label for="ItemName">{{row.Document.ItemName }}</label>
                        </td>
                        <td>
                            <label for="ShippingDate">{{row.Document.CreationTime | date: 'd/M/yy' }}</label>
                        </td>
                        <td>
                            <label for="OrderDisplay">{{row.OrderDisplay}}</label>
                        </td>
                        <td>
                            <label for="UserResponsable">{{row.UserResponsable}}</label>
                        </td>
                        <td>
                            <label for="Agent">{{row.Agent}}</label>
                        </td>
                        <td>
                            <label for="UserPurchaseLine">{{row.UserPurchaseLine}}</label>
                        </td>
                      <td>
                        <label for="DocumentName">{{row.DocumentName}}</label>
                      </td>
                        <td>
                            <label for="FileName">{{row.FileName}}</label>
                        </td>
                        <td>
                            <label for="StatusName">{{row.StatusName}}</label>
                        </td>
                        <td class="text-center">
                            <!-- <input class="form-check-input" [attr.checked]="(row.StatusDocument == 'A') ? 'checked' : null" name="{{row.Name}}" (change)="clickOnCheck($event,row.DocumentId)" type="checkbox"> -->
                            <input class="form-check-input" (change)="clickOnCheck($event,row.DocumentApproveId)" [checked]="isRowSelected(row.DocumentApproveId)" type="checkbox">

                        </td>
                        <td class="action-buttons">
                            <i (click)="DownloadFile(row)" style="cursor:pointer;" class="mdi mdi-cloud-download text-info m-r-10" title="{{'DownloadFile' | translate}}"></i>
                            <!-- <i (click)="ValidateDocument(row)" style="cursor:pointer;" class="far fa-file-alt text-dark m-r-10" title="{{'ValidateDocument' | translate}}"></i> -->
                            &nbsp;
                            <i *ngIf="(row.PathFile != NULL)" (click)="openUpdateModal(view, row)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="12">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<ng-template #view let-c="close" let-d="dismiss" let-modal>
  <!--  <ng-template #view let-modal>-->
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'preview' | translate}}</h4>
    <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
      <a [href]="pdfSrc" target="_blank"><i class="mdi mdi-arrow-right text-info m-r-10"></i></a>
      <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                    style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
      </ng-container>
      <ng-template #elseTemplate>
        <div style="display: flex;">
          <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
               alt="">
        </div>
      </ng-template>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
  </div>
</ng-template>
