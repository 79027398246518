<!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->

<div class="card-info">
  <div class="card-flex" *ngFor="let row of items" [routerLink]="row.url" [hidden]="!hasPermission(row.perm)">
    <div class="card-body">
      <div class="container-values second-color-text">
        <div class="icon"><i class="{{row.icon}}"></i></div>
        <div class="title">{{ row.title | translate }}</div>
      </div>
    </div>
  </div>
</div>

<!-- [hidden]="hasPermission(row.perm)" -->