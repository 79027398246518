import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';;
import { ApiService } from 'src/app/services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";

import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { GlobalService } from '../../../../services/main/global.service';
import { AuthService } from '../../../../services/authentication/auth.service';
PdfMakeWrapper.setFonts(pdfFonts); // fonts provided for pdfmake

@Component({
  selector: 'app-report-payments',
  templateUrl: './report-payments.component.html',
  styleUrls: ['./report-payments.component.css']
})
export class ReportPaymentsComponent extends BaseCrudComponent implements OnInit {

  //Filter
  showIconF = false;
  dataSearchList = [];
  private workbook: Workbook;
  private lista_titulos;
  wareHouses = [];
  CompanyTrans = [];
  TypeProduct = [];

  @ViewChild('thead', { static: true }) thead: ElementRef;
  userInfo;
  option_processes = null;
  origenList = environment.Global.COUNTRY;
  date_init = null;
  date_end = null;
  type_product = "";
  name = 'Imports';
  list_processes = [{ title: 'purchase_orders', id: 1 }, { title: 'Dispatchs', id: 2 }, { title: 'submenu_skretting_payments', id: 3 },
  { title: 'submenu_skretting_purchase_order_detail_line', id: 4 }, { title: 'ImportCustomAgent', id: 5 },
  // { title: 'TributaryCredit', id: 6 }, { title: 'CustomsAclarance', id: 7 },
  { title: 'ImportCosts', id: 8 }, { title: 'ProvisionVSReal', id: 9 }, { title: 'ArrivalByTypeProduct', id: 10 },
  { title: 'AssignedContainers', id: 11 }
  ];
  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = false;
  isSkretting = false;
  isClean = false;
  pageSize = 10;
  flag = false;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  col_value = 0;
  url_report_ = '';
  fontSize: number = 0;
  marginLeft: number = 30;
  ConceptList = [];
  l = [];
  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService,

  ) {
    super(srv, modalService, storage, router);
    this.option_processes = 3;
    this.ConceptList = []
    // this.search_fields = ['TypeProduct', 'AgentName', 'UserAgentName', 'OrderNumber', 'ItemNumber', 'Origen', 'PackagingName'];

  }


  async ngOnInit() {
    await super.ngOnInit();
    this.user = this._authService.getUserLogged();
    await this.GetTypeProductActive();
    await this.GetConcepts();

    this.getNotificationAP(this.user.sub);
    this.getNotificationAC(this.user.sub);
    this.getNotificationCXP(this.user.sub);
    this.getNotificationCP(this.user.sub);
    this.getNotificationCA(this.user.sub);


    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
      });
    /*this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(this.userInfo)
      if (isNumeric(this.userInfo.logon)) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });*/
    //this.getCompanies();
    if (this.request_selected) {
      this.disabledCompany = false;
      this.disabledRuc = false;
    } else {
      this.disabledCompany = true;
      this.disabledRuc = true;
    }
  }

  public async GetConcepts() {
    this.url = environment.Global.API_GET_LIST_CONCEPT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object != null) {
          this.ConceptList = result.Object
        }
      }
    })
  }
  public async getCompanyTransport() {
    this.url = environment.Global.API_GET_ACTIVE_TRS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.CompanyTrans = result.Object
        //console.log("Bodegas:",this.CompanyTrans)
      }
    })
  }
  clearFilters() {
    if (this.option_processes == null) {
      this.option_processes = null;
    }
    this.options_company = null;
    this.options_ruc = null;
    this.date_end = null;
    this.date_init = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.url_report_ = '';
    this.disabledCompany = true;
    this.disabledRuc = true;
    // console.log(this.options_company);
    // console.log(this.options_ruc);
  }
  dataToFind() {
    this.isFind = true;
    this.showIconF = false;

    if (this.option_processes === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }
    if (this.date_init == null || this.date_end == null) {
      this.isFind = false;
      return Swal.fire("Error", this.translations.msg_error_empty_date, 'error');
    }


    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    this.setUrlToFindData();
  }
  async setUrlToFindData() {
    let url = '';
    // console.log(this.option_processes)
    switch (Number(this.option_processes)) {
      case 1:
        //console.log('entro aqui 1', this.userInfo.logon)
        url = environment.Global.GET_ALL_PUR_ORD;
        this.search_fields = ['OrderNumber', 'Supplier', 'GrossWeightOrder', 'CreationTime', 'UserName', 'AgentName'];
        /*if (isNumeric(this.userInfo.logon)) {
          url = environment.Global.GET_ALL_REQUEST_INDUCTION_BY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          url = environment.Global.GET_ALL_REQUEST_INDUCTION;
          this.isSkretting = true;
        }*/
        break;
      case 2:
        // console.log('entro aqui 2')
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        //  console.log("URL:", url);
        this.search_fields = ['TypeProduct', 'Division', 'AgentName', 'UserAgentName', 'OrderNumber', 'DealNumber', 'ItemNumber', 'Origen', 'PackagingName'];
        break;
      case 3:
        // Pago a proveedores
        url = environment.Global.API_GET_REPORT_GET_PAYMENTS;
        this.search_fields = ['ItemName', 'OrderNumber', 'Div', 'IssueDate', 'UserAgentName', 'InvoiceNumber', 'Supplier'];
        // console.log("URL:", url);

        break;
      case 4:
        //Contenedores por despacho
        url = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
        this.search_fields = ['AgentName', 'Agent', 'EtaRequired', 'OrderNumber', 'CompanyName', 'PackagingName', 'ItemName'];
        break;

      case 5:
        // Importación de agente de Aduana
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['ProcedureImportline', 'Division', 'Invoice', 'InvoiceDate', 'ItemNumber', 'ItemName', 'TariffHeading', 'Status', 'CompanyName'];
        // console.log("URL:", url);
        break;

      case 6:
        // ISD Tributary Credit
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['OrderNumber', 'Invoice', 'CustomsAclarance', 'DAI', 'TariffHeading', 'QuantityLiquidation', 'CompanyName'];
        // console.log("URL:", url);
        break;
      // Liquidaciones Aduaneras del mes


      case 8:
        // Imports cost
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['OrderNumber', 'Division', 'OrderDisplay', 'Invoice', 'InvoiceDate', 'CompanyName', 'ItemName'];
        // console.log("URL:", url);
        break;
      case 9:
        // <!-- Provision vs Real  -->
        url = environment.Global.GET_ALL_PUR_ORD_ALL;
        this.search_fields = ['OrderNumber', 'Division', 'OrderDisplay', 'CompanyName', 'ItemName'];

        break;
      case 10:
        url = environment.Global.GET_ALL_SUM_PUR_ORDER_PRODUCT_TYPE;
        this.search_fields = ['TypeProduct', 'ItemNumber', 'ItemName', 'GrossWeightDispatched'];

        break;
      case 11:
        //Contenedores por despacho
        url = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
        this.search_fields = ['DateDepartureReception'];
        break;

    }
    // console.log(url);
    this.isFind = false;
    this.getListFromApi();
  }
  public async GetTypeProductActive() {
    this.url = environment.Global.API_GET_ACTIVE_TP;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.TypeProduct = result.Object;
      }
    })
  }
  async getListFromApi() {
    // console.log(this.option_processes);
    this.isFind = true;
    this.url = environment.Global.API_GET_REPORT_GET_PAYMENTS;
    console.log(this.url)
    let list_tmp = [];
    // console.log( this.date_init, this.date_end, this.option_processes,this.options_ruc, this.options_company);
    this.messageShowLoading();

    await this.srv.getCall(this.url).subscribe(x => {
      if (x.Status) {
        if (x.Object != null) {

          // console.log(x.Object);
          this.rawdata = x.Object;
          console.log(this.rawdata);
          let new_list = [];
          list_tmp = x.Object;

          list_tmp.forEach(element => {

            if (element.TypeProduct != null) {
              this.TypeProduct.forEach(product => {
                if (product.id == element.TypeProduct) {
                  element.TypeProductName = product.nombre;
                }
              });

            } else {
              element.TypeProductName = "";
            }

            // }

            if (element.IssueDate != null && element.AdmissionDate != null) {

              let IssueDate = this.formatOnlyDate(new Date(element.IssueDate));
              let AdmissionDate = this.formatOnlyDate(new Date(element.AdmissionDate));

              var F_EmisionFact_VS_F_Notificacion = new Date(IssueDate).getTime() - new Date(AdmissionDate).getTime();

              element.F_EmisionFact_VS_F_Notificacion = Math.round(F_EmisionFact_VS_F_Notificacion / (1000 * 60 * 60 * 24));

            }
            if (element.AdmissionDate != null && element.ReviewDate != null) {

              let AdmissionDate = this.formatOnlyDate(new Date(element.AdmissionDate));
              let ReviewDate = this.formatOnlyDate(new Date(element.ReviewDate));
              element.F_Notificacion_VS_F_Solicitud = Math.round((new Date(AdmissionDate).getTime() - new Date(ReviewDate).getTime()) / (1000 * 60 * 60 * 24))
            }
            if (element.ReviewDate != null && element.ApprovalDate != null) {

              let ReviewDate = this.formatOnlyDate(new Date(element.ReviewDate));
              let ApprovalDate = this.formatOnlyDate(new Date(element.ApprovalDate));
              element.F_Solicitud_VS_FAprobacion = Math.round((new Date(ReviewDate).getTime() - new Date(ApprovalDate).getTime()) / (1000 * 60 * 60 * 24))
            }
            if (element.ApprovalDate != null && element.DepartureDate != null) {

              let ApprovalDate = this.formatOnlyDate(new Date(element.ApprovalDate));
              let DepartureDate = this.formatOnlyDate(new Date(element.DepartureDate));
              element.F_Aprobacion_VS_FPago = Math.round((new Date(ApprovalDate).getTime() - new Date(DepartureDate).getTime()) / (1000 * 60 * 60 * 24))
            }

          });


          list_tmp.forEach(tmp => {

            if (tmp.UserSkrettingResponse == this.u_obj['UserId'] || tmp.ItemNumber == this.u_obj['ItemId'] || tmp.TypeProduct == this.u_obj['TypeProduct']) {
              new_list.push(tmp);

            }
          })
          this.rawdata = new_list;
          this.rows = new_list;

          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.closeMessageDialog();
          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      } else {
        this.isFind = false;
        Swal.fire(x.Message, '', 'error');
        return;
      }
    });

  }

  // Devuelve el nombre de un Concepto, segun su Subconcepto
  public getConceptName(NameSubconcept: any) {

    var text = ''
    var findOk = 0
    var findEr = 0

    // Convierte a Mayuscula, tildes, espacios al final del string, quita dos espacios entre palabra
    var string_all_caps = NameSubconcept.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().trim().replace("  ", " ");


    // Recorre los Concepts
    this.ConceptList.forEach((value1, index) => {
      // Recorre los Subconceptos
      value1.SubconceptList.forEach((value2, index) => {

        var string_all_caps_bd = value2.SubconceptName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().trim().replace("  ", " ");

        if (string_all_caps == string_all_caps_bd) {

          findOk++

          if (findOk == 1) {
            text = value1.ConceptName
          } else {
            text = 'NO APLICA'
          }

        } else {
          findEr++
        }

      });

    });

    if (findEr > 0 && findOk == 0) {
      text = 'NO APLICA'
    }

    return text
  }


  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }
  selectRequest() {
    this.request_selected = true;
    this.rows = [];
    this.page = 0;
    this.pageTotal = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
    this.url_report_ = '';
    if (this.isSkretting) {
      if (this.option_processes == 1) {
        this.col_value = 7
      } /*else if (this.option_processes == 2) {
       this.col_value = 8
     } else if (this.option_processes == 3) {
       this.col_value = 8
     }*/
    } else {
      if (this.option_processes == 1) {
        this.col_value = 6
      } /*else if (this.option_processes == 2) {
       this.col_value = 7
     } else if (this.option_processes == 3) {
       this.col_value = 8
     }*/
    }

    if (this.option_processes == 1) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD;//Global.GET_REPORT_REQUEST;
    } else if (this.option_processes == 2) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 3) {
      this.url_report_ = environment.Global.API_GET_PAYMENTS;
    } else if (this.option_processes == 4) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
    } else if (this.option_processes == 5) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 6) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 8) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 9) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_ALL;
    } else if (this.option_processes == 10) {
      this.url_report_ = environment.Global.GET_ALL_SUM_PUR_ORDER_PRODUCT_TYPE;
    } else if (this.option_processes == 11) {
      this.url_report_ = environment.Global.GET_ALL_PUR_ORD_DET_ALL;
    }

    this.options_ruc = null;
    this.options_company = null;
    this.date_end = null;
    this.date_init = null;
  }



  async reports() {
    this.l = [];
    this.rows.forEach(element => {
      if (this.rows !== null && this.rows !== ' ') {
        if (this.option_processes == 3) {
          let obj = [
            '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', ''

          ]//,'',''

          obj[0] = element.AgentName;
          obj[1] = element.AgentUsers;
          obj[2] = element.UserResponsableSk;
          obj[3] = element.TypeProductName;
          obj[4] = element.OrderNumber;
          obj[5] = element.Div;
          obj[6] = element.Dispatch;
          obj[7] = element.ItemNumber;
          obj[8] = element.ItemName;
          obj[9] = element.InvoiceNumber;
          obj[10] = element.IssueDate == null ? "" : element.IssueDate.split('T')[0];
          obj[11] = element.Amount;
          obj[12] = element.AmountLine;
          obj[13] = element.PaymentTerm;
          obj[14] = element.Supplier;
          obj[15] = this.getConceptName(element.SubConcept);
          obj[16] = element.SubConcept;
          obj[17] = "-";
          obj[18] = element.AdmissionDate == null ? "" : element.AdmissionDate.split('T')[0];
          obj[19] = element.ReviewDate == null ? "" : element.ReviewDate.split('T')[0]
          obj[20] = element.ApprovalDate == null ? "" : element.ApprovalDate.split('T')[0]
          obj[21] = element.DepartureDate == null ? "" : element.DepartureDate.split('T')[0]
          obj[22] = element.F_EmisionFact_VS_F_Notificacion;
          obj[23] = element.F_Notificacion_VS_F_Solicitud;
          obj[24] = element.F_Solicitud_VS_FAprobacion;
          obj[25] = element.F_Aprobacion_VS_FPago;
          obj[26] = "-";
          obj[27] = "-";
          obj[28] = "-";

          this.l.push(obj);

        }
      }

    });
  }


  async converDataToRow(list) {
    let nueva_lista = [];
    this.reports();
    if (this.rows !== null && this.rows !== ' ') {
      nueva_lista = this.l;
      return nueva_lista;
    }
  }

  async downloadReport() {
    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    let listInReport = this.rows;
    const pdf = new PdfMakeWrapper();
    let nameDoc = '';
    let title = '';
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];


    if (this.option_processes == 3) {
      nameDoc = 'Pagos a proveedores';
      title = 'Reporte de pagos a proveedores';
    }

    if (this.isSkretting) {
      if (this.option_processes == 3) {
        lista_titulos = [
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableAgent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.UserResponsableSk).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Category).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.dispatch).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EmissionDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Amount).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.AmountLine).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PaymentTerm).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Concept).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DetailSubconcept).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.FoundMacobsa).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.NotificationDate2).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApplicationDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApprovalDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PaymentDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DateIssueInvoiceVSDateNotification).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.NotificationDateVSApplicationDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApplicationDateVSApprovalDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApprovalDateVSPaymentDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.submenu_skretting_observations).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.IVA).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.SUBTOTAL).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',
          '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',
          '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      }
    } else {
      if (this.option_processes == 3) {
        lista_titulos = [
          new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ResponsableAgent).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.UserResponsableSk).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Category).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.OrderNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Division).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.dispatch).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemNumber).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Invoice).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.EmissionDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Amount).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.AmountLine).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PaymentTerm).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.Concept).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DetailSubconcept).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.FoundMacobsa).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.NotificationDate2).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApplicationDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApprovalDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.PaymentDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.DateIssueInvoiceVSDateNotification).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.NotificationDateVSApplicationDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApplicationDateVSApprovalDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ApprovalDateVSPaymentDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.submenu_skretting_observations).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.IVA).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.SUBTOTAL).margin([0, 5, 0, 5]).color('white').bold().end).end,
        ];
        lista_widths = [
          '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',
          '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',
          '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
        ]
      }
    }
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${nameDoc}`,
      author: `${"Skretting"}`,//this.userInfo.name
      subject: 'Listado',
    });

    if (this.option_processes == "3" || this.option_processes == "4" || this.option_processes == "11") {
      this.fontSize = 6;
      this.marginLeft = 10;
    } else {
      this.fontSize = 10;
      this.marginLeft = 30;
    }
    //OPTIONS

    pdf.defaultStyle({
      bold: false,
      fontSize: this.fontSize,
      alignment: 'justify'
    });

    pdf.pageMargins([10, 110, 10, 110]);
    // if (this.option_processes == "11") {
    //   pdf.pageOrientation("portrait");
    //   pdf.background(
    //     await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 765).width(900).build() //505
    //   )
    // } else {
    pdf.pageOrientation("landscape");
    pdf.background(
      await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 515).width(900).build() //505
    )
    // }


    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/Skretting-logo.png').width('160').build()).end,
          ]
        ]
      )
        .margin([this.marginLeft, 30, 30, 0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0, 0, 0, 20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        //new Txt([new Txt(this.translations.requested_by + ': ').bold().end,"_"]).fontSize(10).alignment('left').end,//this.userInfo.name
        //new Txt([new Txt(this.translations.user + ': ').bold().end, "_"]).fontSize(10).alignment('left').margin([0,0,0,20]).end,//this.userInfo.logon
        new Table(
          [
            lista_titulos,
            ...await this.converDataToRow(listInReport)
          ]
        ).layout({
          fillColor: (rowIndex: number, node: any, columnIndex: number) => {
            return rowIndex === 0 ? '#f62d51' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(this.fontSize)
          .widths(lista_widths)
          // .widths('*')
          .alignment('center')
          .end,
      ]
    )

    pdf.footer(function (currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0, 10, 0, 0]).end).end,
          new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40, 20, 0]).end).end
        ]
      ).end
    });

    let nombre = 'Reporte';


    if (this.option_processes == 1) {
      nombre = 'Reporte de Ordenes de compra';

    } else if (this.option_processes == 2) {
      nombre = 'Reporte de despachos';
    } else if (this.option_processes == 3) {
      nombre = 'Reporte de pagos a proveedores';
    } else if (this.option_processes == 4) {
      nombre = 'Reporte de contenedores de despacho';
    }
    else if (this.option_processes == 5) {
      nombre = 'Reporte de importacion de agente';
    }
    else if (this.option_processes == 6) {
      nombre = 'Reporte de ISD crédito tributario';
    } else if (this.option_processes == 8) {
      nombre = 'Reporte de Gastos de importación';
    } else if (this.option_processes == 9) {
      nombre = 'Reporte de Provisión vs Real';
    } else if (this.option_processes == 10) {
      nombre = 'Reporte de Llegada por tipo de producto';
    } else if (this.option_processes == 11) {
      nombre = 'Reporte de Contenedores Asignados';
    }
    pdf.create().download(nombre);
    Swal.close();
  }

  public showIconFilter(flag: boolean) {
    this.showIconF = flag;
    if (!flag) {
      this.searchData('');
      // this.arrayList = [];
    }

  }
  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public resetData(index, columnName) {
    let array = [];
    this.l[index] = [];
    this.rows.forEach(element => {
      if (element[columnName] != undefined) {
        let flag = array.indexOf(element[columnName]);
        if (flag == -1) {
          array.push(element[columnName]);
        }
      }
    });

    array.push('(Vacíos)');
    this.l[index] = array;
  }



  public searchDataSelect(event) {
    let data = event.split('|'); debugger;
    if (data[2] == '(Vacíos)') {
      data[2] = null;
    }
    this.dataSearchList[data[0]] = data[2] != '' ? (data[1] + '|' + data[2]) : null;//''
    this.search2 = this.dataSearchList;
    this.filterAllSelect();
  }

  downloadExcel() {

    this.workbook = new Workbook();
    let nombreXLSX = "Reporte de Ordenes de compra.xlsx";
    this.lista_titulos = [


      this.translations.Agent,
      this.translations.ResponsableAgent,
      this.translations.UserResponsableSk,
      this.translations.Category,
      this.translations.OrderNumber,
      this.translations.Division,
      this.translations.dispatch,
      this.translations.ItemNumber,
      this.translations.ItemName,
      this.translations.Invoice,
      this.translations.EmissionDate,
      this.translations.Amount,
      this.translations.AmountLine,
      this.translations.PaymentTerm,
      this.translations.Supplier,
      this.translations.Concept,
      this.translations.DetailSubconcept,
      this.translations.FoundMacobsa,
      "NotificationDate2",
      this.translations.ApplicationDate,
      this.translations.ApprovalDate,
      this.translations.PaymentDate,
      this.translations.DateIssueInvoiceVSDateNotification,
      this.translations.NotificationDateVSApplicationDate,
      this.translations.ApplicationDateVSApprovalDate,
      this.translations.ApprovalDateVSPaymentDate,
      this.translations.submenu_skretting_observations,
      "IVA",
      "SUBTOTAL"
    ];

    this.ContainsExcel();
    this.workbook.creator = "Comex";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      fs.saveAs(blob, nombreXLSX);
    })
  }
  private ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 20;
    sheet.getColumn("B").width = 20;
    sheet.getColumn("C").width = 20;
    sheet.getColumn("D").width = 20;
    sheet.getColumn("E").width = 20;
    sheet.getColumn("F").width = 20;
    sheet.getColumn("G").width = 20;
    sheet.getColumn("H").width = 20;
    sheet.getColumn("I").width = 20;
    sheet.getColumn("J").width = 20;
    sheet.getColumn("K").width = 20;
    sheet.getColumn("L").width = 20;
    sheet.getColumn("M").width = 20;
    sheet.getColumn("N").width = 20;
    sheet.getColumn("O").width = 20;
    sheet.getColumn("P").width = 20;
    sheet.getColumn("Q").width = 20;
    sheet.getColumn("R").width = 20;
    sheet.getColumn("S").width = 20;
    sheet.getColumn("T").width = 20;
    sheet.getColumn("U").width = 20;
    sheet.getColumn("V").width = 20;
    sheet.getColumn("W").width = 20;
    sheet.getColumn("X").width = 20;
    sheet.getColumn("Y").width = 20;
    sheet.getColumn("Z").width = 20;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });



    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    // console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12 };

    // agregar los datos a las filas
    const rowsToInsert = sheet.getRows(2, this.rows.length);

    for (let index = 0; index < rowsToInsert.length; index++) {
      const itemData = this.rows[index]; //obtenemos el item segun el index de la iteraciòn(recorrido)
      const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
      // los valores de itemData seran asignados a row (fila actual en la iteraciòn)


      row.values = [

        itemData.AgentName,
        itemData.AgentUsers,
        itemData.UserResponsableSk,
        itemData.TypeProductName,
        itemData.OrderNumber,
        itemData.Div,
        itemData.Dispatch,
        itemData.ItemNumber,
        itemData.ItemName,
        itemData.InvoiceNumber,
        itemData.IssueDate == null ? "" : itemData.IssueDate.split('T')[0],
        itemData.Amount,
        itemData.AmountLine,
        itemData.PaymentTerm,
        itemData.Supplier,
        this.getConceptName(itemData.SubConcept),
        itemData.SubConcept,
        "-",
        itemData.AdmissionDate == null ? "" : itemData.AdmissionDate.split('T')[0],
        itemData.ReviewDate == null ? "" : itemData.ReviewDate.split('T')[0],
        itemData.ApprovalDate == null ? "" : itemData.ApprovalDate.split('T')[0],
        itemData.DepartureDate == null ? "" : itemData.DepartureDate.split('T')[0],
        itemData.F_EmisionFact_VS_F_Notificacion,
        itemData.F_Notificacion_VS_F_Solicitud,
        itemData.F_Solicitud_VS_FAprobacion,
        itemData.F_Aprobacion_VS_FPago,
        "-",
        "-",
        "-",

      ];
    }
  }

}
