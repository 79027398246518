import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent extends BaseCrudComponent implements OnInit {


  showErrorMessage = false;
  showAgent = false;
  showTransport = false;
  agentList = [];
  userList = [];
  columnsList = [];
  statusList = [];
  status: any = [];
  GroupUser = [];
  DepartmentList = [];
  ExternalUser = [{ IsExternal: "0", name: "NO" }, { IsExternal: "1", name: "SI" }, { IsExternal: "2", name: "Sin aginar" },];
  userType: boolean = false;

  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['FirstName', 'LastName', 'Email', 'IsExternalName', 'DepartmentName', 'GroupIdName'];
    this.GroupUser = this.Group;


  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.getInitialData();
    await this.getDepartment();
    this._authService.ExistPermission("CX_ADM") ? this.userType == false : true;
    // this.userType = true;
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_US");
    this.buttonCreateVisible = false;
  }

  public async getInitialData() {
    this.messageShowLoading();
    // this.url = environment.Global.API_GET_USER_INT;
    this.url = environment.Global.API_GET_USER_ALL;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if (Array.isArray(this.rows)) {
          this.pageTotal = this.rows.length;
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
  }

  public async getDepartment() {
    this.url = environment.Global.API_GET_DEPARTMENT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        result.Object.forEach(element => {
          this.DepartmentList.push(element)
        });
      }
    })
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openUpdateModal(modal, object) {
    console.log("Modal inicial:", object);
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    // console.log("Objeto:", this.u_obj);
    this.u_obj['UpdateAlertByUser'] = [];
    // console.log("Type:", this.u_obj);

    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });

  }

  public updateItem(modal, object) {
    console.log("Enviado a actualizar:", object);
    this.isUpdating = true;

    this.obj = {
      UserId: object.UserId,
      GroupId: object.GroupId,
      DepartmentId: object.DepartmentId,
      IsExternal: object.IsExternal,
      ModifierUser: this.user.name,
    }

    console.log("DATA:", this.obj);
    this.url = environment.Global.API_UPDATE_USER;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      console.log("Objeto enviado:", result);
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }


}
