import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import {saveAs as importedSaveAs  } from "file-saver";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-approve-field',
  templateUrl: './approve-field.component.html',
  styleUrls: ['./approve-field.component.css']
})
export class ApproveFieldComponent extends BaseCrudComponent implements OnInit {

  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['OrderDisplay', 'ItemName','ItemNumber', 'GrossWeight','Origen', 'Division'];
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.permissionList = this._authService.GetPermissionsByModule("CX_APF");
    this.user = this._authService.getUserLogged();
   
    await this.getInitialData();
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public async getInitialData() {
    
    this.url = environment.Global.API_GET_FIELDS_TO_APPROVE + this.user.sub;
    this.messageShowLoading();
   
    this.srv.get(this.url).subscribe(result => {
      if (result.Status) {
        if (result.Object != null) {
          this.rawdata = result.Object;
          this.rows = result.Object;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.closeMessageDialog();
        } else {
          this.messageDialog("Empty list", '', 'warning');
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    });
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
  }

  public ApproveField(row, type) {
    let object = JSON.parse(JSON.stringify(row));
    //console.log(object)
    this.message(type, object);
  }

  public message (type, object) {
    let data = this.translations[object.Field];
    if ( type == 1) {
      this.translations.BodyFieldValidation = this.translations.BodyFieldValidation.replace("{FIELD}", data)
      Swal.fire(
        {
          title: this.translations.FieldValidation,
          html: this.translations.BodyFieldValidation,
          icon: 'info',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: this.translations.Cancel,
          cancelButtonColor: '#84888b',
          confirmButtonText: this.translations.Approve,
          confirmButtonColor: '#C8102E',
          showCloseButton: true,
        }
      ).then((result) => {
        if (result.isConfirmed) {
          object.Approve = "A";
          object.UserApproval = this.user.sub;
          object.CurrentValue = !isNaN(Number(object.CurrentValue)) ? Number(object.CurrentValue) : object.CurrentValue;
          object.PreviousValue = !isNaN(Number(object.PreviousValue)) ? Number(object.PreviousValue) : object.PreviousValue;
          //console.log("objecto aprovado:",object)
          this.messageShowLoading();
          this.srv.post(environment.Global.API_POST_APPROVE_FIELD, object).subscribe(result => {
            this.closeMessageDialog();
            if(result.Status) {
              this.messageDialog(result.Message, '', 'success');
              this.clearData();
            } else {
              this.messageDialog(result.Message, '', 'error');
            }
          });
        }
      })
    } else if ( type == 2) {
      this.translations.BodyFieldValidationReject = this.translations.BodyFieldValidationReject.replace("{FIELD}", data)
      Swal.fire(
        {
          title: this.translations.FieldValidationReject,
          html: this.translations.BodyFieldValidationReject,
          icon: 'info',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: this.translations.Cancel,
          cancelButtonColor: '#84888b',
          confirmButtonText: this.translations.Reject,
          confirmButtonColor: '#C8102E',
          showCloseButton: true,
          input: 'text',
          preConfirm: (data) => {
            if (data == '') {
              Swal.showValidationMessage(
                this.translations.AddComment
              )
            }
          }
        }
      ).then((result) => {
        if (result.isConfirmed) {
          object.Approve = "R";
          object.UserApproval = this.user.sub;
          object.CommentaryApprover = result.value;
          this.messageShowLoading();
          this.srv.post(environment.Global.API_POST_APPROVE_FIELD, object).subscribe(result => {
            this.closeMessageDialog();
            if(result.Status) {
              this.messageDialog(result.Message, '', 'success');
              this.clearData();
            } else {
              this.messageDialog(result.Message, '', 'error');
            }
          });
        }
      })
    }

  }

}
