import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/services/main/global.service';
@Component({
  selector: 'app-header-options',
  templateUrl: './header-options.component.html',
  styleUrls: ['./header-options.component.css']
})
export class HeaderOptionsComponent implements OnInit {

  @Output() openModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() isLoading: boolean = false;
  @Input() buttonCreateVisible: boolean = false;
  @Input() PermissionList: Array<string>;

  word: string = '';

  constructor(
    public globalService: GlobalService
  ) {    
  }

  ngOnInit() {
  }
  

  openCreateModal() {
    this.openModal.emit();
  }

  getList() {
    this.updateInfo.emit();
  }

  findWord() {
    this.search.emit(this.word);
  }

}
