import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Component({
  selector: 'app-transportcompany',
  templateUrl: './transportcompany.component.html',
  styleUrls: ['./transportcompany.component.css']
})
export class TransportcompanyComponent extends BaseCrudComponent implements OnInit {
  statusList = [];
  userList = [];
  userListCombinated = [];
  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Name','Description','RUC'];
  }

  async ngOnInit() {
    await super.ngOnInit();
    await this.getInitialData();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_TC");
  }

  public async getInitialData() {
    this.messageShowLoading();
    this.url = environment.Global.API_GET_ALL_TRS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if( Array.isArray(this.rows) ){
          this.pageTotal = this.rows.length;
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
    await this.getUserExternalUnAssigned();
  }

  public createForm(object:any): boolean{
    let myForm:any = {};
    myForm = new FormGroup({
      'Name': new FormControl(object.Name, [Validators.required]),
      'Description': new FormControl(object.Description, [Validators.required]),
      'RUC': new FormControl(object.RUC, [Validators.required]),
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.c_obj['UserByTransportCompany'] = [];
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }

  public createItem(modal, object) {
    this.isCreating = true;
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    let users = [];
    if (object.UserByTransportCompany.length > 0) {
      object.UserByTransportCompany.forEach(element => {
        let data = {
          UserId: Number(element)
        }
        users.push(data)
      });
    }
    this.obj = {
      Name: object.Name,
      Description: object.Description,
      RUC: object.RUC,
      CreatorUser: this.user.name,
      UserByTransportCompany: users
    }
    this.url = environment.Global.API_CREATE_TRS;
    this.srv.post(this.url, this.obj).toPromise().then( result => {
      if(result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isCreating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err,'','error');
      this.isCreating = false;
    })
  }

  public clickOnCheck(event, id) {
    if ( event.target.checked ) {
      this.changeStatus('1', id);
    }else {
      this.changeStatus('2', id);
    }
  }

  public changeStatus(value, id) {
    this.url = environment.Global.API_UPDATE_STATUS_TRS;
    this.obj = {
      TransportId: id,
      Status: value,
      ModifierUser: this.user.name,
      DeleteUser: this.user.name
    }
    this.srv.post(this.url,this.obj).toPromise().then(result => {
      if(result.Status) {
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    }, err => {
      this.messageDialog(err,'','error');
    })
  }

  public openUpdateModal(modal, object) {
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    this.u_obj['UpdateUserByTransport'] = [];
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
    this.getUserCombinated(object.TransportId);
    if (this.u_obj['UserByTransportCompany'].length > 0) {
      this.u_obj['UserByTransportCompany'].forEach(element => {
        this.u_obj['UpdateUserByTransport'].push(element.UserId.toString());
      });
    }
  }

  public updateItem(modal, object) {
    //console.log("OBj 1:",object)
    this.isUpdating = true;
    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    }
    let users = [];
    if (object.UpdateUserByTransport.length > 0) {
      object.UpdateUserByTransport.forEach(element => {
        let data = {
          UserId: Number(element)
        }
        users.push(data)
      });
    }

    this.obj = {
      TransportId: object.TransportId,
      Name: object.Name,
      Description: object.Description,
      RUC: object.RUC,
      ModifierUser: this.user.name,
      UserByTransportCompany:users//object.UpdateUserByTransport
    }
    //console.log("OBj 2:",this.obj)
    this.url = environment.Global.API_UPDATE_TRS;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err,'','error');
      this.isUpdating = false;
    })
  }

  public async getUserExternalUnAssigned() {
    this.url = environment.Global.API_GET_USER_NASS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object.length > 0) {
          this.userList = result.Object;
        }
      }
    })
  }

  public async getUserCombinated(TransportId) {
    this.url = environment.Global.API_GET_USER_COMB + TransportId + '/2';
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object.length > 0) {
          this.userListCombinated = result.Object;
        }
      }
    })
  }
}
