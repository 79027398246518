<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-3 col-sm-12">
            <ng-select [(ngModel)]="u_obj['TypeProduct']" name="TypeProduct" [multiple]="false">
                <ng-option value="0">{{'Select'|translate}} {{'Category'|translate}}... </ng-option>
                <ng-option *ngFor="let option of TypeProduct; index as i" value="{{option.id}}">{{i + 1}} -
                    {{option.nombre}}</ng-option>
            </ng-select>
        </div>
        <div class="col-md-3 col-sm-12">
            <ng-select [(ngModel)]="u_obj['ItemId']" name="ItemId" [multiple]="false">
                <ng-option value="0">{{'Select'|translate}} {{'ItemName'|translate}}... </ng-option>
                <ng-option *ngFor="let st of itemList" value="{{st.ItemNumber}}">{{st.ItemNumber}} -
                    {{st.Name}}</ng-option>
            </ng-select>
        </div>
        <div class="col-md-3 col-sm-12">
            <ng-select [(ngModel)]="u_obj['UserId']" name="UserId" [multiple]="false">
                <ng-option value="0">{{'Select'|translate}} {{'UserIdSK'|translate}}... </ng-option>
                <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                    {{user.FirstName}} {{user.LastName}}</ng-option>
            </ng-select>
        </div>
       
        <div class="col-md-3 col-sm-12">
            <!-- *ngIf="this.u_obj['ItemId']!=0 && this.u_obj['UserId']!=0 ||this.u_obj['UserId']==null " -->
            <button [ladda]="isFind" type="button" (click)="dataToFind()"
                
                class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
            <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
        </div>


    </div>
</div>
<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>


        <!-- Contenedores Asignados (11)-->
        <div *ngIf="rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <!-- <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th> -->
                        <th>{{'OrderDisplay' | translate}}</th>
                        <th>{{'Category' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'Origen' | translate}}</th>
                        <th>{{'CountryShipment' | translate}}</th>
                        <th>{{'PortShipment' | translate}}</th>
                        <th>{{'Agent' | translate}}</th>
                        <th>{{'UserIdSK' | translate}}</th>
                        <th>{{'AssignmentDate'| translate}}</th>
                        <th>{{'ReAssignmentDate'| translate}}</th>

                        <th>{{'AssignmentTime'| translate}}</th>
                        <!-- (Desde que la OC llega a Comex hasta que fue asignada) -->
                        <th>{{'TimeInStatusN'| translate}}</th>
                        <th>{{'DatePassedToE'| translate}}</th>
                        <th>{{'TimeInStatusE'| translate}}</th>
                        <th>{{'DatePassedToT'| translate}}</th>
                        <th>{{'TimeInStatusT'| translate}}</th>
                        <th>{{'DatePassedToP'| translate}}</th>
                        <th>{{'TimeInStatusP'| translate}}</th>
                        <th>{{'DatePassedToF'| translate}}</th>
                        <th>{{'TimeInStatusF'| translate}}</th>
                        <th>{{'TotalTime'| translate}}</th> <!-- (Desde la asignación hasta que pasa a Status F) -->

                    </tr>
                </thead>
                <tbody>

                    <!-- <tr *ngIf="showIconF">
                        <td></td>
                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'WarehousesName')">
                                <app-select-filter [column]="'WarehousesName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(2,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ContainerNumber')">
                                <app-select-filter [column]="'ContainerNumber'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'Type')">
                                <app-select-filter [column]="'Type'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'BlSeal')">
                                <app-select-filter [column]="'BlSeal'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'ProductWeight')">
                                <app-select-filter [column]="'ProductWeight'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'DateDepartureReception')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[10]"
                                    [indexPosition]="10" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'HourDepartureReception')">
                                <app-select-filter [column]="'HourDepartureReception'" [arrayList]="l[11]"
                                    [indexPosition]="11" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'TransportCompanyName')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[12]"
                                    [indexPosition]="12" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'Date')">
                                <app-select-filter [column]="'Date'" [arrayList]="l[13]" [indexPosition]="13"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(14,'Hour')">
                                <app-select-filter [column]="'Hour'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr> -->
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <!-- <td>
                            </td> -->

                            <td><label for="OrderDisplay">{{row.OrderDisplay}} </label></td>
                            <td><label for="TypeProductName">{{row.TypeProductName}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="OrigenName">{{row.OrigenName}} </label></td>
                            <td><label for="CountryName">{{row.CountryName}} </label></td>
                            <td><label for="PortShipment">{{row.PortShipment}} </label></td>
                            <td><label for="AgentName">{{row.AgentName}} </label></td>
                            <td><label for="UserName">{{row.UserName}} </label></td>
                            <td><label for="AssignmentDate">{{row.AssignmentDate}} </label></td>
                            <td><label for="ReAssignmentDate">{{row.ReAssignmentDate}} </label></td>
                            <td><label for="TimeAssignment">{{row.TimeAssignment}}</label></td>
                            <td><label for="TimeInStatusN">{{row.TimeInStatusN}} </label></td>
                            <td><label for="FinishStatusDateN">{{row.FinishStatusDateN}} </label></td>
                            <!--FinishStatusDateN se llena cuando se cambia de estado, entonces esta misma fecha es la fecha de paso a estado E -->
                            <td><label for="TimeInStatusE">{{row.TimeInStatusE}} </label></td>
                            <td><label for="FinishStatusDateE">{{row.FinishStatusDateE}} </label></td>
                            <!--FinishStatusDateN se llena cuando se cambia de estado, entonces esta misma fecha es la fecha de paso a estado E -->
                            <td><label for="TimeInStatusT">{{row.TimeInStatusT}} </label></td>
                            <td><label for="FinishStatusDateT">{{row.FinishStatusDateT}} </label></td>
                            <!--FinishStatusDateN se llena cuando se cambia de estado, entonces esta misma fecha es la fecha de paso a estado E -->
                            <td><label for="TimeInStatusP">{{row.TimeInStatusP}} </label></td>
                            <td><label for="FinishStatusDateP">{{row.FinishStatusDateP}} </label></td>
                            <!--FinishStatusDateN se llena cuando se cambia de estado, entonces esta misma fecha es la fecha de paso a estado E -->
                            <td><label for="TimeInStatusF">{{row.TimeInStatusF}} </label></td>
                            <td><label for="TotalTime">{{row.TotalTime}} </label></td>

                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

    </div>
</div>