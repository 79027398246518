import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { computeStartOfLinePositions } from "@angular/compiler-cli/src/ngtsc/sourcemaps/src/source_file";
import Swal from 'sweetalert2';
import { conditionallyCreateMapObjectLiteral } from "@angular/compiler/src/render3/view/util";
import { filter } from 'rxjs/operators';
import { element } from 'protractor';
import { NonNullAssert } from '@angular/compiler';


@Component({
  selector: 'app-purchaseorderassign',
  templateUrl: './purchaseorderassign.component.html',
  styleUrls: ['./purchaseorderassign.component.css']
})
export class PurchaseorderassignComponent extends BaseCrudComponent implements OnInit {
  agentList = [];
  userList = [];
  userPerAgentList = [];
  transportList = [];
  existAgent = false;
  columnsList = [];
  optionSelected = 0;
  TransportIdSelected = 0;
  UserSKSelected = 0;


  //@Input() user: any = null;
  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['OrderNumber', 'AgentName', 'UserName', 'CompanyName', 'GrossWeightOrder', 'ItemName', 'StatusOrderName'];
  }

  async ngOnInit() {
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_POA");
    await super.ngOnInit();
    await this.getInitialData();
    await this.getAgents();


  }

  public async getInitialData() {
    this.messageShowLoading();
    this.url = environment.Global.API_GET_ALL_PO;
    // console.log(this.url);
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        // console.log(this.rows);
        this.rawdata = result.Object;
        if (Array.isArray(this.rows)) {
          this.pageTotal = this.rows.length;
        }
        this.rows.forEach(order => {
          if (order.PurchaseOrderLine.length > 0) {
            order.ItemName = order.PurchaseOrderLine[0].ItemName;
          }
        });
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    });
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);

    this.filterAll();
  }

  public createForm(object: any): boolean {
    let myForm: any = {};
    myForm = new FormGroup({
      'AgentId': new FormControl(object.AgentId, [Validators.required]),
      'UserId': new FormControl(object.UserId, [Validators.required]),
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public async openUpdateModal(modal, object) {
    // console.log(object)
    await this.getInternalUser();
    await this.getTransport();
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    console.log("openUpdate:", this.u_obj);
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });

    this.u_obj['AgentId'] = this.u_obj['AgentId'] != 0 ? this.u_obj['AgentId'].toString() : null;
    this.u_obj['UserId'] = this.u_obj['UserId'] != 0 ? this.u_obj['UserId'].toString() : null;
    this.existAgent = this.u_obj['UserId'] != null ? true : false;
    await this.addUserByAgent(this.u_obj['AgentId'], true);


    this.optionSelected = null;
    this.UserSKSelected = null;

    this.u_obj.PurchaseOrderLine.forEach(element => {
      element.OrderLineId = element.OrderLineId;
      element.TransportId = element.TransportId != 0 ? element.TransportId.toString() : null;
      element.UserIdSK = element.UserIdSK != 0 ? element.UserIdSK.toString() : null;

      this.UserSKSelected = element.UserIdSK;
      //console.log("this.UserSKSelected=> :",this.UserSKSelected,"element:::",element.UserIdSK)

      //this.existAgent = element.UserId != null ? true : false;
      ///aqui vamos a poner multipleuser
      element.UserIds = element.UserIds == null ? '' : element.UserIds;
      element.List = element.UserIds.includes(',') ? element.UserIds.split(',') : [element.UserIds];
      element.List = element.UserIds == '' ? [] : element.List;
      element.bloquearUserIdSK = element.UserIdSK == null ? false : true
      element.bloquearTransportId = element.TransportId == null ? false : true
      element.bloquearUserIds = element.UserIds == '' ? false : true
      if (element.bloquearUserIdSK && element.bloquearTransportId && element.bloquearUserIds) {
        this.u_obj['isBlock'] = true;
      }
    });
    let valor = this.u_obj.PurchaseOrderLine.filter(element => element.TransportId > 0).map(element => element.TransportId);
    if (valor.length > 0) {
      this.optionSelected = valor[0];
    }
    debugger
    // VALIDAMOS QUE TODAS LAS LINEAS DE DESPACHO TENGAN ASIGNADOS TRANSPORTISTAS,USUARIOS SKRETTING, USUARIOS AGENTE DE ADUANA
    const transportistaEncontrado = this.u_obj.PurchaseOrderLine.some(item => item.UserIds === '' || item.TransportId == 0 || item.UserIdSK ==0);
    if (transportistaEncontrado) {
      this.existAgent = false;
    } else {
      this.existAgent = true;
    }


    // console.log(valor);
    await this.GetColumnsNamePerTable(this.u_obj.OrderId);
    this.columnsList.forEach(column => {
      let flag = false;
      this.u_obj.PurchaseOrderLine.forEach(permission => {
        if (permission.OrderDisplay == column.OrderDisplay) {
          flag = true;
        }
      })
      if (!flag) {
        let obj = {
          // OrderLineId: 0,
          // List: []
          AmountUE: 0,
          AuthorizedDepartureDate: null,
          AutorizedDateVsArrivalPlant: null,
          Bl: null,
          CAS: null,
          Capacity: null,
          CodeAdv: null,
          CodeIva: null,
          Commentary: null,
          Containers: 0,
          Country: null,
          CreationTime: null,
          CustomsAclarance: null,
          DAI: null,
          DaysFree: 0,
          DaysFreeNegotiated: 0,
          Delay: null,
          DifferenceDaysTT: 0,
          DifferenceDaysTTReal: 0,
          DifferenceFI: 0,
          EmissionDate: null,
          EtaDepartureAuthorized: 0,
          EtaReal: null,
          EtaRequired: null,
          EtdEnd: null,
          EtdInit: null,
          EtdReal: null,
          EtdRequired: null,
          Fodinfa: 0,
          GrossWeight: 0,
          GrossWeightDifference: 0,
          GrossWeightDispatched: null,
          IncoTerm: null,
          Invoice: null,
          InvoiceDate: null,
          IsdException: null,
          ItemName: "Soy Lecithin Liq",
          ItemNumber: 0,
          Iva: 0,
          List: [],
          MeasureUnite: null,
          NacionalitationTime: 0,
          OrderDisplay: 0,
          OrderId: 5,
          OrderLine: 60,
          OrderLineId: 13,
          OrderLineSuffix: "0",
          Origen: "AR",
          PackagingNumber: "000000",
          PackagingNumber2: null,
          PaymentDateEfectiveLq: null,
          PaymentDateGuaranted: null,
          Permission: null,
          PlannedDate: "2022-06-10T15:53:47.693",
          PlantArrival: null,
          PlantPrice: 0,
          PlantPriceReal: 0,
          Policy: null,
          PortPlantTT: 0,
          PortShipment: null,
          ProcedureImportline: 0,
          ProvisionedFI: 0,
          PurchaseOrder: null,
          PurchaseOrderLineDetail: [],
          PurchaseOrderLineDocument: [],
          PurchasePrice: 1.4900000095367432,
          QuantityDispatchedTM: 0,
          QuantityLiquidation: 0,
          QuantityRequiredTM: 0,
          RealFI: 0,
          Responsible: null,
          SenaeDate: null,
          ShipmentAlert: 0,
          Shipping: null,
          SpecificTariff: 0,
          StatusId: 2,
          StatusLine: "A",
          SupplierContract: null,
          Tariff: 0,
          TariffHeading: null,
          TariffPercent: 0,
          TransportCompany: null,
          TransportId: "1",
          TypeCNT: null,
          TypeProduct: null,
          UpdateStatusDate: null,
          UpdateTime: null,
          UserId: 11,
          UserIds: "11,31",
          UserSystem: null,
          WarehouseNumber: "5B8"
        }
        this.u_obj.PurchaseOrderLine.push(obj);
      }
    })
  }

  public async GetColumnsNamePerTable(ID: number) {
    this.url = environment.Global.API_GET_ALLPOL_BY_ID + ID;

    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.columnsList = JSON.parse(JSON.stringify(result.Object));
        if (Array.isArray(this.rows)) {
          this.pageTotal2 = this.columnsList.length;
        }
      }
    });
  }

  public async getAgents() {
    this.url = environment.Global.API_GET_ALL_AG;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.agentList = result.Object;
      }
    })
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    }
    // console.log("object.PurchaseOrderLine=>", object.PurchaseOrderLine)
    let flag = false;
    object.PurchaseOrderLineFilter = object.PurchaseOrderLine.filter(element => element.List != undefined && element.List.length > 0);


    object.PurchaseOrderLineFilter.forEach(element => {


      // if (element.TransportId == null && element.List.length <= 0) {
      if (element.TransportId == null) {
        flag = true;
      }
      if (element.List != null && element.List.length > 0) {
        element.UserIds = element.List.join(',');
        element.UserId = element.List[0];
      }
    });


    // console.log("object.PurchaseOrderLineFilter=>", object.PurchaseOrderLineFilter)



    if (flag) {
      this.isUpdating = false;
      return this.errorMessage('enter_all_fields_required', 'YouMustSelectUserAgent', 'error');
    }
    this.obj = {
      AgentId: object.AgentId,
      UserId: object.UserId,
      OrderId: object.OrderId,
      ModifierUser: this.user.name,
      RemarkUpdate: this.user.sub,
      PurchaseOrderLine: object.PurchaseOrderLineFilter
    }


    this.url = environment.Global.API_UPDATE_ASSIG_PO;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err, '', 'error');
      this.isUpdating = false;
    })
  }

  public async getInternalUser() {
    this.url = environment.Global.API_GET_USER_INT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.userList = result.Object;
      }
    })
  }

  public async addTransportCompany(id, object, formUpdate) {
    if (id != null) {
      if (object != 0) {
        object.TransportId = id;

      }
      else {
        this.u_obj.PurchaseOrderLine.forEach(element => {
          element.TransportId = id;
        });
      }
    }
  }

  public async addUserSK(id, object, formUpdate) {

    if (this.userList.length > 0 && id != null) {
      if (object != 0) {
        object.UserIdSK = id;
      }
      else {

        this.u_obj.PurchaseOrderLine.forEach(element => {
          element.UserIdSK = id;
        });
      }

    }
  }

  public async addUserByAgent(id, formUpdate) {
    if (id != null) {
      this.url = environment.Global.API_GET_USER_BY_AGENT + id;
      await this.srv.get(this.url,).toPromise().then(result => {
        if (result.Status) {
          if (!formUpdate) {
            this.u_obj.PurchaseOrderLine.forEach(element => {
              element.UserId = null;
              element.TransportId = null;
              element.userIdSK = null;
            });
            this.u_obj.isBlock = false;
            this.existAgent = false;
          } else {
            // this.u_obj.isBlock = true;
            // this.existAgent = true;
          }
          this.userPerAgentList = result.Object;
        }
      })
    } else {
      this.userPerAgentList = [];
      this.u_obj.PurchaseOrderLine.forEach(element => {
        element.TransportId = null;
        element.UserId = null;
        element.userIdSK = null;
      });
      this.existAgent = false;
    }
  }

  public async getTransport() {
    this.url = environment.Global.API_GET_ALL_TRS;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.transportList = result.Object;
      }
    })
  }

  public interruptItem(modal, object) {
    Swal.fire({
      title: this.translations.SureInterrupt,
      text: this.translations.MsgInterupt,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: this.translations.Cancel,
      confirmButtonColor: '#C8102E',
      cancelButtonColor: '#84888b',
      confirmButtonText: this.translations.Accept,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.isInterrupt = true;
        this.url = environment.Global.API_STOP_ASSIG_PO + object['OrderId'] + '/' + this.user.sub;
        this.srv.get(this.url).toPromise().then(result => {
          if (result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.clearData();
            this.closeModal(modal);
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
          this.getInitialData();
          this.isInterrupt = false;
        }, err => {
          this.messageDialog(err, '', 'error');
          this.isInterrupt = false;
          this.getInitialData();
          this.closeModal(modal);
        })
      }
    })
  }
  public interruptItemSpecific(modal, object) {
    console.log(object)
    Swal.fire({
      title: this.translations.SureInterrupt,
      text: this.translations.MsgInterupt,
      icon: "info",
      showCancelButton: true,
      cancelButtonText: this.translations.Cancel,
      confirmButtonColor: '#C8102E',
      cancelButtonColor: '#84888b',
      confirmButtonText: this.translations.Accept,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.isInterrupt = true;
        this.url = environment.Global.API_STOP_ASSIG_POL + object['OrderLineId'] + '/' + this.user.sub;
        console.log(this.url)
        this.srv.get(this.url).toPromise().then(result => {
          if (result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.clearData();
            this.closeModal(modal);
            this.existAgent = false;
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
          this.getInitialData();
          this.isInterrupt = false;
        }, err => {
          this.messageDialog(err, '', 'error');
          this.isInterrupt = false;
          this.getInitialData();
          this.closeModal(modal);
        })
      }
    })
  }
}
