import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { BaseCrudComponent } from 'src/app/comex/base-crud/base-crud.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.css']
})
export class SelectFilterComponent extends BaseCrudComponent implements OnInit {

  @Output() filterValue: EventEmitter<string> =  new EventEmitter<string>();
  @Input() column: string = '';
  @Input() arrayList: any[] = [];
  @Input() indexPosition: string = null;
  valueFilter = null;
  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  sendFilter() {
    if (this.valueFilter != null) {
      this.filterValue.emit(this.indexPosition + '|'  + this.column  + '|' + this.valueFilter);
    } else {
      this.filterValue.emit(this.indexPosition + '|' + this.column + '|' + '');
    }
  }

}
