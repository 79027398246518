<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
        </div>
        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
        </div>
        <div class="col-md-3 col-sm-12">

            <button [ladda]="isFind" type="button" (click)="dataToFind()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
            <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
        </div>
    </div>
</div>

<!-- *ngIf="this.rows.length > 0" -->

<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>

        <!-- Dispatchs (2) -->
        <div *ngIf="option_processes == 2 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th> <label class="text-nowrap" name="ID">ID</label></th>
                        <th> <label class="text-nowrap" name="ResponsableSK">{{'ResponsableSK' | translate}}</label>
                        </th>
                        <th *ngIf="isSkretting"> <label class="text-nowrap" name="CompanyName">{{'CompanyName' |
                                translate}}</label>
                        <th class="sh"><label class="text-nowrap" name="Agent">{{'Agent' | translate}}</label></th>
                        <th class="sh"><label class="text-nowrap" name="ResponsableAgent">{{'ResponsableAgent' |
                                translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="IsdException">{{'ProcedureImportline' |
                                translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="DealNumber">{{'DealNumber' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="QuantityContract">{{'QuantityContract' |
                                translate}}</label>
                        </th>
                        <!-- <th class="sh"><label class="text-nowrap" name="OrderNumber">{{'OrderNumber' | translate}}</label></th> -->
                        <th class="sh"><label class="text-nowrap" name="OrderNumber">{{'OC' | translate}}</label></th>
                        <th class="sh">
                            <label class="text-nowrap" name="Division">{{'Division' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="OC">{{'purchase_order_line' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="SupplierCode">{{'SupplierCode' |
                                translate}}</label></th>
                        <th class="sh"><label class="text-nowrap" name="Supplier">{{'Supplier' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="TypeProduct">{{'TypeProduct' |
                                translate}}</label></th>
                        <th class="sh"><label class="text-nowrap" name="ItemNumber">{{'ItemNumber' | translate
                                }}</label></th>
                        <th class="sh"><label class="text-nowrap" name="ItemName">{{'ItemName' | translate}}</label>
                        </th>
                        <th class="sh"><label class="text-nowrap" name="SupplierContract">{{'SupplierContract' |
                                translate}}</label>
                        </th>
                        <th class="sh"> <label class="text-nowrap" name="IncoTerm">{{'IncoTerm' | translate}}</label>
                        </th>
                        <th class="sh"> <label class="text-nowrap" name="ProcedureImportline"> {{'ProcedureImportline' |
                                translate}}</label> </th>
                        <th class="sh"> <label class="text-nowrap" name="Origen"> {{'Origen' | translate}}</label> </th>
                        <th class="sh"> <label class="text-nowrap" name="PackagingName"> {{'Packaging' |
                                translate}}</label>
                        <th class="sh"> <label class="text-nowrap" name="EtaReal"> {{'EtaReal'| translate}}</label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'UserName')">
                                <app-select-filter [column]="'UserName'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'UserAgentName')">
                                <app-select-filter [column]="'UserAgentName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="l[3]"
                                    [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'DealNumber')">
                                <app-select-filter [column]="'DealNumber'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'GrossWeight')">
                                <app-select-filter [column]="'GrossWeight'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'TypeProduct')">
                                <app-select-filter [column]="'TypeProduct'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[13]" [indexPosition]="13"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(14,'SupplierContract')">
                                <app-select-filter [column]="'SupplierContract'" [arrayList]="l[14]"
                                    [indexPosition]="14" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'IncoTerm')">
                                <app-select-filter [column]="'IncoTerm'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(16,'IsdException')">
                                <app-select-filter [column]="'IsdException'" [arrayList]="l[16]" [indexPosition]="16"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'Origen')">
                                <app-select-filter [column]="'Origen'" [arrayList]="l[17]" [indexPosition]="17"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(18,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[18]" [indexPosition]="18"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td>
                                <label for="code">{{i + 1}}</label>
                            </td>
                            <td>
                                <label for="UserName">{{row.UserName}}</label>
                            </td>
                            <td>
                                <label for="AgentName">{{row.AgentName}}</label>
                            </td>
                            <td>
                                <label for="UserAgentName">{{row.UserAgentName}}</label>
                            </td>
                            <td>
                                <label for="ProcedureImportline">{{row.ProcedureImportline}}</label>
                            </td>
                            <td>
                                <label for="DealNumber">{{row.DealNumber}}</label>
                            </td>
                            <td>
                                <label for="GrossWeight">{{row.GrossWeight}}</label>
                            </td>
                            <td>
                                <label for="OrderNumber">{{row.OrderNumber}}</label>
                            </td>
                            <td>
                                <label for="Division">{{row.Division}}</label>
                            </td>
                            <td>
                                <label for="OrderDisplay">{{row.OrderDisplay}}</label>
                            </td>
                            <td>
                                <label for="Supplier">{{row.Supplier}}</label>
                            </td>
                            <td>
                                <label for="CompanyName">{{row.CompanyName}}</label>
                            </td>
                            <td>
                                <label for="TypeProduct">{{row.TypeProduct}}</label>
                            </td>
                            <td>
                                <label for="ItemNumber">{{row.ItemNumber}}</label>
                            </td>
                            <td>
                                <label for="ItemName">{{row.ItemName}}</label>
                            </td>
                            <td>
                                <label for="SupplierContract">{{row.SupplierContract}}</label>
                            </td>
                            <td>
                                <label for="IncoTerm">{{row.IncoTerm}}</label>
                            </td>
                            <td>
                                <label for="IsdException">{{row.IsdException}}</label>
                            </td>
                            <td>
                                <label for="Origen">{{row.Origen}}</label>
                            </td>
                            <td>
                                <label for="PackagingName">{{row.PackagingName}}</label>
                            </td>

                            <td><label for="EtaReal">{{row.EtaReal | date:'y-MM-dd'}}</label></td>
                        </tr>
                    </ng-container>
                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>


    </div>
</div>
