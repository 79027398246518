<div class="auth-wrapper d-flex no-block justify-content-center align-items-center">
    <div class="auth-box">
        <div class="card">
            <div class="m-b-30">
                <img src="../../../assets/images/logo/Skretting logo.png" style="width:100%"/>

            </div>
            <br>
            <div class="row">
                <div class="col-12">
                    <form class="form-horizontal m-t-20" id="loginform">
                        <div class="form-group text-center">
                            <div class="col-sm-8 offset-sm-2 p-b-20">
                                <button [ladda]="loading" data-style="expand-left" class="btn btn-rounded btn-block btn-md btn-custom-C8102E" (click)="login()">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
</div>