<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-5 col-sm-12">

            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <label for="">{{'EmissionDate'|translate}}</label>
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
                </div>
            </div>
        </div>

        <div class="col-md-7 col-sm-12">
            <div class="row">
                <div class="col-md-6 sk-ibox">

                    <!-- <ng-select [(ngModel)]="u_obj['UserId']" name="UserId" [multiple]="false">
                        <ng-option value="0">{{'Select'|translate}} {{'Agent'|translate}}... </ng-option>
                        <ng-option *ngFor="let st of AgentUser" value="{{st.UserId}}">{{st.AgentName}} -
                            {{st.Nombres}}</ng-option>
                    </ng-select> -->
                    <!-- <ng-select  [(ngModel)]="u_obj['UserId']" name="UserId" >
                        <ng-option>{{'Select'|translate}} {{'Agent'|translate}}... </ng-option>
                        <ng-option *ngFor="let user of AgentUser; index as i" value="{{user.UserId}}">{{user.AgentName}} - {{user.Nombres}} </ng-option>
                    </ng-select> -->

                    <select class="form-control" [(ngModel)]="u_obj['UserId']" name="UserId">
                        <option value="0">{{'Select'|translate}} {{'Agent'|translate}}...</option>
                        <option *ngFor="let type of AgentUser" value="{{type.UserId}}">{{type.AgentName}} -
                          {{type.Nombres}}</option>
                      </select>
                </div>
                <div class="col-md-6 col-sm-12">

                    <!-- *ngIf="this.u_obj['ItemId']!=0 && this.u_obj['UserId']!=0 ||this.u_obj['UserId']==null " -->
                    <button [ladda]="isFind" type="button" (click)="dataToFind()"
                        class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
                    <button [ladda]="isClean" type="button" (click)="clearFilters()"
                        class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <!-- <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button> -->
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>

                        <!-- EMBALAJE -->
                        <th>{{'ID'| translate}}</th>
                        <th>{{'Category'| translate}}</th>
                        <th>{{'Agent'| translate}}</th>
                        <th>{{'ProcedureImportline'| translate}}</th>
                        <th>{{'OrderDisplay'| translate}}</th>
                        <th>{{'CompanyName'| translate}}</th>
                        <th>{{'ItemNumber'| translate}}</th>
                        <th>{{'ItemName'| translate}}</th>
                        <th>{{'Status'| translate}}</th>
                        <th>{{'GrossWeightDispatched'| translate}}</th>
                        <th>{{'EtdReal'| translate}}</th>
                        <th>{{'EtaReal'| translate}}</th>
                        <th>{{'Capacity'| translate}}</th>
                        <th>{{'CustomsAclarance'| translate}}</th>
                        <th>{{'DAI'| translate}}</th>
                        <th>{{'EmissionDate'| translate}}</th>
                        <th>{{'PaymentDateGuaranted'| translate}}</th>
                        <th>{{'PaymentDateEfectiveLq'| translate}}</th>
                        <th>{{'QuantityLiquidation'| translate}}</th>
                        <th>{{'Tariff' | translate}} Advalorem</th>
                        <th>{{'TariffPercent' | translate}}</th>
                        <th>{{'SpecificTariff' | translate}}</th>
                        <th>{{'SpecificTariffPercent'| translate}}</th>
                        <th>{{'Fodinfa'| translate}}</th>
                        <th>{{'Iva'| translate}}</th>
                        <th>{{'Multa'| translate}}</th>
                        <th>{{'TariffHeading'| translate}}</th>
                        <th>{{'CodeAdv'| translate}}</th>
                        <th>{{'CodeIva'| translate}}</th>
                        <th>{{'Antidumping'| translate}}</th>
                        <th>{{'AdvaloremICE'| translate}}</th>
                        <th>{{'SpecificICE'| translate}}</th>
                        <th>{{'Safeguard'| translate}}</th>
                        <th>{{'SpecificSafeguard'| translate}}</th>
                        <th>{{'RedeemableTax'| translate}}</th>
                        <th>TPN {{'Reference'| translate}}</th>
                        <th>{{'Commentary'| translate}}</th>
                        <!-- <th>{{'AgentUser'| translate}}</th> -->

                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>
                        <td>
                            <div (click)="resetData(1,'TypeProductName')">
                                <app-select-filter [column]="'TypeProductName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(2,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="l[3]"
                                    [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'StatusName')">
                                <app-select-filter [column]="'StatusName'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'GrossWeightDispatched')">
                                <app-select-filter [column]="'GrossWeightDispatched'" [arrayList]="l[9]"
                                    [indexPosition]="9" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'EtdReal')">
                                <app-select-filter [column]="'EtdReal'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'Capacity')">
                                <app-select-filter [column]="'Capacity'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'CustomsAclarance')">
                                <app-select-filter [column]="'CustomsAclarance'" [arrayList]="l[13]"
                                    [indexPosition]="13" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(14,'DAI')">
                                <app-select-filter [column]="'DAI'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'EmissionDate')">
                                <app-select-filter [column]="'EmissionDate'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(16,'PaymentDateGuaranted')">
                                <app-select-filter [column]="'PaymentDateGuaranted'" [arrayList]="l[16]"
                                    [indexPosition]="16" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'PaymentDateEfectiveLq')">
                                <app-select-filter [column]="'PaymentDateEfectiveLq'" [arrayList]="l[17]"
                                    [indexPosition]="17" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(18,'QuantityLiquidation')">
                                <app-select-filter [column]="'QuantityLiquidation'" [arrayList]="l[18]"
                                    [indexPosition]="18" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'Tariff')">
                                <app-select-filter [column]="'Tariff'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(20,'TariffPercent')">
                                <app-select-filter [column]="'TariffPercent'" [arrayList]="l[20]" [indexPosition]="20"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(21,'SpecificTariff')">
                                <app-select-filter [column]="'SpecificTariff'" [arrayList]="l[21]" [indexPosition]="21"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(22,'SpecificTariffPercent')">
                                <app-select-filter [column]="'SpecificTariffPercent'" [arrayList]="l[22]"
                                    [indexPosition]="22" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(23,'Fodinfa')">
                                <app-select-filter [column]="'Fodinfa'" [arrayList]="l[23]" [indexPosition]="23"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(24,'Iva')">
                                <app-select-filter [column]="'Iva'" [arrayList]="l[24]" [indexPosition]="24"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(25,'Multa')">
                                <app-select-filter [column]="'Multa'" [arrayList]="l[25]" [indexPosition]="25"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(26,'TariffHeading')">
                                <app-select-filter [column]="'TariffHeading'" [arrayList]="l[26]" [indexPosition]="26"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(27,'CodeAdv')">
                                <app-select-filter [column]="'CodeAdv'" [arrayList]="l[27]" [indexPosition]="27"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(28,'CodeIva')">
                                <app-select-filter [column]="'CodeIva'" [arrayList]="l[28]" [indexPosition]="28"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(29,'Antidumping')">
                                <app-select-filter [column]="'Antidumping'" [arrayList]="l[29]" [indexPosition]="29"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(30,'ICEAdvalorem')">
                                <app-select-filter [column]="'ICEAdvalorem'" [arrayList]="l[30]" [indexPosition]="30"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(31,'ICESpecific')">
                                <app-select-filter [column]="'ICESpecific'" [arrayList]="l[31]" [indexPosition]="31"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(32,'Safeguard')">
                                <app-select-filter [column]="'Safeguard'" [arrayList]="l[32]" [indexPosition]="32"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(33,'SafeguardSpecific')">
                                <app-select-filter [column]="'SafeguardSpecific'" [arrayList]="l[33]"
                                    [indexPosition]="33" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(34,'RedeemableTax')">
                                <app-select-filter [column]="'RedeemableTax'" [arrayList]="l[34]" [indexPosition]="34"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(35,'TPNReference')">
                                <app-select-filter [column]="'TPNReference'" [arrayList]="l[35]" [indexPosition]="35"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(36,'Observacion')">
                                <app-select-filter [column]="'Observacion'" [arrayList]="l[36]" [indexPosition]="36"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td>
                                {{i+1}}
                            </td>

                            <td><label for="TypeProductName">{{row.TypeProductName}} </label></td>
                            <td><label for="AgentName">{{row.AgentName}} </label></td>
                            <td><label for="ProcedureImportline">{{row.ProcedureImportline}} </label></td>
                            <td><label for="OrderDisplay">{{row.OrderDisplay}} </label></td>
                            <!-- <td><label for="OrderDisplay">{{row.OrderDisplay}} </label></td> -->
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="ItemNumber">{{row.ItemNumber}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="StatusName">{{row.StatusName}} </label></td>
                            <td><label for="GrossWeightDispatched">{{row.GrossWeightDispatched}} </label></td>
                            <td><label for="EtdReal">{{row.EtdReal | date:'y-MM-dd'}} </label></td>
                            <td><label for="EtaReal">{{row.EtaReal | date:'y-MM-dd'}} </label></td>
                            <td><label for="Capacity">{{row.Capacity}} </label></td>
                            <td><label for="CustomsAclarance">{{row.CustomsAclarance}} </label></td>
                            <td><label for="DAI">{{row.DAI}} </label></td>
                            <td><label for="EmissionDate">{{row.EmissionDate | date:'y-MM-dd'}} </label></td>
                            <td><label for="PaymentDateGuaranted">{{row.PaymentDateGuaranted | date:'y-MM-dd'}} </label>
                            </td>
                            <td><label for="PaymentDateEfectiveLq">{{row.PaymentDateEfectiveLq | date:'y-MM-dd'}}
                                </label></td>
                            <td><label for="QuantityLiquidation">{{row.QuantityLiquidation}} </label></td>
                            <td><label for="Tariff">{{row.Tariff}} </label></td>
                            <td><label for="TariffPercent">{{row.TariffPercent}} </label></td>
                            <td><label for="SpecificTariff">{{row.SpecificTariff}} </label></td>
                            <td><label for="SpecificTariffPercent">{{row.SpecificTariffPercent}} </label></td>
                            <td><label for="Fodinfa">{{row.Fodinfa}} </label></td>
                            <td><label for="Iva">{{row.Iva}} </label></td>
                            <td><label for="Multa">{{row.Multa}} </label></td>
                            <td><label for="TariffHeading">{{row.TariffHeading}} </label></td>
                            <td><label for="CodeAdv">{{row.CodeAdv}} </label></td>
                            <td><label for="CodeIva">{{row.CodeIva}} </label></td>
                            <td><label for="Antidumping">{{row.Antidumping}} </label></td>
                            <td><label for="ICEAdvalorem">{{row.ICEAdvalorem}} </label></td>
                            <td><label for="ICESpecific">{{row.ICESpecific}} </label></td>
                            <td><label for="Safeguard">{{row.Safeguard}} </label></td>
                            <td><label for="SafeguardSpecific">{{row.SafeguardSpecific}} </label></td>
                            <td><label for="RedeemableTax">{{row.RedeemableTax}} </label></td>
                            <td><label for="TPNReference">{{row.TPNReference}} </label></td>
                            <td><label for="Comentary">{{row.Observacion}} </label></td>
                            <!-- <td><label for="Comentary">{{row.UsersAgentsNames}} </label></td> -->

                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

    </div>
</div>