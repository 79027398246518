import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { SettingsComponent } from './settings/settings.component';
import { CrudComponent } from './maint/crudhome/crud.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { PurchaseordersComponent } from './dashboard/purchaseorders/purchaseorders.component';
import { PurchaseorderlinesComponent } from './dashboard/purchaseorderlines/purchaseorderlines.component';
import { PurchaseorderlinedetailsComponent } from './forms/purchaseorderlinedetails/purchaseorderlinedetails.component';
import { AlertsComponent } from './maint/alerts/alerts.component';
import { CustomagentComponent } from './maint/customagent/customagent.component';
import { CustomagentemployeeComponent } from './maint/customagentemployee/customagentemployee.component';
import { DocumentsComponent } from './maint/documents/documents.component';
import { LiberatorycodeComponent } from './maint/liberatorycode/liberatorycode.component';
import { PackagingComponent } from './maint/packaging/packaging.component';
import { ParametersComponent } from './maint/parameters/parameters.component';
import { PermissionsComponent } from './maint/permissions/permissions.component';
import { PurchaseorderassignComponent } from './maint/purchaseorderassign/purchaseorderassign.component';
import { PurchaseorderlineassignComponent } from './maint/purchaseorderlineassign/purchaseorderlineassign.component';
import { PurchaseorderlinedetailassignComponent } from './maint/purchaseorderlinedetailassign/purchaseorderlinedetailassign.component';
import { StatusComponent } from './maint/status/status.component';
import { WarehousesComponent } from './maint/warehouses/warehouses.component';
import { TransportcompanyemployeesComponent } from './maint/transportcompanyemployees/transportcompanyemployees.component';
import { TransportcompanyComponent } from './maint/transportcompany/transportcompany.component';
import { ObservationsComponent } from './maint/observations/observations.component';
import { ParametersdetailComponent } from './maint/parametersdetail/parametersdetail.component';
import { AuthenticationGuard } from '../guards/authentication.guard';
import { PermissionResolveService } from '../services/permission-resolve.service';
import { ApprovedocumentComponent } from './dashboard/approvedocument/approvedocument.component';
import { PaymentsComponent } from "./dashboard/payments/payments.component";
import { ApproveFieldComponent } from './dashboard/approve-field/approve-field.component';
import { ReportingComponent } from "./processes/reporting/reporting.component";
import { UserComponent } from './maint/user/user.component';
import { AddDocumentationComponent } from './maint/add-documentation/add-documentation.component';
import { DocumentrepositoryComponent } from './dashboard/documentrepository/documentrepository.component';

import { ConceptComponent } from './maint/concept/concept.component';
import { SubconceptComponent } from './maint/subconcept/subconcept.component';
import { ReportPurchaseOrderComponent } from './processes/reporting/report-purchase-order/report-purchase-order.component';
import { ReportDispatchComponent } from './processes/reporting/report-dispatch/report-dispatch.component';
import { ReportPaymentsComponent } from './processes/reporting/report-payments/report-payments.component';
import { ReportPurchaseOrderDetailLineComponent } from './processes/reporting/report-purchase-order-detail-line/report-purchase-order-detail-line.component';
import { ReportImportExpensesComponent } from './processes/reporting/report-import-expenses/report-import-expenses.component';
import { ReportProvisionRealComponent } from './processes/reporting/report-provision-real/report-provision-real.component';
import { ReportArrivalProductTypeComponent } from './processes/reporting/report-arrival-product-type/report-arrival-product-type.component';
import { ReportAssignedContainersComponent } from './processes/reporting/report-assigned-containers/report-assigned-containers.component';
import { ReportImportCustomAgentComponent } from './processes/reporting/report-import-custom-agent/report-import-custom-agent.component';
import { ReportStateDispatchsComponent } from './processes/reporting/report-state-dispatchs/report-state-dispatchs.component';






export const Comexroutes: Routes = [
  {
    path: 'comex',
    component: FullComponent,
    children: [
      {
        path: 'purchase',
        canActivate: [AuthenticationGuard],
        // resolve: {
        //   permission: PermissionResolveService
        // },
        component: DashboardComponent,
        data: {
          title: 'menu_oc',
          urls: []
        }
      },
      {
        path: 'purchase/purchase-orders',
        component: PurchaseordersComponent,
        data: {
          title: 'submenu_skretting_purchase_order',
          urls: [
            { title: 'menu_oc', url: 'purchase' },
            { title: 'submenu_skretting_purchase_order' }
          ]
        }
      },
      {
        path: 'purchase/purchase-order-lines/:id',
        component: PurchaseorderlinesComponent,
        data: {
          title: 'submenu_skretting_purchase_order_detail',
          urls: [
            { title: 'menu_oc', url: 'purchase' },
            { title: 'submenu_skretting_purchase_order_detail' }
          ]
        }
      },
      {
        path: 'purchase/approve-document',
        component: ApprovedocumentComponent,
        data: {
          title: 'submenu_skretting_approve_document',
          urls: [
            { title: 'menu_oc', url: 'purchase' },
            { title: 'submenu_skretting_approve_document' }
          ]
        }
      },
      {
        path: 'purchase/approve-field',
        component: ApproveFieldComponent,
        data: {
          title: 'submenu_skretting_approve_field',
          urls: [
            { title: 'menu_oc', url: 'purchase' },
            { title: 'submenu_skretting_approve_field' }
          ]
        }
      },
      {
        path: 'purchase/payments',
        component: PaymentsComponent,
        data: {
          title: 'submenu_skretting_payments',
          urls: [
            { title: 'menu_oc', url: 'purchase' },
            { title: 'submenu_skretting_payments' }
          ]
        }
      },
      {
        path: 'purchase/document-repository',
        component: DocumentrepositoryComponent,
        data: {
          title: 'submenu_skretting_document_repository',
          urls: [
            { title: 'menu_oc', url: 'purchase' },
            { title: 'submenu_skretting_document_repository' }
          ]
        }
      },
      {
        path: 'logistics',
        component: FormshomeComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'menu_logistics',
          urls: []
        }
      },
      {
        path: 'logistics/purchase-order-line-detail/:id',
        component: PurchaseorderlinedetailsComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'submenu_skretting_purchase_order_detail_line',
          urls: [
            { title: 'menu_logistics', url: 'logistics' },
            { title: 'submenu_skretting_purchase_order_detail_line' }
          ]
        }
      },
      {
        path: 'reports',
        component: ProchomeComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'menu_reports',
          urls: []
        }
      },
      {
        path: 'reports/reporting',
        component: ReportingComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'menu_reports',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'menu_reporting' }
          ]
        }
      },
      {
        path: 'reports/purchase',
        component: ReportPurchaseOrderComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'purchase_orders',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'purchase_orders' }
          ]
        }
      },
      {
        path: 'reports/dispatch',
        component: ReportDispatchComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'Dispatchs',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'Dispatchs' }
          ]
        }
      },

      {
        path: 'reports/payments',
        component: ReportPaymentsComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'submenu_skretting_payments',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'submenu_skretting_payments' }
          ]
        }
      },
      {
        path: 'reports/purchase_order_detail_line',
        component: ReportPurchaseOrderDetailLineComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'submenu_skretting_purchase_order_detail_line',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'submenu_skretting_purchase_order_detail_line' }
          ]
        }
      },
      {
        path: 'reports/import-custom-agent',
        component: ReportImportCustomAgentComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'ImportCustomAgent',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'ImportCustomAgent' }
          ]
        }
      },

      {
        path: 'reports/import-expenses',
        component: ReportImportExpensesComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'ImportCosts',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'ImportCosts' }
          ]
        }
      },
      {
        path: 'reports/provision-real',
        component: ReportProvisionRealComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'ProvisionVSReal',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'ProvisionVSReal' }
          ]
        }
      },
      {
        path: 'reports/Arrival-product-type',
        component: ReportArrivalProductTypeComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'ArrivalByTypeProduct',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'ArrivalByTypeProduct' }
          ]
        }
      },
      {
        path: 'reports/assigned-containers',
        component: ReportAssignedContainersComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'AssignedContainers',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'AssignedContainers' }
          ]
        }
      },
      {
        path: 'reports/states-by-dispatchs',
        component: ReportStateDispatchsComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'StatesByDispatchs',
          urls: [
            { title: 'menu_reports', url: 'reports' },
            { title: 'StatesByDispatchs' }
          ]
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'menu_title_general',
          urls: []
        }
      },

      {
        path: 'cruds',
        component: CrudComponent,
        canActivate: [AuthenticationGuard],
        data: {
          title: 'menu_maintenance',
          urls: [
          ]
        }
      },
      {
        path: 'cruds/alert-config',
        // canActivate: [AuthenticationGuard],
        component: AlertsComponent,
        data: {
          title: 'submenu_skretting_alert',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_alert' }
          ]
        }
      },
      {
        path: 'cruds/custom-agent-config',
        // canActivate: [AuthenticationGuard],
        component: CustomagentComponent,
        data: {
          title: 'submenu_skretting_custom_agent',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_custom_agent' }
          ]
        }
      },
      {
        path: 'cruds/custom-agent-employee-config',
        // canActivate: [AuthenticationGuard],
        component: CustomagentemployeeComponent,
        data: {
          title: 'submenu_skretting_custom_agent_employee',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_custom_agent_employee' }
          ]
        }
      },
      {
        path: 'cruds/documents-config',
        // canActivate: [AuthenticationGuard],
        component: DocumentsComponent,
        data: {
          title: 'submenu_skretting_documents',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_documents' }
          ]
        }
      },
      {
        path: 'cruds/liberatory-code-config',
        // canActivate: [AuthenticationGuard],
        component: LiberatorycodeComponent,
        data: {
          title: 'submenu_skretting_liberatory_code',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_liberatory_code' }
          ]
        }
      },
      {
        path: 'cruds/observation-config',
        // canActivate: [AuthenticationGuard],
        component: ObservationsComponent,
        data: {
          title: 'submenu_skretting_observations',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_observations' }
          ]
        }
      },
      {
        path: 'cruds/packaging-config',
        // canActivate: [AuthenticationGuard],
        component: PackagingComponent,
        data: {
          title: 'submenu_skretting_packaging',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_packaging' }
          ]
        }
      },
      {
        path: 'cruds/parameter-config',
        // canActivate: [AuthenticationGuard],
        component: ParametersComponent,
        data: {
          title: 'submenu_skretting_parameters',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_parameters' }
          ]
        }
      },
      {
        path: 'cruds/parameter-config/parameter-detail-config/:id',
        // canActivate: [AuthenticationGuard],
        component: ParametersdetailComponent,
        data: {
          title: 'submenu_skretting_parameter_details',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_parameters', url: 'cruds/parameter-config' },
            { title: 'submenu_skretting_parameter_details' }
          ]
        }
      },
      // Concept
      {
        path: 'cruds/concept',
        // canActivate: [AuthenticationGuard],
        component: ConceptComponent,
        data: {
          title: 'submenu_skretting_concept',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_concept' }
          ]
        }
      },
      // Subconcept
      {
        path: 'cruds/concept/subconcepts/:id',
        // canActivate: [AuthenticationGuard],
        component: SubconceptComponent,
        data: {
          title: 'submenu_skretting_concept_details',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_concept', url: 'cruds/concept' },
            { title: 'submenu_skretting_concept_details' }
          ]
        }
      },

      {
        path: 'cruds/permission-config',
        // canActivate: [AuthenticationGuard],
        component: PermissionsComponent,
        data: {
          title: 'submenu_skretting_permission',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_permission' }
          ]
        }
      },
      {
        path: 'cruds/po-assign-config',
        // canActivate: [AuthenticationGuard],
        component: PurchaseorderassignComponent,
        data: {
          title: 'submenu_skretting_purchase_order_assign',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_purchase_order_assign' }
          ]
        }
      },
      {
        path: 'cruds/pol-assign-config',
        // canActivate: [AuthenticationGuard],
        component: PurchaseorderlineassignComponent,
        data: {
          title: 'submenu_skretting_purchase_order_line_assign',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_purchase_order_line_assign' }
          ]
        }
      },
      {
        path: 'cruds/pold-assign-config',
        // canActivate: [AuthenticationGuard],
        component: PurchaseorderlinedetailassignComponent,
        data: {
          title: 'submenu_skretting_purchase_order_line_detail_assign',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_purchase_order_line_detail_assign' }
          ]
        }
      },
      {
        path: 'cruds/status-config',
        // canActivate: [AuthenticationGuard],
        component: StatusComponent,
        data: {
          title: 'submenu_skretting_status',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_status' }
          ]
        }
      },
      {
        path: 'cruds/transport-company-config',
        // canActivate: [AuthenticationGuard],
        component: TransportcompanyComponent,
        data: {
          title: 'submenu_skretting_transport_company',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_transport_company' }
          ]
        }
      },
      {
        path: 'cruds/transport-company-employee-config',
        // canActivate: [AuthenticationGuard],
        component: TransportcompanyemployeesComponent,
        data: {
          title: 'submenu_skretting_transport_company_employee',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_transport_company_employee' }
          ]
        }
      },
      {
        path: 'cruds/warehouses-config',
        // canActivate: [AuthenticationGuard],
        component: WarehousesComponent,
        data: {
          title: 'submenu_skretting_warehouses',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_warehouses' }
          ]
        }
      },
      {
        path: 'cruds/users-config',
        // canActivate: [AuthenticationGuard],
        component: UserComponent,
        data: {
          title: 'submenu_skretting_users_group',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_users_group' }
          ]
        }
      },
      {
        path: 'cruds/app-add-documentation',
        // canActivate: [AuthenticationGuard],
        component: AddDocumentationComponent,
        data: {
          title: 'submenu_skretting_additional_documentation',
          urls: [
            { title: 'menu_maintenance', url: 'cruds' },
            { title: 'submenu_skretting_additional_documentation' }
          ]
        }
      }
    ]
  }
];
