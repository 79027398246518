import { Component, Inject, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { ConnectionService } from 'ng-connection-service';
import { ApiService } from './services/main/api.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './services/authentication/auth.service';
import { environment } from 'src/environments/environment';
import { UserCx } from './interfaces/User';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'app';
  version:any;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public userAuthenticated = false;
  constructor(
    private translate: TranslateService,
    private swUpdate: SwUpdate,
    private connectionService: ConnectionService,
    public srv: ApiService,
    private router: Router,
    private _authService: AuthService
  ) {
      var userLang = 'es';
      translate.setDefaultLang(userLang);
      translate.use(userLang);

  
  }

  ngOnInit() {

  }

}
