<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
        </div>
        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
        </div>
        <div class="col-md-3 col-sm-12">

            <button [ladda]="isFind" type="button" (click)="dataToFind()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
            <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
        </div>
    </div>
</div>

<!-- *ngIf="this.rows.length > 0" -->

<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>
        <!-- Purchase Orders (1) -->
        <div class="table-responsive text-center" *ngIf="option_processes == 1 && rows.length > 0">
            <table class="table">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <!-- <th>{{'OrderNumber' | translate}} </th> -->
                        <th>{{'OC' | translate}} </th>
                        <th>{{'CodeSupplier' | translate}}</th>
                        <th *ngIf="isSkretting">{{'CompanyName' | translate}}</th>
                        <th>{{'GrossWeightOrder' | translate}} </th>
                        <th>{{'date_creation' | translate}}</th>
                        <th>{{'UserResponsableSk' | translate}}</th>
                        <th>{{'Agent' | translate}}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>

                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td *ngIf="isSkretting">
                            <div (click)="resetData(2,'CompanyName')">
                                <app-select-filter [column]="'UserAgentName'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'GrossWeightOrder')">
                                <app-select-filter [column]="'GrossWeightOrder'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'CreationTime')">
                                <app-select-filter [column]="'CreationTime'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'UserName')">
                                <app-select-filter [column]="'UserName'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                    </tr>

                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                        <td></td>
                        <td>{{row.OrderNumber}}</td>
                        <td>{{row.Supplier}}</td>
                        <td *ngIf="isSkretting">{{row.CompanyName}}</td>
                        <td>{{row.GrossWeightOrder}}</td>
                        <td>{{row.CreationTime.split('T')[0]}}</td>
                        <!---->
                        <td>{{row.UserName}}</td>
                        <td>{{row.AgentName}}</td>
                    </tr>
                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>
        </div>

    </div>
</div>
