import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';

@Component({
  selector: 'app-formshome',
  templateUrl: './formshome.component.html',
  styleUrls: ['./formshome.component.css']
})
export class FormshomeComponent  extends BaseCrudComponent implements OnInit {

  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any;
  permissions = [];
  constructor(
    private spinner: NgxSpinnerService,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public translate: TranslateService,
    private auth: AuthorizationService,
    private _authService: AuthService
  ) {
    super(srv, modalService, storage, router);


  }

  async ngOnInit() {
    await super.ngOnInit();
      this.items = [
        {title: 'submenu_skretting_purchase_order_detail_line', url: '/comex/logistics/purchase-order-line-detail/ALL', icon: 'fas fa-industry', perm: 'CX_POLD'},// se debe crer elpermiso real
      ];
  }

  hasPermission(str:string){
    return this.auth.existPermission(str);
  }

}
