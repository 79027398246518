<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
        </div>
        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
        </div>
        <div class="col-md-3 col-sm-12">

            <button [ladda]="isFind" type="button" (click)="dataToFind()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
            <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
        </div>
    </div>
</div>

<!-- *ngIf="this.rows.length > 0" -->

<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>


        <!-- Purchase order line (4)-->
        <div *ngIf="option_processes == 4 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <th>{{'OC' | translate}}</th>
                        <th>{{'dispatch' | translate}}</th>
                        <th>{{'Division' | translate}}</th>
                        <th>{{'ContainerNumber' | translate}}</th>

                        <th>{{'DateDepartureReception' | translate}}</th>
                        <th>{{'HourDepartureReception' |translate}}</th>
                        <th>{{'RescheduleDate' | translate}}</th>
                        <th>{{'RescheduleHour' | translate}}</th>

                        <th>{{'Agent' | translate}}</th>
                        <th>{{'ResponsableAgent' | translate}}</th>
                        <th>{{'Eta' | translate}}</th>
                        <th>{{'EndCas' | translate}}</th>
                        <th>{{'PortShipment' | translate}}</th>
                        <th>{{'ResponsableSK' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'Packaging' | translate}}</th>
                        <th>{{'Bl' | translate}}</th>
                        <th>{{'StampBl' | translate}}</th>
                        <th>{{'WeightItem' | translate}}</th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>
                        <td>
                            <div (click)="resetData(0,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'Division')">
                                <app-select-filter [column]="'Division'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'ContainerNumber')">
                                <app-select-filter [column]="'ContainerNumber'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(4,'DateDepartureReception')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[4]"
                                    [indexPosition]="4" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(5,'HourDepartureReception')">
                                <app-select-filter [column]="'HourDepartureReception'" [arrayList]="l[5]"
                                    [indexPosition]="5" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'RescheduleDate')">
                                <app-select-filter [column]="'RescheduleDate'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'RescheduleHour')">
                                <app-select-filter [column]="'RescheduleHour'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'Agent')">
                                <app-select-filter [column]="'Agent'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'UserIdsNames')">
                                <app-select-filter [column]="'UserIdsNames'" [arrayList]="l[9]" [indexPosition]="9"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'EtaRequired')">
                                <app-select-filter [column]="'EtaRequired'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'CAS')">
                                <app-select-filter [column]="'CAS'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'PortShipment')">
                                <app-select-filter [column]="'PortShipment'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'NameResposabilitySK')">
                                <app-select-filter [column]="'NameResposabilitySK'" [arrayList]="l[13]"
                                    [indexPosition]="13" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(14,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(16,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[16]" [indexPosition]="16"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'Bl')">
                                <app-select-filter [column]="'Bl'" [arrayList]="l[17]" [indexPosition]="17"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(18,'BlSeal')">
                                <app-select-filter [column]="'BlSeal'" [arrayList]="l[18]" [indexPosition]="18"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'ProductWeight')">
                                <app-select-filter [column]="'ProductWeight'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td><label for="OrderNumber">{{row.OrderNumber}} </label></td>
                            <td><label for="OrderDisplay">{{row.OrderDisplay}} </label></td>
                            <td><label for="Division">{{row.Division}}</label></td>
                            <td><label for="ContainerNumber">{{row.ContainerNumber}} </label></td>
                            <td><label for="DateDepartureReception">{{row.DateDepartureReception |
                                    date:'y-MM-d'}}</label></td>
                            <td><label for="HourDepartureReception">{{row.HourDepartureReception}}</label></td>
                            <td><label for="RescheduleDate">{{row.RescheduleDate | date:'y-MM-d'}}</label></td>
                            <td><label for="RescheduleHour">{{row.RescheduleHour}}</label></td>
                            <td><label for="Agent">{{row.Agent}} </label></td>
                            <td><label for="UserIdsNames">{{row.UserIdsNames}} </label></td>
                            <td><label for="EtaRequired">{{row.EtaRequired | date:'y-MM-dd'}} </label></td>
                            <td><label for="CAS">{{row.CAS | date:'y-MM-dd'}} </label></td>
                            <td><label for="PortShipment">{{row.PortShipment}} </label></td>
                            <td><label for="NameResposabilitySK">{{row.NameResposabilitySK}} </label></td>
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="PackagingName">{{row.PackagingName}} </label></td>
                            <td><label for="Bl">{{row.Bl}} </label></td>
                            <td><label for="BlSeal">{{row.BlSeal}} </label></td>
                            <td><label for="ProductWeight">{{row.ProductWeight}} </label></td>

                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>



    </div>
</div>
