import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purchaseorderlinedetailassign',
  templateUrl: './purchaseorderlinedetailassign.component.html',
  styleUrls: ['./purchaseorderlinedetailassign.component.css']
})
export class PurchaseorderlinedetailassignComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
