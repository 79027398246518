import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { saveAs as importedSaveAs } from "file-saver";
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-documentrepository',
  templateUrl: './documentrepository.component.html',
  styleUrls: ['./documentrepository.component.css']
})
export class DocumentrepositoryComponent extends BaseCrudComponent implements OnInit {

  urlDetail = 'comex/purchase/purchase-order-lines/';
  userType = '';
  pgSize: number = 20;

  //Filter
  showIconF = false;
  seeFilter = false;
  arrayList = [];
  hiddenFunction = [];
  hiddenFilter = [];
  dataFilter = [];
  dataSearchList = [];
  objRow = null;
  hidden = [];
  archivos = [];
  isPDF = true;
  isImage = false;
  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  @Input() index: any = null;
  pdfSrc = "";
  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['OrderNumber', 'OrderDisplay', 'Division', 'Supplier',
      'ItemName', 'ProcedureImportline', 'GrossWeightDispatched', 'DocumentName',
      'StateDocumentDispatch', 'RequireApproval', 'FileName', 'StatusFileDocumentUpload'];

  }
  async ngOnInit() {
    this.pageSize = this.pgSize;
    await super.ngOnInit();
    this.permissionList = this._authService.GetPermissionsByModule("CX_PO");
    this.userType = this._authService.ExistPermission("CX_ADM") ? 'ADM'
      : this._authService.ExistPermission("CX_AGA") ? 'AGA'
        : this._authService.ExistPermission("CX_TRA") ? 'TRA'
          : this._authService.ExistPermission("CX_FIN") ? 'FIN'
            : this._authService.ExistPermission("CX_TAX") ? 'TAX'
              // : this._authService.ExistPermission("CX_CAL") ? 'CAL'
              : this._authService.ExistPermission("CX_COM") ? 'COM'
                : this._authService.ExistPermission("CX_CM") ? 'CM' : 'ADM';

    this.currentUserName = this._authService.getUserName();
    this.user = this._authService.getUserLogged();
   
    await this.getPurchaseOrdersDocuments();
  }

  async getPurchaseOrdersDocuments() {
    this.isLoading = true;
    this.messageShowLoading();
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
    this.url = environment.Global.API_GET_ALL_POR_BY_USR + this.userType + '/' + this.user.sub;
    console.log("Purchase Orders URL:", this.url);
    this.srv.get(this.url).subscribe(x => {
      console.log("data:", x);
      if (x.Status) {
        if (x.Object != null) {
          this.rawdata = x.Object;
          this.rows = x.Object;
          this.page = 1;
          this.rows.forEach(element => {
            element.GrossWeightDispatched = Number(element.GrossWeightDispatched);
          });
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.closeMessageDialog();
        }
      }
    })
  }

  public openCreateModal(content) {
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }
  public DownloadFile(obj) {
    if (obj.ExistFile != true) {
     // debugger
      this.messageDialog("No se encontro el archivo para descargar", '', 'error');
      return;
    }
    console.log("entramos a descargar, objeto:", obj)
    let FileName = obj.FileName;
    console.log(FileName)
    this.url = environment.Global.API_DOWNLOAD_FILE_POL + obj.PurchaseOrderLineDocumentId;
    console.log("url:", this.url, "filename:", FileName)
    this.messageShowLoading();
    this.srv.downloadFiles(this.url).subscribe(result => {
      importedSaveAs(result, FileName)
      this.closeMessageDialog();
    })
  }


  public searchDataSelect(event) {
    let data = event.split('|'); 
    // debugger;
    if (data[2] == '(Vacíos)') {
      data[2] = null;
    }
    this.dataSearchList[data[0]] = data[2] != '' ? (data[1] + '|' + data[2]) : null;//''
    this.search2 = this.dataSearchList;
    this.filterAllSelect();
  }


  public async seeDetailsRow(index, row) {
    this.objRow = row;
    this.hidden[index] = !this.hidden[index];
  }


  public offFilter(index) {
    this.hiddenFunction[index] = !this.hiddenFunction[index];
    this.hiddenFilter[index] = !this.hiddenFilter[index];
    this.arrayList[index] = [];
    this.searchData('');
  }

  public resetData(index, columnName) {
    let array = [];
    this.arrayList[index] = [];
    this.rows.forEach(element => {
      if (element[columnName] != undefined) {
        let flag = array.indexOf(element[columnName]);
        if (flag == -1) {
          array.push(element[columnName]);
        }
      }
    });
    array.push('(Vacíos)');
    this.arrayList[index] = array;
  }

  public showIconFilter(flag: boolean) {
    this.showIconF = flag;
    if (!flag) {
      this.searchData('');
      this.arrayList = [];
    }
  }
  public openUpdateModal(modal, object, size: number) {
    this.u_obj['OrderNumber'] = object.OrderNumber;
    this.u_obj['OrderDisplay'] = object.OrderDisplay;
    this.u_obj['StateDocumentDispatch'] = object.StateDocumentDispatch;
    this.u_obj['DocumentName'] = object.DocumentName;
    this.u_obj['OrderLineId'] = object.OrderLineId;
    this.u_obj['DocumentId'] = object.DocumentId;
    console.log("Data openUpdateModal:",object);
    // validamos que la variable que verifica que existe el archivo sea true
    if (object.ExistFile != true) {
      // debugger
      this.messageDialog("No se encontro el archivo para visualizar", '', 'error');
      this.closeModal(modal);
      return;
    }
    if (object.UserIdSK == 0) {
      // debugger
      this.messageDialog("Este despacho no tiene asignado(a) un responsable", '', 'error');
      this.closeModal(modal);
      return;
    }

    let url = environment.Global.url.substring(0, (environment.Global.url.length - 4))
    let termino = "Documents";

    let PathFile = object.PathFile;
    let posicion = PathFile.indexOf(termino);

    if (object.Extension == 'pdf') {
      this.isPDF = true;
      this.isImage = false;
    } else if (object.Extension == 'png' || object.Extension == 'jpeg' || object.Extension == 'jpg' || object.Extension == 'PNG' || object.Extension == 'JPEG' || object.Extension == 'JPG') {
      this.isPDF = false;
      this.isImage = true;
    } else {
      this.isPDF = false;
      this.isImage = false;
    }

    // if (posicion !== -1) {
    //   console.log("La palabra está en la posición " + posicion);
    //   // let va= PathFile.replace(/\\/g, "/");
    //   let valor = object.PathFile.substring(posicion, PathFile.length).replace(/\\/g, "/");
    // }
    // else
    //   console.log("No encontré lo que estás buscando");

    this.pdfSrc = url + (PathFile.substring(posicion, PathFile.length));
    this.pdfSrc = this.pdfSrc.replace(/\\/g, "/");
    // console.log(this.pdfSrc);
    // this.isUpdating = false;
    // console.log("Objeto:", this.u_obj);
    // console.log("Type:", this.u_obj);

    if (size == 1)
      this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    else if (size == 2)
      this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
  }


  public AddFile(event, id) {
    const archivocapture = event.target.files[0];
    let obj = {
      id: id,
      file: archivocapture,
    }
    if (this.archivos.length == 0) {
      this.archivos.push(obj);
    } else {
      let flag = false;
      this.archivos.forEach(element => {
        if (element.id == obj.id) {
          element.file = archivocapture
          flag = true;
        }
      })
      if (!flag) {
        this.archivos.push(obj)
      }
    }
    // console.log("This archivos:", this.archivos);
  }

  public createForm(object: any): boolean {
    debugger;
    // console.log(object);
    let myForm: any = {};
    myForm = new FormGroup({
      'Commentary': new FormControl(object.Commentary, [Validators.required]),
    });
    return myForm.valid;
  }
  public async SaveFiles(modal, object) {

    console.log(object.OrderLineId);
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }

    if (object)
      if (this.archivos.length > 0) {
        let lista = [];
        this.archivos.forEach(file => {
          if (file.file.size > environment.Global.SizeFile10) {
            lista.push(file.file.name)
          }
        });
        if (lista.length > 0) {
          let files = lista.join('-');
          this.errorMessageWithoutHtml('FileError', 'Estos archivos poseen mas de 10 MB: ' + files + '. Deberá cargar otros', 'error');
          return;
        } else {
          this.isUploadFile = true;
          let formData = new FormData();
          this.archivos.forEach(file => {
            formData.append('Files[-' + file.id + '-]', file.file);
          })
          formData.append('OrderLineId', object.OrderLineId);
          formData.append('CreatorUser', this.user.name);
          formData.append('UserCode', this.user.sub);
          formData.append('DocumentComment', object.Commentary);
          this.messageShowLoading();
          await this.srv.AddFiles(environment.Global.API_POST_FILES_POR, formData).toPromise().then(result => {
            if (result) {

              this.messageDialog("Archivos cargados con exito", '', 'success');
              this.closeModal(modal);

              setTimeout(() => {
                this.getPurchaseOrdersDocuments();
              }, 3000);


            } else {
              this.messageDialog('', '', 'error');
            }
            this.isUploadFile = false;
          });
        }

      } else {
        this.errorMessage('EmptyList', 'UploadFileDocument', "error");
        this.isUploadFile = false;
      }
  }



}
