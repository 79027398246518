<br>
<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'" >
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' && sidebarnavItem.path !== 'about-us' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem)" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title | translate}}</span>
            </a>
        </li>
    </ul>
</nav>