import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import {NgxSpinnerService} from "ngx-spinner";
import {ApiService} from "../../../services/main/api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Storage} from "@ionic/storage";
import {AuthorizationService} from "../../../services/authorization/authorization.service";
import {AuthService} from "../../../services/authentication/auth.service";
import {BaseCrudComponent} from "../../base-crud/base-crud.component";

@Component({
  selector: 'app-prochome',
  templateUrl: './prochome.component.html',
  styleUrls: ['./prochome.component.css']
})
export class ProchomeComponent  extends BaseCrudComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

  	constructor(
      private spinner: NgxSpinnerService,
      srv: ApiService,
      modalService: NgbModal,
      storage: Storage,
      router: Router,
      public translate: TranslateService,
      private auth: AuthorizationService,
      private _authService: AuthService
  	) {
      super(srv, modalService, storage, router);
    }

  async ngOnInit() {
    await super.ngOnInit();
        this.items = [
          // {title: 'menu_reporting', url: '/comex/reports/reporting', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'purchase_orders', url: '/comex/reports/purchase', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'Dispatchs', url: '/comex/reports/dispatch', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'submenu_skretting_payments', url: '/comex/reports/payments', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'submenu_skretting_purchase_order_detail_line', url: '/comex/reports/purchase_order_detail_line', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'ImportCustomAgent', url: '/comex/reports/import-custom-agent', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'ImportCosts', url: '/comex/reports/import-expenses', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'ProvisionVSReal', url: '/comex/reports/provision-real', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'ArrivalByTypeProduct', url: '/comex/reports/Arrival-product-type', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          // {title: 'AssignedContainers', url: '/comex/reports/assigned-containers', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},

        // {title: 'menu_reporting', url: '/comex/reports/reporting', icon: 'fas fa-chart-bar', perm: 'CX_POLA'},
          {title: 'purchase_orders', url: '/comex/reports/purchase', icon: 'fas fa-chart-bar', perm: 'CX_RPO'},
          {title: 'Dispatchs', url: '/comex/reports/dispatch', icon: 'fas fa-chart-bar', perm: 'CX_RDIS'},
          {title: 'submenu_skretting_payments', url: '/comex/reports/payments', icon: 'fas fa-chart-bar', perm: 'CX_RPAY'},
          {title: 'submenu_skretting_purchase_order_detail_line', url: '/comex/reports/purchase_order_detail_line', icon: 'fas fa-chart-bar', perm: 'CX_RPODL'},
          {title: 'ImportCustomAgent', url: '/comex/reports/import-custom-agent', icon: 'fas fa-chart-bar', perm: 'CX_RICA'},
          {title: 'ImportCosts', url: '/comex/reports/import-expenses', icon: 'fas fa-chart-bar', perm: 'CX_RIEX'},
          {title: 'ProvisionVSReal', url: '/comex/reports/provision-real', icon: 'fas fa-chart-bar', perm: 'CX_RPVR'},
          {title: 'ArrivalByTypeProduct', url: '/comex/reports/Arrival-product-type', icon: 'fas fa-chart-bar', perm: 'CX_RATP'},
          {title: 'AssignedContainers', url: '/comex/reports/assigned-containers', icon: 'fas fa-chart-bar', perm: 'CX_RACO'},
          {title: 'StatesByDispatchs', url: '/comex/reports/states-by-dispatchs', icon: 'fas fa-chart-bar', perm: 'CX_RACO'},
        ];
  	}

  hasPermission(str:string){
    return this.auth.existPermission(str);
  }

}
