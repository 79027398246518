import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/main/global.service';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent extends BaseCrudComponent implements OnInit {
  constructor(
    public globalService: GlobalService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private _authService: AuthService,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Name','Description','DurationTime'];
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.getInitialData();
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_ST");
  }

  public getInitialData() {
    this.messageShowLoading();
    this.url = environment.Global.API_GET_ALL_ST;
    this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.rows = result.Object;
        this.rawdata = result.Object;
        if( Array.isArray(this.rows) ){
          this.pageTotal = this.rows.length;
        }
      }  else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
  }

  public createForm(object:any): boolean{
    let myForm:any = {};
    myForm = new FormGroup({
      'Name': new FormControl(object.Name, [Validators.required]),
      'Description': new FormControl(object.Description, [Validators.required]),
      'DurationTime': new FormControl(object.DurationTime, [Validators.required])
    });
    return myForm.valid;
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'sm', centered:true });
  }

  public createItem(modal, object) {
    this.isCreating = true;
    if (!this.createForm(object)) {
      this.isCreating = false;
      return this.errorFormMessage();
    }
    this.obj = {
      Name: object.Name,
      Description: object.Description,
      DurationTime: object.DurationTime,
      CreatorUser: this.user.name
    }
    this.url = environment.Global.API_CREATE_ST;
    this.srv.post(this.url, this.obj).toPromise().then( result => {
      if(result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();       
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isCreating = false;
      this.closeModal(modal);
    }, err => {
      this.messageDialog(err,'','error');
      this.isCreating = false;
    })
  }

  public clickOnCheck(event, id) {
    if ( event.target.checked ) {
      this.changeStatus('1', id);
    }else {
      this.changeStatus('2', id);
    }
  }

  public changeStatus(value, id) {
    this.url = environment.Global.API_UPDATE_STATUS_ST;
    this.obj = {
      StatusId: id,
      IsActive: value,  
      ModifierUser: this.user.name,      
      DeleteUser: this.user.name
    }
    this.srv.post(this.url,this.obj).toPromise().then(result => {
      if(result.Status) {
        this.messageDialog(result.Message, '', 'success');
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    }, err => {
      this.messageDialog(err,'','error');
    })
  }

  public openUpdateModal(modal, object) {
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'sm', centered:true });
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    if (!this.createForm(object)) {
      this.isUpdating = false;
      return this.errorFormMessage();
    } 
    this.obj = {
      StatusId: object.StatusId,
      Name: object.Name,
      Description: object.Description,
      DurationTime: object.DurationTime,
      ModifierUser: this.user.name
    }
    this.url = environment.Global.API_UPDATE_ST;
    this.srv.post(this.url, this.obj).toPromise().then(result => {
      if (result.Status) {
        this.messageDialog(result.Message, '', 'success');
        this.clearData();
        this.closeModal(modal);
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.isUpdating = false;
    }, err => {
      this.messageDialog(err,'','error');
      this.isUpdating = false;
    })
  }

}
