import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
  export class GlobalService {
    public userLogged : any  = null;
    constructor(private router : Router) {}

    formatOnlyDate(date: Date) {
        return date.getFullYear().toString() + '-' + (date.getMonth() <= 8 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
    }

    formatDate(date: Date) {
        return date.getFullYear().toString() + '-' + (date.getMonth() <= 8 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString())
        + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' +
        (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
        (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
        (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
    }

    containPermission(PermissionList: string[], permission: string) {
      let flag = false;
      PermissionList.forEach(element => {
        if (element == permission) {
          flag = true;
        }
      });
      return flag;
    }

    goToParameterDetail(url) {
      this.router.navigateByUrl(url);
    }
  }
