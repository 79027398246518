<ng-container *ngIf="(u_obj | json) != ({} | json)">
    <h4 class="second-color-text">{{'dispatch' | translate}}: {{u_obj['OrderNumber']}} - {{u_obj['OrderLine']}} -
        {{u_obj['OrderLineSuffix']}} {{'Status' | translate}}: {{u_obj['StatusName']}} </h4>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Detail' | translate}}</a>
            <ng-template ngbNavContent>
                <div>
                    <h5 class="second-color-text">
                        {{'Header' | translate}}
                    </h5>
                </div>
                <div class="row">
                    <div class="col-md-3 sk-ibox">
                        <label>{{'Category' | translate}}</label>
                        <!--                        <input type="text" class="form-control" name="TypeProduct" [(ngModel)]="u_obj['TypeProduct']" [disabled]="!VerifactePermissionUpdate('TypeProduct')">-->
                        <!-- <ng-select [(ngModel)]="u_obj['TypeProduct']" name="TypeProduct" [multiple]="false"
                            [disabled]="!VerifactePermissionUpdate('TypeProduct')">
                            <ng-option *ngFor="let option of TypeProduct" value="{{option}}">{{option}}</ng-option>
                        </ng-select> -->

                        <ng-select [(ngModel)]="u_obj['TypeProduct']" name="TypeProduct" [multiple]="false"
                            (change)="VerifyApprove('TypeProduct')"
                            [disabled]="!VerifactePermissionUpdate('TypeProduct')">
                            <ng-option *ngFor="let option of TypeProduct" value="{{option.id}}">
                                {{option.nombre}}</ng-option>

                        </ng-select>




                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'ResponsableSK' | translate}}</label>
                        <input type="text" class="form-control" name="ResponsableSK" [(ngModel)]="u_obj['UserName']"
                            disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'Agent' | translate}}</label>
                        <input type="text" class="form-control" name="ResponsableAgent" [(ngModel)]="u_obj['AgentName']"
                            disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'ResponsableAgent' | translate}}</label>
                        <input type="text" class="form-control" name="UserAgentName"
                            [(ngModel)]="u_obj['UserAgentName']" disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 sk-ibox">
                        <label>{{'ProcedureImportline' | translate}}</label>
                        <input type="text" class="form-control" name="ProcedureImportline"
                            (change)="VerifyApprove('ProcedureImportline')" [(ngModel)]="u_obj['ProcedureImportline']"
                            [disabled]="!VerifactePermissionUpdate('ProcedureImportline')">
                        <!-- [disabled]="!VerifactePermissionUpdateJL('ProcedureImportline')"> -->
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'IsdException' | translate}}</label>
                        <ng-select [(ngModel)]="u_obj['IsdException']" name="IsdException" [multiple]="false"
                            [disabled]="!VerifactePermissionUpdate('IsdException')">
                            <ng-option *ngFor="let option of optionsISD" value="{{option}}">{{option}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'DealNumber' | translate}}</label>
                        <!--Se cambio este nombre por orden de compras ya que no representa el valor actual del contrato-->
                        <input type="text" class="form-control" name="DealNumber" [(ngModel)]="u_obj['DealNumber']"
                            [disabled]="!VerifactePermissionUpdate('DealNumber')">
                    </div>
                    <div class="col-md-2 sk-ibox">
                        <label>{{'QuantityContract' | translate}}</label>
                        <input type="text" class="form-control" name="GrossWeightOrder" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                            [(ngModel)]="u_obj['GrossWeightOrder']" disabled>
                    </div>
                    <div class="col-md-1 sk-ibox">
                        <label>{{'Measure' | translate}}</label>
                        <input type="text" class="form-control" name="MeasureUnite" [(ngModel)]="u_obj['MeasureUnite']"
                            disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 sk-ibox">
                        <label>{{'OrderNumber' | translate}}</label>
                        <input type="text" class="form-control" name="OrderNumber" [(ngModel)]="u_obj['OrderNumber']"
                            disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'SupplierCode' | translate}}</label>
                        <input type="text" class="form-control" name="Supplier" [(ngModel)]="u_obj['Supplier']"
                            disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'Supplier' | translate}}</label>
                        <input type="text" class="form-control" name="CompanyName" [(ngModel)]="u_obj['CompanyName']"
                            disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'ItemNumber' | translate}}</label>
                        <input type="text" class="form-control" name="ItemNumber" [(ngModel)]="u_obj['ItemNumber']"
                            disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 sk-ibox">
                        <label>{{'ItemName' | translate}}</label>
                        <input type="text" class="form-control" name="ItemName" [(ngModel)]="u_obj['ItemName']"
                            disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'SupplierContract' | translate}}</label>
                        <input type="text" class="form-control" name="SupplierContract"
                            [(ngModel)]="u_obj['SupplierContract']" disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <label>{{'IncoTerm' | translate}}</label>
                        <input type="text" class="form-control" name="IncoTerm" [(ngModel)]="u_obj['IncoTerm']"
                            disabled>
                        <!--"[disabled]=!VerifactePermissionUpdate('IncoTerm')"-->
                    </div>
                    <div class="col-md-3 sk-ibox">
                        <!-- <label>{{'PackagingNumber' | translate}}</label> -->
                        <label>{{'Packaging' | translate}}</label>

                        <!-- <select [(ngModel)]="u_obj['PackagingNumber2']" class="custom-select custom-select-md"
                            name="Packaging2" [multiple]="false"
                            [disabled]="!VerifactePermissionUpdate('PackagingNumber2')">
                            <option *ngFor="let type of pack" value="{{type.PackagingNumber}}">{{type.Description}}
                            </option>
                        </select> -->


                        <ng-select [(ngModel)]="u_obj['PackagingNumber2']" name="Packaging2" [multiple]="false"
                            [disabled]="!VerifactePermissionUpdate('PackagingNumber2')">
                            <ng-option *ngFor="let type of pack" value="{{type.PackagingNumber}}">{{type.Description}}
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="col-md-12 sk-ibox">
                        <label>{{'RemarkUpdate' | translate}}</label>
                        <input type="text" class="form-control" name="RemarkUpdate" [(ngModel)]="u_obj['RemarkUpdate']"
                            [(disabled)]="!this.RemarkUpdate" (input)="checkInputLength()">
                    </div>

                </div>

                <hr>
                <h4 class="second-color-text">{{'Status' | translate}}</h4>
                <div class="row">
                    <div class="col-md-12">
                        <ul ngbNav #navStatus="ngbNav" [(activeId)]="activeStatus" class="nav-tabs">
                            <!-- NOTIFICAR AL PROVEEDOR -->
                            <li [ngbNavItem]="statusList[0].StatusId">
                                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{statusList[0].Description}}</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'QuantityRequired' | translate}}</label>
                                            <input type="text" class="form-control GrossWeight" name="GrossWeight" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['GrossWeight']" disabled>
                                        </div>
                                        <!-- <div class="col-md-3 sk-ibox">
                                            <label>{{'GrossWeightDispatched' | translate}}</label>
                                            <input  min="0" value="0" step=".01" placeholder="200,000.00" class="form-control" name="GrossWeightDispatched"
                                            [(ngModel)]="u_obj['GrossWeightDispatched'] " mask="separator.2" [thousandSeparator]="','" (change)="VerifyApprove('GrossWeightDispatched')" (blur)="setDifferenceWeight()" [disabled]="!VerifactePermissionUpdate('GrossWeightDispatched')">
                                        </div> -->
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Difference' | translate}}</label>
                                            <input type="text" class="form-control" name="GrossWeightDifference" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['GrossWeightDifference']" disabled>
                                        </div>
                                        <div class="col-md-2 sk-ibox">
                                            <label>{{'PurchasePrice' | translate}}</label>
                                            <input type="text" class="form-control" name="PurchasePrice" mask="separator.4" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['PurchasePrice'] " disabled>
                                        </div>
                                        <div class="col-md-1 sk-ibox">
                                            <label>{{'Currency' | translate}}</label>
                                            <input type="text" class="form-control" name="CurrencyUnite"
                                                [(ngModel)]="u_obj['CurrencyUnite']" disabled>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PlantPrice' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="PlantPrice" [(ngModel)]="u_obj['PlantPrice']" mask="separator.4" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                (change)="VerifyApprove('PlantPrice')"
                                                [disabled]="!VerifactePermissionUpdate('PlantPrice')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'RateExchange' | translate}}</label>
                                            <input type="texr" class="form-control" name="RateExchange"
                                                [(ngModel)]="u_obj['RateExchange']" (blur)="setFIProvisioned()" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('RateExchange')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'RateExchangeDate' | translate}}</label>
                                            <input type="date" class="form-control" name="RateExchangeDate"
                                                [(ngModel)]="u_obj['RateExchangeDate']"
                                                [disabled]="!VerifactePermissionUpdate('RateExchangeDate')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'ProvisionedFI' | translate}}</label>
                                            <input type="text" class="form-control" name="ProvisionedFI"
                                                [(ngModel)]="u_obj['ProvisionedFI']" mask="percent.2" suffix="%" disabled>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtdInit' | translate}}</label>
                                            <input type="date" class="form-control" name="EtdInit"
                                                [(ngModel)]="u_obj['EtdInit']"
                                                [disabled]="!VerifactePermissionUpdate('EtdInit')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtdEnd' | translate}}</label>
                                            <input type="date" class="form-control" name="EtdEnd"
                                                [(ngModel)]="u_obj['EtdEnd']"
                                                [disabled]="!VerifactePermissionUpdate('EtdEnd')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtdRequired' | translate}}</label>
                                            <input type="date" class="form-control" name="EtdRequired"
                                                (blur)="setDifferenceDaysTT()" [(ngModel)]="u_obj['EtdRequired']"
                                                [disabled]="!VerifactePermissionUpdate('EtdRequired')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtaRequired' | translate}}</label>
                                            <input type="date" class="form-control" name="EtaRequired"
                                                (blur)="setDifferenceDaysTT()" [(ngModel)]="u_obj['EtaRequired']"
                                                [disabled]="!VerifactePermissionUpdate('EtaRequired')">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'DifferenceDaysTT' | translate}}</label>
                                            <input type="number" class="form-control" name="DifferenceDaysTT"
                                                [(ngModel)]="u_obj['DifferenceDaysTT']" disabled>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'DaysFreeNegotiated' | translate}}</label>
                                            <input type="number" class="form-control" name="DaysFreeNegotiated"
                                                (change)="VerifyApprove('DaysFreeNegotiated')"
                                                [(ngModel)]="u_obj['DaysFreeNegotiated']"
                                                [disabled]="!VerifactePermissionUpdate('DaysFreeNegotiated')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Origen' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['Origen']" name="Origen" [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('Origen')">
                                                <ng-option *ngFor="let origen of origenList" value="{{origen.code}}">
                                                    {{origen.name_en}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Packaging' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['PackagingNumber']" name="Packaging"
                                                [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('PackagingNumber')">
                                                <ng-option *ngFor="let type of pack" value="{{type.PackagingNumber}}">
                                                    {{type.Description}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PaymentTerm' | translate}}</label>
                                            <input type="text" class="form-control" name="PaymentTerm"
                                                [(ngModel)]="u_obj['PaymentTerm']"
                                                [disabled]="!VerifactePermissionUpdate('PaymentTerm')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Permission' | translate}}</label>
                                            <input type="text" class="form-control" name="Permission"
                                                (change)="VerifyApprove('Permission')" [(ngModel)]="u_obj['Permission']"
                                                [disabled]="!VerifactePermissionUpdate('Permission')">
                                        </div>
                                    </div>

                                    <hr>
                                    <h5 *ngIf="u_obj.DocumentsN.length > 0" class="second-color-text">{{'Documents' |
                                        translate}} ({{statusList[0].Description}})</h5>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-color">
                                                        <tr>
                                                            <!-- <th class="sh">
                                                                visible
                                                            </th> -->
                                                            <th class="sh">
                                                                Id
                                                            </th>
                                                            <th class="sh">
                                                                {{'Document' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Description' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Observation' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'UploadFile' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'RequireApproval' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Status' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'' | translate}}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let document of u_obj.DocumentsN; index as i">

                                                            <!-- <td
                                                                >
                                                                <label for="code">{{ document.ItemCode.ItemNumber ==
                                                                    u_obj['ItemNumber']}}</label>
                                                                == u_obj['ItemNumber'] -->
                                                            <!-- ngIf="document.ItemId != null && -->
                                                            <!-- </td>  -->
                                                            <td>
                                                                <label for="code">{{i + 1}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="DocumentName">{{document.DocumentName}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Description">{{document.Description}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Observation">{{document.Observation}}</label>
                                                            </td>
                                                            <td>
                                                                <input type="file" class="form-control" name="File"
                                                                    (change)="AddFile($event, document.DocumentId)"
                                                                    [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'">
                                                                <small>{{'MaximumSize' | translate}} 3MB</small>
                                                            </td>
                                                            <td>
                                                                <label for="RequireApproval">{{document.RequireApproval
                                                                    == 0 ? "No" : "Si"}}</label>
                                                            </td>
                                                            <td>
                                                                <label for="StatusDocument">
                                                                    {{document.DocumentUpload == null ? "Archivo no
                                                                    cargado" : document.DocumentUpload.Status}}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="DownloadFile(document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-cloud-download text-info m-r-10"
                                                                    title="{{'DownloadFile' | translate}}"></i>
                                                                <!-- *ngIf="(document.DocumentUpload == NULL)" -->
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="openUpdateModalView(view, document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-eye text-info m-r-10"
                                                                    title="{{'Visualize' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    style="cursor:pointer;"
                                                                    class="far fa-edit text-info m-r-10"
                                                                    (click)="openUpdateModal(update1,document)"
                                                                    title="{{'UpdateFile' | translate}}"></i>
                                                                <!-- *ngIf="row.StatusFile == 'RC' || row.StatusFile == 'RV'"" -->

                                                            </td>



                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="u_obj.DocumentsN.length > 0" class="row"> -->
                                    <div class="row">
                                        <!-- <div class="col-md-2 sk-ibox">
                                            <button [ladda]="isUploadFile" type="button" (click)="SaveFiles(1)"
                                                class="form-control btn btn-custom-C8102E">{{'SaveFiles' |
                                                translate}}</button>
                                        </div> -->
                                        <div class="col-md-2 sk-ibox">
                                            <button [ladda]="isUpdating" type="button" (click)="UpdateData(u_obj)"
                                                class="form-control btn btn-custom-C8102E">{{'Update' |
                                                translate}}</button>
                                        </div>
                                    </div>

                                </ng-template>
                            </li>

                            <!-- EMBARCADO -->
                            <li [ngbNavItem]="statusList[1].StatusId">
                                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{statusList[1].Description}}</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'GrossWeightDispatched' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="GrossWeightDispatched"
                                                [(ngModel)]="u_obj['GrossWeightDispatched']" mask="separator.2"  [thousandSeparator]="','" [decimalMarker]="'.'"
                                                (blur)="setDifferenceWeight()"
                                                [disabled]="!VerifactePermissionUpdate('GrossWeightDispatched')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Difference' | translate}}</label>
                                            <input type="text" class="form-control" name="GrossWeightDifference" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['GrossWeightDifference']" disabled>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PlantPrice' | translate}}</label>
                                            <input type="text" class="form-control" name="PlantPrice"
                                                [(ngModel)]="u_obj['PlantPrice']" mask="separator.4" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('PlantPrice')">
                                        </div>
                                        <div class="col-md-2 sk-ibox">
                                            <label>{{'PurchasePrice' | translate}}</label>
                                            <input type="number" class="form-control" name="PurchasePrice" mask="separator.4" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['PurchasePrice'] " disabled>
                                        </div>
                                        <div class="col-md-1 sk-ibox">
                                            <label>{{'Currency' | translate}}</label>
                                            <input type="text" class="form-control" name="CurrencyUnite"
                                                [(ngModel)]="u_obj['CurrencyUnite']" disabled>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtdReal' | translate}}</label>
                                            <input type="date" class="form-control" (blur)="setDifferenceDaysTTReal()"
                                                (change)="VerifyApprove('EtdReal')" name="EtdReal"
                                                [(ngModel)]="u_obj['EtdReal']"
                                                [disabled]="!VerifactePermissionUpdate('EtdReal')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtaReal' | translate}}</label>
                                            <input type="date" class="form-control" (blur)="setDifferenceDaysTTReal()"
                                                (change)="VerifyApprove('EtaReal')" name="EtaReal"
                                                [(ngModel)]="u_obj['EtaReal']"
                                                [disabled]="!VerifactePermissionUpdate('EtaReal')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'DifferenceDaysTTReal' | translate}}</label>
                                            <input type="number" class="form-control" name="DifferenceDaysTTReal"
                                                [(ngModel)]="u_obj['DifferenceDaysTTReal']" disabled>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'CountryShipment' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['Country']" name="Country" [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('CountryShipment')">
                                                <ng-option *ngFor="let Country of countryList" value="{{Country.code}}">
                                                    {{Country.name_en}}</ng-option>
                                            </ng-select>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PortShipment' | translate}}</label>
                                            <input type="text" class="form-control" name="PortShipment"
                                                (change)="VerifyApprove('PortShipment')"
                                                [(ngModel)]="u_obj['PortShipment']"
                                                [disabled]="!VerifactePermissionUpdate('PortShipment')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Bl' | translate}}</label>
                                            <input type="text" class="form-control" name="Bl"
                                                (change)="VerifyApprove('Bl')" [(ngModel)]="u_obj['Bl']"
                                                [disabled]="!VerifactePermissionUpdate('Bl')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Shipping' | translate}}</label>
                                            <!-- <input type="text" class="form-control" name="Shipping"
                                                (change)="VerifyApprove('Shipping')" [(ngModel)]="u_obj['Shipping']"
                                                [disabled]="!VerifactePermissionUpdate('Shipping')"> -->


                                            <ng-select [(ngModel)]="u_obj['Shipping']" name="Shipping"
                                                [multiple]="false" (change)="VerifyApprove('Shipping')"
                                                [disabled]="!VerifactePermissionUpdate('Shipping')">
                                                <ng-option *ngFor="let option of Shipping" value="{{option.id}}">
                                                    {{option.nombre}}</ng-option>

                                            </ng-select>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Containers' | translate}}</label>
                                            <input type="number" class="form-control" name="Containers"
                                                (change)="VerifyApprove('Containers')" [(ngModel)]="u_obj['Containers']"
                                                [disabled]="!(!VerifactePermissionUpdate('Containers') || editContainer)">
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'TypeCNT' | translate}}</label>
                                            <!--                                            <input type="text" class="form-control" name="TypeCNT" (change)="VerifyApprove('TypeCNT')"  [(ngModel)]="u_obj['TypeCNT']" [disabled]="!VerifactePermissionUpdate('TypeCNT')">-->
                                            <ng-select [(ngModel)]="u_obj['TypeCNT']" name="TypeCNT" [multiple]="false"
                                                (change)="VerifyApprove('TypeCNT')"
                                                [disabled]="!VerifactePermissionUpdate('TypeCNT')">
                                                <ng-option *ngFor="let option of TypeContainer" value="{{option}}">
                                                    {{option}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Packaging' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['PackagingNumber']" name="Packaging"
                                                [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('PackagingNumber')">
                                                <ng-option *ngFor="let type of pack_E" value="{{type.PackagingNumber}}">
                                                    {{type.Description}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Policy' | translate}}</label>
                                            <input type="text" class="form-control" name="Policy"
                                                [(ngModel)]="u_obj['Policy']"
                                                [disabled]="!VerifactePermissionUpdate('Policy')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'ShipmentAlert' | translate}}</label>
                                            <input type="number" class="form-control" name="ShipmentAlert"
                                                [(ngModel)]="u_obj['ShipmentAlert']" disabled>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Invoice' | translate}}</label>
                                            <input type="text" class="form-control" name="Invoice"
                                                (change)="VerifyApprove('Invoice')" [(ngModel)]="u_obj['Invoice']"
                                                [disabled]="!VerifactePermissionUpdate('Invoice')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'InvoiceDate' | translate}}</label>
                                            <input type="date" class="form-control" name="InvoiceDate"
                                                (change)="VerifyApprove('InvoiceDate')"
                                                [(ngModel)]="u_obj['InvoiceDate']"
                                                [disabled]="!VerifactePermissionUpdate('InvoiceDate')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'AmountUE' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                class="form-control" (change)="VerifyApprove('AmountUE')" disabled="true"
                                                name="AmountUE" [(ngModel)]="u_obj['AmountUE']">
                                                <!-- [disabled]="!VerifactePermissionUpdate('AmountUE')"> -->
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'CountryOrigin' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['CountryOrigin']" name="CountryOrigin" [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('CountryOrigin')">
                                                <ng-option *ngFor="let Country of countryList" value="{{Country.code}}">
                                                    {{Country.name_en}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-md-2 sk-ibox" *ngIf="validateStatusUnite()">

                                            <button [ladda]="isUpdating" type="button" (click)="UpdateDataUnite(u_obj)"
                                                [disabled]="!VerifacteUnite()"
                                                class="form-control btn btn-custom-C8102E">{{'UpdateUnite' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                    <hr>
                                    <h5 *ngIf="u_obj.DocumentsE.length > 0" class="second-color-text">{{'Documents' |
                                        translate}} ({{statusList[1].Description}})</h5>
                                    <div *ngIf="u_obj.DocumentsE.length > 0" class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-color">
                                                        <tr>
                                                            <th class="sh">
                                                                Id
                                                            </th>
                                                            <th class="sh">
                                                                {{'Document' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Description' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Observation' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'UploadFile' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'RequireApproval' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Status' | translate}}
                                                            </th>
                                                            <th class="sh">

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let document of u_obj.DocumentsE; index as i">
                                                            <td>
                                                                <label for="code">{{i + 1}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="DocumentName">{{document.DocumentName}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Description">{{document.Description}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Observation">{{document.Observation}}</label>
                                                            </td>
                                                            <td>
                                                                <input type="file" class="form-control" name="file"
                                                                    (change)="AddFile($event, document.DocumentId)"
                                                                    [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'">
                                                            </td>
                                                            <td>
                                                                <label for="RequireApproval">{{document.RequireApproval
                                                                    == 0 ? "No" : "Si"}}</label>
                                                            </td>
                                                            <td>
                                                                <label for="StatusDocument">
                                                                    {{document.DocumentUpload == null ? "Archivo no
                                                                    cargado" : document.DocumentUpload.Status}}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="DownloadFile(document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-cloud-download text-info m-r-10"
                                                                    title="{{'DownloadFile' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="openUpdateModalView(view, document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-eye text-info m-r-10"
                                                                    title="{{'Visualize' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    style="cursor:pointer;"
                                                                    class="far fa-edit text-info m-r-10"
                                                                    (click)="openUpdateModal(update1,document)"
                                                                    title="{{'UpdateFile' | translate}}"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-md-2 sk-ibox">
                                            <button type="button" (click)="SaveFiles(1)"
                                                class="form-control btn btn-custom-C8102E">{{'SaveFiles' |
                                                translate}}</button>
                                        </div> -->
                                        <div class="col-md-2 sk-ibox">
                                            <button [ladda]="isUpdating" type="button" (click)="UpdateData(u_obj)"
                                                class="form-control btn btn-custom-C8102E">{{'Update' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <!-- TRAMITE DE ADUANA -->
                            <li [ngbNavItem]="statusList[2].StatusId">
                                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{statusList[2].Description}}</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'CAS' | translate}}</label>
                                            <input type="date" class="form-control" (blur)="setDaysFree()"
                                                (change)="VerifyApprove('CAS')" name="CAS" [(ngModel)]="u_obj['CAS']"
                                                [disabled]="!VerifactePermissionUpdate('CAS')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'DaysFree' | translate}}</label>
                                            <input type="number" class="form-control" name="DaysFree"
                                                [(ngModel)]="u_obj['DaysFree']" disabled>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'AuthorizedDepartureDate' | translate}}</label>
                                            <input type="date" class="form-control" (blur)="setEtaDepartureAuthorized()"
                                                (change)="VerifyApprove('AuthorizedDepartureDate')"
                                                name="AuthorizedDepartureDate"
                                                [(ngModel)]="u_obj['AuthorizedDepartureDate']"
                                                [disabled]="!VerifactePermissionUpdate('AuthorizedDepartureDate')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EtaDepartureAuthorized' | translate}}</label>
                                            <input type="number" class="form-control" name="EtaDepartureAuthorized"
                                                [(ngModel)]="u_obj['EtaDepartureAuthorized']" disabled>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'TransportCompany' | translate}}</label>
                                            <input type="text" class="form-control" name="TransportName"
                                                [(ngModel)]="u_obj['TransportName']" disabled>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'WarehouseName' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['WarehouseNumber']" name="WarehouseNumber"
                                                (change)="VerifyApprove('WarehouseNumber')" [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('WarehouseNumber')">
                                                <ng-option *ngFor="let type of wareHousesPort" value="{{type}}">{{type}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'CustomsAclarance' | translate}}</label>
                                            <input type="number" class="form-control" name="CustomsAclarance"
                                                (change)="VerifyApprove('CustomsAclarance')"
                                                [(ngModel)]="u_obj['CustomsAclarance']"
                                                [disabled]="!VerifactePermissionUpdate('CustomsAclarance')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'DAI' | translate}}</label>
                                            <input type="number" class="form-control" name="DAI" SoloNumerosEnteros [maxLength]="17"
                                                (change)="VerifyApprove('DAI')" [(ngModel)]="u_obj['DAI']"
                                                [disabled]="!VerifactePermissionUpdate('DAI')">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'EmissionDate' | translate}}</label>
                                            <input type="date" class="form-control" name="EmissionDate"
                                                (change)="VerifyApprove('EmissionDate')"
                                                [(ngModel)]="u_obj['EmissionDate']"
                                                [disabled]="!VerifactePermissionUpdate('EmissionDate')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PaymentDateGuaranted' | translate}}</label>
                                            <input type="date" class="form-control" name="PaymentDateGuaranted"
                                                (change)="VerifyApprove('PaymentDateGuaranted')"
                                                [(ngModel)]="u_obj['PaymentDateGuaranted']"
                                                [disabled]="!VerifactePermissionUpdate('PaymentDateGuaranted')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PaymentDateEfectiveLq' | translate}}</label>
                                            <input type="date" class="form-control" name="PaymentDateEfectiveLq"
                                                (change)="VerifyApprove('PaymentDateEfectiveLq')"
                                                [(ngModel)]="u_obj['PaymentDateEfectiveLq']"
                                                [disabled]="!VerifactePermissionUpdate('PaymentDateEfectiveLq')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'QuantityLiquidation' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="QuantityLiquidation" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                (change)="VerifyApprove('QuantityLiquidation')"
                                                [(ngModel)]="u_obj['QuantityLiquidation']"
                                                [disabled]="!VerifactePermissionUpdate('QuantityLiquidation')">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2 sk-ibox">

                                            <label>{{'Tariff' | translate}} Advalorem</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="Tariff" (change)="VerifyApprove('Tariff')"
                                                [(ngModel)]="u_obj['Tariff']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('Tariff')">


                                        </div>
                                        <div class="col-md-1 sk-ibox">
                                            <label>{{'TariffPercent' | translate}}</label>
                                            <input type="text" min="0" class="form-control"
                                                name="TariffPercent" (change)="VerifyApprove('TariffPercent')" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['TariffPercent']"
                                                [disabled]="!VerifactePermissionUpdate('TariffPercent')">
                                        </div>

                                        <div class="col-md-2 sk-ibox">
                                            <label>{{'SpecificTariff' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="SpecificTariff" (change)="VerifyApprove('SpecificTariff')"
                                                [(ngModel)]="u_obj['SpecificTariff']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('SpecificTariff')">
                                        </div>
                                        <div class="col-md-1 sk-ibox">
                                            <label>{{'SpecificTariffPercent' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="TariffPercent" (change)="VerifyApprove('SpecificTariffPercent')"
                                                [(ngModel)]="u_obj['SpecificTariffPercent']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('SpecificTariffPercent')">
                                        </div>

                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Iva' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".001" class="form-control"
                                                name="Iva" (change)="VerifyApprove('Iva')" [(ngModel)]="u_obj['Iva']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('Iva')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Fodinfa' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" class="form-control"
                                                name="Fodinfa" (change)="VerifyApprove('Fodinfa')" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['Fodinfa']"
                                                [disabled]="!VerifactePermissionUpdate('Fodinfa')">
                                        </div>
                                    </div>
                                    <div class="row">


                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'TariffHeading' | translate}}</label>
                                            <input type="text" class="form-control" name="TariffHeading"
                                                (change)="VerifyApprove('TariffHeading')"
                                                [(ngModel)]="u_obj['TariffHeading']"
                                                [disabled]="!VerifactePermissionUpdate('TariffHeading')">
                                        </div>

                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'CodeAdv' | translate}}</label>
                                            <!-- <ng-select [(ngModel)]="u_obj['CodeAdv']" name="CodeAdv"
                                                (change)="VerifyApprove('CodeAdv')" [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('CodeAdv')">
                                                <ng-option *ngFor="let type of releaseADV" value="{{type.Name}}">
                                                    {{type.Name}}</ng-option>
                                            </ng-select> -->
                                            <input type="number" class="form-control" name="CodeAdv"  SoloNumerosEnteros
                                                (change)="VerifyApprove('CodeAdv')" [(ngModel)]="u_obj['CodeAdv']">
                                            <!-- [disabled]="!VerifactePermissionUpdate('TariffHeading')"> -->
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'CodeIva' | translate}}</label>
                                            <!-- <ng-select [(ngModel)]="u_obj['CodeIva']" name="CodeIva"
                                                (change)="VerifyApprove('CodeIva')" [multiple]="false"
                                                [disabled]="!VerifactePermissionUpdate('CodeIva')">
                                                <ng-option *ngFor="let type of releaseIva" value="{{type.Name}}">
                                                    {{type.Name}}</ng-option>
                                            </ng-select> -->
                                            <input type="number" class="form-control" name="CodeIva"  SoloNumerosEnteros
                                                (change)="VerifyApprove('CodeIva')" [(ngModel)]="u_obj['CodeIva']">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Antidumping' | translate}}</label>
                                            <input type="text" class="form-control" name="Antidumping"
                                                [(ngModel)]="u_obj['Antidumping']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('Antidumping')">
                                        </div>

                                    </div>
                                    <div class="row">


                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'AdvaloremICE' | translate}}</label>
                                            <input type="text" class="form-control" name="ICEAdvalorem"
                                                [(ngModel)]="u_obj['ICEAdvalorem']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('ICEAdvalorem')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'SpecificICE' | translate}}</label>
                                            <input type="text" class="form-control" name="ICESpecific"
                                                [(ngModel)]="u_obj['ICESpecific']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('ICESpecific')">

                                        </div>

                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Safeguard' | translate}}</label>
                                            <input type="text" class="form-control" name="Safeguard"
                                                [(ngModel)]="u_obj['Safeguard']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('Safeguard')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'SpecificSafeguard' | translate}}</label>
                                            <input type="text" class="form-control" name="SafeguardSpecific"
                                                [(ngModel)]="u_obj['SafeguardSpecific']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('SafeguardSpecific')">
                                        </div>
                                    </div>
                                    <!-- impuestos valor del tag montoAPagar en el json-->
                                    <div class="row">


                                        <div class="col-md-3 sk-ibox">
                                            <label
                                                title="Impuesto Redimible a las Botellas Plásticas No Retornables">{{'RedeemableTax'
                                                | translate}}</label>
                                            <input type="text" class="form-control" name="RedeemableTax"
                                                [(ngModel)]="u_obj['RedeemableTax']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('RedeemableTax')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label title="">TPN {{'Reference' | translate}}</label>
                                            <input type="string" class="form-control" name="TPNReference"
                                                [(ngModel)]="u_obj['TPNReference']" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [disabled]="!VerifactePermissionUpdate('TPNReference')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Delay' | translate}}</label>
                                            <ng-select [(ngModel)]="u_obj['Delay']" name="Delay"
                                                (change)="VerifyApprove('Delay')" [multiple]="false">
                                                <ng-option *ngFor="let option of optionsISD" value="{{option}}">
                                                    {{option}}</ng-option>
                                            </ng-select>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Responsable' | translate}}</label>
                                            <textarea type="text" class="form-control" name="Responsible" SoloLetras
                                                (change)="VerifyApprove('Responsible')"
                                                [(ngModel)]="u_obj['Responsible']"
                                                [disabled]="!VerifactePermissionUpdate('Responsible')"></textarea>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Observation' | translate}}</label>
                                            <textarea type="text" class="form-control" name="Commentary"
                                                (change)="VerifyApprove('Commentary')" [(ngModel)]="u_obj['Commentary']"
                                                [disabled]="!VerifactePermissionUpdate('Commentary')"></textarea>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'Capacity' | translate}}</label>
                                            <textarea type="text" class="form-control" name="Capacity" SoloLetras
                                                (change)="VerifyApprove('Capacity')" [(ngModel)]="u_obj['Capacity']"
                                                [disabled]="!VerifactePermissionUpdate('Capacity')"></textarea>
                                        </div>


                                    </div>

                                    <hr>
                                    <h5 *ngIf="u_obj.DocumentsT.length > 0" class="second-color-text">{{'Documents' |
                                        translate}} ({{statusList[2].Description}})</h5>
                                    <div *ngIf="u_obj.DocumentsT.length > 0" class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-color">
                                                        <tr>
                                                            <th class="sh">
                                                                Id
                                                            </th>
                                                            <th class="sh">
                                                                {{'Document' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Description' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Observation' | translate}}
                                                            </th>
                                                            <th class="sh">***
                                                                {{'UploadFile' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'RequireApproval' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Status' | translate}}
                                                            </th>
                                                            <th>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let document of u_obj.DocumentsT; index as i">
                                                            <td>
                                                                <label for="code">{{i + 1}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="DocumentName">{{document.DocumentName}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Description">{{document.Description}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Observation">{{document.Observation}}</label>
                                                            </td>
                                                            <td>
                                                                <input type="file" class="form-control" name="file"
                                                                    (change)="AddFile($event, document.DocumentId)"
                                                                    [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'">
                                                            </td>
                                                            <td>
                                                                <label for="RequireApproval">{{document.RequireApproval
                                                                    == 0 ? "No" : "Si"}}</label>
                                                            </td>
                                                            <td>
                                                                <label for="StatusDocument">
                                                                    {{document.DocumentUpload == null ? "Archivo no
                                                                    cargado" : document.DocumentUpload.Status}}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="DownloadFile(document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-cloud-download text-info m-r-10"
                                                                    title="{{'DownloadFile' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="openUpdateModalView(view, document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-eye text-info m-r-10"
                                                                    title="{{'Visualize' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    style="cursor:pointer;"
                                                                    class="far fa-edit text-info m-r-10"
                                                                    (click)="openUpdateModal(update1,document)"
                                                                    title="{{'UpdateFile' | translate}}"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-md-2 sk-ibox">
                                            button type="button" (click)="SaveFiles(1)"
                                                class="form-control btn btn-custom-C8102E">{{'SaveFiles' |
                                                translate}}</button>

                                        </div> -->
                                        <div class="col-md-2 sk-ibox">
                                            <button [ladda]="isUpdating" type="button" (click)="UpdateData(u_obj)"
                                                class="form-control btn btn-custom-C8102E">{{'Update' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <!-- PLANIFICADA -->
                            <li [ngbNavItem]="statusList[3].StatusId">
                                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{statusList[3].Description}}</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PlantArrival' | translate}}</label>
                                            <input type="date" class="form-control" (blur)="setPortPlantTT()"
                                                (change)="VerifyApprove('PlantArrival')" name="PlantArrival"
                                                [(ngModel)]="u_obj['PlantArrival']"
                                                [disabled]="!VerifactePermissionUpdate('PlantArrival')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PortPlantTT' | translate}}</label>
                                            <input type="number" class="form-control" name="PortPlantTT"
                                                [(ngModel)]="u_obj['PortPlantTT']" disabled>
                                        </div>
                                    </div>
                                    <hr>
                                    <h5 *ngIf="u_obj.DocumentsP.length > 0" class="second-color-text">{{'Documents' |
                                        translate}} ({{statusList[3].Description}})</h5>
                                    <div *ngIf="u_obj.DocumentsP.length > 0" class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-color">
                                                        <tr>
                                                            <th class="sh">
                                                                Id
                                                            </th>
                                                            <th class="sh">
                                                                {{'Document' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Description' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Observation' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'UploadFile' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'RequireApproval' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Status' | translate}}
                                                            </th>
                                                            <th>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let document of u_obj.DocumentsP; index as i">
                                                            <td>
                                                                <label for="code">{{i + 1}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="DocumentName">{{document.DocumentName}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Description">{{document.Description}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Observation">{{document.Observation}}</label>
                                                            </td>
                                                            <td>
                                                                <input type="file" class="form-control" name="file"
                                                                    (change)="AddFile($event, document.DocumentId)"
                                                                    [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'">
                                                            </td>
                                                            <td>
                                                                <label for="RequireApproval">{{document.RequireApproval
                                                                    == 0 ? "No" : "Si"}}</label>
                                                            </td>
                                                            <td>
                                                                <label for="StatusDocument">
                                                                    {{document.DocumentUpload == null ? "Archivo no
                                                                    cargado" : document.DocumentUpload.Status}}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="DownloadFile(document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-cloud-download text-info m-r-10"
                                                                    title="{{'DownloadFile' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="openUpdateModalView(view, document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-eye text-info m-r-10"
                                                                    title="{{'Visualize' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    style="cursor:pointer;"
                                                                    class="far fa-edit text-info m-r-10"
                                                                    (click)="openUpdateModal(update1,document)"
                                                                    title="{{'UpdateFile' | translate}}"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="u_obj.DocumentsP.length > 0">
                                        <!-- <div class="col-md-2 sk-ibox">
                                            <button type="button" (click)="SaveFiles(1)"
                                                class="form-control btn btn-custom-C8102E">{{'SaveFiles' |
                                                translate}}</button>
                                        </div> -->
                                        <div class="col-md-2 sk-ibox">
                                            <button [ladda]="isUpdating" type="button" (click)="UpdateData(u_obj)"
                                                class="form-control btn btn-custom-C8102E">{{'Update' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                            <!-- TRAMITE FINALIZADO -->
                            <li [ngbNavItem]="statusList[4].StatusId">
                                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{statusList[4].Description}}</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'PlantPriceReal' | translate}}</label>
                                            <input type="text" min="0" value="0" step=".01" (blur)="setRealFI()"
                                                class="form-control" name="PlantPriceReal" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                                                [(ngModel)]="u_obj['PlantPriceReal']"
                                                [disabled]="!VerifactePermissionUpdate('PlantPriceReal')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'LiftDateSenae' | translate}}</label>
                                            <input type="date" class="form-control" name="SenaeDate"
                                                (change)="VerifyApprove('SenaeDate')" [(ngModel)]="u_obj['SenaeDate']"
                                                [disabled]="!VerifactePermissionUpdate('SenaeDate')">
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'RealFI' | translate}}</label>
                                            <input type="number" min="0" value="0" step=".01" class="form-control"
                                                name="RealFI" [(ngModel)]="u_obj['RealFI']" disabled>
                                        </div>
                                        <div class="col-md-3 sk-ibox">
                                            <label>{{'DifferenceFI' | translate}}</label>
                                            <input type="number" min="0" value="0" step=".01" class="form-control"
                                                name="DifferenceFI" [(ngModel)]="u_obj['DifferenceFI']" disabled>
                                        </div>
                                    </div>
                                    <hr>
                                    <h5 *ngIf="u_obj.DocumentsF.length > 0" class="second-color-text">{{'Documents' |
                                        translate}} ({{statusList[4].Description}})</h5>
                                    <div *ngIf="u_obj.DocumentsF.length > 0" class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-color">
                                                        <tr>
                                                            <th class="sh">
                                                                Id
                                                            </th>
                                                            <th class="sh">
                                                                {{'Document' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Description' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Observation' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'UploadFile' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'RequireApproval' | translate}}
                                                            </th>
                                                            <th class="sh">
                                                                {{'Status' | translate}}
                                                            </th>
                                                            <th>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let document of u_obj.DocumentsF; index as i">
                                                            <td>
                                                                <label for="code">{{i + 1}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="DocumentName">{{document.DocumentName}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Description">{{document.Description}}</label>
                                                            </td>
                                                            <td>
                                                                <label
                                                                    for="Observation">{{document.Observation}}</label>
                                                            </td>
                                                            <td>
                                                                <input type="file" class="form-control" name="file"
                                                                    (change)="AddFile($event, document.DocumentId)"
                                                                    [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'">
                                                            </td>
                                                            <td>
                                                                <label for="RequireApproval">{{document.RequireApproval
                                                                    == 0 ? "No" : "Si"}}</label>
                                                            </td>
                                                            <td>
                                                                <label for="StatusDocument">
                                                                    {{document.DocumentUpload == null ? "Archivo no
                                                                    cargado" : document.DocumentUpload.Status}}
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="DownloadFile(document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-cloud-download text-info m-r-10"
                                                                    title="{{'DownloadFile' | translate}}"></i>
                                                                <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    (click)="openUpdateModalView(view, document.DocumentUpload)"
                                                                    style="cursor:pointer;"
                                                                    class="mdi mdi-eye text-info m-r-10"
                                                                    title="{{'Visualize' | translate}}"></i>
                                                                <!-- <i *ngIf="(document.DocumentUpload != NULL)"
                                                                    style="cursor:pointer;"
                                                                    class="far fa-edit text-info m-r-10"
                                                                    (click)="openUpdateModal(update1,document)"
                                                                    title="{{'UpdateFile' | translate}}"></i> -->
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-md-2 sk-ibox">
                                            <button type="button" (click)="SaveFiles(1)"
                                                class="form-control btn btn-custom-C8102E">{{'SaveFiles' |
                                                translate}}</button>
                                        </div> -->
                                        <div class="col-md-2 sk-ibox">
                                            <button [ladda]="isUpdating" type="button" (click)="UpdateData(u_obj)"
                                                class="form-control btn btn-custom-C8102E">{{'Update' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="navStatus" class="mt-2"></div>
                    </div>
                </div>
                <div class="row" *ngIf="validateStatus()">
                    <div class="col-md-2 sk-ibox">
                        <button [ladda]="isChange" type="button" (click)="ChangeStatus()"
                            class="form-control btn btn-custom-84888b">{{'ChangeStatus' | translate}}</button>
                    </div>
                </div>
            </ng-template>
        </li>
        <!-- <li [ngbNavItem]="2" *ngIf="this.userType == 'ADM' || this.userType == 'CM'"> -->
        <li [ngbNavItem]="2" *ngIf="this.userTypeLog">
            <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Log' | translate}}</a>
            <ng-template ngbNavContent>
                <div class="row" *ngIf="logList.length > 0">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-color">
                                    <tr>
                                        <th class="sh">
                                            <label class="text-nowrap" for="icon">
                                                {{'Detail' | translate}}
                                            </label>
                                        </th>
                                        <th class="sh">
                                            <label class="text-nowrap" name="ID">ID</label>
                                        </th>
                                        <th class="sh" class="text-nowrap">
                                            <label class="text-nowrap" name="FieldName">{{'Field' | translate}}</label>
                                        </th>
                                        <th class="sh">
                                            <label class="text-nowrap" name="QuantityChange">{{'QuantityChange' |
                                                translate}}</label>
                                        </th>
                                        <th class="sh">
                                            <label class="text-nowrap" name="LastModification">{{'LastModification' |
                                                translate}}</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let log of logList | slice: (pageLog-1) * pageSizeLog : (pageLog-1) * pageSizeLog + pageSizeLog; index as i">
                                        <tr>
                                            <td>
                                                <i style="cursor:pointer;" class="mdi mdi-eye-off text-dark"
                                                    *ngIf="hiddenLog[i]" (click)="hiddenLog[i] = !hiddenLog[i]"></i>
                                                <i (click)="OpenDetail(i, log)" style="cursor:pointer;"
                                                    class="mdi mdi-eye text-dark m-r-10"
                                                    title="{{'Detail' | translate}}" *ngIf="!hiddenLog[i]"></i>
                                            </td>
                                            <td>
                                                <label for="code">{{i + 1}}</label>
                                            </td>
                                            <td>
                                                <label for="FieldName">{{log.FieldName | translate}}</label>
                                            </td>
                                            <td>
                                                <label for="TableFieldDetail">{{log.TableFieldDetail.length}}</label>
                                            </td>
                                            <td>
                                                <label
                                                    for="LastModification">{{log.TableFieldDetail[log.TableFieldDetail.length
                                                    - 1].ModificationTime.split('T')[0]}}</label>
                                            </td>


                                        </tr>
                                        <tr *ngIf="hiddenLog[i]">
                                            <td colspan="5">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead class="thead-color-two">
                                                            <th class="sh">
                                                                <label class="text-nowrap" name="ID">ID</label>
                                                            </th>
                                                            <th class="sh" class="text-nowrap">
                                                                <label class="text-nowrap" name="user">{{'user' |
                                                                    translate}}</label>
                                                            </th>
                                                            <th class="sh" class="text-nowrap">
                                                                <label class="text-nowrap"
                                                                    name="PreviousValue">{{'PreviousValue' |
                                                                    translate}}</label>
                                                            </th>
                                                            <th class="sh" class="text-nowrap">
                                                                <label class="text-nowrap"
                                                                    name="CurrentValue">{{'CurrentValue' |
                                                                    translate}}</label>
                                                            </th>
                                                            <th class="sh" class="text-nowrap">
                                                                <label class="text-nowrap"
                                                                    name="UserCodeApproval">{{'UserCodeApproval' |
                                                                    translate}}</label>
                                                            </th>
                                                            <th class="sh" class="text-nowrap">
                                                                <label class="text-nowrap"
                                                                    name="ModificationTime">{{'ModificationTime' |
                                                                    translate}}</label>
                                                            </th>
                                                            <td>
                                                                <label>{{'Observation' | translate}}</label>

                                                            </td>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let logDetail of log.TableFieldDetail | slice: (pageLogDetail-1) * pageSizeLogDetail : (pageLogDetail-1) * pageSizeLogDetail + pageSizeLogDetail; index as i">
                                                                <td class="sh">
                                                                    <label for="ID">{{i + 1}}</label>
                                                                </td>
                                                                <td class="sh">
                                                                    <label for="UserName">{{logDetail.UserName
                                                                        }}</label>
                                                                </td>
                                                                <td class="sh">
                                                                    <label for="PreviousValue">{{logDetail.PreviousValue
                                                                        }}</label>
                                                                </td>
                                                                <td class="sh">
                                                                    <label for="CurrentValue">{{logDetail.CurrentValue
                                                                        }}</label>
                                                                </td>
                                                                <td class="sh">
                                                                    <label for="FieldName">{{logDetail.UserNameApproval
                                                                        != null ? logDetail.UserNameApproval.UserName :
                                                                        "No se requirió aprobación" }}</label>
                                                                </td>
                                                                <td class="sh">
                                                                    <label
                                                                        for="FieldName">{{logDetail.ModificationTime.split('T')[0]}}</label>
                                                                </td>
                                                                <td class="sh">
                                                                    <div class="row" style="display: flex;">
                                                                        <div class="col-md-10 sk-ibox">
                                                                            <!--  -->

                                                                            <textarea type="text" class="form-control"
                                                                                (keydown)="handleKeyDown($event,200)"
                                                                                [disabled]="!disabledComment(logDetail)"
                                                                                minlength="0" maxlength="200"
                                                                                [(ngModel)]="logDetail.Comment"></textarea>
                                                                        </div>
                                                                        <div class="col-md-2 sk-ibox"
                                                                            *ngIf="disabledComment(logDetail)"
                                                                            style="flex-direction: center;align-items: center;">
                                                                            <i style="cursor:pointer;"
                                                                                class="far fa-save text-info m-r-10"
                                                                                (click)="SaveComment(logDetail)"
                                                                                title="{{'save' | translate}}"></i>
                                                                        </div>

                                                                    </div>



                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <td colspan="6">
                                                                <ngb-pagination
                                                                    [collectionSize]="log.TableFieldDetail.length"
                                                                    [(page)]="pageLogDetail"
                                                                    [pageSize]="pageSizeLogDetail" [maxSize]="5"
                                                                    style="float: right;"></ngb-pagination>
                                                            </td>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tfoot>
                                    <td colspan="11">
                                        <ngb-pagination [collectionSize]="pageTotalLog" [(page)]="pageLog"
                                            [pageSize]="pageSizeLog" [maxSize]="5" style="float: right;">
                                        </ngb-pagination>
                                    </td>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2">
    </div>


</ng-container>
<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'update_item' | translate}}</h4>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="card-body">
                <div class="form-group">

                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'OrderNumber' | translate}}</label>
                            <input disabled type="text" class="form-control" name="OrderNumber"
                                [(ngModel)]="u_obj['OrderNumber']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'OrderDisplay' | translate}}</label>
                            <input type="text" disabled class="form-control" name="OrderDisplay"
                                [(ngModel)]="u_obj['OrderDisplay']">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Status' | translate}} {{'dispatch'| translate}}</label>
                            <input type="text" disabled class="form-control" name="StateDocumentDispatch"
                                [(ngModel)]="u_obj['Status']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'DocumentName' | translate}}</label>
                            <span *ngIf="u_obj['Status'] =='N'">
                                <input type="text" disabled class="form-control" name="DocumentName"
                                    [(ngModel)]="this.c_obj['DocumentName']">
                            </span>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Commentary' | translate}}</label>
                            <textarea type="text" class="form-control" name="Commentary" value="N/A"
                                [(ngModel)]="this.c_obj['DocumentComment']">
                            </textarea>
                        </div>
                    </div>

                    <td>
                        <input type="file" class="form-control" name="File"
                            accept="image/png, image/jpg, image/jpeg, .xlsx,.xls,.doc, .docx,.pdf"
                            (change)="AddFile($event, this.c_obj.DocumentId)">
                        <!-- [disabled]="document.DocumentUpload != null && document.DocumentUpload.StatusFile != 'RC'" -->

                    </td>



                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' |
            translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
            (click)="SaveFiles(2,update1)">{{'update' | translate}}</button>
    </div>
</ng-template>

<ng-template #view let-c="close" let-d="dismiss" let-modal>
    <!--  <ng-template #view let-modal>-->
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'preview' | translate}}</h4>
        <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
            <a [href]="pdfSrc" target="_blank"><i class="mdi mdi-arrow-right text-info m-r-10"></i></a>
            <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <ng-container *ngIf=" this.isPDF == true; else elseTemplate ">
                <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                    style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>

            </ng-container>
            <ng-template #elseTemplate>
                <div style="display: flex;">
                    <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
                        alt="">
                </div>
            </ng-template>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
    </div>
</ng-template>
