import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';;
import { ApiService } from 'src/app/services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { GlobalService } from '../../../../services/main/global.service';
import { AuthService } from '../../../../services/authentication/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-state-dispatchs',
  templateUrl: './report-state-dispatchs.component.html',
  styleUrls: ['./report-state-dispatchs.component.css']
})
export class ReportStateDispatchsComponent extends BaseCrudComponent implements OnInit {

  //Filter
  showIconF = false;
  dataSearchList = [];
  private workbook: Workbook;
  private lista_titulos;
  wareHouses = [];
  CompanyTrans = [];
  itemList = [];
  userList = [];
  TypeProduct = [];

  @ViewChild('thead', { static: true }) thead: ElementRef;
  userInfo;
  option_processes = null;
  origenList = environment.Global.COUNTRY;
  countryList = environment.Global.COUNTRY;
  date_init = null;
  date_end = null;
  type_product = "";
  name = 'Imports';

  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = false;
  isSkretting = false;
  isClean = false;
  pageSize = 10;
  flag = false;

  col_value = 0;
  url_report_ = '';
  fontSize: number = 0;
  marginLeft: number = 30;
  l = [];

  constructor(
    public GlobalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService,

  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['OrderDisplay', 'Category', 'ItemName',
      'CompanyName', 'OrigenName', 'CountryName'
      , 'PortShipment', 'AgentName', 'UserName', 'AssignmentDate', 'ReAssignmentDate'
      , 'FinishStatusDateN', 'FinishStatusDateE', 'FinishStatusDateT', 'FinishStatusDateP'];
  }


  async ngOnInit() {
    this.u_obj['ItemId'] = "0";
    this.u_obj['UserId'] = "0";
    this.u_obj['TypeProduct'] = "0";
    await super.ngOnInit();
    await this.getItems();
    await this.getInternalUser();
    await this.GetTypeProductActive();
    this.user = this._authService.getUserLogged();
    this.getNotificationAP(this.user.sub);
    this.getNotificationAC(this.user.sub);
    this.getNotificationCXP(this.user.sub);
    this.getNotificationCP(this.user.sub);
    this.getNotificationCA(this.user.sub);
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
      });

    if (this.request_selected) {
      this.disabledCompany = false;
      this.disabledRuc = false;
    } else {
      this.disabledCompany = true;
      this.disabledRuc = true;
    }
  }


  public async getItems() {
    this.url = environment.Global.API_GET_ITEM;

    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.itemList = result.Object;
      }
    })
    // console.log(this.itemList);
  }
  public async getInternalUser() {
    this.url = environment.Global.API_GET_USER_INT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.userList = result.Object;
      }
    })
  }
  public async GetTypeProductActive() {
    this.url = environment.Global.API_GET_ACTIVE_TP;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        this.TypeProduct = result.Object;
      }
    })
  }
  clearFilters() {
    this.u_obj['ItemId'] = "0";
    this.u_obj['UserId'] = "0"
    this.u_obj['TypeProduct'] = "0";
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.url_report_ = '';
    this.disabledCompany = true;
    this.disabledRuc = true;
    // console.log(this.options_company);
    // console.log(this.options_ruc);
  }
  dataToFind() {
    this.isFind = true;
    this.showIconF = false;


    if (this.u_obj['ItemId'] == "0" || this.u_obj['ItemId'] == null) {
      this.u_obj['ItemId'] = "0";
    }
    if (this.u_obj['UserId'] == "0" || this.u_obj['UserId'] == null) {

      this.u_obj['UserId'] = "0";
    }
    if (this.u_obj['TypeProduct'] == "0" || this.u_obj['TypeProduct'] == null) {
      this.u_obj['TypeProduct'] = "0";
    }

    if (this.u_obj['ItemId'] == "0" && this.u_obj['UserId'] == "0" && this.u_obj['TypeProduct'] == "0" ||
      this.u_obj['ItemId'] == null && this.u_obj['UserId'] == null && this.u_obj['TypeProduct'] == null) {

      this.isFind = false;
      return Swal.fire("Error", this.translations.msg_error_empty, 'error');
    }

    this.setUrlToFindData();
  }
  async setUrlToFindData() {
    let url = '';
    this.isFind = false;
    await this.getListFromApi();
  }
  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() < 10 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }
  async getListFromApi() {
    // console.log(this.option_processes);
    this.isFind = true;
    this.url = environment.Global.API_GET_REPORT_PURCHASEORDERSLINE;
    console.log(this.url)
    let list_tmp = [];
    // console.log( this.date_init, this.date_end, this.option_processes,this.options_ruc, this.options_company);
    this.messageShowLoading();

    await this.srv.getCall(this.url).subscribe(x => {
      if (x.Status) {
        if (x.Object != null) {

          // console.log(x.Object);
          this.rawdata = x.Object;
          console.log(this.rawdata);
          let new_list = [];
          list_tmp = x.Object;

          list_tmp.forEach(element => {
            if (element.Origen != null) {

              this.origenList.forEach(origen => {
                if (origen.code == element.Origen) {
                  element.OrigenName = origen.name_en;
                }
              });

            }else {
              element.OrigenName ="";
            }
            if (element.Country != null) {

              this.origenList.forEach(origen => {
                if (origen.code == element.Country) {
                  element.CountryName = origen.name_en;
                }
              });

            }else {
              element.CountryName ="";
            }
            if (element.TypeProduct != null) {
              debugger
              this.TypeProduct.forEach(product => {
                if (product.id == element.TypeProduct) {
                  element.TypeProductName = product.nombre;
                }
              });

            }else {
              element.TypeProductName ="";
            }

            // }
            if (element.CreationTime != null && element.AssignmentDate != null) {

              let CreationTime = this.formatOnlyDate(new Date(element.CreationTime));
              let AssignmentDate = this.formatOnlyDate(new Date(element.AssignmentDate));

              // let FinishStatusDateE = this.formatOnlyDate(new Date(element.FinishStatusDateE));

              var TimeAssignment = new Date(AssignmentDate).getTime() - new Date(CreationTime).getTime();

              element.TimeAssignment = Math.round(TimeAssignment / (1000 * 60 * 60 * 24));

            }
            if (element.CreationTime != null && element.FinishStatusDateN != null) {

              let CreationTime = this.formatOnlyDate(new Date(element.CreationTime));
              let FinishStatusDateN = this.formatOnlyDate(new Date(element.FinishStatusDateN));
              element.TimeInStatusN = Math.round((new Date(FinishStatusDateN).getTime() - new Date(CreationTime).getTime()) / (1000 * 60 * 60 * 24))
            }
            if (element.FinishStatusDateN != null && element.FinishStatusDateE != null) {

              let a = this.formatOnlyDate(new Date(element.FinishStatusDateN));
              let FinishStatusDateE = this.formatOnlyDate(new Date(element.FinishStatusDateE));
              element.TimeInStatusE = Math.round((new Date(FinishStatusDateE).getTime() - new Date(a).getTime()) / (1000 * 60 * 60 * 24))
            }
            if (element.FinishStatusDateE != null && element.FinishStatusDateT != null) {

              let a = this.formatOnlyDate(new Date(element.FinishStatusDateE));
              let FinishStatusDateT = this.formatOnlyDate(new Date(element.FinishStatusDateT));
              element.TimeInStatusT = Math.round((new Date(FinishStatusDateT).getTime() - new Date(a).getTime()) / (1000 * 60 * 60 * 24))
            }
            if (element.FinishStatusDateT != null && element.FinishStatusDateP != null) {

              let a = this.formatOnlyDate(new Date(element.FinishStatusDateT));
              let b = this.formatOnlyDate(new Date(element.FinishStatusDateP));
              element.TimeInStatusP = Math.round((new Date(b).getTime() - new Date(a).getTime()) / (1000 * 60 * 60 * 24))
              // console.log(element.TimeInStatusP);
            }
            if (element.FinishStatusDateP != null && element.FinishStatusDateF != null) {

              let a = this.formatOnlyDate(new Date(element.FinishStatusDateP));
              let b = this.formatOnlyDate(new Date(element.FinishStatusDateF));
              element.TimeInStatusF = Math.round((new Date(b).getTime() - new Date(a).getTime()) / (1000 * 60 * 60 * 24))

            }
            if (element.AssignmentDate != null && element.FinishStatusDateF != null) {

              let a = this.formatOnlyDate(new Date(element.AssignmentDate));
              let b = this.formatOnlyDate(new Date(element.FinishStatusDateF));
              element.TotalTime = Math.round((new Date(b).getTime() - new Date(a).getTime()) / (1000 * 60 * 60 * 24))
            }

          });


          list_tmp.forEach(tmp => {

            if (tmp.UserSkrettingResponse == this.u_obj['UserId'] || tmp.ItemNumber == this.u_obj['ItemId'] || tmp.TypeProduct == this.u_obj['TypeProduct']) {
              new_list.push(tmp);
            }
          })
          this.rawdata = new_list;
          this.rows = new_list;

          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.closeMessageDialog();
          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      } else {
        this.isFind = false;
        Swal.fire(x.Message, '', 'error');
        return;
      }
    });

  }


  selectRequest() {
    this.request_selected = true;
    this.rows = [];
    this.page = 0;
    this.pageTotal = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
    this.url_report_ = '';

  }

  async reports() {
    this.l = [];
    this.rows.forEach(element => {
      if (this.rows !== null && this.rows !== ' ') {
        let obj = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']//,'',''
        obj[0] = element.OrderDisplay;
        obj[1] = element.TypeProductName;
        obj[2] = element.ItemName;
        obj[3] = element.CompanyName;
        obj[4] = element.OrigenName;
        obj[5] = element.CountryName;
        obj[6] = element.PortShipment;
        obj[7] = element.AgentName;
        obj[8] = element.UserName;
        obj[9] = element.AssignmentDate != null ? element.AssignmentDate.split('T')[0] : "";
        obj[10] = element.FinishStatusDateP != null ? element.ReAssignmentDate.split('T')[0] : "";
        obj[11] = element.TimeAssignment;
        obj[12] = element.TimeInStatusN;
        obj[13] = element.FinishStatusDateN != null ? element.FinishStatusDateN.split('T')[0] : "";
        obj[14] = element.TimeInStatusE;
        obj[15] = element.FinishStatusDateE != null ? element.FinishStatusDateE.split('T')[0] : "";
        obj[16] = element.TimeInStatusT;
        obj[17] = element.FinishStatusDateT != null ? element.FinishStatusDateT.split('T')[0] : "";
        obj[18] = element.TimeInStatusP;
        obj[19] = element.FinishStatusDateP != null ? element.FinishStatusDateP.split('T')[0] : "";
        obj[20] = element.TimeInStatusF != null ? element.TimeInStatusF : "";
        obj[21] = element.TotalTime != null ? element.TotalTime : "";

        this.l.push(obj)
      }

    });
  }



  async converDataToRow(list) {
    let nueva_lista = [];
    this.reports();
    if (this.rows !== null && this.rows !== ' ') {
      nueva_lista = this.l;
      return nueva_lista;
    }
  }

  async downloadReport() {
    debugger
    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    let listInReport = this.rows;
    const pdf = new PdfMakeWrapper();
    let nameDoc = '';
    let title = '';
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];


    title = 'Reporte de Estados por despachos';

    lista_titulos = [
      new Cell(new Txt(this.translations.OrderDisplay).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.Category).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.ItemName).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.Supplier).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.Origen).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.CountryShipment).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.PortShipment).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.Agent).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.UserIdSK).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.AssignmentDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.ReAssignmentDate).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.AssignmentTime).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.TimeInStatusN).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.DatePassedToE).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.TimeInStatusE).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.DatePassedToT).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.TimeInStatusT).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.DatePassedToP).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.TimeInStatusP).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.DatePassedToF).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.TimeInStatusF).margin([0, 5, 0, 5]).color('white').bold().end).end,
      new Cell(new Txt(this.translations.TotalTime).margin([0, 5, 0, 5]).color('white').bold().end).end
    ];
    lista_widths = [
      'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
      // '*', 'auto', '*', 'auto'
    ]

    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${nameDoc}`,
      author: `${"Skretting"}`,//this.userInfo.name
      subject: 'Listado',
    });

    //OPTIONS
    this.fontSize = 6;
    this.marginLeft = 10;


    pdf.defaultStyle({
      bold: false,
      fontSize: this.fontSize,
      alignment: 'justify'
    });

    pdf.pageMargins([10, 110, 10, 110]);

    pdf.pageOrientation("landscape");
    pdf.background(
      await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 515).width(900).build() //505
    )


    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/Skretting-logo.png').width('160').build()).end,
          ]
        ]
      )
        .margin([this.marginLeft, 30, 30, 0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0, 0, 0, 20]).alignment('center').end,
        new Table(
          [
            lista_titulos,
            ...await this.converDataToRow(listInReport)
          ]
        ).layout({
          fillColor: (rowIndex: number, node: any, columnIndex: number) => {
            return rowIndex === 0 ? '#f62d51' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(this.fontSize)
          .widths(lista_widths)
          // .widths('*')
          .alignment('center')
          .end,
      ]
    )

    pdf.footer(function (currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0, 10, 0, 0]).end).end,
          new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40, 20, 0]).end).end
        ]
      ).end
    });

    let nombre = 'Reporte';

    pdf.create().download(nombre);
    Swal.close();
  }

  public showIconFilter(flag: boolean) {
    this.showIconF = flag;
    if (!flag) {
      this.searchData('');
      // this.arrayList = [];
    }

  }
  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public resetData(index, columnName) {
    let array = [];
    this.l[index] = [];
    this.rows.forEach(element => {
      if (element[columnName] != undefined) {
        let flag = array.indexOf(element[columnName]);
        if (flag == -1) {
          array.push(element[columnName]);
        }
      }
    });

    array.push('(Vacíos)');
    this.l[index] = array;
  }

  public searchDataSelect(event) {
    let data = event.split('|'); debugger;
    if (data[2] == '(Vacíos)') {
      data[2] = null;
    }
    this.dataSearchList[data[0]] = data[2] != '' ? (data[1] + '|' + data[2]) : null;//''
    this.search2 = this.dataSearchList;
    this.filterAllSelect();
  }

  downloadExcel() {

    this.workbook = new Workbook();
    let nombreXLSX = "Reporte de Ordenes de compra.xlsx";
    this.lista_titulos = [


      this.translations.OrderDisplay,
      this.translations.Category,
      this.translations.ItemName,
      this.translations.Supplier,
      this.translations.Origen,
      this.translations.CountryShipment,
      this.translations.PortShipment,
      this.translations.Agent,
      this.translations.UserIdSK,
      this.translations.AssignmentDate,
      this.translations.ReAssignmentDate,
      this.translations.AssignmentTime,
      this.translations.TimeInStatusN,
      this.translations.DatePassedToE,
      this.translations.TimeInStatusE,
      this.translations.DatePassedToT,
      this.translations.TimeInStatusT,
      this.translations.DatePassedToP,
      this.translations.TimeInStatusP,
      this.translations.DatePassedToF,
      this.translations.TimeInStatusF,
      this.translations.TotalTime
    ];

    this.ContainsExcel();
    this.workbook.creator = "Comex";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      fs.saveAs(blob, nombreXLSX);
    })
  }
  private ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 20;
    sheet.getColumn("B").width = 20;
    sheet.getColumn("C").width = 20;
    sheet.getColumn("D").width = 20;
    sheet.getColumn("E").width = 20;
    sheet.getColumn("F").width = 20;
    sheet.getColumn("G").width = 20;
    sheet.getColumn("H").width = 20;
    sheet.getColumn("I").width = 20;
    sheet.getColumn("J").width = 20;
    sheet.getColumn("K").width = 20;
    sheet.getColumn("L").width = 20;
    sheet.getColumn("M").width = 20;
    sheet.getColumn("N").width = 20;
    sheet.getColumn("O").width = 20;
    sheet.getColumn("P").width = 20;
    sheet.getColumn("Q").width = 20;
    sheet.getColumn("R").width = 20;
    sheet.getColumn("S").width = 20;
    sheet.getColumn("T").width = 20;
    sheet.getColumn("U").width = 20;
    sheet.getColumn("V").width = 20;
    sheet.getColumn("W").width = 20;
    sheet.getColumn("X").width = 20;
    sheet.getColumn("Y").width = 20;
    sheet.getColumn("Z").width = 20;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });



    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    // console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12 };

    // agregar los datos a las filas
    const rowsToInsert = sheet.getRows(2, this.rows.length);

    for (let index = 0; index < rowsToInsert.length; index++) {
      const itemData = this.rows[index]; //obtenemos el item segun el index de la iteraciòn(recorrido)
      const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
      // los valores de itemData seran asignados a row (fila actual en la iteraciòn)


      row.values = [

        itemData.OrderDisplay,
        itemData.TypeProductName,
        itemData.ItemName,
        itemData.CompanyName,
        itemData.OrigenName,
        itemData.CountryName,
        itemData.PortShipment,
        itemData.AgentName,
        itemData.UserName,
        itemData.AssignmentDate == null ? "" : itemData.AssignmentDate.split('T')[0],
        itemData.FinishStatusDateP == null ? "" : itemData.ReAssignmentDate.split('T')[0],
        itemData.TimeAssignment,
        itemData.TimeInStatusN,
        itemData.FinishStatusDateN == null ? "" : itemData.FinishStatusDateN.split('T')[0],
        itemData.TimeInStatusE,
        itemData.FinishStatusDateE == null ? "" : itemData.FinishStatusDateE.split('T')[0],
        itemData.TimeInStatusT,
        itemData.FinishStatusDateT == null ? "" : itemData.FinishStatusDateT.split('T')[0],
        itemData.TimeInStatusP,
        itemData.FinishStatusDateP == null ? "" : itemData.FinishStatusDateP.split('T')[0],
        itemData.TimeInStatusF,
        itemData.TotalTime

      ];
    }
  }



}
