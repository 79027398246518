import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  
  {
    path: 'about-us',
    title: 'menu_aboutus',
    icon: 'icon icon-info',
    class: '',
    extralink: false,
    perm: '',
    submenu: [],
    url: 'https://www.skretting.com/es-ec/esto-es-skretting/mision-vision-y-valores/'
  },
  {
    path: 'purchase',
    title: 'menu_oc',
    icon: 'mdi mdi-note-outline',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },
  {
    path: 'logistics',
    title: 'menu_logistics',
    icon: 'mdi mdi-home-outline',
    class: '',
    extralink: false,
    perm: 'SI_ADMREGISTRO',
    submenu: []
  },
  {
    path: 'reports',
    title: 'menu_reports',
    icon: 'mdi mdi-note-text',
    class: '',
    extralink: false,
    perm: 'SI_ADMSOLICITUD',
    submenu: []
  },  
  {
    path: 'cruds',
    title: 'menu_maintenance',
    icon: 'fa fa-wrench',
    class: '',
    extralink: false,
    perm: 'SI_ADMCONSULTA',
    submenu: []
  },
  {
    path: 'settings',
    title: 'menu_general',
    icon: 'icon icon-settings',
    class: '',
    extralink: false,
    perm: 'sett',
    submenu: []
  },
  
];
