import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

    translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    momentjs: any = moment;
    last_sync:any;

  	constructor(
  		public srv: LoginService,
  		public _router: Router,
      public translate: TranslateService,
      public api: ApiService
  	) { }

  	ngOnInit() {
          //console.log(environment.version);
          //this.version = environment.version;
          this.getTranslations();

          //console.log(this.translate.currentLang);
          this.currlang = this.translate.currentLang;
          //console.log(this.translate);

          this.translate.onLangChange.subscribe(() => {
              this.getTranslations();
          });

          this.version = environment.version;
          
          this.api.getPeristedData('md_lastsync').then(x => {
              this.last_sync = x;
          });
  	}

  	logout(){
  		Swal.fire({
              title: this.translations.logout_msg,
              text: '',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.translations.cancel,
              confirmButtonText: this.translations.ok
          }).then((result) => {
              if (result.value) {
              	//console.log(this.srv);
              	this.srv.clearData().then(() => {
              		this._router.navigate(['/authentication/login']);
              	});
              }
          });
  	}

    getTranslations(){
        this.translate.getTranslation(this.translate.currentLang)
          .subscribe((translations) => {
                this.translations = translations;
          });
    }

    setLang(str:any){
        //console.log(str);
        this.translate.use(str);
    }

    async checkUpdates(){
        Swal.fire({
              title: this.translations.update_confirm,
              text: '',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.translations.cancel,
              confirmButtonText: this.translations.ok
          }).then((result) => {
              if (result.value) {
                  window.location.reload(true);
              }
          });
    }

    public getMasterData(){

        Swal.fire({
              title: this.translations.masterdata_confirm,
              text: '',
              icon: 'warning',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              cancelButtonText: this.translations.cancel,
              confirmButtonText: this.translations.ok,
              preConfirm: () => {
                  this.getCompanyData().subscribe(c => {
                      this.getCommonData().subscribe(d => {
                          Swal.fire(this.translations.updated, '', 'success');
                      });
                  });//end company data
              }
          }).then((result) => {
                
          });
        
    }

    public getCompanyData(){
        //var ent_url = 'PathologicalDataInput/PathologicalEntities';
        var ent_url = 'Sampling/SamplingEntities';

        return this.api.getCall(ent_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          //return of(mydata.result.accessToken);
                          this.last_sync = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD hh:mm');
                          this.api.persistData('md_lastsync', this.last_sync);
                          return from(this.api.persistData('companies', mydata.val));
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getCommonData(){
        var par_url = 'SystemParameter/All';

        return this.api.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.last_sync = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD hh:mm');
                          this.api.persistData('md_lastsync', this.last_sync);
                          return from(this.api.persistData('common', mydata.val));
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

}
