import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  permissions = [];
  modules = null;
  private _userObservable: BehaviorSubject<any>;

  constructor( private authService: AuthService) {
  }

  existPermission(moduleName: string): boolean {
    let flag = false;
    this.authService.PermissionObservable.subscribe(data => {
      data.forEach(element => {
        if(element.name == moduleName) {
          flag = true;
        }
      });
    })
    return flag;
  }


}
