<app-header-options (updateInfo)="getPurchaseOrders()" (search)="searchData($event)" [isLoading]="isLoading"
    [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>

<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr>
                        <th class="sh">
                            ID
                        </th>
                        <th (click)="setOrder('OrderNumber')" class="sh">
                            {{'OrderNumber' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='OrderNumber')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>

                        <th class="sh">
                            {{'CodeSupplier' | translate}}
                        </th>
                        <th class="sh">
                            {{'CompanyName' | translate}}
                        </th>
                        <th class="sh">
                            {{'GrossWeightOrder' | translate}}
                        </th>
                        <th class="sh">
                            {{'UserResponsableSk' | translate}}
                        </th>
                        <th class="sh">
                            {{'Agent' | translate}}
                        </th>
                        <th>
                            {{'actions' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i"> -->
                    <tr
                        *ngFor="let row of rows |slice: (page-1) * pageSize : page * pageSize | orderBy:orderKey:orderDesc; index as i">

                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>
                        <td>
                            <label for="OrderNumber">{{row.OrderNumber}}</label>
                        </td>


                        <td>
                            <label for="Supplier">{{row.Supplier}}</label>
                        </td>
                        <td>
                            <label for="CompanyName">{{row.CompanyName}}</label>
                        </td>
                        <td>
                            <label for="GrossWeightOrder">{{row.GrossWeightOrder}}</label>
                        </td>
                        <td>
                            <label for="UserResponsableSk">{{row.UserName}}</label>
                        </td>
                        <td>
                            <label for="Agent">{{row.AgentName}}</label>
                        </td>
                        <td>
                            <i (click)="globalService.goToParameterDetail(urlDetail + row.OrderId)"
                                style="cursor:pointer;" class="far fa-file-alt text-warning m-r-10"
                                title="{{'submenu_skretting_purchase_order_detail' | translate}}"></i>
                            <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;"
                                class="far fa-clock text-info m-r-10" title="{{'edit' | translate}}"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="17">

                        <div class="d-flex justify-content-end">
                            <div class="col-md-1 col-sm-2">
                                <select [(ngModel)]="pageSize" class="form-control" name="pageSize">
                                    <option *ngFor="let pg of PageSizeSelect" value="{{pg.Id}}">
                                        {{pg.Name}}
                                    </option>
                                </select>
                            </div>

                            <ngb-pagination [collectionSize]="pageTotal" [(page)]="page"
                                [(pageSize)]="pageSize"></ngb-pagination>

                        </div>

                    </td>
                </tfoot>


            </table>

        </div>
    </div>
</div>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'MassCasUpdateDate' | translate}}</h4>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="card-body">
                <div class="form-group">

                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'OC' | translate}}</label>
                            <input type="text" class="form-control" name="OrderNumber" disabled="true" [(ngModel)]="u_obj['OrderNumber']">
                            
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'ContainerNumber' | translate}}</label>
                            <ng-select [(ngModel)]="u_obj['ContainerNumber']" name="ContainerNumber" [multiple]="true">
                                <ng-option *ngFor="let option of ContainerNumber" value="{{option.ContainerNumber}}">
                                    {{option.ContainerNumber}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'CasupdateDate' | translate}}</label>
                            <input type="date" class="form-control" name="CasupdateDate" [(ngModel)]="u_obj['CasupdateDate']">
                        </div>
                    </div>
                   

                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' |
            translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
            (click)="updateCAS(update1, u_obj)">{{'update' | translate}}</button>
    </div>
</ng-template>