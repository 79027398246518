import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[SoloLetras]'
})
export class SoloLetrasDirective {

  @HostListener('input', ['$event']) onInput(event: any) {
    const soloLetras = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    event.target.value = soloLetras;
  }
  constructor() { }

}
