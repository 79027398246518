import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customagentemployee',
  templateUrl: './customagentemployee.component.html',
  styleUrls: ['./customagentemployee.component.css']
})
export class CustomagentemployeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
