import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[SoloNumerosEnteros]'
})
export class SoloNumerosEnterosDirective {
  @Input() maxLength: number = 0;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const currentValue: string = this.el.nativeElement.value;
    const cleanedValue: string = currentValue.replace(/[^0-9]/g, '');

    let truncatedValue: string;
    
    // Aplicar límite solo si maxLength es mayor a 0
    if (this.maxLength > 0) {
      truncatedValue = cleanedValue.substr(0, this.maxLength);
      
    } else {
      truncatedValue = cleanedValue;
    }

    if (currentValue !== truncatedValue) {
      this.el.nativeElement.value = truncatedValue;
      this.moveCursorToEnd();
      event.stopPropagation();
    }
  }

  private moveCursorToEnd(): void {
    this.el.nativeElement.selectionStart = this.el.nativeElement.selectionEnd = this.el.nativeElement.value.length;
  }
}
