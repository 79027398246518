<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-5 col-sm-12">

            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <label for="">ETA</label>
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
                </div>
            </div>
        </div>

        <div class="col-md-7 col-sm-12">
            <div class="row">
                <!-- <div class="col-md-6 col-sm-12">
                    <ng-select [(ngModel)]="u_obj['ItemId']" name="ItemId" [multiple]="false">
                        <ng-option value="0">{{'Select'|translate}} {{'ItemName'|translate}}... </ng-option>
                        <ng-option *ngFor="let st of itemList" value="{{st.ItemNumber}}">{{st.ItemNumber}} -
                            {{st.Name}}</ng-option>
                    </ng-select>
                </div> -->
                <div class="col-md-6 col-sm-12">
                    <ng-select [(ngModel)]="u_obj['ItemId']" name="ItemId" [multiple]="false">
                        <ng-option value="0">{{'Select'|translate}} {{'ItemName'|translate}}... </ng-option>
                        <ng-option *ngFor="let st of itemList" value="{{st.ItemNumber}}">{{st.ItemNumber}} -
                            {{st.Name}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 col-sm-12">

                        <!-- *ngIf="this.u_obj['ItemId']!=0 && this.u_obj['UserId']!=0 ||this.u_obj['UserId']==null " -->
                        <button [ladda]="isFind" type="button" (click)="dataToFind()"
                            class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
                        <button [ladda]="isClean" type="button" (click)="clearFilters()"
                            class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>


                </div>

            </div>

        </div>




    </div>
</div>
<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>


        <!-- Contenedores Asignados (11)-->
        <div *ngIf="rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                        <!-- Categoria del producto -->
                        <th>{{'Category' | translate}}</th>
                        <th>{{'Agent'| translate}}</th>
                        <th>{{'DealNumber' | translate}}</th>
                        <th>{{'OrderDisplay' | translate}}</th>
                        <th>{{'Supplier' | translate}}</th>
                        <th>{{'ItemNumber' | translate}}</th>
                        <th>{{'ItemName' | translate}}</th>
                        <th>{{'Status'| translate}}</th>
                        <th>{{'GrossWeightDispatched'| translate}}</th>
                        <th>{{'EtdReal'| translate}}</th>
                        <th>{{'EtaReal'| translate}}</th>
                        <th>{{'Bl'| translate}}</th>
                        <th>{{'Invoice'| translate}}</th>
                        <th>{{'InvoiceDate'| translate}}</th>
                        <th>{{'AmountUE'| translate}}</th>
                        <th>{{'IncoTerm'| translate}}</th>


                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF"> 
                        <td></td>
                       
                        <td>
                            <div (click)="resetData(0,'TypeProductName')">
                                <app-select-filter [column]="'TypeProductName'" [arrayList]="l[0]" [indexPosition]="0"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        

                        <td>
                            <div (click)="resetData(1,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(2,'DealNumber')">
                                <app-select-filter [column]="'DealNumber'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'OrderDisplay')">
                                <app-select-filter [column]="'OrderDisplay'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                       
                        <td>
                            <div (click)="resetData(4,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'StatusName')">
                                <app-select-filter [column]="'StatusName'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'GrossWeightDispatched')">
                                <app-select-filter [column]="'GrossWeightDispatched'" [arrayList]="l[8]"
                                    [indexPosition]="8" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                      
                        <td>
                            <div (click)="resetData(9,'EtdReal')">
                                <app-select-filter [column]="'EtdReal'" [arrayList]="l[9]"
                                    [indexPosition]="9" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                       
                        <td>
                            <div (click)="resetData(11,'Bl')">
                                <app-select-filter [column]="'Bl'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'Invoice')">
                                <app-select-filter [column]="'Invoice'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'InvoiceDate')">
                                <app-select-filter [column]="'InvoiceDate'" [arrayList]="l[13]" [indexPosition]="13"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(14,'AmountUE')">
                                <app-select-filter [column]="'AmountUE'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'IncoTerm')">
                                <app-select-filter [column]="'IncoTerm'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr> 
                    
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>

                            <td><label for="TypeProductName">{{row.TypeProductName}} </label></td>
                            <td><label for="AgentName">{{row.AgentName}} </label></td>
                            <td><label for="DealNumber">{{row.DealNumber}} </label></td>
                            <td><label for="OrderDisplay">{{row.OrderDisplay}} </label></td>
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="ItemNumber">{{row.ItemNumber}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="StatusName">{{row.StatusName}} </label></td>                           
                            <td><label for="GrossWeightDispatched">{{row.GrossWeightDispatched}} </label></td>
                            <td><label for="EtdReal">{{row.EtdReal  | date:'y-MM-dd'}} </label></td>
                            <td><label for="EtaReal">{{row.EtaReal  | date:'y-MM-dd'}} </label></td>
                            <td><label for="Bl">{{row.Bl}} </label></td>
                            <td><label for="Invoice">{{row.Invoice}} </label></td>
                            <td><label for="InvoiceDate">{{row.InvoiceDate | date:'y-MM-dd'}} </label></td>
                            <td><label for="AmountUE">{{row.AmountUE}} </label></td>
                            <td><label for="IncoTerm">{{row.IncoTerm}} </label></td>

                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

    </div>
</div>