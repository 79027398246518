import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/main/api.service';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseCrudComponent implements OnInit {
  date_actually = new Date();

  items:any;
  constructor(
    private spinner: NgxSpinnerService,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public translate: TranslateService,
    private auth: AuthorizationService,
    private _authService: AuthService
  ) {
    super(srv, modalService, storage, router);


  }

  async ngOnInit() {
    await super.ngOnInit();
    this.items = [
      {title: 'submenu_skretting_purchase_order', url: '/comex/purchase/purchase-orders', icon: 'mdi mdi-adjust', perm: 'CX_PO'},
      {title: 'submenu_skretting_purchase_order_detail', url: '/comex/purchase/purchase-order-lines/All', icon: 'mdi mdi-adjust', perm: 'CX_POL'},
      {title: 'submenu_skretting_approve_document', url: '/comex/purchase/approve-document', icon: 'mdi mdi-adjust', perm: 'CX_APD'},
      {title: 'submenu_skretting_approve_field', url: '/comex/purchase/approve-field', icon: 'mdi mdi-adjust', perm: 'CX_APF'},
      {title: 'submenu_skretting_payments', url: '/comex/purchase/payments', icon: 'mdi mdi-adjust', perm: 'CX_PY'},
      {title: 'submenu_skretting_document_repository', url: '/comex/purchase/document-repository', icon: 'mdi mdi-note', perm: 'CX_DRE'},
    ];
    this._authService.UpdateUserSystemList();
  }

  hasPermission(str:string){
    return this.auth.existPermission(str);
  }


}
