import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  loading: boolean = false;
  public userAuthenticated = false;

  constructor(
    private router: Router,
    private _authService: AuthService) {
  }


  async ngOnInit() {
  }

  login() {
    this.loading = true;
    this._authService.login().then(_ => {
      this.loading = false;
    });
  }
  
}
