import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent extends BaseCrudComponent implements OnInit {
  loadingList = false;
  items:any;
  constructor(
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) { 
    super(srv, modalService, storage, router);
  }

  async ngOnInit() {
    await this.ngOnInit();
    this.items = [
      {title: 'submenu_skretting_whatis', url: 'https://www.skretting.com/es-ec/', icon: 'mdi mdi-newspaper'},
      {title: 'submenu_skretting_history', url: 'https://www.skretting.com/es-ec/esto-es-skretting/nuestra-historia/', icon: 'mdi mdi-newspaper'},
      {title: 'submenu_skretting_mission', url: 'https://www.skretting.com/es-ec/esto-es-skretting/mision-vision-y-valores/', icon: 'mdi mdi-newspaper'},
      {title: 'submenu_skretting_contact', url: 'https://www.skretting.com/es-ec/esto-es-skretting/contacto/', icon: 'mdi mdi-newspaper'},
      {title: 'submenu_skretting_company', url: 'https://www.skretting.com/es-ec/esto-es-skretting/Datos-de-la-empresa/', icon: 'mdi mdi-newspaper'},
    ];
  }


}
