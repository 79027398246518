<app-header-options (updateInfo)="getInitialData()" (search)="searchData($event)" [isLoading]="isLoading"
                    (openModal)="openCreateModal(create1)" [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>



<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-color">
        <tr>
          <th class="sh">
            ID
          </th>
          <th (click)="setOrder('Name')" class="sh">
            {{'Name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')"
                                     [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
          </th>
          <th (click)="setOrder('Description')" class="sh">
            {{'Description' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')"
                                            [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
          </th>

          <th class="text-center">
            {{'StatusDocument' | translate}}
          </th>


          <th class="text-center">
            {{'enable/disable' | translate}}
          </th>
          <th>
            {{'actions' | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
          <td>
            <label for="code">{{i + 1}}</label>
          </td>
          <td>
            <label for="DocumentName">{{row.Name}}</label>
          </td>
          <td>
            <label for="Description">{{row.Description}}</label>
          </td>


          <td class="text-center">
            <label for="Status">{{row.Status}}</label>
          </td>

          <td class="text-center">
            <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null"
                   name="{{row.Name}}" (change)="clickOnCheck($event,row.AlertId)" type="checkbox">
          </td>
          <td *ngIf="row.Status == 'A'">
            <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10"
               title="{{'edit' | translate}}"></i>
            <i (click)="changeStatus('3', row.AlertId)" style="cursor:pointer;"
               class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
          </td>
        </tr>
        </tbody>
        <tfoot *ngIf="rows.length > 0">
        <td colspan="9">
          <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                          style="float: right;"></ngb-pagination>
        </td>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'create_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Name' | translate}}</label>
              <input type="text" class="form-control" name="Name" [(ngModel)]="c_obj['Name']">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Description' | translate}}</label>
              <input type="text" class="form-control" name="Description" [(ngModel)]="c_obj['Description']">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'SelectOption' | translate}}</label>
              <ng-select [(ngModel)]="optionSelected" name="optionSelected" [multiple]="false" >
                <ng-option *ngFor="let option of optionsList; index as i" value="{{option}}">{{i + 1}} - {{option | translate}}</ng-option>
              </ng-select>
              <small style="color: #C8102E;" *ngIf="showErrorMessage">{{'MustSelectOption' | translate}}</small>
            </div>
          </div>

          <div class="row" *ngIf="optionSelected != null && optionSelected == 'Grupo'">
            <ng-select name="groupName">
              <ng-option *ngFor="let group of Groups; index as i" value="{{group}}">{{i + 1}} - {{group | translate}}</ng-option>
            </ng-select>
          </div>

          <div class="row" *ngIf="optionSelected != null && optionSelected == 'GroupName'">
            <div class="col-md-12 sk-ibox">
              <label>{{'Select' | translate}}&nbsp;{{'GroupName' | translate}}</label>
              <ng-select name="groupName"[(ngModel)]="c_obj['GroupId']" [multiple]="false">
                <!-- <ng-option *ngFor="let group of Group; index as i" value="{{group.groupId}}">{{i + 1}} -
                  {{group.name | translate }}</ng-option> -->
                  <ng-select name="GroupId" [(ngModel)]="u_obj['GroupId']" [multiple]="false">
                    <ng-option *ngFor="let group of this.Group; index as i" value="{{group.groupId}}">{{i + 1}} -
                      {{group.Name | translate}}</ng-option>
                  </ng-select>
              </ng-select>
            </div>
          </div>


          <div class="row" *ngIf="optionSelected != null && optionSelected == 'User'">

            <div class="col-md-12 sk-ibox">
              <label>{{'Select' | translate}}&nbsp;{{'User' | translate}}</label>
              <ng-select [(ngModel)]="c_obj['AlertByUser']" name="user" [multiple]="true">
                <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                  {{user.FirstName}} {{user.LastName}}</ng-option>
              </ng-select>
            </div>
          </div>


        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
    <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
            (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
  </div>
</ng-template>


<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'update_item' | translate}}</h4>
    <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Name' | translate}}</label>
              <input type="text" class="form-control" name="DocumentName" [(ngModel)]="u_obj['Name']">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'Description' | translate}}</label>
              <input type="text" class="form-control" name="Description" [(ngModel)]="u_obj['Description']">
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 sk-ibox">
              <label>{{'SelectOption' | translate}}</label>
              <ng-select [(ngModel)]="optionSelected" name="optionSelected" [multiple]="false" >
                <ng-option *ngFor="let option of optionsList; index as i" value="{{option}}">{{i + 1}} - {{option | translate}}</ng-option>
              </ng-select>
              <small style="color: #C8102E;" *ngIf="showErrorMessage">{{'MustSelectOption' | translate}}</small>
            </div>
          </div>

          <div class="row" *ngIf="optionSelected != null && optionSelected == 'Grupo'">
            <ng-select name="groupName" [(ngModel)]="optionSelected">
              <ng-option *ngFor="let group of Groups; index as i" value="{{group}}">{{i + 1}} - {{group | translate}}</ng-option>
            </ng-select>
          </div>

          <div class="row" *ngIf="optionSelected != null && optionSelected == 'GroupName'">
            <div class="col-md-12 sk-ibox">
              <label>{{'Select' | translate}}&nbsp;{{'GroupName' | translate}}</label>
              <ng-select name="GroupId" [(ngModel)]="u_obj['GroupId']" [multiple]="false">
                <ng-option *ngFor="let group of Group; index as i" value="{{group.GroupId}}">{{i + 1}} -
                  {{group.Name | translate}}</ng-option>
              </ng-select>
            </div>
          </div>


          <div class="row" *ngIf="optionSelected != null && optionSelected == 'User'">

            <div class="col-md-12 sk-ibox">
              <label>{{'Select' | translate}}&nbsp;{{'User' | translate}}</label>
              <ng-select [(ngModel)]="u_obj['UpdateAlertByUser']" name="user" [multiple]="true">
                <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} - {{user.FirstName}} {{user.LastName}}</ng-option>
              </ng-select>
            </div>
          </div>


        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
    <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-C8102E"
            (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
  </div>
</ng-template>
