import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { ConsoleLogger } from "@angular/compiler-cli/ngcc";

@Component({
  selector: 'app-purchaseorderlinedetails',
  templateUrl: './purchaseorderlinedetails.component.html',
  styleUrls: ['./purchaseorderlinedetails.component.css']
})
export class PurchaseorderlinedetailsComponent extends BaseCrudComponent implements OnInit {
  //id = '';
  id: any;
  mode = 0; // Show all purchase order lines
  userType = '';
  activeStatus = 0;
  statusList = [];
  documentList = [];
  toggle = true;
  hidden = [];
  OrderDisplay = '';
  OrderNumber = '';
  objRow = null;
  buttonCreateVisible = false;


  //Filter
  showIconF = false;
  seeFilter = false;
  arrayList = [];
  hiddenFunction = [];
  hiddenFilter = [];
  dataFilter = [];
  dataSearchList = [];

  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;
    //console.log("id:", this.id)
    this.search_fields = ['OrderDisplay', 'ItemName', 'ItemNumber', 'GrossWeight', 'Origen', 'Division', 'AssociatedPurchaseOrders'];
    if (this.id == "All") {
      this.mode = 0; // Show all purchase order lines per purchase order selected
    } else if (this.id > 0) {
      this.mode = 2;
    }
  }
 

  async ngOnInit() {
    await super.ngOnInit();
    this.permissionList = this._authService.GetPermissionsByModule("CX_POLD");
    //console.log("permisos:",this.permissionList)

    // this.userType = this._authService.ExistPermission("CX_ADM") ? 'ADM'
    // : this._authService.ExistPermission("CX_AGA") ? 'AGA'
    //   : this._authService.ExistPermission("CX_CM") ? 'CM' 
    //   : this._authService.ExistPermission("CX_COM") ? 'COM'
    //   : this._authService.ExistPermission("CX_FIN") ? 'FIN'
    //   : this._authService.ExistPermission("CX_CAL") ? 'CAL'
    //   : this._authService.ExistPermission("CX_TAX") ? 'TAX'
    //   :'ADM';

    // this.userType = this._authService.ExistPermission("CX_ADM") ? '1'
    //   : this._authService.ExistPermission("CX_AGA") ? '4'
    //     : this._authService.ExistPermission("CX_TRA") ? '5'
    //       : this._authService.ExistPermission("CX_BOD") ? '6'
    //         : this._authService.ExistPermission("CX_LOG") ? '7'
    //           : this._authService.ExistPermission("CX_CM") ? '3' : '1';

    this.userType = this._authService.ExistPermission("CX_ADM") ? '1'
      : this._authService.ExistPermission("CX_AGA") ? '4'
        : this._authService.ExistPermission("CX_TRA") ? '5'
          : this._authService.ExistPermission("CX_BOD") ? '6'
            : this._authService.ExistPermission("CX_LOG") ? '7'
              : this._authService.ExistPermission("CX_CM") ? '3'
                : this._authService.ExistPermission("CX_CAL") ? '8'
                  : this._authService.ExistPermission("CX_TAX") ? '9'
                    : this._authService.ExistPermission("CX_COM") ? '10'
                      : this._authService.ExistPermission("CX_FIN") ? '11' : '1';
    this.user = this._authService.getUserLogged();

    await this.getPurchaseOrderLinesDetails();

  }

  public async getPurchaseOrderLinesDetails() {
    //console.log("modo", this.mode);
    if (this.mode == 0) {
      this.url = environment.Global.API_GET_ALL_POLD_BY_USER + this.userType + "/" + this.user.sub;
    } else {
      this.url = environment.Global.API_GET_ALL_POLD_BY_POL + this.id
    }
    // console.log(this.url);
    this.messageShowLoading();

    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object.length > 0) {
          //console.log("DATA:",result);
          this.rows = result.Object;
          this.rawdata = result.Object;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.OrderNumber = result.Object[0].OrderNumber;
          this.OrderDisplay = result.Object[0].OrderLine;

          //console.log("objeto:",result.Object);
          //console.log("row:",this.rows)
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
      this.closeMessageDialog();
    })
    this.getNotificationAP(this.user.sub);
    this.getNotificationAC(this.user.sub);
    this.getNotificationCXP(this.user.sub);
    this.getNotificationCP(this.user.sub);
    this.getNotificationCA(this.user.sub);
    this.filterAll();
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }
  public searchDataSelect(event) {
    let data = event.split('|'); debugger;
    if (data[2] == '(Vacíos)') {
      data[2] = null;
    }
    this.dataSearchList[data[0]] = data[2] != '' ? (data[1] + '|' + data[2]) : null;//''
    this.search2 = this.dataSearchList;
    this.filterAllSelect();
  }
  public resetData(index, columnName) {
    let array = [];
    this.arrayList[index] = [];
    this.rows.forEach(element => {
      if (element[columnName] != undefined) {
        let flag = array.indexOf(element[columnName]);
        if (flag == -1) {
          array.push(element[columnName]);
        }
      }
    });
    array.push('(Vacíos)');
    this.arrayList[index] = array;
  }

  public async refreshData(obj) {
    let row = {};
    this.hidden[obj.id] = !this.hidden[obj.id];
    await this.getPurchaseOrderLinesDetails();
    this.rows.forEach(line => {
      if (line.OrderLineId == obj.idRow) {
        row = line;
      }
    });
    setTimeout(() => {
      this.seeDetailsRow(obj.id, row);
    }, 750);
  }

  public async seeDetailsRow(index, row) {
    this.objRow = row;
    this.hidden[index] = !this.hidden[index];
  }

  public showIconFilter(flag: boolean) {
    this.showIconF = flag;
    if (!flag) {
      this.searchData('');
      this.arrayList = [];
    }
    //console.log("url:",this.url)
  }


}
