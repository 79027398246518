import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { mergeMap, catchError, timeout } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { OfflineContainer } from '../offlinecontainer';
import { environment } from '../../../environments/environment';
import { Response } from 'src/app/interfaces/Response';
import { UserCx } from 'src/app/interfaces/User';
import Swal from 'sweetalert2';
import { UserManager, User, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { UserLogged } from 'src/app/interfaces/UserLogged';

import { data } from 'jquery';


const API_GENERAL_URL = environment.Global.API;

//max time for api requests
const MAX_TIME = 180000;
export interface Notificacion {
  tipo_noti: number,
  total: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: any;
  public offline = new BehaviorSubject(false);
  private _userManager: UserManager;
  private NotificationAP: BehaviorSubject<Notificacion> = new BehaviorSubject<Notificacion>({ tipo_noti: 1, total: "0" });
  private NotificationAC: BehaviorSubject<Notificacion> = new BehaviorSubject<Notificacion>({ tipo_noti: 1, total: "0" });
  private NotificationCXP: BehaviorSubject<Notificacion> = new BehaviorSubject<Notificacion>({ tipo_noti: 1, total: "0" });
  private NotificationCP: BehaviorSubject<Notificacion> = new BehaviorSubject<Notificacion>({ tipo_noti: 1, total: "0" });
  private NotificationCA: BehaviorSubject<Notificacion> = new BehaviorSubject<Notificacion>({ tipo_noti: 1, total: "0" });



  constructor(
    private _router: Router,
    private http: HttpClient,
    public storage: Storage,
  ) {
    this.url = API_GENERAL_URL;
  }

  private get idpSettings(): UserManagerSettings {
    return {
      authority: environment.Global.authority,
      client_id: environment.Global.client_Id,
      redirect_uri: environment.Global.redirect_uri,
      scope: environment.Global.scope,
      response_type: environment.Global.response_type,
      post_logout_redirect_uri: environment.Global.post_logout_redirect_uri,
      automaticSilentRenew: false,
      response_mode: "query",
      userStore: new WebStorageStateStore({ store: window.localStorage })
    }
  }

  public async getUserLogged() {
    let user: UserCx = null;
    let dato = JSON.parse(localStorage.getItem(environment.Global.key_user));
    user = {
      name: dato.profile.name,
      company: dato.profile.CompanyName,
      isLogged: true,
      sub: dato.profile.sub
    }
    let userLogged: UserLogged = {
      UserName: user.name,
      Sub: user.sub,
      LoggedTime: ''
    }
    let url = environment.Global.API_GET_USER_LOG + '/' + user.sub + '/' + user.name;
    let url2 = environment.Global.API_SET_USER_LOG;
    /*await this.get(url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object != null) {
          let value = result.Object;
          let fechaIniciado = new Date(value.LoggedTime.split('T')[0] + ' ' + value.LoggedTime.split('T')[1]);
          let fechaActual = new Date();
          let difference = fechaActual.getTime() - fechaIniciado.getTime();
          let hours = Number((difference / environment.Global.MiliSecondsToHour).toPrecision(2));
          if (hours > 4) {
            Swal.fire({
              title: 'Límite de tiempo excedido',
              text: 'Debe iniciar nuevamente',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#C8102E',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK',
              allowOutsideClick: false
            }).then((result) => {
              if (result.value) {
                this.post(url2, userLogged).subscribe(result => {
                  if (result.Status) {
                    localStorage.clear();
                    this._userManager = new UserManager(this.idpSettings);
                    this._userManager.signoutRedirect();
                  }
                })
              }
            })
          }
        }
      }
    })*/
  }

  public getCall(qst) {
    let resp: Response = {
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` });
        // console.log(headers)
        return this.http.get(`${this.url}` + qst, { headers })
          .pipe(
            mergeMap(data => {
              let mydata: any = null;
              mydata = data;
              if (mydata.Status == 'success') {
                resp.Status = true;
              }
              resp.Message = mydata.Message;
              resp.Object = mydata.Object;
              resp.Code = mydata.Code;
              return of(resp);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              resp.Message = 'system error, ' + err;
              return of(resp);
            })
          );
      })
    );
  }

  public get(qst) {
    let resp: Response = {
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${this.url}` + qst, { headers })
      .pipe(
        mergeMap(data => {
          let mydata: any = null;
          mydata = data;
          if (mydata.Status == 'success') {
            resp.Status = true;
          }
          resp.Message = mydata.Message;
          resp.Object = mydata.Object;
          resp.Code = mydata.Code;
          return of(resp);
        }),
        timeout(MAX_TIME),
        catchError(err => {
          resp.Message = 'system error, ' + err;
          return of(resp);
        }
        )
      );
  }

  public postCall(qst, obj) {
    var resp = { success: false, msg: '', val: [] };
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` });
        return this.http.post(`${this.url}` + qst, obj, { headers })
          .pipe(
            mergeMap(data => {
              let mydata: any = { STATUS: '', OBJETO: [], MENSAJE: '' };
              mydata = data;

              if (mydata.STATUS == 'success') {
                //return of(mydata.OBJETO);
                resp.success = true;
              }

              resp.msg = mydata.MENSAJE;
              resp.val = mydata.OBJETO;

              return of(resp);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              resp['msg'] = 'system error';
              return of(resp);
            })
          );
      })
    );
  }

  public post(qst, obj) {
    let resp: Response = {
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.url}` + qst, obj, { headers })
      .pipe(
        mergeMap(data => {
          let mydata: any = null;
          mydata = data;
          if (mydata.Status == 'success') {
            resp.Status = true;
          }
          resp.Message = mydata.Message;
          resp.Object = mydata.Object;
          resp.Code = mydata.Code;
          return of(resp);
        }),
        timeout(MAX_TIME),
        catchError(err => {
          resp.Message = 'system error, ' + err;
          return of(resp);
        }
        )
      );
  }

  public persistData(key: string, val: any) {
    return this.storage.set(key, val);
  }

  public getPeristedData(key: string) {
    return this.storage.get(key);
  }

  public getBlobCall(qst) {
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        return this.http.get(`${this.url}` + qst, { headers, responseType: 'blob' })
          .pipe(
            mergeMap(data => {
              //console.log(data);
              return of(data);
              //return of(true);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              return of(false);
            })
          );
      })
    );
  }

  public postBlobCall(qst, to) {
    return from(this.storage.get('token')).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        return this.http.post(`${this.url}` + qst, to, { headers, responseType: 'blob' })
          .pipe(
            mergeMap(data => {
              //console.log(data);
              return of(data);
              //return of(true);
            }),
            timeout(MAX_TIME),
            catchError(err => {
              return of(false);
            })
          );
      })
    );
  }

  public saveOfflineData(key: string, obj: any) {
    let tmp = new OfflineContainer();
    tmp.setData(obj);
    return this.persistData(key, tmp);
  }

  public getOfflineData(key: string) {
    return this.getPeristedData(key).then(x => {
      if ((x == undefined) || (x == null)) {
        return null;
      }
      else {
        let tmp = new OfflineContainer();
        tmp.data = x.data;
        tmp.last_update = x.last_update;
        return tmp;
      }

    });
  }

  public isReachable() {
    var url = 'https://ha.skretting360.com/';
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(function (resp) {
        return resp && (resp.ok || resp.type === 'opaque');
      })
      .catch(function (err) {
        console.warn('[conn test failure]:', err);
      });
  }


  public downloadFiles(qst): Observable<Blob> {
    return this.http.get(this.url + qst, { responseType: 'blob' });

  }

  AddFiles(Url, data: FormData): Observable<string> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post<string>(this.url + Url, data, httpOptions);
  }

  //  EMITIMOS EL EVENTO PARA LAS CAMBIAR EL CONTADOR DE LAS NOTIFICACIONES.
  get NotificationObserverAP() {
    return this.NotificationAP.asObservable();
  }
  set NotificationObserverAPData(data: Notificacion) {
    this.NotificationAP.next(data);
  }

  get NotificationObserverAC() {
    return this.NotificationAC.asObservable();
  }
  set NotificationObserverACData(data: Notificacion) {
    this.NotificationAC.next(data);
  }

  get NotificationObserverCXP() {
    return this.NotificationCXP.asObservable();
  }
  set NotificationObserverCXPData(data: Notificacion) {
    this.NotificationCXP.next(data);
  }
  get NotificationObserverCP() {
    return this.NotificationCP.asObservable();
  }
  set NotificationObserverCPData(data: Notificacion) {
    this.NotificationCP.next(data);
  }
  get NotificationObserverCA() {
    return this.NotificationCA.asObservable();
  }
  set NotificationObserverCAData(data: Notificacion) {
    this.NotificationCA.next(data);
  }

}
