import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import {saveAs as importedSaveAs  } from "file-saver";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-approvedocument',
  templateUrl: './approvedocument.component.html',
  styleUrls: ['./approvedocument.component.css']
})
export class ApprovedocumentComponent extends BaseCrudComponent implements OnInit {

  isPDF = true;
  isImage = false;
  pdfSrc = "";
  listDocumentAprovved = []
  obj2:any

  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    private route: ActivatedRoute,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    //this.search_fields = ['OrderDisplay','OrderNumber', 'ItemName','ItemNumber', 'GrossWeight','Origen', 'Division'];
    this.search_fields= ['OrderNumber','OrderDisplay','UserResponsable','Agent','UserPurchaseLine','DocumentName','FileName','StatusName'];
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.permissionList = this._authService.GetPermissionsByModule("CX_APD");
    this.user = this._authService.getUserLogged();

    await this.getInitialData();
  }

  public async getInitialData() {
    //console.log('LLEGA APROBACION => INFO')

    this.url = environment.Global.API_GET_DOC_POL_US_NEW + this.user.sub; //API_GET_DOC_POL_US
    this.messageShowLoading();

    this.srv.get(this.url).subscribe(result => {
      if (result.Status) {
        if (result.Object != null) {
          this.rawdata = result.Object;
          this.rows = result.Object;
          console.log(this.rows)
          this.page = 1;
          //console.log("result.Object:",result.Object)
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.closeMessageDialog();
        } else {
          this.messageDialog("Empty list", '', 'warning');
        }
      } else {
        this.messageDialog(result.Message, '', 'error');
      }
    })
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
  }

  public searchData(event) {
    //console.log("event",event)
    this.search = event;
    this.filterAll();
  }


  public clickOnCheck(event, DocumentId) {
    /*if(this.listDocumentAprovved.length === 0){
      // Agrega el primer Documento para Aprobar
      this.listDocumentAprovved.push({ DocumentId: DocumentId})

    }else{
      // Busca el Documento en el arrgeglo
      const documentoEncontrado = this.listDocumentAprovved.find(objeto => objeto.DocumentId === DocumentId);

      if(documentoEncontrado == undefined){

        // Agrega el Documento al Arreglo
        this.listDocumentAprovved.push({ DocumentId: DocumentId})

      } else{

        // Obtiene el index del Documento en el arreglo
        const index = this.listDocumentAprovved.findIndex(obj => obj.DocumentId === DocumentId);

        if (index !== -1) {
          // Elimina el Documento del Arreglo
          this.listDocumentAprovved.splice(index, 1);
        }
      }
    }
    //console.log(this.listDocumentAprovved)
    return this.listDocumentAprovved */
    const index = this.listDocumentAprovved.findIndex(obj => obj.DocumentApproveId === DocumentId);
    if (index === -1) {
      this.listDocumentAprovved.push({ DocumentApproveId: DocumentId}); // Si no está seleccionada, agregar
    } else {
      // Obtiene el index del Documento en el arreglo
      this.listDocumentAprovved.splice(index, 1); // Si está seleccionada, quitar
    }
  }
  isRowSelected(id: number): boolean {
    return this.listDocumentAprovved.findIndex(obj => obj.DocumentApproveId === id) !=-1;
  }

  public DownloadFile(row) {
    //console.log("row:",row)
    let FileName = row.FileName
    this.url = environment.Global.API_DOWNLOAD_FILE_POL + row.PurchaseOrderLineDocumentId;
    this.messageShowLoading();
    this.srv.downloadFiles(this.url).subscribe( result => {
      //console.log(result)
      importedSaveAs(result,FileName )
      this.closeMessageDialog();
    }, err => {
      this.messageDialog(this.translations.FileErrorDownload, '', 'error');
    })
  }


  public validateAllDocuments(listDocumentAprovved){


    //console.log(listDocumentAprovved)

    if(listDocumentAprovved.length === 0){
      // Alerta de seleccion null
      this.messageDialog('Seleccione al menos un Documento para aprobar', '', 'error');

    } else{


      var Documents = []




      // llamar a funcion de validar documentos

      Swal.fire(
        {
          title: this.translations.DocumentValidation,
          html: this.translations.BodyDocumentValidation,
          icon: 'info',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: this.translations.Reject,
          cancelButtonColor: '#84888b',
          confirmButtonText: this.translations.Approve,
          confirmButtonColor: '#C8102E',
          showCloseButton: true,
        }
      ).then((result) => {
        if (result.isConfirmed) {
          // APRUEBA

          listDocumentAprovved.forEach(element => {
            var DocumentId = element.DocumentApproveId
             var row = this.rows.find(obj => obj.DocumentApproveId === DocumentId)
             this.obj = {
               DocumentApproveId: row.DocumentApproveId,
               PurchaseOrderLineDocumentId: row.PurchaseOrderLineDocumentId,
               UserId: row.UserId,
               Comment: null,
               Approved: "AP",
             }
             Documents.push(this.obj)
           });

          this.srv.post(environment.Global.API_APPROVE_ALLFILE_POL, Documents).subscribe( result => {
            if(result.Status) {
              this.messageDialog(result.Message, '', 'success');
              this.clearData();
              this.listDocumentAprovved = [];
            } else {
              this.messageDialog(result.Message, '', 'error');
            }
          })

        } else {
          // RECHAZA

          this.AddAllComment(listDocumentAprovved, Documents);
        }
      })
    }

  }

  public ValidateDocument(row) {
    this.obj = {
      DocumentApproveId: row.DocumentApproveId,
      PurchaseLineDocumentId: row.PurchaseOrderLineDocumentId,
      UserId: row.UserId,
      Comment: null,
      Approved: ''
    }
    Swal.fire(
      {
        title: this.translations.DocumentValidation,
        html: this.translations.BodyDocumentValidation,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Reject,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.Approve,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
      }
    ).then((result) => {
      if (result.isConfirmed) {
        this.obj.Approved = "AP";
        this.srv.post(environment.Global.API_APPROVE_FILE_POL, this.obj).subscribe( result => {
          if(result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.clearData();
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
        })
      } else {
        this.AddComment();
      }
    })
  }

  public AddComment() {
    Swal.fire(
      {
        title: this.translations.RejectDocument,
        html: this.translations.AddComment,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.Reject,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
        input: 'text',
        preConfirm: (data) => {
          if (data == '') {
            Swal.showValidationMessage(
              this.translations.AddComment
            )
          }
        }
      }
    ).then((result) => {
      if (result.isConfirmed) {
        this.obj.Approved = "RC";
        this.obj.Comment = result.value
        this.srv.post(environment.Global.API_APPROVE_FILE_POL, this.obj).subscribe( result => {
          if(result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.clearData();
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
        });
      }
    })
  }

  public AddAllComment(listDocumentAprovved,Documents ) {
    Swal.fire(
      {
        title: this.translations.RejectDocument,
        html: this.translations.AddComment,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.Reject,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
        input: 'text',
        preConfirm: (data) => {
          if (data == '') {
            Swal.showValidationMessage(
              this.translations.AddComment
            )
          }
        }
      }
    ).then((result) => {
      if (result.isConfirmed) {
        //debugger;
        listDocumentAprovved.forEach(element => {
          var DocumentId = element.DocumentApproveId//DocumentId
           var row = this.rows.find(obj => obj.DocumentApproveId === DocumentId)
           this.obj = {
             DocumentApproveId: row.DocumentApproveId,
             PurchaseOrderLineDocumentId: row.PurchaseOrderLineDocumentId,
             UserId: row.UserId,
             Comment: result.value, // Agrega el comentario de rechazo
             Approved: "RC",
           }
           Documents.push(this.obj)
         });

        this.srv.post(environment.Global.API_APPROVE_ALLFILE_POL, Documents).subscribe( result => {
          if(result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.clearData();
            this.listDocumentAprovved = [];
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
        })
      }
    })
  }

  public openUpdateModal(modal, object){

    let url = environment.Global.url.substring(0, (environment.Global.url.length - 4))
    //console.log("url:=>",url)
    let termino = "Documents";

    let PathFile = object.PathFile;
    let posicion = PathFile.indexOf(termino);

    let extension=object.FileName.split('.');
    extension=extension[extension.length-1];

    if(extension=='pdf'){
      this.isPDF = true;
      this.isImage = false;
    } else if (extension == 'jpg' || extension == 'JPG' || extension== 'jpeg' || extension == 'JPEG' || extension== 'png' || extension == 'PNG' ) {
      this.isPDF = false;
      this.isImage = true;
    } else {
      this.isPDF = false;
      this.isImage = false;
    }

    this.pdfSrc = url + (PathFile.substring(posicion, PathFile.length));
    this.pdfSrc = this.pdfSrc.replace(/\\/g, "/");
    //console.log("this.pdfSrc=>",this.pdfSrc);
    this.isUpdating = false;
   // this.u_obj = JSON.parse(JSON.stringify(object));

    if(this.isPDF != false || this.isImage != false) {
      this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
    }
    else {
      this.messageDialog("Archivo de extensión no valido para visualizar", '', 'error');
      this.closeModal(modal);
    }
  }
}
