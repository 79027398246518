import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-comex',
  templateUrl: './comex.component.html',
  styleUrls: ['./comex.component.css']
})
export class ComexComponent implements OnInit {

  constructor(private translate: TranslateService) {
        /*var userLang = 'es';
      	translate.setDefaultLang(userLang);
      	translate.use(userLang);*/
    }

  ngOnInit() {
  }

}
