<app-header-options
(updateInfo)="getInitialData()"
(search)="searchData($event)"
[isLoading]="isLoading"
[buttonCreateVisible]="buttonCreateVisible"
[PermissionList]="permissionList"
>
</app-header-options>

<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr >
                        <th class="sh">
                            <label class="text-nowrap" name="ID">ID</label>
                        </th>
                        <th class="sh" class="text-nowrap">
                            <label class="text-nowrap" name="Agent">{{'Agent' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="User">{{'User' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="Table">{{'Table' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="OrderDisplay">{{'OrderDisplay' | translate}}</label>
                        </th>
                        <th class="sh">
                            <label class="text-nowrap" name="Field">{{'Field' | translate}}</label>
                        </th>
                        <th >
                            <label class="text-nowrap" name="PreviousValue">{{'PreviousValue' | translate}}</label>
                        </th>
                        <th >
                            <label class="text-nowrap" name="CurrentValue">{{'CurrentValue' | translate}}</label>
                        </th>
                        <th >
                            <label class="text-nowrap" name="Reason">{{'Reason' | translate}}</label>
                        </th>
                        <th >
                            <label class="text-nowrap" name="Status">{{'Status' | translate}}</label>
                        </th>
                        <th >
                            <label class="text-nowrap" name="actions">{{'actions' | translate}}</label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>
                        <td>
                            <label for="Agent">{{row.Agent}}</label>
                        </td>
                        <td>
                            <label for="UserName">{{row.UserName}}</label>
                        </td>
                        <td>
                            <label for="TableName">{{row.TableName | translate}}</label>
                        </td>
                        <td>
                            <label for="OrderDisplay">{{row.OrderDisplay}}</label>
                        </td>
                        <td>
                            <label for="Field">{{row.Field | translate}}</label>
                        </td>
                        <td>
                            <label for="PreviousValue">{{row.PreviousValue}}</label>
                        </td>
                        <td>
                            <label for="CurrentValue">{{row.CurrentValue}}</label>
                        </td>
                        <td>
                            <label for="Commentary">{{row.Commentary}}</label>
                        </td>
                        <td>
                            <label for="Status">{{row.Approve == "P" ? "Pendiente":"P"}}</label>
                        </td>
                        <td>
                            <i (click)="ApproveField(row, 1)" style="cursor:pointer;" class="mdi mdi-check text-success m-r-10" title="{{'ApproveField' | translate}}"></i>
                            <i (click)="ApproveField(row, 2)" style="cursor:pointer;" class="mdi mdi-close-circle-outline text-danger m-r-10" title="{{'RejectField' | translate}}"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="11">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>
