<div class="m-b-20">
    <input type="text" [(ngModel)]="word" class="form-control"  name="search" placeholder="{{'search_msg' | translate}}" (keyup)="findWord()">
</div>
<div class="row m-b-20">
    <div class="col-lg-12">
        <div class="btn-toolbar justify-content-between" role="toolbar">
            <div>
                <button *ngIf="buttonCreateVisible && globalService.containPermission(PermissionList,'Create') " type="button" class="btn btn-custom-C8102E" (click)="openCreateModal()" ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
            </div>
            <div>
                <button *ngIf="globalService.containPermission(PermissionList,'Read')" [ladda]="isLoading" data-style="expand-left" type="button" class="btn btn-custom-C8102E" (click)="getList()"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
            </div>
        </div>
    </div>
</div>
