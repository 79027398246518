<div >
    <div class="row" style="margin-left: 15px;">

        
        <div class="col-md-4 col-sm-12">
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <label for="">{{'DateDepartureReception'|translate}}</label>
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init_dr">
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end_dr">
                </div>
            </div>
        </div>

        
        <div class="col-md-4 col-sm-12">
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <label for="">{{'EntryToPort'|translate}}</label>
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init_ep">
                </div>
                <div class="col-md-5 col-sm-12">
                    <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end_ep">
                </div>
            </div>
        </div>

        <div class="col-md-4 col-sm-12">
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <label for="">{{'ItemName'|translate}}</label>
                </div>

                <div class="col-md-5 col-sm-12">
                    <ng-select [(ngModel)]="u_obj['ItemId']" name="ItemId" [multiple]="false">
                        <ng-option value="0">{{'Select'|translate}} {{'ItemName'|translate}}... </ng-option>
                        <ng-option *ngFor="let st of itemList" value="{{st.ItemNumber}}">{{st.ItemNumber}} -
                            {{st.Name}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-5 col-sm-12">

                    <!-- *ngIf="this.u_obj['ItemId']!=0 && this.u_obj['UserId']!=0 ||this.u_obj['UserId']==null " -->
                    <button [ladda]="isFind" type="button" (click)="dataToFind()"
                        class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
                    <button [ladda]="isClean" type="button" (click)="clearFilters()"
                        class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button>
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>

                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>

                        <th>{{'Agent'| translate}}</th>
                        <th>{{'ResponsableAgent'| translate}}</th>
                        <th>{{'EtaReal'| translate}}</th>
                        <th>{{'CASExpiration'| translate}}</th>
                        <th>{{'CAS'| translate}}</th>
                        <th>{{'ProcedureImportline'| translate}}</th>
                        <th>{{'Supplier'| translate}}</th>
                        <th>{{'OrderNumber'| translate}}</th>
                        <th>{{'ItemNumber'| translate}}</th>
                        <th>{{'ItemName'| translate}}</th>
                        <th>{{'Packaging'| translate}}</th>
                        <th>{{'BL'| translate}}</th>
                        <th>{{'ContainerNumber'| translate}}</th>
                        <th>{{'Type'| translate}}</th>
                        <th>{{'StampBl'| translate}}</th>
                        <th>{{'WeightItem'| translate}}</th>
                        <th>{{'EntryToPort'| translate}}</th>
                        <th>{{'HourInPlant'| translate}}</th>
                        <th>{{'TransportCompany'| translate}}</th>
                        <th>{{'WarehouseReception'| translate}}</th>
                        <th>{{'DateDepartureReception'| translate}}</th>
                        <th>{{'HourDepartureReception'| translate}}</th>
                        <th>{{'RescheduleDate'| translate}}</th>
                        <th>{{'RescheduleHour'| translate}}</th>
                        <!-- <th>{{'AgentUser'| translate}}</th> -->

                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="showIconF">
                        <td></td>
                        <td>
                            <div (click)="resetData(1,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(2,'UsersAgentsNames')">
                                <app-select-filter [column]="'UsersAgentsNames'" [arrayList]="l[2]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'EtaReal')">
                                <app-select-filter [column]="'EtaReal'" [arrayList]="l[3]"
                                    [indexPosition]="3" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(4,'CASExpiration')">
                                <app-select-filter [column]="'CASExpiration'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'CAS')">
                                <app-select-filter [column]="'CAS'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'ProcedureImportline')">
                                <app-select-filter [column]="'ProcedureImportline'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'CompanyName')">
                                <app-select-filter [column]="'CompanyName'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(9,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[9]"
                                    [indexPosition]="9" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(10,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[10]" [indexPosition]="10"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'PackagingName')">
                                <app-select-filter [column]="'PackagingName'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'Bl')">
                                <app-select-filter [column]="'Bl'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(13,'ContainerNumber')">
                                <app-select-filter [column]="'ContainerNumber'" [arrayList]="l[13]"
                                    [indexPosition]="13" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                        <td>
                            <div (click)="resetData(14,'Type')">
                                <app-select-filter [column]="'Type'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(15,'BlSeal')">
                                <app-select-filter [column]="'BlSeal'" [arrayList]="l[15]" [indexPosition]="15"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(16,'ProductWeight')">
                                <app-select-filter [column]="'ProductWeight'" [arrayList]="l[16]"
                                    [indexPosition]="16" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'EntryToPort')">
                                <app-select-filter [column]="'EntryToPort'" [arrayList]="l[17]"
                                    [indexPosition]="17" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(18,'HourInPlant')">
                                <app-select-filter [column]="'HourInPlant'" [arrayList]="l[18]"
                                    [indexPosition]="18" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'TransportName')">
                                <app-select-filter [column]="'TransportName'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <!-- <td>
                            <div (click)="resetData(20,'NameWareHouse')">
                                <app-select-filter [column]="'NameWareHouse'" [arrayList]="l[20]" [indexPosition]="20"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td> -->
                        <td>
                            <div (click)="resetData(21,'NameWareHouse')">
                                <app-select-filter [column]="'NameWareHouse'" [arrayList]="l[21]" [indexPosition]="21"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(22,'DateDepartureReception')">
                                <app-select-filter [column]="'DateDepartureReception'" [arrayList]="l[22]"
                                    [indexPosition]="22" (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(23,'HourDepartureReception')">
                                <app-select-filter [column]="'HourDepartureReception'" [arrayList]="l[23]" [indexPosition]="23"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(24,'RescheduleDate')">
                                <app-select-filter [column]="'RescheduleDate'" [arrayList]="l[24]" [indexPosition]="24"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(25,'RescheduleHour')">
                                <app-select-filter [column]="'RescheduleHour'" [arrayList]="l[25]" [indexPosition]="25"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                      

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>

                            <td><label for="AgentName">{{row.AgentName}} </label></td>
                            <td><label for="UsersAgentsNames">{{row.UsersAgentsNames}} </label></td>
                            <td><label for="EtaReal">{{row.EtaReal | date:'y-MM-dd'}} </label></td>
                            <td><label for="CASExpiration">{{row.CASExpiration | date:'y-MM-dd'}} </label></td>
                            <td><label for="CAS">{{row.CAS  | date:'y-MM-dd'}} </label></td>
                            <td><label for="ProcedureImportline">{{row.ProcedureImportline}} </label></td>
                            <td><label for="CompanyName">{{row.CompanyName}} </label></td>
                            <td><label for="OrderNumber">{{row.OrderNumber}} </label></td>
                            <td><label for="ItemNumber">{{row.ItemNumber}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="PackagingName">{{row.PackagingName}} </label></td>
                            <td><label for="Bl">{{row.Bl}} </label></td>
                            <td><label for="ContainerNumber">{{row.ContainerNumber}} </label></td>
                            <td><label for="Type">{{row.Type }} </label></td>
                            <td><label for="BlSeal">{{row.BlSeal}} </label></td>
                            <td><label for="ProductWeight">{{row.ProductWeight}} </label></td>
                            <td><label for="EntryToPort">{{row.EntryToPort | date:'y-MM-dd'}} </label></td>
                            <td><label for="HourInPlant">{{row.HourInPlant }} </label></td>
                            <td><label for="TransportName">{{row.TransportName}} </label></td>
                            <td><label for="NameWareHouse">{{row.NameWareHouse}} </label></td>
                            <td><label for="DateDepartureReception">{{row.DateDepartureReception | date:'y-MM-dd'}} </label></td>
                            <td><label for="HourDepartureReception">{{row.HourDepartureReception}} </label></td>
                            <td><label for="RescheduleDate">{{row.RescheduleDate | date:'y-MM-dd'}} </label></td>
                            <td><label for="RescheduleHour">{{row.RescheduleHour}} </label></td>
                            <!-- <td><label for="Comentary">{{row.UsersAgentsNames}} </label></td> -->

                        </tr>

                    </ng-container>


                </tbody>

            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5">
            </ngb-pagination>

        </div>

    </div>
</div>