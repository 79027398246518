<app-header-options (updateInfo)="getInitialData()" (search)="searchData($event)" [isLoading]="isLoading"
    [PermissionList]="permissionList">
</app-header-options>

<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr>
                        <th class="sh">
                            ID
                        </th>
                        <th (click)="setOrder('OrderNumber')" class="sh">
                            {{'OrderNumber' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='OrderNumber')"
                                [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>
                        <th>
                            {{'CompanyName' | translate}}
                        </th>
                        <th>
                            {{'ItemName' | translate}}
                        </th>
                        <th>
                            {{'Agent' | translate}}
                        </th>
                        <th>
                            {{'UserResponsableSk' | translate}}
                        </th>
                        <th class="sh text-center">
                            {{'GrossWeightOrder' | translate}}
                        </th>
                        <th class="sh text-center">
                            {{'Status' | translate}}
                        </th>
                        <th>
                            {{'actions' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>
                        <td>
                            <label for="OrderNumber">{{row.OrderNumber}}</label>
                        </td>
                        <td>
                            <label for="CompanyName">{{row.CompanyName}}</label>
                        </td>
                        <td>
                            <label for="ItemName">{{row.ItemName}}</label>
                        </td>
                        <td>
                            <label for="AgentName">{{row.AgentName}}</label>
                        </td>
                        <td>
                            <label for="UserName">{{row.UserName}}</label>
                        </td>
                        <td class="text-center">
                            <label for="GrossWeightOrder">{{row.GrossWeightOrder}}</label>
                        </td>
                        <td class="text-center">
                            <!-- <label for="StatusOrder">{{row.StatusOrder == 1 || row.StatusOrder == null ? 'Sin progreso' : row.StatusOrder == 2 ? 'En progreso': 'Finalizada'}}</label> -->
                            <label for="StatusOrderName">{{row.StatusOrderName}}</label>
                        </td>
                        <td class="text-center">
                            <i *ngIf="globalService.containPermission(permissionList,'Update')"
                                (click)="openUpdateModal(update1, row)" style="cursor:pointer;"
                                class="far fa-edit text-info m-r-10" title="{{'assign_user' | translate}}"></i>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="rows.length > 0">
                    <td colspan="10">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                            style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
        <h4 class="modal-title">{{'update_item' | translate}}</h4>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="card-body">
                <h3>{{'Header' | translate}}</h3>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'OrderNumber' | translate}}</label>
                            <input type="text" class="form-control" name="OrderNumber"
                                [(ngModel)]="u_obj['OrderNumber']" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'CompanyName' | translate}}</label>
                            <input type="text" class="form-control" name="CompanyName"
                                [(ngModel)]="u_obj['CompanyName']" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Partner' | translate}}</label>
                            <input type="text" class="form-control" name="Partner" [(ngModel)]="u_obj['Partner']"
                                disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'ItemName' | translate}}</label>
                            <input type="text" class="form-control" name="ItemName" [(ngModel)]="u_obj['ItemName']"
                                disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'UserResponsableSk' | translate}}</label>
                            <ng-select [(ngModel)]="u_obj['UserId']" name="UserId" [multiple]="false"
                                (change)="addUserSK(u_obj['UserId'],0, false)" [disabled]="u_obj['isBlock']">
                                <ng-option *ngFor="let user of userList; index as i" value="{{user.UserId}}">{{i + 1}} -
                                    {{user.FirstName}} {{user.LastName}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'Agent' | translate}}</label>
                            <ng-select [(ngModel)]="u_obj['AgentId']" name="AgentId" [multiple]="false"
                                (change)="addUserByAgent(u_obj['AgentId'], false)" [disabled]="u_obj['isBlock']">
                                <ng-option *ngFor="let agent of agentList" value="{{agent.AgentId}}">{{agent.RUC}} -
                                    {{agent.Name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 sk-ibox">
                            <label>{{'TransportCompany' | translate}}</label>
                            <ng-select [(ngModel)]="optionSelected" name="TransportCompanyId" [multiple]="false"
                                (change)="addTransportCompany(optionSelected,0, false)" [disabled]="u_obj['isBlock']">
                                <ng-option *ngFor="let transport of transportList"
                                    value="{{transport.TransportId}}">{{transport.RUC}} - {{transport.Name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <h3>{{'Detail' | translate}}</h3>
                <div class="form-group">
                    <div class="row" *ngFor="let pol of u_obj['PurchaseOrderLine']">
                        <div class="col-md-2 sk-ibox">
                            <label>{{'OrderDisplay' | translate}}</label>
                            <input type="text" class="form-control" name="OrderDisplay" disabled
                                value="{{pol.OrderDisplay}}">
                        </div>
                        <div class="col-md-3 sk-ibox">
                            <label>{{'UserResponsableSk' | translate}} </label>
                            <ng-select [(ngModel)]="pol.UserIdSK" [ngModelOptions]="{standalone: true}"
                                [multiple]="false" (change)="addUserSK(UserSKSelected,pol, false)"
                                [disabled]="pol.bloquearUserIdSK">
                                <ng-option *ngFor="let user of this.userList; index as i"
                                    value="{{user.UserId}}">{{user.FirstName}} {{user.LastName}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3 sk-ibox">
                            <label>{{'User' | translate}} {{'Agent' | translate}}</label>
                            <ng-select [(ngModel)]="pol.List" [ngModelOptions]="{standalone: true}" [multiple]="true"
                                [disabled]="pol.bloquearUserIds">
                                <ng-option *ngFor="let user of userPerAgentList; index as i"
                                    value="{{user.UserId}}">{{user.userName}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3 sk-ibox">
                            <label>{{'TransportCompany' | translate}}</label>
                            <ng-select [(ngModel)]="pol.TransportId" [ngModelOptions]="{standalone: true}"
                                [multiple]="false" (change)="addTransportCompany(pol.TransportId,pol, false)"
                                [disabled]="pol.bloquearTransportId">
                                <ng-option *ngFor="let transport of transportList; index as i"
                                    value="{{transport.TransportId}}">{{transport.RUC}} - {{transport.Name}}</ng-option>
                               
                            </ng-select>
                        </div>
                        <div class="col-md-1 sk-ibox">
                            <label></label>
                            <i (click)="interruptItemSpecific(update1, pol)" style="cursor:pointer;margin-top: 38px;"
                                *ngIf="pol.TransportId != null" class="far fa-trash-alt text-danger m-r-10"
                                title="{{'Reassign' | translate}}"></i>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-custom-84888b" (click)="closeModal(update1)">{{'cancel' |
            translate}}</button>
        <button *ngIf="existAgent" [ladda]="isInterrupt" data-style="expand-left" type="button"
            class="btn btn-custom-C8102E" (click)="interruptItem(update1, u_obj)">{{'Interrupt' | translate}}</button>
        <button *ngIf="!existAgent" [ladda]="isUpdating" data-style="expand-left" type="button"
            class="btn btn-custom-C8102E" (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
    </div>
</ng-template>