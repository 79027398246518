import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { User } from 'oidc-client';
import { AuthService } from './auth.service';

const API_GENERAL_URL = environment.Global.API;
// API_GENERAL_URL
//max time for api requests
const PERMISSIONS_KEY = 'permissions';
const TOKEN_EXPIRES_PERIOD = 20;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {

  private url: any;
  public cont: number;
  isLogged: boolean = false;

  user: User | null = null;  
  tokenExpired = false;

  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage,
    private auth: AuthService
  ) {
    this.url = API_GENERAL_URL;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var page = state.url;
    return this.storage.get(PERMISSIONS_KEY).then(mods => {
        if( navigator.onLine ){
          /**/// Obtener el usuario actual
          this.auth.getUsuario().then(user => {
            this.user = user;
    
            // Verificar si el token está próximo a expirar o ya ha expirado
            this.checkTokenExpiry();
    
            // Renovar el token si es necesario
            this.renewTokenIfNeeded();
          }).catch(error => {
            console.error('Error al obtener el usuario:', error);
          });
        }
        
        let permissions = ['dashboard', 'settings'];
        let ipage:any;

        if( !Array.isArray(mods) ){
            this.clearData().then(() => {
                this._router.navigate(['/authentication/login']);
            });
            return false;
        }

        mods.forEach(i => {
            if( ipage != false ){
                permissions.push(ipage);
            }
        });


        let checker = permissions.filter(p => {
              return page.toLowerCase().includes(p.toLowerCase());
        });


        if( checker.length > 0 ){
            return true;
        }
        else{
            this.clearData().then(() => {
                this._router.navigate(['/authentication/login']);
            });

            return false;
        }
    });
  }


  getPermissions(){
      return this.storage.get(PERMISSIONS_KEY);
  }


  public login(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}login/authenticate`, params, { headers });
  }


  public loginExternal(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}loginExternal/ExternalLogin`, params, { headers });
}

  public getPinCode(email: any) {
    var aq_url='https://wa-t-prevent.azurewebsites.net';
    const params = {
        Ruc: email
    };
    console.log("params:",params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}/api/CompaniesConsulta/CompanySelectRuc`, params, { headers });
  }

  public resetPwd(email: any, pin:any, pwd:any) {
    var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';
    const params = {
        servicetoken: aq_apptoken,
        email: email,
        pincode: pin,
        newpassword: pwd
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/resetpassword`, params, { headers });
  }


  public clearData(){
      return this.storage.clear();
  }

  checkTokenExpiry(): void {debugger;
    if (this.user && this.user.expires_in) {
      const expiryTime = new Date(this.user.expires_at);
      const currentTime = new Date();

      // Verificar si el token ya ha expirado
      if (expiryTime <= currentTime) {
        this.tokenExpired = true;
        return;
      }

      // Definir un margen de tiempo antes de la expiración para considerar "próximo a expirar"
      const marginSeconds = 60; // Por ejemplo, 60 segundos antes de la expiración

      // Verificar si el token está próximo a expirar
      if (expiryTime.getTime() - currentTime.getTime() <= marginSeconds * 1000) {
        this.tokenExpired = true;
      }
    }
  }

  renewTokenIfNeeded(): void {debugger;
    if (this.tokenExpired) {
      // Renovar el token
      this.auth.renewToken().then(() => {
        console.log('Token renovado con éxito.');

        // Obtener el usuario actualizado
        return this.auth.getUser();
      }).then(user => {
        this.user = user;

        // Resetear el estado de tokenExpired
        this.tokenExpired = false;
      }).catch(error => {
        console.error('Error al renovar el token:', error);
      });
    }
  }

}
