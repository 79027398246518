import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.css']
})
export class CrudComponent extends BaseCrudComponent implements OnInit {

  items:any;

  constructor(
    public _router: Router,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public translate: TranslateService,
    private auth: AuthorizationService
  ) {
    super(srv, modalService, storage, router);
   }

  async ngOnInit() {

    await super.ngOnInit();
    this.items = [
      {title: 'submenu_skretting_alert', url: '/comex/cruds/alert-config', icon: 'mdi mdi-newspaper', perm: 'CX_AL'},
      {title: 'submenu_skretting_custom_agent', url: '/comex/cruds/custom-agent-config', icon: 'mdi mdi-newspaper', perm: 'CX_CA'},
      {title: 'submenu_skretting_purchase_order_assign', url: '/comex/cruds/po-assign-config', icon: 'mdi mdi-newspaper', perm: 'CX_POA'},
      // {title: 'submenu_skretting_purchase_order_line_assign', url: '/comex/cruds/pol-assign-config', icon: 'mdi mdi-newspaper', perm: 'CX_POLA'},
      //{title: 'submenu_skretting_purchase_order_line_detail_assign', url: '/comex/cruds/pold-assign-config', icon: 'mdi mdi-newspaper', perm: 'CX_POLDA'},
      // {title: 'submenu_skretting_custom_agent_employee', url: '/comex/cruds/custom-agent-employee-config', icon: 'mdi mdi-newspaper', perm: 'CX_CAE'},
      {title: 'submenu_skretting_warehouses', url: '/comex/cruds/warehouses-config', icon: 'mdi mdi-newspaper', perm: 'CX_WH'},
      {title: 'submenu_skretting_liberatory_code', url: '/comex/cruds/liberatory-code-config', icon: 'mdi mdi-newspaper', perm: 'CX_LC'},
      {title: 'submenu_skretting_transport_company', url: '/comex/cruds/transport-company-config', icon: 'mdi mdi-newspaper', perm: 'CX_TC'},
      {title: 'submenu_skretting_documents', url: '/comex/cruds/documents-config', icon: 'mdi mdi-newspaper', perm: 'CX_DOC'},
      {title: 'submenu_skretting_packaging', url: '/comex/cruds/packaging-config', icon: 'mdi mdi-newspaper', perm: 'CX_PCK'},
      {title: 'submenu_skretting_status', url: '/comex/cruds/status-config', icon: 'mdi mdi-newspaper', perm: 'CX_ST'},
      {title: 'submenu_skretting_observations', url: '/comex/cruds/observation-config', icon: 'mdi mdi-newspaper', perm: 'CX_OB'},
      {title: 'submenu_skretting_parameters', url: '/comex/cruds/parameter-config', icon: 'mdi mdi-newspaper', perm: 'CX_PRM'},
      {title: 'submenu_skretting_permission', url: '/comex/cruds/permission-config', icon: 'mdi mdi-newspaper', perm: 'CX_PRS'},
      {title: 'submenu_skretting_users_group', url: '/comex/cruds/users-config', icon: 'mdi mdi-newspaper', perm: 'CX_US'},
      {title: 'submenu_skretting_additional_documentation', url: '/comex/cruds/app-add-documentation', icon: 'mdi mdi-newspaper',perm: 'CX_ADOC'},      
      {title: 'submenu_skretting_additional_concept', url: '/comex/cruds/concept', icon: 'mdi mdi-newspaper',perm: 'CX_CC'},
      //{title: 'submenu_skretting_additional_subconcept', url: '/comex/cruds/subconcepts', icon: 'mdi mdi-newspaper',perm: 'CX_ADOC'}, // CX_SC

      // {title: 'submenu_skretting_transport_company_employee', url: '/comex/cruds/transport-company-employee-config', icon: 'mdi mdi-newspaper', perm: 'CX_TCE'},
    ];

  }


  hasPermission(str:string){
    return this.auth.existPermission(str);
  }

}
