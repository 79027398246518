import { Component, AfterViewInit, EventEmitter, Output, Inject, OnInit, Input } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { UserCx } from 'src/app/interfaces/User';
import { Observable } from 'rxjs';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent extends BaseCrudComponent implements OnInit, AfterViewInit {

  @Output() toggleSidebar = new EventEmitter<void>();
  // @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  @Input() ViewIconNotify: boolean;
  @Input() UserlogName: boolean;
  @Input() index: any = null;
  public user$: Observable<UserCx>;
  public config: PerfectScrollbarConfigInterface = {};
  public showSearch = false;
  // public countAP = "0";
  // public countAC = "0";
  // public countCP = "0";
  // public countCA = "0";
  userType = '';
  objRow = null;
  hidden = [];

  urlDetail = 'comex/purchase/approve-document';
  urlDetailPOL = 'comex/logistics/purchase-order-line-detail/';
  urlDetailPOLD = 'comex/logistics/purchase-order-line-detail/';



  constructor(
    public globalService: GlobalService, public _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);



  }

  async ngOnInit() {
    this.user$ = this._authService.UserObservable;
    this.user = this._authService.getUserLogged();
    this.permissionList = this._authService.GetPermissionsByModule("CX_POL");
    // this.userType = this._authService.ExistPermission("CX_ADM") ? 'ADM'
    //   : this._authService.ExistPermission("CX_AGA") ? 'AGA'
    //     : this._authService.ExistPermission("CX_CM") ? 'CM' 
    //     : this._authService.ExistPermission("CX_COM") ? 'COM'
    //     : this._authService.ExistPermission("CX_FIN") ? 'FIN'
    //     : this._authService.ExistPermission("CX_CAL") ? 'CAL'
    //     : this._authService.ExistPermission("CX_TAX") ? 'TAX'
    //     :'ADM';
    this.getNotificationAP(this.user.sub);
    this.getNotificationAC(this.user.sub);
    this.getNotificationCXP(this.user.sub);
    this.getNotificationCP(this.user.sub);
    this.getNotificationCA(this.user.sub);
   

  }

  ngAfterViewInit() {
  }

  public logout() {
    this._authService.logout();
  }



  public async redirect(object: any, id: number) {

    // actualizamos la vista de la notificación
    this.url = environment.Global.GET_UPDATE_NOTIFICATION + this.user.sub + "/" + object.NotificationId + "/" + id + "/1/0";
    console.log(this.url);
    debugger
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (id == 3) {
          this.globalService.goToParameterDetail(this.urlDetailPOL + object.DocumentId)

        } else if (id == 4 || id == 5) {
          this.globalService.goToParameterDetail(this.urlDetailPOLD + object.pold[0].OrderLineId)
        }
        else {
          this.globalService.goToParameterDetail(this.urlDetail);
        }
        
      }
    })
    location.reload(); 
  }



  public async seeDetailsRow(index, row) {
    console.log(row);
    this.objRow = row;
    this.hidden[index] = !this.hidden[index];
  }
  

}
