<app-header-options (updateInfo)="getPayments()" (search)="searchData($event)" [isLoading]="isLoading" [buttonCreateVisible]="buttonCreateVisible" [PermissionList]="permissionList">
</app-header-options>

<div class="row">
    <div class="col-lg-12">
        <div class="table-responsive">
            <table class="table">
                <thead class="thead-color">
                    <tr>
                        <th class="sh">
                            ID
                        </th>
                        <th (click)="setOrder('OrderNumber')" class="sh">
                            {{'OrderNumber' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='OrderNumber')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                        </th>
                        <th class="sh">
                            {{'dispatch' | translate}}
                        </th>
                        <th>
                            {{'Division' | translate}}
                        </th>
                        <th class="sh">
                            {{'ItemName' | translate}}
                        </th>
                        <th class="sh">
                            {{'Supplier' | translate}}
                        </th>
                        <th class="sh">
                            {{'Invoice' | translate}}
                        </th>
                        <th class="sh">
                            {{'EmissionDate' | translate}}
                        </th>
                        <th class="sh">
                            {{'Amount' | translate}}
                        </th>
                        <th class="sh">
                            {{'ReviewDate' | translate}}
                        </th>
                        <th class="sh">
                            {{'ApprovalDate' | translate}}
                        </th>
                        <th class="sh">
                            {{'DetailSubconcept' | translate}}
                        </th>
                        <th class="sh">
                            {{'Concept' | translate}}
                        </th>
                        <th>
                            {{'actions' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!--        <div class="col-lg-12" id="div1">-->
                    <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                        <td>
                            <label for="code">{{i + 1}}</label>
                        </td>
                        <td>
                            <label for="OrderNumber">{{row.OrderNumber}}</label>
                        </td>
                        <td>
                            <label for="Dispatch">{{row.Dispatch}}</label>
                        </td>
                        <td>
                            <label for="Div">{{row.Div}}</label>
                        </td>
                        <td>
                            <label for="ItemName">{{row.ItemName}}</label>
                        </td>
                        <td>
                            <label for="Supplier">{{row.Supplier}}</label>
                        </td>
                        <td>
                            <label for="InvoiceNumber">{{row.InvoiceNumber}}</label>
                        </td>
                        <td>
                            <label for="IssueDate">{{row.IssueDate|date:'y-MM-dd'}}</label>
                        </td>
                        <td>
                            <label for="Amount">{{row.AmountLine}}</label>
                        </td>
                        <td>
                            <label for="ReviewDate">{{row.ReviewDate|date:'y-MM-dd'}}</label>
                        </td>
                        <td>
                            <label for="ApprovalDate">{{row.ApprovalDate|date:'y-MM-dd'}}</label>
                        </td>
                        <td>
                            <label for="SubConcept">{{row.SubConcept}}</label>
                        </td>
                        <td>
                            <span [innerHTML]="getConceptName(row.SubConcept)"></span>
                        </td>
                        <td>
                            <i *ngIf="(row.PathFile != NULL)" (click)="DownloadFile(row)" style="cursor:pointer;" class="mdi mdi-cloud-download text-info m-r-10" title="{{'DownloadFile' | translate}}"></i>
                            <i *ngIf="(row.PathFile != NULL)" (click)="openUpdateModal(view, row)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>
            <!--<i (click)="showFollowup(follow1, row)" style="cursor:pointer;" class="fa fa-trash-alt text-info m-r-10" title="{{'reject' | translate}}"></i>-->
                        </td>
                    </tr>
                    <!--        </div>-->
                </tbody>

                <tfoot>
                    <td colspan="14">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>

          <ng-template #view let-c="close" let-d="dismiss" let-modal>
          <!--  <ng-template #view let-modal>-->
            <div class="modal-header head-modal-bg">
              <h4 class="modal-title">{{'preview' | translate}}</h4>
              <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
                <a [href]="pdfSrc" target="_blank"><i class="mdi mdi-arrow-right text-info m-r-10"></i></a>
                <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="modal-body">
              <form class="form-horizontal">
                <ng-container *ngIf=" this.isPDF == true; ">
                  <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                              style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>

                </ng-container>
                <ng-template *ngIf=" this.isImage == true; else elseTemplate">
                  <div style="display: flex;">
                    <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
                         alt="">
                  </div>
                </ng-template>
                <ng-template #elseTemplate>
                  <div style="display: flex;">
                     <h4 class="modal-title" style="align: center;">{{'ViewFile' | translate}}</h4>
                  </div>
                </ng-template>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
            </div>
          </ng-template>
        </div>
    </div>
</div>
