import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../../services/authentication/login.service';
import { isUndefined } from 'util';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  public company: any;
  permissions = [];

  // this is for the open close
  addExpandClass(data) {
    if (data.path == 'about-us') {
      window.open(data.url, '_blank');
    } else {
      if (data.title === this.showMenu) {
        this.showMenu = '0';
      } else {
        this.showMenu = data.title;
      }
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    public srv: LoginService
  ) {}

  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);

  }

  hasPermission(str:string){
      if( this.permissions.indexOf(str) > -1 ){
          return true;
      }
      else{
          return false;
      }
  }
}
