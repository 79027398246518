//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';
import {saveAs as importedSaveAs  } from "file-saver";
import { async } from '@angular/core/testing';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
//export class PaymentsComponent implements OnInit {
export class PaymentsComponent extends BaseCrudComponent implements OnInit {

  userType = '';
  ConceptList = [];
  isPDF = true;
  isImage = false;
  pdfSrc = "";

  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Id','Supplier','Ruc','Div','InvoiceNumber','SubConcept','OrderNumber','Dispatch'];
    this.ConceptList = []
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.buttonCreateVisible = false;
    this.permissionList = this._authService.GetPermissionsByModule("CX_PY");
    this.userType = this._authService.ExistPermission("CX_ADM") ? 'ADM'
      :  this._authService.ExistPermission("CX_AGA") ? 'AGA'
        : this._authService.ExistPermission("CX_CM") ? 'CM' : 'ADM';
    this.user = this._authService.getUserLogged();
   
    await this.getPayments();
    this.GetConcepts(); // Get List Subconcepts By Concepts
  }


  // API devuelve el listado de Conceptos con Subconceptos Activos
  public async GetConcepts () {
    this.url = environment.Global.API_GET_LIST_CONCEPT;
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {
        if (result.Object != null) {
          this.ConceptList = result.Object
        }
      }
    })
  }

  // Devuelve el nombre de un Concepto, segun su Subconcepto
  public getConceptName(NameSubconcept:any){

    var text = ''
    var findOk = 0
    var findEr = 0

    // Convierte a Mayuscula, tildes, espacios al final del string, quita dos espacios entre palabra
    var string_all_caps = NameSubconcept.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().trim().replace("  ", " ");


    // Recorre los Concepts
    this.ConceptList.forEach((value1,index)=>{
      // Recorre los Subconceptos
      value1.SubconceptList.forEach((value2,index)=>{

        var string_all_caps_bd = value2.SubconceptName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().trim().replace("  ", " ");

        if(string_all_caps == string_all_caps_bd ){

          findOk++

          if(findOk == 1){
            text = value1.ConceptName
          } else{
            text = 'NO APLICA'
          }

        } else{
          findEr++
        }

      });

    });

    if(findEr > 0 && findOk == 0 ){
      text = 'NO APLICA'
    }

    return text
  }


  async getPayments() {

    this.isLoading = true;
    this.messageShowLoading();
    this.url = environment.Global.API_GET_PAYMENTS
    this.srv.get(this.url).subscribe(x => {
      if (x.Status) {
        if (x.Object != null) {
          this.rawdata = x.Object;
          this.rows = x.Object;
          this.page = 1;
          this.rows.forEach(element => {
            element.GrossWeightOrder = Number(element.GrossWeightOrder);
          });
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.closeMessageDialog();
        }
      }
    });
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
  }

  public DownloadFile(row) {
    //console.log("entramos a descargar, objeto:",row)
    let FileName = row.AccessKey + '.pdf';
     this.url = environment.Global.API_DOWNLOAD_FILE_PY + row.Id;
    //console.log("url:",this.url, "filename:",FileName)
     this.messageShowLoading();
     this.srv.downloadFiles(this.url).subscribe( result => {
       importedSaveAs(result,FileName )
       this.closeMessageDialog();
     })
  }

  public ApprovalInvoice(row,valor)
  {
    //console.log("pendiente")
  }

  public showFollowup(content:any, obj:any){
    //let newobj = JSON.parse(JSON.stringify(obj));
    //console.log("dato de la fila:",obj);
    this.modalService.open(content, { centered: true });
    //this.f_obj = newobj;
    //this.loadFollowComments();
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }

  public openUpdateModal(modal, object){

    let url = environment.Global.url.substring(0, (environment.Global.url.length - 4))
    //console.log("url:=>",url)
    let termino = "Documents";

    let PathFile = object.PathFile;
    let posicion = PathFile.indexOf(termino);

    let extension=object.PathFile.split('.');
    extension=extension[extension.length-1];

    if(extension=='pdf'){
      this.isPDF = true;
      this.isImage = false;
    } else if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' || extension == 'JPEG' || extension == 'png' || extension == 'PNG' ) {
      this.isPDF = false;
      this.isImage = true;
    } else {
      this.isPDF = false;
      this.isImage = false;
    }


    this.pdfSrc = url + (PathFile.substring(posicion, PathFile.length));
    this.pdfSrc = this.pdfSrc.replace(/\\/g, "/");
    //console.log("this.pdfSrc=>",this.pdfSrc);
    this.isUpdating = false;

    // console.log("Objeto:", this.u_obj);
    // console.log("Type:", this.u_obj);
    if(this.isPDF != false || this.isImage != false) {
      this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
    }
    else {
      this.messageDialog("Archivo de extensión no valido para visualizar", '', 'error');
      this.closeModal(modal);
    }
  }

}
