import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { GlobalService } from 'src/app/services/main/global.service';
// import { url } from 'inspector';
// import { Console } from 'console';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-purchaseorders',
  templateUrl: './purchaseorders.component.html',
  styleUrls: ['./purchaseorders.component.css']
})
export class PurchaseordersComponent extends BaseCrudComponent implements OnInit {
  urlDetail = 'comex/purchase/purchase-order-lines/';
  userType = '';
  pgSize: number = 20;
  ContainerNumber: [] = [];
  constructor(
    public globalService: GlobalService,
    private _authService: AuthService,
    public srv: ApiService,
    public modalService: NgbModal,
    public storage: Storage,
    public router: Router,
    public translate?: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.search_fields = ['OrderNumber', 'CodeSupplier', 'CompanyName', 'UserName', 'AgentName'];
  }

  async ngOnInit() {
    this.pageSize = this.pgSize;
    await super.ngOnInit();
    this.permissionList = this._authService.GetPermissionsByModule("CX_PO");
    this.userType = this._authService.ExistPermission("CX_ADM") ? 'ADM'
      : this._authService.ExistPermission("CX_AGA") ? 'AGA'
        : this._authService.ExistPermission("CX_CM") ? 'CM' : 'ADM';
    this.user = this._authService.getUserLogged();

    await this.getPurchaseOrders();
  }

  async getPurchaseOrders() {
    
    this.isLoading = true;
    this.messageShowLoading();
    await this.getNotificationAP(this.user.sub);
    await this.getNotificationAC(this.user.sub);
    await this.getNotificationCXP(this.user.sub);
    await this.getNotificationCP(this.user.sub);
    await this.getNotificationCA(this.user.sub);
    this.url = environment.Global.API_GET_ALL_PO_BY_USR + this.userType + '/' + this.user.sub;
    // console.log("Purchase Orders URL:",this.url);
    this.srv.get(this.url).subscribe(x => {
      // console.log("data:", x);
      if (x.Status) {
        if (x.Object != null) {
          this.rawdata = x.Object;
          this.rows = x.Object;
          this.page = 1;
          this.rows.forEach(element => {
            element.GrossWeightOrder = Number(element.GrossWeightOrder);
          });
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.closeMessageDialog();
        }
      }
    })
  }

  public openCreateModal(content) {
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public searchData(event) {
    this.search = event;
    this.filterAll();
  }
  public openUpdateModal(modal, object) {
    this.isUpdating = false;
    this.u_obj = JSON.parse(JSON.stringify(object));
     console.log("Objeto:", this.u_obj);
    this.u_obj['UpdateContainerByUser'] = [];
    // console.log("Type:", this.u_obj);
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    this.getContainerNumber(this.u_obj['OrderId']);
  }

 public async getContainerNumber(orderId:number) {
    this.url = environment.Global.API_GET_CONTAINER_NUMBER_BY_ORDERID + orderId;
    console.log(this.url)
    // console.log(this.url)
    await this.srv.get(this.url).toPromise().then(result => {
      if (result.Status) {

        this.ContainerNumber = result.Object
        console.log("Contenedores:",this.ContainerNumber)
      }
    })
  }

  updateCAS(modal,object){
    this.isUpdating = true;
    this.obj =  {
      CasupdateDate: object.CasupdateDate,
      orderNumberContainer:object.ContainerNumber
    };
    this.url = environment.Global.GET_UPDATE_CAS_POLD;
    console.log(this.obj)
    Swal.fire(
      {
        title: this.translations.MessageConfirmTitle,
        text: this.translations.TextConfirm,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.Accept,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
      }
    ).then((result) => {
      if (result.isConfirmed) {
        this.messageShowLoading();
   

        this.srv.post(this.url, this.obj).toPromise().then(result => {
          if (result.Status) {
            this.messageDialog(result.Message, '', 'success');
            this.closeModal(modal);
            this.getPurchaseOrders();
          } else {
            this.messageDialog(result.Message, '', 'error');
          }
          this.isUpdating = false;
          
        }, err => {
          this.messageDialog(err, '', 'error');
          this.isUpdating = false;
        })


      } else {
        this.isUpdating = false;
      }
    })
    // if (object.ContainerNumber.length > 0) {
    //   object.ContainerNumber.forEach(element => {
    //     let data = {
    //       UserId: Number(element)
    //     }
    //     users.push(data)
    //   });
    // }
  }

}
