import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserCx } from 'src/app/interfaces/User';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs-compat';

export interface Notificacion {
    tipo_noti: number,
    total: string
}

@Component({
    selector: 'app-base-crud',
    templateUrl: './base-crud.component.html',
    styleUrls: ['./base-crud.component.css']
})

export class BaseCrudComponent implements OnInit {
    public create_url: any;
    public read_url: any;
    public update_url: any;
    public delete_url: any;
    public updateStatus_url: any;
    public rows: any;
    public rawdata: any;
    public regularForm: FormGroup;
    public SEPARADOR_MILES = ",";
    public SEPARADOR_DECIMALES = ".";
    public SEPARADOR_MILLONES = "'";
    public entero: any;
    public decimales: any;
    // public permissionListGlobal: any;
    public notificacionesAP = [];
    public notificacionesAC = [];
    public notificacionesCXP = [];
    public notificacionesCP = [];
    public notificacionesCA = [];
    public countAP = "0";
    public countAC = "0";
    public countCXP = "0";
    public countCP = "0";
    public countCA = "0";


    public Group = [
        { GroupId: "0", Name: "NotAssigned" },
        { GroupId: "1", Name: "Imports" },
        { GroupId: "2", Name: "Logistics" },
        { GroupId: "3", Name: "Finance" },
    ];
    public TypeDocumentAdditional = [
        { Id: "1", Name: "SRIFile" },
        { Id: "2", Name: "ExemptionReport" },
    ];

    public PageSizeSelect = [
        { Id: "20", Name: "20" },
        { Id: "30", Name: "30" },
        { Id: "50", Name: "50" },
    ];

    public c_obj: any;
    public u_obj: any;
    public obj: any;

    public isGetting = false;
    public isCreating = false;
    public isUpdating = false;
    public isLoading = false;
    public isInterrupt = false;
    public isUploadFile = false;

    page: any;
    page2: any;
    pageSize: any;
    pageSize2: any;
    pageTotal: any;
    pageTotal2: any;

    search: any;
    search2: any;
    orderKey: any;
    orderDesc: any;
    search_fields: any;

    f1_key: string;
    f2_key: string;
    f3_key: string;

    f1_val: string;
    f2_val: string;
    f3_val: string;

    translations: any;
    url: string = null;

    public isOffline: boolean;
    public buttonCreateVisible: boolean = true;
    public user: UserCx = null;

    public currentUserName: string;
    public currentCountry: string;
    // public currentPosition:string;
    // public currentDepartment:string;
    public permissionList = [];
    public user$: Observable<UserCx>;
    public NotificationObserverAP$: Observable<Notificacion>
    public NotificationObserverAC$: Observable<Notificacion>
    public NotificationObserverCXP$: Observable<Notificacion>
    public NotificationObserverCP$: Observable<Notificacion>
    public NotificationObserverCA$: Observable<Notificacion>


    constructor(
        public srv: ApiService,
        public modalService: NgbModal,
        public storage: Storage,
        public router: Router,
        public translate?: TranslateService
    ) {
        this.NotificationObserverAP$ = srv.NotificationObserverAP;
        this.NotificationObserverAC$ = srv.NotificationObserverAC;
        this.NotificationObserverCXP$ = srv.NotificationObserverCXP;
        this.NotificationObserverCP$ = srv.NotificationObserverCP;
        this.NotificationObserverCA$ = srv.NotificationObserverCA;

        this.rows = [];
        // this.permissionListGlobal = [];
        this.rawdata = [];
        this.create_url = '';
        this.read_url = '';
        this.update_url = '';
        this.delete_url = '';

        //pagination
        this.page = 1;
        this.page2 = 1;
        this.pageTotal = 10;
        this.pageTotal2 = 10;
        this.pageSize = 20;
        this.pageSize2 = 20;

        //manipulation objects
        this.c_obj = {};
        this.u_obj = {};
        this.obj = {};

        this.search = '';
        this.orderKey = null;
        this.orderDesc = true;
        //should reference  only to string fields
        this.search_fields = [];
        this.f1_key = '';
        this.f2_key = '';
        this.f3_key = '';
        this.f1_val = '';
        this.f2_val = '';
        this.f3_val = '';


        this.isOffline = false;

    }

    async ngOnInit() {
        // if( navigator.onLine ){
        //     var _obj = this;
        //     this.srv.isReachable().then(function(online) {
        //         if (online) {
        //           _obj.srv.offline.next(false);
        //         } else {
        //           _obj.srv.offline.next(true);
        //         }
        //     });
        // }
        // else{
        //     this.isOffline = true;
        // }
        await this.translate.getTranslation(this.translate.currentLang)
            .toPromise().then((translations) => {
                this.translations = translations;
            });
        await this.srv.getUserLogged();
       
    }



    public clearData() {
        this.rows = [];
        this.c_obj = {};
        this.u_obj = {};
        this.obj = {};
    }

    public closeModal(content: any) {
        this.modalService.dismissAll(content);
    }

    public goTo(url, params) {
        this.router.navigate([url], { queryParams: { state: params } });
    }

    public filterAll() {
        var fields = this.search_fields;
        var resp: boolean;
        var dummy: any;
        if (!Array.isArray(fields)) {
            return;
        }
        if (this.search == '') {
            this.rows = this.rawdata;
        }
        else {
            if (this.rows.length == 0) {
                this.rows = this.rawdata;
            }
            let test = this.rows.filter(s => {
                resp = false;
                fields.forEach(f => {
                    if (f.indexOf('array.') > -1) {
                        let parts = f.split('.');
                        let f1 = parts[0];
                        let f2 = parts[1];
                        if ((s[f2] !== null) && (s[f2] !== undefined)) {
                            resp = resp || this.inArray(this.search, s[f2]);
                        }
                    }
                    else if ((s[f] !== null) && (s[f] !== undefined)) {
                        resp = resp || String(s[f]).toLowerCase().includes(this.search.toLowerCase());
                    }

                });
                return resp;
            });
            this.rows = test;
        }
        if (this.f1_val != '') {
            dummy = this.rows.filter(s => {
                return s[this.f1_key] == this.f1_val;
            });
            this.rows = dummy;
        }
        if (this.f2_val != '') {
            dummy = this.rows.filter(s => {
                return s[this.f2_key] == this.f2_val;
            });
            this.rows = dummy;
        }
        if (this.f3_val != '') {
            dummy = this.rows.filter(s => {
                return s[this.f3_key] == this.f3_val;
            });
            this.rows = dummy;
        }
        this.pageTotal = this.rows.length;
    }

    public filterAllSelect() {
        debugger
        let copyRows = this.rows.slice();
        let array = [];
        let flag = this.search2.filter(element => element != undefined);//null
        if (flag.length > 0) {
            copyRows.forEach(element => {
                let flag = true;
                this.search2.forEach(filter => {
                    // console.log(filter)
                    let filters = filter != null && filter.split('|'); debugger;//
                    if (filters[1] == "null") {
                        filters[1] = null;
                    }//else if (filters[1] == ""){
                    //return  this.rows = this.rawdata;
                    // }

                    if (element[filters[0]] != filters[1]) {
                        flag = false;
                    }

                });
                if (flag) {
                    array.push(element);
                }
            });
            this.rows = array;
        } else {
            this.rows = this.rawdata;
        }
        this.pageTotal = this.rows.length;
    }

    public inArray(str: any, arr: any) {
        let resp = false;

        arr.forEach(x => {
            x = x + '';

            if (x.indexOf(str) > -1) {
                resp = true;
            }
        });

        return resp;
    }

    public setMaskNumber(valor: any) {

        this.entero = "";
        this.decimales = "";
        this.entero = Number(valor);
        var position = valor.toString().indexOf('.');
        if (position >= 0) {
            this.decimales = valor.toString().slice(position, valor.toString().length);
            this.entero = valor.toString().slice(0, position);
            // console.log("Decimales:", this.decimales);
            // console.log("Entero", valor.toString().slice(0, position));

        } else {
            this.decimales = undefined;
            // console.log("Entero", this.entero.toString().slice(0, this.entero.toString().length));
            this.entero = this.entero.toString().slice(0, this.entero.toString().length);
        }
        // NUMEROS NEGATIVOS
        var negative = this.entero.toString().indexOf('-');
        if (negative < 0) {
            this.entero = this.entero.toString().slice(0, this.entero.toString().length);
        } else {
            this.entero = this.entero.toString().slice(negative + 1, this.entero.toString().length);
        }

        switch (this.entero.toString().length) {

            case 1:

                valor = this.entero;
                break
            case 2:

                valor = this.entero;
                break
            case 3:
                valor = this.entero;
                break
            case 4:

                // console.log("ENTERO:", this.entero.toString());
                this.entero = this.entero.toString().slice(0, 1) + this.SEPARADOR_MILES + this.entero.toString().slice(1, this.entero.toString().length);;

                break
            case 5:
                this.entero = this.entero.toString().slice(0, 2) + this.SEPARADOR_MILES + this.entero.toString().slice(2, this.entero.toString().length);
                // console.log(this.entero)
                break
            case 6:
                // console.log("ENTERO:", this.entero.toString());
                this.entero = this.entero.toString().slice(0, 3) + this.SEPARADOR_MILES + this.entero.toString().slice(3, this.entero.toString().length);
                break

            case 7:

                this.entero = this.entero.toString().slice(0, 1) + this.SEPARADOR_MILLONES + this.entero.toString().slice(1, 4) + this.SEPARADOR_MILES + this.entero.toString().slice(4, this.entero.toString().length);
                break

            case 8:
                // console.log("ENTERO:", this.entero.toString());
                this.entero = this.entero.toString().slice(0, 2) + this.SEPARADOR_MILLONES + this.entero.toString().slice(2, 5) + this.SEPARADOR_MILES + this.entero.toString().slice(5, this.entero.toString().length);
                break
            case 9:
                // console.log("ENTERO:", this.entero.toString());
                this.entero = this.entero.toString().slice(0, 3) + this.SEPARADOR_MILLONES + this.entero.toString().slice(3, 6) + this.SEPARADOR_MILES + this.entero.toString().slice(6, this.entero.toString().length);
                break
        }
        if (negative >= 0) {
            this.entero = "-" + this.entero;
        }
        if (this.decimales != undefined) {
            this.entero = this.entero + this.SEPARADOR_DECIMALES + this.decimales.toString().slice(1, 5);
        }



        return this.entero;
    }

    public onKeyUpSearch(ev: any) {
        var str = ev.target.value;
        if ((str == '') && (this.rows.length != this.rawdata.length)) {
            //console.log('works');
            this.rows = this.rawdata;
            this.pageTotal = this.rows.length;
        }
    }

    public setOrder(str: any) {
        this.orderKey = str;
        this.orderDesc = !this.orderDesc;
    }

    public confirmDialogWithModal(title, text, icon, content) {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.closeModal(content)
                //   this.getList();
            }
        })
    }

    public confirmDialog(title, text, icon) {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                //   this.getList();
            }
        })
    }

    public messageDialog(title, text, icon) {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                Swal.close()
                if (icon != "error") {
                    this.getInitialData();
                }
            }
        })
    }

    public messageShowLoading() {
        Swal.fire(
            {
                title: this.translations['Loading'],
                html: '',
                icon: 'info',
                allowOutsideClick: false
            }
        );
        Swal.showLoading();
        this.isLoading = true;
    }

    public closeMessageDialog() {
        this.isLoading = false;
        Swal.close();
    }

    public getInitialData() {

    }

    public errorFormMessage() {
        Swal.fire(
            {
                title: this.translations['enter_all_fields_required'],
                html: '',
                icon: 'error',
                allowOutsideClick: false
            }
        );
    }

    public errorMessage(title: string, body: string, icon: any) {
        Swal.fire(
            {
                title: this.translations[title],
                html: this.translations[body],
                icon: icon,
                allowOutsideClick: false
            }
        );
    }

    public errorMessageWithoutHtml(title: string, body: string, icon: any) {
        Swal.fire(
            {
                title: this.translations[title],
                text: body,
                icon: icon,
                allowOutsideClick: false
            }
        );
    }

    public onlyNumberKey(event) {
        return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
    }

    public containPermission(permissionList, permission: string) {
        let flag = false;
        setTimeout(() => {
            flag = permissionList.includes(permission);

        }, 2000);
        return flag;
    }

    // DOCUMENTOS POR APROBAR
    public async getNotificationAP(user_sub) {
        this.url = environment.Global.GET_NOTIFICATION + user_sub + "/1/P";

        // console.log("DOCUMENTOS POR APROBAR", this.url);

        await this.srv.get(this.url).toPromise().then(result => {
            if (result.Status) {
                if (result.Object != null) {
                    if (result.Object.length > 20) {
                        // this._countAPObservable.next("+20");
                        this.countAP = "+20";
                        this.srv.NotificationObserverAPData = { tipo_noti: 1, total: this.countAP };
                    } else {
                        // this._countAPObservable.next(result.Object.length);
                        this.countAP = result.Object.length;
                        this.srv.NotificationObserverAPData = { tipo_noti: 1, total: result.Object.length };
                        // console.log(this.countAP);
                    }
                    this.notificacionesAP = result.Object;
                }
            }
        })
        // console.log(this.notificacionesAP);
    }

    // DOCUMENTOS COMPLETADOS
    public async getNotificationAC(user_sub) {
        this.url = environment.Global.GET_NOTIFICATION + user_sub + "/2/C";
        // console.log("Documentos completados:", this.url)
        await this.srv.get(this.url).toPromise().then(result => {
            if (result.Status) {
                if (result.Object != null) {
                    if (result.Object.length > 20) {
                        this.countAC = "+20";
                        this.srv.NotificationObserverACData = { tipo_noti: 1, total: this.countAC};

                    } else {
                        this.countAC = result.Object.length;
                        this.srv.NotificationObserverACData = { tipo_noti: 1, total: result.Object.length };
                    }
                    this.notificacionesAC = result.Object;
                }
            }
        })
        // console.log(this.notificacionesAP);
    }

    // NOTIFICACIONES PARA CONTENTENDORES PENDIENTE DE PLANIFICAR.
    // solo para los usuarios de logíssticas 
    public async getNotificationCXP(user_sub) {

        this.url = environment.Global.GET_NOTIFICATION_CONTAINER_PENDING_PLANNING + user_sub + "/3/A";
        // console.log("Contenedores pendientes de planificar", this.url)
        await this.srv.get(this.url).toPromise().then(result => {
            if (result.Status) {
                if (result.Object != null) {
                    if (result.Object.length > 20) {
                        this.countCXP = "+20";
                        this.srv.NotificationObserverCXPData = { tipo_noti: 1, total: this.countCXP };
                    } else {
                        this.countCXP = result.Object.length;
                        this.srv.NotificationObserverCXPData = { tipo_noti: 1, total: result.Object.length };
                    }
                    this.notificacionesCXP = result.Object;
                }
            }
        })
        // console.log(this.notificacionesAP);
    }

    // CONTENEDORES PENDIENTES
    public async getNotificationCP(user_sub) {

        this.url = environment.Global.GET_NOTIFICATION_PLANNED_CONTAINERS + user_sub + "/4";
        // console.log("Contenedores pendientes", this.url)
        await this.srv.get(this.url).toPromise().then(result => {
            // console.log(result);
            if (result.Status) {
                if (result.Object != null) {
                    if (result.Object.length > 20) {
                        this.countCP = "+20";
                        this.srv.NotificationObserverCPData = { tipo_noti: 1, total: this.countCP };
                    } else {
                        this.countCP = result.Object.length;
                        this.srv.NotificationObserverCPData = { tipo_noti: 1, total: result.Object.length };
                    }
                    this.notificacionesCP = result.Object;
                }
            }
        })
        // console.log(this.notificacionesCP);
    }
    // CONTENEDORES POR ASIGNAR
    public async getNotificationCA(user_sub) {

        this.url = environment.Global.GET_NOTIFICATION_CONTAINERS_TO_ASSIGN + user_sub + "/5";
        // console.log("Contenedores pendientes de asignar", this.url)
        await this.srv.get(this.url).toPromise().then(result => {
            // console.log(result)
            if (result.Status) {
                if (result.Object != null) {
                    if (result.Object.length > 20) {
                        this.countCA = "+20";
                        this.srv.NotificationObserverCAData = { tipo_noti: 1, total: this.countCA };
                    } else {
                        this.countCA = result.Object.length;
                        this.srv.NotificationObserverCAData = { tipo_noti: 1, total: this.countCA };
                    }
                    this.notificacionesCA = result.Object;
                }
            }
        })
        // console.log(this.notificacionesAP);
    }

}
