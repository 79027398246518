<div class="container">
    <div class="row justify-content-center">

        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init">
        </div>
        <div class="col-md-3 col-sm-12">
            <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_end">
        </div>
        <div class="col-md-3 col-sm-12">

            <button [ladda]="isFind" type="button" (click)="dataToFind()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'search_filters' | translate}}</button>
            <button [ladda]="isClean" type="button" (click)="clearFilters()" *ngIf="option_processes>0"
                class="btn btn-custom-84888b u-button-find">{{'clean' | translate}}</button>
        </div>
    </div>
</div>

<!-- *ngIf="this.rows.length > 0" -->

<div class="row m-t-10">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <!-- <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class="far fa-file-pdf"></i>
                                {{'download_report' |
                                translate}}</button> -->
                            <button type="button" class="btn btn-custom-84888b m-t-10" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"> <i class="far fa-file-excel"></i>
                                {{'download_report_excel' |
                                translate}}</button>

                        </div>

                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>

        <!-- Payments (3) -->
        <div class="text-center" *ngIf="option_processes == 3 && rows.length > 0">
            <table class="table table-responsive">
                <thead class="thead-color text-white" #thead>
                    <tr>
                        <th>
                            <label class="text-nowrap" name="Filter" title="{{'NoFilter' | translate}}"><i
                                    class="icon mdi mdi-filter-remove" *ngIf="showIconF"
                                    (click)="showIconFilter(false)"></i></label>
                            <label class="text-nowrap" name="Filter" title="{{'Filter' | translate}}"><i
                                    class="icon mdi mdi-filter" *ngIf="!showIconF"
                                    (click)="showIconFilter(true)"></i></label>
                        </th>
                       
                        <th>
                            ID
                        </th>
                        <th>
                            {{'Agent' | translate}}
                        </th>
                        <th>
                            {{'ResponsableAgent' | translate}}
                        </th>
                        <th>
                            {{'UserResponsableSk' | translate}}
                        </th>
                        <th>
                            {{'Category' | translate}}
                        </th>

                        <th>
                            {{'OrderNumber' | translate}}>
                        </th>
                        <th>
                            {{'Division' | translate}}
                        </th>

                        <th>
                            {{'dispatch' | translate}}
                        </th>
                        <th>
                            {{'ItemNumber' | translate}}
                        </th>
                        <th>
                            {{'ItemName' | translate}}
                        </th>

                        <th>
                            {{'Invoice' | translate}}
                        </th>

                        <th>
                            {{'EmissionDate' | translate}}
                        </th>
                        <th>
                            {{'Amount' | translate}}
                        </th>

                        <th>
                            {{'AmountLine' | translate}}
                        </th>
                        <th>
                            {{'PaymentTerm' | translate}}
                        </th>

                        <th>
                            {{'Supplier' | translate}}
                        </th>

                        <th>
                            {{'Concept' | translate}}
                        </th>

                        <th>
                            {{'DetailSubconcept' | translate}}
                        </th>

                        <th>
                            {{'FoundMacobsa' | translate}}
                        </th>
                        <th>
                            {{'NotificationDate2' | translate}}
                        </th>
                        <th>
                            {{'ApplicationDate' | translate}}
                        </th>
                        <th>
                            {{'ApprovalDate' | translate}}
                        </th>
                        <th>
                            {{'PaymentDate' | translate}}
                        </th>
                        <th>
                            {{'DateIssueInvoiceVSDateNotification' | translate}}
                        </th>
                        <th>
                            {{'NotificationDateVSApplicationDate' | translate}}
                        </th>
                        <th>
                            {{'ApplicationDateVSApprovalDate' | translate}}
                        </th>
                       
                        <th>
                            {{'ApprovalDateVSPaymentDate' | translate}}
                        </th>
                        <th>
                            {{'submenu_skretting_observations' | translate}}
                        </th>
                        <th>
                            {{'IVA' | translate}}
                        </th>
                        <th>
                            {{'SUBTOTAL' | translate}}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="showIconF">
                        <td></td>
                        <td></td>

                        <td>
                            <div (click)="resetData(26,'AgentName')">
                                <app-select-filter [column]="'AgentName'" [arrayList]="l[26]" [indexPosition]="26"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(27,'AgentUsers')">
                                <app-select-filter [column]="'AgentUsers'" [arrayList]="l[27]" [indexPosition]="27"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(28,'UserResponsableSk')">
                                <app-select-filter [column]="'UserResponsableSk'" [arrayList]="l[28]" [indexPosition]="2"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(29,'TypeProductName')">
                                <app-select-filter [column]="'TypeProductName'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(7,'OrderNumber')">
                                <app-select-filter [column]="'OrderNumber'" [arrayList]="l[7]" [indexPosition]="7"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(3,'Div')">
                                <app-select-filter [column]="'Div'" [arrayList]="l[3]" [indexPosition]="3"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(8,'Dispatch')">
                                <app-select-filter [column]="'Dispatch'" [arrayList]="l[8]" [indexPosition]="8"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(31,'ItemNumber')">
                                <app-select-filter [column]="'ItemNumber'" [arrayList]="l[31]" [indexPosition]="31"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(17,'ItemName')">
                                <app-select-filter [column]="'ItemName'" [arrayList]="l[17]" [indexPosition]="17"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(12,'InvoiceNumber')">
                                <app-select-filter [column]="'InvoiceNumber'" [arrayList]="l[12]" [indexPosition]="12"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(5,'IssueDate')">
                                <app-select-filter [column]="'IssueDate'" [arrayList]="l[5]" [indexPosition]="5"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(6,'Amount')">
                                <app-select-filter [column]="'Amount'" [arrayList]="l[6]" [indexPosition]="6"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(11,'AmountLine')">
                                <app-select-filter [column]="'AmountLine'" [arrayList]="l[11]" [indexPosition]="11"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(32,'PaymentTerm')">
                                <app-select-filter [column]="'PaymentTerm'" [arrayList]="l[32]" [indexPosition]="32"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(1,'Supplier')">
                                <app-select-filter [column]="'Supplier'" [arrayList]="l[1]" [indexPosition]="1"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div (click)="resetData(4,'SubConcept')">
                                <app-select-filter [column]="'SubConcept'" [arrayList]="l[4]" [indexPosition]="4"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <div (click)="resetData(13,'AdmissionDate')">
                                <app-select-filter [column]="'AdmissionDate'" [arrayList]="l[13]" [indexPosition]="13"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(25,'ReviewDate')">
                                <app-select-filter [column]="'ReviewDate'" [arrayList]="l[25]" [indexPosition]="25"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(19,'ApprovalDate')">
                                <app-select-filter [column]="'ApprovalDate'" [arrayList]="l[19]" [indexPosition]="19"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>
                        <td>
                            <div (click)="resetData(14,'DepartureDate')">
                                <app-select-filter [column]="'DepartureDate'" [arrayList]="l[14]" [indexPosition]="14"
                                    (filterValue)="searchDataSelect($event)"></app-select-filter>
                            </div>
                        </td>

                    </tr>
                    <ng-container
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                        <tr>
                            <td>
                            </td>
                            <td>{{i+1}}</td>
                            <td><label for="AgentName">{{row.AgentName}} </label></td>
                            <td><label for="AgentUsers">{{row.AgentUsers}} </label></td>
                            <td><label for="UserResponsableSk">{{row.UserResponsableSk}} </label></td>
                            <td><label for="TypeProductName">{{row.TypeProductName}} </label></td>
                            <td><label for="OrderNumber">{{row.OrderNumber}} </label></td>
                            <td><label for="Div">{{row.Div}} </label></td>
                            <td><label for="Dispatch">{{row.Dispatch}} </label></td>
                            <td><label for="ItemNumber">{{row.ItemNumber}} </label></td>
                            <td><label for="ItemName">{{row.ItemName}} </label></td>
                            <td><label for="InvoiceNumber">{{row.InvoiceNumber}} </label></td>
                            <td><label for="IssueDate">{{row.IssueDate.split('T')[0]}} </label></td>
                            <td><label for="Amount">{{row.Amount}} </label></td>
                            <td><label for="AmountLine">{{row.AmountLine}} </label></td>
                            <td><label for="PaymentTerm">{{row.PaymentTerm}} </label></td>
                            <td><label for="Supplier">{{row.Supplier}} </label></td>
                            <td>
                                <span [innerHTML]="getConceptName(row.SubConcept)"></span>
                            </td>
                            <td><label for="SubConcept">{{row.SubConcept}} </label></td>
                            <td><label for="">-</label></td>
                            <td><label for="AdmissionDate">{{row.AdmissionDate | date:'y-MM-dd'}}</label></td>
                            <td><label for="ReviewDate">{{row.ReviewDate | date:'y-MM-dd'}}</label></td>
                            <td><label for="ApprovalDate">{{row.ApprovalDate | date:'y-MM-dd'}}</label></td>
                            <td><label for="DepartureDate">{{row.DepartureDate | date:'y-MM-dd'}}</label></td>
                            <td><label for="F_EmisionFact_VS_F_Notificacion">{{row.F_EmisionFact_VS_F_Notificacion}}</label></td>
                            <td><label for="F_Notificacion_VS_F_Solicitud">{{row.F_Notificacion_VS_F_Solicitud}}</label></td>
                            <td><label for="F_Solicitud_VS_FAprobacion">{{row.F_Solicitud_VS_FAprobacion}}</label></td>
                            <td><label for="F_Aprobacion_VS_FPago">{{row.F_Aprobacion_VS_FPago}}</label></td>
                            <td><label for="">-</label></td>
                            <td><label for="">-</label></td>
                            <td><label for="">-</label></td>

                        </tr>

                    </ng-container>
                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pageTotal" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
        </div>



    </div>
</div>