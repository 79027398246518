<ng-container *ngIf="(u_obj | json) != ({} | json)">
  <!--  - {{'Status' | translate}}: {{u_obj['StatusName']}}-->
  <h4 class="second-color-text">{{'ContainerNumber' | translate}} : {{u_obj['ContainerNumber']}} </h4>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Detail' | translate}}</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-md-3 sk-ibox">
            <label>{{'Agent' | translate}}</label>
            <input type="text" class="form-control" name="Agent" [(ngModel)]="u_obj['Agent']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'ResponsableAgent' | translate}}</label>
            <input type="text" class="form-control" name="ResponsableAgent" [(ngModel)]="u_obj['NameAgenteAduana']"
              disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'EtaRequired' | translate}}</label>
            <input type="text" class="form-control" name="EtaRequired" [(ngModel)]="u_obj['EtaRequired']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'CAS' | translate}}</label>
            <input type="text" class="form-control" name="CAS" [(ngModel)]="u_obj['CAS']" disabled>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 sk-ibox">
            <label>{{'PortShipment' | translate}}</label>
            <input type="Text" class="form-control" name="PortShipment" [(ngModel)]="u_obj['PortShipment']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'UserResponsableSk' | translate}}</label>
            <!--Se cambio este nombre por orden de compras ya que no representa el valor actual del contrato-->
            <input type="text" class="form-control" name="NameResposabilitySK"
              [(ngModel)]="u_obj['NameResposabilitySK']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'Bl' | translate}}</label>
            <input type="text" class="form-control" name="Bl" [(ngModel)]="u_obj['Bl']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'Packaging' | translate}}</label>
            <input type="text" class="form-control" name="Packaging" [(ngModel)]="u_obj['PackagingName']" disabled>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 sk-ibox">
            <label>{{'SupplierCode' | translate}}</label>
            <input type="text" class="form-control" name="Supplier" [(ngModel)]="u_obj['Supplier']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'Supplier' | translate}}</label>
            <input type="text" class="form-control" name="CompanyName" [(ngModel)]="u_obj['CompanyName']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'ItemNumber' | translate}}</label>
            <input type="text" class="form-control" name="ItemNumber" [(ngModel)]="u_obj['ItemNumber']" disabled>
          </div>
          <div class="col-md-3 sk-ibox">
            <label>{{'ItemName' | translate}}</label>
            <input type="text" class="form-control" name="ItemName" [(ngModel)]="u_obj['ItemName']" disabled>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <ul ngbNav #navStatus="ngbNav" [(activeId)]="activeStatus" class="nav-tabs">
              <!-- Compras -->
              <li ngbNavItem="{{'oc' | translate}}">
                <a ngbNavLink class="nav-link" style="color: #c8102e;">{{'oc' | translate}}</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'EntryToPort' | translate}}</label>
                      <input type="date" class="form-control" name="DateDeparturePort"
                        [(ngModel)]="u_obj['DateDeparturePort']" (change)="VerifyApprove('DateDeparturePort')"
                        [disabled]="!VerifactePermissionUpdate('DateDeparturePort') ">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'HourDeparturePort' | translate}}</label>
                      <input type="time" class="form-control" name="HourDeparturePort"
                        [(ngModel)]="u_obj['HourDeparturePort']" (change)="VerifyApprove('HourDeparturePort')"
                        [disabled]="!VerifactePermissionUpdate('HourDeparturePort')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'TransportCompany' | translate}}</label>
                      <select [(ngModel)]="u_obj['TransportId']" class="form-control" name="TransportId"
                        (change)="VerifyApprove('TransportId')" [disabled]="!VerifactePermissionUpdate('TransportId')">
                        <option *ngFor="let origen of CompanyTrans" value="{{origen.TransportId}}">{{origen.Name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </ng-template>
              </li>
              <!--Logistica-->
              <li ngbNavItem="{{'Logistics' | translate}}">
                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Logistics' | translate}}</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WarehouseReception' | translate}}</label>
                      <select class="form-control" [(ngModel)]="u_obj['WareHouseId']" name="WareHouseId"
                        (change)="VerifyApprove('WareHouseId')" [disabled]="!VerifactePermissionUpdate('WareHouseId')">
                        <option *ngFor="let type of wareHouses" value="{{type.WarehouseId}}">{{type.WarehouseNumber}} -
                          {{type.Name}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DateDepartureReception' | translate}}</label>
                      <input type="date" class="form-control" name="DateDepartureReception"
                        [(ngModel)]="u_obj['DateDepartureReception']" (change)="VerifyApprove('DateDepartureReception')"
                        [disabled]="!VerifactePermissionUpdate('DateDepartureReception')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'HourDepartureReception' | translate}}</label>
                      <input type="time" class="form-control" name="HourDepartureReception"
                        [(ngModel)]="u_obj['HourDepartureReception']" (change)="VerifyApprove('HourDepartureReception')"
                        [disabled]="!VerifactePermissionUpdate('HourDepartureReception')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'RescheduleDate' | translate}}</label>
                      <input type="date" class="form-control" name="RescheduleDate"
                        [(ngModel)]="u_obj['RescheduleDate']" (change)="VerifyApprove('RescheduleDate')"
                        (blur)="selectedDateBefore()"
                        [disabled]="!VerifactePermissionUpdate('RescheduleDate')">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'RescheduleHour' | translate}}</label>
                      <input type="time" class="form-control" name="RescheduleHour"
                        [(ngModel)]="u_obj['RescheduleHour']" (change)="VerifyApprove('RescheduleHour')"
                        [disabled]="!VerifactePermissionUpdate('RescheduleHour')">
                    </div>
                  </div>
                </ng-template>
              </li>
               <!--Transportista-->
               <li ngbNavItem="{{'Transport' | translate}}">
                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Transport' | translate}}</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'RUC' | translate}}</label>
                      <input type="text" class="form-control" name="CarAssigned" [(ngModel)]="u_obj['RucTransport']"
                        (change)="VerifyApprove('RucTransport')"
                        [disabled]="!VerifactePermissionUpdate('RucTransport')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'TransportAssign' | translate}}</label>
                      <input type="text" class="form-control" name="CarAssigned" [(ngModel)]="u_obj['CarAssigned']"
                        (change)="VerifyApprove('CarAssigned')" [disabled]="!VerifactePermissionUpdate('CarAssigned')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DriverName' | translate}}</label>
                      <input type="text" class="form-control" name="DriverName" [(ngModel)]="u_obj['DriverName']"
                        (change)="VerifyApprove('DriverName')" [disabled]="!VerifactePermissionUpdate('DriverName')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DriverIdentification' | translate}}</label>
                      <input type="text" class="form-control" name="HourDeparturePort"
                        [(ngModel)]="u_obj['DriverIdentification']" (change)="VerifyApprove('DriverIdentification')"
                        [disabled]="!VerifactePermissionUpdate('DriverIdentification')">
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Date' | translate}} / {{'Happens' | translate}}</label>
                      <input type="date" class="form-control" name="RescheduleHour" [(ngModel)]="u_obj['Date']"
                        (change)="VerifyApprove('Date')">
                      <!-- <input type="date" class="form-control" name="RescheduleHour" [(ngModel)]="u_obj['Date']"
                        (change)="VerifyApprove('Date')" [disabled]="!VerifactePermissionUpdate('Date')"> -->
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Hour' | translate}} / {{'Happens' | translate}}</label>
                      <!-- <input type="time" class="form-control" name="Hour" [(ngModel)]="u_obj['Hour']"
                        (change)="VerifyApprove('Hour')" [disabled]="!VerifactePermissionUpdate('Hour')"> -->
                      <input type="time" class="form-control" name="Hour" [(ngModel)]="u_obj['Hour']"
                        (change)="VerifyApprove('Hour')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Observation' | translate}}</label>
                      <select [(ngModel)]="u_obj['ObservationTransportId']" name="TransportId" class="form-control"
                        (change)="VerifyApprove('ObservationTransportId')"
                        [disabled]="!VerifactePermissionUpdate('ObservationTransportId')">
                        <option *ngFor="let transob of ObservationTrans" value="{{transob.ObservationId}}">
                          {{transob.Name}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Status' | translate}}</label>
                      <ng-select [(ngModel)]="u_obj['StatusTransportId']" name="DeparturePort" [multiple]="false"
                        (change)="VerifyApprove('StatusTransportId')"
                        [disabled]="!VerifactePermissionUpdate('StatusTransportId')">
                        <ng-option *ngFor="let option of StatusBodega" value="{{option.id}}">{{option.nombre}}</ng-option>
                      </ng-select>
                    </div>

                  </div>
                  <div class="row">
                    <!--<div class="col-md-3 sk-ibox">
                      <label>{{'Transit' | translate}}</label>
                      <input type="text" min="0" value="0" step=".01" class="form-control" name="Transit" [(ngModel)]="u_obj['Transit']" (change)="VerifyApprove('Transit')" [disabled]="!VerifactePermissionUpdate('Transit')">
                    </div>-->
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Pass' | translate}}</label>
                      <input type="text" min="0" value="0" step=".01" class="form-control" name="ObservationEmpty"
                        [(ngModel)]="u_obj['ObservationEmpty']" (change)="VerifyApprove('ObservationEmpty')"
                        [disabled]="!VerifactePermissionUpdate('ObservationEmpty')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightItemOutStorage' | translate}}</label>
                      <input type="number" min="0" value="0" class="form-control" name="WeightOutPutStorage"
                        [(ngModel)]="u_obj['WeightOutPutStorage']" (blur)="setWeightDifferenceMRNOutputPort()"
                        (change)="VerifyApprove('WeightOutPutStorage')"
                        [disabled]="!VerifactePermissionUpdate('WeightOutPutStorage')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DeparturePort' | translate}}</label>
                      <ng-select [(ngModel)]="u_obj['DeparturePort']" name="DeparturePort" [multiple]="false"
                        (change)="VerifyApprove('DeparturePort')"
                        [disabled]="!VerifactePermissionUpdate('DeparturePort')">
                        <ng-option *ngFor="let option of optionsDeparturePort" value="{{option}}">{{option}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </ng-template>
              </li>
              <!--Agente Aduana-->
              <li ngbNavItem="{{'Agent' | translate}}">
                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Agent' | translate}}</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'ContainerNumber' | translate}}</label>
                      <!-- <input type="text" class="form-control" name="ContainerNumber"
                        [(ngModel)]="u_obj['ContainerNumber']" (change)="VerifyApprove('ContainerNumber')"
                        [disabled]="!VerifactePermissionUpdate('ContainerNumber')"> -->

                      <!-- <ng-select [(ngModel)]="u_obj['ContainerNumber']" name="ContainerNumber" [multiple]="false" (change)="VerifyApprove('ContainerNumber')" [disabled]="!VerifactePermissionUpdate('ContainerNumber')"> -->
                      <ng-select [(ngModel)]="u_obj['ContainerNumber']" name="ContainerNumber" [multiple]="false" (change)="VerifyApprove('ContainerNumber')" [disabled]="!VerifactePermissionUpdate('ContainerNumber')">
                        <ng-option *ngFor="let option of ContainerNumber" value="{{option.ContainerNumber}}">
                          {{option.ContainerNumber}}</ng-option>
                      </ng-select>

                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Type' | translate}}</label>
                      <input type="text" class="form-control" name="Type" [(ngModel)]="u_obj['Type']"
                        (change)="VerifyApprove('Type')" (change)="VerifyApprove('Type')"
                        [disabled]="!VerifactePermissionUpdate('Type') ">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'StampBl' | translate}}</label>
                      <input type="text" class="form-control" name="BlSeal" [(ngModel)]="u_obj['BlSeal']"
                        (change)="VerifyApprove('BlSeal')" (change)="VerifyApprove('BlSeal')"
                        [disabled]="!VerifactePermissionUpdate('BlSeal')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightItem' | translate}}</label>
                      <input type="text" min="0" value="0" class="form-control" name="ProductWeight" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                        [(ngModel)]="u_obj['ProductWeight']" (blur)="setWeightDifferenceProductBl()"
                        (change)="VerifyApprove('ProductWeight')" (change)="VerifyApprove('ProductWeight')"
                        [disabled]="!VerifactePermissionUpdate('ProductWeight') ">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightItemBl' | translate}}</label>
                      <input type="text" min="0" value="0" class="form-control" name="BlWeight" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                        [(ngModel)]="u_obj['BlWeight']" (blur)="setWeightDifferenceProductBl()"
                        (change)="VerifyApprove('BlWeight')" [disabled]="!VerifactePermissionUpdate('BlWeight')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightTareBl' | translate}}</label>
                      <input type="text" min="0" value="0" class="form-control" name="TareBl" mask="separator.2" [thousandSeparator]="','" [decimalMarker]="'.'"
                        [(ngModel)]="u_obj['TareBl']" (blur)="setWeightBlTare()" (change)="VerifyApprove('TareBl')"
                        [disabled]="!VerifactePermissionUpdate('TareBl')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DifferenceProductBl' | translate}}</label>
                      <input type="number" class="form-control" name="TareBl"
                        [(ngModel)]="u_obj['WeightDifferenceProductBl']" disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'ObservationDifferenceProductBl' | translate}} </label>
                      <input type="text" class="form-control" name="TareBl"
                        [(ngModel)]="u_obj['ObservationDifferenceProductBl']" [disabled]="!VerifactePermissionUpdate('TareBl')">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DifferenceBlTare' | translate}}</label>
                      <input type="number" class="form-control" name="WeightBlTare" [(ngModel)]="u_obj['WeightBlTare']"
                        disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightItemInStorage' | translate}}</label>
                      <input type="number" min="0" value="0" class="form-control" name="WeightInStorage"
                        [(ngModel)]="u_obj['WeightInStorage']" (blur)="setWeightDifferenceBlTareStorage()"
                        (change)="VerifyApprove('WeightInStorage')"
                        [disabled]="!VerifactePermissionUpdate('WeightInStorage')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DifferenceBlTareStorage' | translate}}</label>
                      <input type="number" class="form-control" name="WeightDifferenceBlTareStorage"
                        [(ngModel)]="u_obj['WeightDifferenceBlTareStorage']" disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DifferenceMRNOutputPort' | translate}}</label>
                      <input type="number" class="form-control" name="WeightDifferenceMRNOutputPort"
                        [(ngModel)]="u_obj['WeightDifferenceMRNOutputPort']" disabled>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DifferenceBlvsInPort' | translate}}</label>
                      <input type="number" class="form-control" name="WeightDifferenceBlInPort"
                        [(ngModel)]="u_obj['WeightDifferenceBlInPort']" disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Observation' | translate}}</label>
                      <input type="text" class="form-control" name="ObservationDifferenceBlInPort"
                        [(ngModel)]="u_obj['ObservationDifferenceBlInPort']" disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'EntryToPort' | translate}}</label>
                      <!--<ng-select [(ngModel)]="u_obj['EntryToPort']" name="DeparturePort" [multiple]="false">
                        <ng-option *ngFor="let option of optionsDeparturePort" value="{{option}}">{{option}}</ng-option>
                      </ng-select>-->
                      <input type="date" class="form-control" name="HourInPlant" [(ngModel)]="u_obj['EntryToPort']"
                        (change)="VerifyApprove('EntryToPort')" [disabled]="!VerifactePermissionUpdate('EntryToPort')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'HourInPlant' | translate}}</label>
                      <input type="time" class="form-control" name="HourInPlant" [(ngModel)]="u_obj['HourInPlant']"
                        [disabled]="!VerifactePermissionUpdate('HourInPlant')">
                    </div>

                  </div>
                  <div class="row">
                    
                    
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Observation' | translate}}</label>
                      <select [(ngModel)]="u_obj['ObservationAgentId']" name="TransportId" class="form-control"
                        (change)="VerifyApprove('ObservationAgentId')"
                        [disabled]="!VerifactePermissionUpdate('ObservationAgentId')">
                        <option *ngFor="let aduanaob of ObservationAduana" value="{{aduanaob.ObservationId}}">
                          {{aduanaob.Name}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'CasupdateDate' | translate}}</label>
                      <input type="date" class="form-control" name="CasupdateDate" [(ngModel)]="u_obj['CasupdateDate']"
                        (change)="VerifyApprove('Date')">
                        <!-- [disabled]="!VerifactePermissionUpdate('CasupdateDate')" -->
                      <!-- <input type="date" class="form-control" name="RescheduleHour" [(ngModel)]="u_obj['Date']"
                        (change)="VerifyApprove('Date')" [disabled]="!VerifactePermissionUpdate('Date')"> -->
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Reason' | translate}}</label>
                      <ng-select [(ngModel)]="u_obj['Reason']" name="DeparturePort" [multiple]="false"
                        (change)="VerifyApprove('Reason')" [disabled]="!VerifactePermissionUpdate('Reason')">
                        <ng-option *ngFor="let option of Reason" value="{{option}}">{{option}}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'CommentaryOther' | translate}}</label>
                      <textarea type="text" class="form-control" name="ObservationWarehouse"
                        [(ngModel)]="u_obj['ObservationDetailAgent']" (change)="VerifyApprove('ObservationDetailAgent')"
                        [disabled]="!VerifactePermissionUpdate('ObservationDetailAgent')"></textarea>
                    </div>

                  </div>
                  <div class="row">
                    
                   
                  </div>
                </ng-template>
              </li>
             
              <!--Bodega-->
              <li ngbNavItem="{{'WarehouseName' | translate}}">
                <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'WarehouseName' | translate}}</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'InPlant' | translate}}</label>
                      <ng-select [(ngModel)]="u_obj['InPlant']" name="InPlant" [multiple]="false"
                        (change)="VerifyApprove('InPlant')" [disabled]="!VerifactePermissionUpdate('InPlant') ">
                        <ng-option *ngFor="let option of optionsInPlant" value="{{option}}">{{option}}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightInWarehouse' | translate}}</label>
                      <input type="number" class="form-control" name="WeightInWarehouse"
                        [(ngModel)]="u_obj['WeightInWarehouse']" (blur)="setWeightDifference()"
                        (change)="VerifyApprove('WeightInWarehouse')"
                        [disabled]="!VerifactePermissionUpdate('WeightInWarehouse')">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'WeightDifference' | translate}}</label>
                      <input type="number" class="form-control" name="WeightDifference"
                        [(ngModel)]="u_obj['WeightDifference']" disabled>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'StampWarehouse' | translate}}</label>
                      <input type="text" class="form-control" name="SealWarehouse" [(ngModel)]="u_obj['SealWarehouse']"
                        (blur)="setSealDifference()" (change)="VerifyApprove('SealWarehouse')"
                        [disabled]="!VerifactePermissionUpdate('SealWarehouse') ">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'StampDifferent' | translate}}</label>
                      <input type="text" class="form-control" name="SealDifference"
                        [(ngModel)]="u_obj['SealDifference']" disabled>
                      <!--(change)="VerifyApprove('SealDifference')"  [disabled]="!VerifactePermissionUpdate('SealDifference')"-->
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DateInWarehouse' | translate}}</label>
                      <input type="date" class="form-control" name="DateInWarehouse"
                        [(ngModel)]="u_obj['DateInWarehouse']" (change)="VerifyApprove('DateInWarehouse')"
                        [disabled]="!VerifactePermissionUpdate('DateInWarehouse') ">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'HourInWarehouse' | translate}}</label>
                      <input type="time" class="form-control" name="HourInWarehouse"
                        [(ngModel)]="u_obj['HourInWarehouse']" (change)="VerifyApprove('HourInWarehouse')"
                        [disabled]="!VerifactePermissionUpdate('HourInWarehouse') ">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DepartureDate' | translate}}</label>
                      <input type="date" class="form-control" name="DepartureDate" [(ngModel)]="u_obj['DepartureDate']"
                        (change)="VerifyApprove('DepartureDate')"
                        [disabled]="!VerifactePermissionUpdate('DepartureDate') ">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DepartureHour' | translate}}</label>
                      <input type="time" class="form-control" name="DepartureHour" [(ngModel)]="u_obj['DepartureHour']"
                        (blur)="setDownloadHour()" (change)="VerifyApprove('DepartureHour')"
                        [disabled]="!VerifactePermissionUpdate('DepartureHour') ">
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'DownloadTime' | translate}}</label>
                      <input type="text" class="form-control" name="DownloadHour" [(ngModel)]="u_obj['DownloadHour']"
                        disabled>
                    </div>
                    <!--<div class="col-md-3 sk-ibox">
                       <label>{{'StandBy' | translate}}</label>
                       <input type="text" class="form-control" name="StandBy" [(ngModel)]="u_obj['StandBy']"
                              (change)="VerifyApprove('StandBy')" [disabled]="!VerifactePermissionUpdate('StandBy')">
                     </div>-->
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Reason' | translate}} {{'Delay2' | translate}} </label>
                      <select [(ngModel)]="u_obj['ObservationWarehouseId']" name="TransportId" class="form-control"
                        (change)="VerifyApprove('ObservationWarehouseId')"
                        [disabled]="!VerifactePermissionUpdate('ObservationWarehouseId')">
                        <option *ngFor="let transob of ObservationWarehouse" value="{{transob.ObservationId}}">
                          {{transob.Name}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 sk-ibox">
                      <label>{{'Commentary' | translate}}</label>
                      <textarea type="text" class="form-control" name="ObservationWarehouse"
                        [(ngModel)]="u_obj['ObservationWareHouse']" (change)="VerifyApprove('ObservationWareHouse')"
                        [disabled]="!VerifactePermissionUpdate('ObservationWareHouse')"></textarea>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="navStatus" class="mt-2"></div>
          </div>
        </div>
        <div>
          <h5 class="second-color-text"> </h5>
        </div>
        <div class="row">
          <!--  <div class="col-md-2 sk-ibox">
               <button [ladda]="isChange" type="button" (click)="ChangeStatus()" class="form-control btn btn-custom-84888b">{{'update' | translate}}</button>
          </div>-->
          <div class="col-md-2 sk-ibox">
            <button [ladda]="isUpdating" type="button" (click)="UpdateData(u_obj)"
              class="form-control btn btn-custom-C8102E">{{'Update' | translate}}</button>
          </div>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="2" *ngIf="this.userType == '1' || this.userType == '3'">
      <a ngbNavLink class="nav-link" style="color: #C8102E;">{{'Log' | translate}}</a>
      <ng-template ngbNavContent>
        <div class="row" *ngIf="logList.length > 0">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-color">
                  <tr>
                    <th class="sh">
                      <label class="text-nowrap" for="icon">
                        {{'Detail' | translate}}
                      </label>
                    </th>
                    <th class="sh">
                      <label class="text-nowrap" name="ID">ID</label>
                    </th>
                    <th class="sh" class="text-nowrap">
                      <label class="text-nowrap" name="FieldName">{{'Field' | translate}}</label>
                    </th>
                    <th class="sh">
                      <label class="text-nowrap" name="QuantityChange">{{'QuantityChange' | translate}}</label>
                    </th>
                    <th class="sh">
                      <label class="text-nowrap" name="LastModification">{{'LastModification' | translate}}</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let log of logList | slice: (pageLog-1) * pageSizeLog : (pageLog-1) * pageSizeLog + pageSizeLog; index as i">
                    <tr>
                      <td>
                        <i style="cursor:pointer;" class="mdi mdi-eye-off text-dark" *ngIf="hiddenLog[i]"
                          (click)="hiddenLog[i] = !hiddenLog[i]"></i>
                        <i (click)="OpenDetail(i, log)" style="cursor:pointer;" class="mdi mdi-eye text-dark m-r-10"
                          title="{{'Detail' | translate}}" *ngIf="!hiddenLog[i]"></i>
                      </td>
                      <td>
                        <label for="code">{{i + 1}}</label>
                      </td>
                      <td>
                        <label for="FieldName">{{log.FieldName | translate}}</label>
                      </td>
                      <td>
                        <label for="TableFieldDetail">{{log.TableFieldDetail.length}}</label>
                      </td>
                      <td>
                        <label for="LastModification">{{log.TableFieldDetail[log.TableFieldDetail.length -
                          1].ModificationTime.split('T')[0]}}</label>
                      </td>
                    </tr>
                    <tr *ngIf="hiddenLog[i]">
                      <td colspan="5">
                        <div class="table-responsive">
                          <table class="table">
                            <thead class="thead-color-two">
                              <th class="sh">
                                <label class="text-nowrap" name="ID">ID</label>
                              </th>
                              <th class="sh" class="text-nowrap">
                                <label class="text-nowrap" name="user">{{'user' | translate}}</label>
                              </th>
                              <th class="sh" class="text-nowrap">
                                <label class="text-nowrap" name="PreviousValue">{{'PreviousValue' | translate}}</label>
                              </th>
                              <th class="sh" class="text-nowrap">
                                <label class="text-nowrap" name="CurrentValue">{{'CurrentValue' | translate}}</label>
                              </th>
                              <th class="sh" class="text-nowrap">
                                <label class="text-nowrap" name="UserCodeApproval">{{'UserCodeApproval' |
                                  translate}}</label>
                              </th>
                              <th class="sh" class="text-nowrap">
                                <label class="text-nowrap" name="ModificationTime">{{'ModificationTime' |
                                  translate}}</label>
                              </th>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let logDetail of log.TableFieldDetail | slice: (pageLogDetail-1) * pageSizeLogDetail : (pageLogDetail-1) * pageSizeLogDetail + pageSizeLogDetail; index as i">
                                <td class="sh">
                                  <label for="ID">{{i + 1}}</label>
                                </td>
                                <td class="sh">
                                  <label for="UserName">{{logDetail.UserName }}</label>
                                </td>
                                <td class="sh">
                                  <label for="PreviousValue">{{logDetail.PreviousValue }}</label>
                                </td>
                                <td class="sh">
                                  <label for="CurrentValue">{{logDetail.CurrentValue }}</label>
                                </td>
                                <td class="sh">
                                  <label for="FieldName">{{logDetail.UserNameApproval != null ?
                                    logDetail.UserNameApproval.UserName : "No se requirió aprobación" }}</label>
                                </td>
                                <td class="sh">
                                  <label for="FieldName">{{logDetail.ModificationTime.split('T')[0]}}</label>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <td colspan="6">
                                <ngb-pagination [collectionSize]="log.TableFieldDetail.length" [(page)]="pageLogDetail"
                                  [pageSize]="pageSizeLogDetail" [maxSize]="5" style="float: right;"></ngb-pagination>
                              </td>
                            </tfoot>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tfoot>
                  <td colspan="11">
                    <ngb-pagination [collectionSize]="pageTotalLog" [(page)]="pageLog" [pageSize]="pageSizeLog"
                      [maxSize]="5" style="float: right;"></ngb-pagination>
                  </td>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2">
  </div>
</ng-container>
