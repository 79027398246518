import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/authentication/auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService) {
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let resourcesLS = localStorage.getItem(environment.Global.key_user);
      if (!(resourcesLS == null) && !(resourcesLS == undefined)) {
        return true;
      } else {
        this.router.navigate(['authentication/login'], { replaceUrl: true });
        return false;
      }
  }
  
}
